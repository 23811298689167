<template>
  <div>
    <div class="legend-container" ref="legendContainer">
      <div class="legend">
        <img src="../../assets/images/Map/live_updated.svg?external" :alt="$t('fastChargingStationPinAltText')" />
        <span>{{ $t('fastChargingStation') }}</span>
      </div>
      <div class="legend">
        <img src="../../assets/images/Map/comingSoon_updated.svg?external" :alt="$t('comingSoonPinAltText')" />
        <span>{{ $t('comingSoon') }}</span>
      </div>
    </div>

    <transition class="modal" name="modal">
      <div class="modal__mask" v-if="isMobile && isOpen && useMobileModal">
        <div class="modal__container">
          <h2>Welcome to the nation’s largest public fast charging network</h2>
          <div class="modal__legend-container">
            <div class="modal__legend">
              <img src="../../assets/images/Map/live_updated.svg?external" alt="Live station" />
              <span>Fast charging</span>
            </div>
          </div>
          <button @click="closeModal" ref="closeModal" class="button modal__button">Find a station</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import handleBackgroundElements from '@/helpers/handleBackgroundElements';
import lang from '@/lang/LocateCharger';

export default {
  name: 'MobileModal',
  data() {
    return {
      isOpen: null,
      useMobileModal: false,
    };
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(950);
    },
  },
  methods: {
    closeModal() {
      this.isOpen = false;
      sessionStorage.setItem('locateCharger_ModalAcknowledged', true);
      handleBackgroundElements();
    },

    updateLegendContainer() {
      // animation for mobile legend container
      const { legendContainer } = this.$refs;
      let ticking = false;

      if (!ticking && legendContainer) {
        window.requestAnimationFrame(() => {
          if (legendContainer.scrollWidth <= legendContainer.offsetWidth) {
            legendContainer.classList.add('legend-container__no-scroll');
          } else {
            legendContainer.classList.remove('legend-container__no-scroll');

            const scrollEnd = legendContainer.scrollWidth - legendContainer.offsetWidth;
            if (legendContainer.scrollLeft < 5) {
              legendContainer.classList.remove('legend-container__scrolling', 'legend-container__scrolled');
            } else if (legendContainer.scrollLeft >= scrollEnd - 5) {
              legendContainer.classList.replace('legend-container__scrolling', 'legend-container__scrolled');
            } else {
              // eslint-disable-next-line no-lonely-if
              if (legendContainer.classList.contains('legend-container__scrolled')) {
                legendContainer.classList.replace('legend-container__scrolled', 'legend-container__scrolling');
              } else {
                legendContainer.classList.add('legend-container__scrolling');
              }
            }
          }
          ticking = false;
        });

        ticking = true;
      }
    },

    useFocusTrap() {
      const { closeModal } = this.$refs;
      if (this.isOpen) {
        closeModal?.focus();
        handleBackgroundElements();
      }
    },
  },
  mounted() {
    // Trigger a Legend update on load, resize, and scroll
    const { legendContainer } = this.$refs;
    window.addEventListener('resize', this.updateLegendContainer);
    legendContainer.addEventListener('scroll', this.updateLegendContainer);
    this.updateLegendContainer();

    if (sessionStorage.getItem('locateCharger_ModalAcknowledged')) {
      this.isOpen = false;
    } else {
      this.isOpen = true;
    }

    if (this.isMobile && this.isOpen) {
      this.useFocusTrap();

      window.addEventListener('keydown', (e) => {
        const { closeModal } = this.$refs;
        if (e.key === 'Tab' && this.isOpen) {
          if (e.shiftKey) {
            e.preventDefault();
            closeModal.focus();
          }
          e.preventDefault();
          closeModal.focus();
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.legend-container {
  align-items: center;
  background-color: #fbfbff;
  box-shadow: rgba(0, 0, 0, 0.35) -50px 0px 36px -28px inset;
  display: flex;
  font-size: 14px;
  font-weight: $fw-regular;
  max-width: 100%;
  overflow-x: auto;
  padding: 16px 0;
  transition: box-shadow 0.3s ease-in-out;
  z-index: 100;

  @media (min-width: 750px) {
    bottom: 0;
    box-shadow: none;
    left: 0;
    margin-left: 32px;
    width: 100%;
    position: absolute;
  }

  @media (min-width: 950px) {
    font-size: 16px;
    padding: 12px 0;
  }

  &__no-scroll {
    box-shadow: none !important;
  }
  &__scrolled {
    box-shadow: rgba(0, 0, 0, 0.35) 50px 0px 36px -28px inset;
  }
  &__scrolling {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 36px -28px inset;
  }

  .legend {
    align-items: center;
    display: flex;
    margin-right: 16px;
    white-space: nowrap;

    @media (min-width: 750px) {
      margin-right: 24px;
    }

    img {
      height: auto;
      width: 25px;

      @media (min-width: 750px) {
        width: 32px;
      }
    }

    span {
      margin-left: 8px;
    }
  }
}

.modal {
  &__button {
    margin-top: 0;
    width: 100%;
  }

  &__container {
    align-items: center;
    background-color: $c-primary-background;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 auto;
    margin-top: 126px;
    padding: 32px 24px 24px;
    transition: all 0.3s ease;
    width: 80%;

    h2 {
      font-size: 18px;
      font-weight: $fw-regular;
      line-height: 22px;
      letter-spacing: 0.36px;
      margin-bottom: 16px;
      margin-left: 8px;
      max-width: 280px;
      text-align: center;
    }
  }
  &__legend {
    align-items: center;
    display: flex;
    font-weight: $fw-regular;
    margin-bottom: 20px;
    margin-left: 8px;
    max-width: 250px;

    img {
      width: 32px;
      height: auto;
    }
    span {
      margin-left: 12px;
    }
    &--commercial {
      section {
        display: flex;
        flex-direction: column;
        span:last-of-type {
          font-size: 14px;
          font-weight: $fw-light;
          line-height: 18px;
        }
      }
    }
  }
  &__legend-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    transition: opacity 0.3s ease;
  }
}
</style>
