export default {
  asOfDate: 'As of December 14, 2021.',
  autoReloadDisclaimer:
    'To help ensure you can charge uninterrupted, your Electrify Canada account balance automatically reloads when it falls below $5. The first time you charge at an Electrify Canada station you’ll be billed the auto-reload amount you selected in the app, and the billed amount will be held in your account to cover costs not covered by this plan, such as Idle Fees and applicable taxes.',
  canadaEgolfExpansion: 'Electrify Canada Expansion network map coverage for illustrative purposes only.',
  charger: 'Charger',
  chargingAtHome: 'Charging at home just got easier',
  chargingStation: 'Charging station',
  chargingStations: 'Electrify Canada charging stations',
  chargingTimesMayVary:
    'Charging times may vary based on the available power supply, battery state of charge and temperature, among other factors.',
  childSleepingWithHeadphones: 'Child sleeping with headphones on during a car ride',
  closeUpOfApp: 'Electrify Canada App featured on mobile device',
  creditCard: 'Credit card',
  customerAssistance: 'Electrify Canada contactless pay',
  diagramShowing: 'The RFID symbol looks like a hand holding a phone with signal waves coming off the sides.',
  digitalCard: 'Smartphone showing a digital card',
  downloadInTheAppStore: 'Download on the App Store',
  downloadTheApp: 'Download the Electrify Canada app',
  electrifyAmerica: 'Electrify America',
  electrifyAmericaLabelOnCharger: 'Electrify America label on charger',
  etronEvPluggedinIn: 'Etron EV plugged in to charger',
  etronParked: 'Etron EV parked by an Electrify Canada charging station',
  getItOnGooglePlay: 'Get it on Google Play',
  handCloseUp: 'Close-up of a hand inserting a credit card into a card reader',
  handCloseUp2:
    'Close-up of a person putting a credit card into a card reader at an Electrify America charging station.',
  homeCharger: 'Home Charger',
  homeStationAlt: 'Electrify Canada HomeStation™',
  manCharging: 'Man charging an electric vehicle',
  map: 'Map',
  mapOfChargersInCanada: 'Map of EV chargers and planned charging stations in Canada',
  messagingAndDataRates:
    "Your mobile carrier's normal messaging, data, and other rates and fees will apply to your use of the Electrify Canada mobile application",
  plugPayCharge: 'Plug, pay, charge, and go.',
  plusFees: 'Plus applicable fees and taxes.',
  porscheAppUser: 'Porsche Taycan App user',
  porscheAtCharger: 'Porsche Taycan at Electrify Canada Charger',
  powerUpYourLiveWire: 'Alimentez votre LiveWire™ avec Electrify America',
  swipeToStart: 'the mobile app’s “Swipe to Start Charge” slider.',
  termsAndConditions: 'Promotion Terms and Conditions',
  twoStations: 'Two Electrify America charging stations',
  twoStationsGlowingGreen: 'Electrify Canada 2 charging stations at dusk',
  vehiclePluggedIn: 'Electric vehicle plugged in',
  vinLocation:
    "The VIN can usually be found on the driver's side dashboard, near or under the bottom portion of the windshield.",
  startingACharge: 'Electrify Canada starting a charge',
  womanUsingPhoneToPay: 'Person using app to start a charge at an Electrify Canada charging station',
  womenCharging: 'Women Charging',
  womenPaying: 'Women Paying',
  pieConeTreeImage: 'Evergreen branch with pine cone',
  cleaningTrash: 'People picking up garbage in a forest',
  plantingSeeds: 'two people planting a seedling',
  flower: 'flower illustration',
};
