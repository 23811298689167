<template>
  <article class="disclosure">
    <h1>e-tron Charging Plan Terms</h1>
    <h2>{{ $t('termsAndConditions') }}</h2>
    <p class="primary-color"><strong>Effective as of September 24th, 2019</strong></p>
    <p>
      By clicking “Agree” below, you agree to receive 30 minutes of complimentary charging per session for your newly
      purchased Audi e-tron on Electrify Canada’s network of electric vehicle charging stations, subject to these
      {{ $t('termsAndConditions') }}. There is no additional fee, purchase, or subscription required for your access to
      this promotion.
    </p>
    <p>
      After signing up, you can redeem this complimentary charging time for your Audi e-tron during your initiation of a
      charging session at any Electrify Canada charging station, by using the Electrify Canada app “Swipe to start” or
      NFC Pass feature with the appropriate plan selected. The first 30 minutes of the charging session will provided on
      a complimentary basis.
      <strong
        >Any charging time beyond the first 30 minutes of each session will be charged to your account at the charging
        station’s regular rate</strong
      >, identical to the Electrify Canada “Pass” membership rate, subject to your participation in other discounts or
      subscriptions separate from this promotion (if any).
    </p>
    <strong class="primary-color"
      >PLEASE NOTE THE FOLLOWING IMPORTANT LIMITATIONS ON YOUR USE OF THIS PROMOTION:</strong
    >
    <ul>
      <li>
        You may only use this complimentary charging time for the Audi e-tron vehicle, purchased from an authorized Audi
        dealer in Canada, whose VIN you submitted when signing up for this promotion on your Electrify Canada account.
        No other vehicle is eligible to receive this complimentary charging through your account, whether owned by you
        or others.
      </li>
      <li>Complimentary charging time does not include any parking or idle fees assessed at the charging station.</li>
      <li>
        You may not use this complimentary charging time in connection with any taxi, ride-share (e.g., Uber or Lyft),
        or other similar service, or for any other commercial purpose. This promotion is for your personal,
        non-commercial use only.
      </li>
      <li>
        You must follow all product, vehicle, safety, and technical documentation included with your Audi e-tron vehicle
        when charging at an Electrify Canada charging station.
      </li>
      <li>
        You must use the correct connector type and charge at the correct power level for your Audi e-tron at the
        charging station.
      </li>
    </ul>
    <p>
      This promotion will automatically end twenty-four (24) months after the date of your purchase of your Audi e-tron
      vehicle, after which you will not be able to access or use this promotion on your account. This complimentary
      charging time is not returnable or redeemable for any cash value, and may not be sold, loaned, or otherwise
      distributed to any third party for any reason.
    </p>
    <p>
      You acknowledge that your use of Electrify Canada’s services is subject to the Electrify Canada Terms of Use
      available at
      <br />
      <router-link class="link" :to="{ name: 'terms-en' }">https://www.electrify-canada.ca/terms</router-link>, and that
      your use of the Electrify Canada mobile app is subject to the Terms of Use accessible by navigating to the
      <strong>Profile</strong> icon in top right hand corner of map, selecting <strong>“Legal”</strong> and then
      selecting <strong>“Terms of Use”</strong> &amp; <strong>“Privacy Policy.”</strong> Please read these Terms of Use
      documents carefully for information about how you can pay through your account for charging session or idling time
      outside of the complimentary 30 minutes offered under this promotion.
    </p>
    <p>
      You agree to comply with all applicable laws and regulations when using Electrify Canada services, and when
      charging your vehicle at Electrify Canada charging stations.
    </p>
    <p>
      Electrify Canada reserves the right to withhold, revoke, reduce, terminate, or suspend your access to all or any
      portion of this promotion, without notice, if Electrify Canada determines or suspects, in its sole discretion,
      that you: (a) are in violation of the Electrify Canada Terms of Use, these {{ $t('termsAndConditions') }}, or any
      other contract between you and Electrify Canada; (b) have engaged in charging sessions that are excessive,
      fraudulent, or otherwise indicate an unauthorized or unintended use of this complimentary promotion; or (c) have
      engaged in any illegal, fraudulent, tortious, injurious, harmful, or abusive conduct in your use of the Electrify
      Canada services, the Electrify Canada mobile app, or an Electrify Canada charging station.
    </p>
    <p>
      Electrify Canada reserves the right to end this complimentary promotion at any time before its automatic
      termination date for any reason, in its sole discretion, with or without notice to you. Following the termination
      or expiration of this promotion for any reason, your access to the complimentary promotion will immediately cease,
      and you may not use or redeem any remaining portion of complimentary charging time. At that time, your account
      will automatically convert to a basic Electrify Canada
      <strong>“Pass”</strong> membership account, at no additional fee or charge to you (neither one-time nor
      recurring). You may, but are not obligated to, sign up for a different type of subscription via the Electrify
      Canada website or mobile app.
    </p>
  </article>
</template>
<script>
import lang from '@/lang';

export default {
  name: 'eTronTermsEn',
  metaInfo: {
    title: 'e-tron Terms',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/e-tron-charging-plan-terms/',
      },
    ],
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
};
</script>
