<template>
  <div class="getting-started">
    <Hero heading="Getting Started with Electrify Canada">
      <template slot="image">
        <picture>
          <source
            srcset="
              @/assets/images/GettingStarted/hero-desktop.webp,
              @/assets/images/GettingStarted/hero-desktop@2x.webp 2x
            "
            media="(min-width: 750px)"
          />
          <source srcset="@/assets/images/GettingStarted/hero-mobile.webp" />
          <img src="@/assets/images/GettingStarted/hero-mobile.webp" alt="" />
        </picture>
      </template>
    </Hero>
    <TwoColumnContentBlock heading="What is DC fast charging?" headlineDisplayClass="headline3" :maxWidth="1000">
      <p>
        <strong>Direct Current (DC) chargers</strong> quickly deliver power to your vehicle’s battery to cut down on
        charging times and get you back on the road. Often used for road trips and on-the-go charging, our DC fast
        chargers offer the fastest charging speeds available today with Hyper-Fast charging up to 350 kW for capable
        vehicles.
      </p>
      <p>
        Explore the network that pioneered the availability of Ultra-Fast (150 kW) and Hyper-Fast (350 kW) chargers.
      </p>
    </TwoColumnContentBlock>
    <h2 class="card-headline headline3">The basics</h2>
    <Card headlineLevel="h3" heading="How does it work?" :maxWidth="1000" headlineDisplayClass="headline4">
      <template #image>
        <picture>
          <source
            srcset="
              @/assets/images/GettingStarted/charging-station-infographic-desktop.webp,
              @/assets/images/GettingStarted/charging-station-infographic-desktop@2x.webp 2x
            "
            media="(min-width: 750px)"
          />
          <source
            srcset="
              @/assets/images/GettingStarted/charging-station-infographic-mobile.webp,
              @/assets/images/GettingStarted/charging-station-infographic-mobile@2x.webp 2x
            "
          />
          <img loading="lazy" src="@/assets/images/GettingStarted/charging-station-infographic-mobile.webp" alt="" />
        </picture>
      </template>

      <p>
        The Electrify Canada charging experience was created with you in mind. When you arrive at one of our
        <strong>charging stations</strong>, you’ll select a <strong>charger</strong>. After you plug the charger’s
        <strong>connector</strong> into your vehicle’s charging port, the charger will deliver power from the
        <strong>power cabinet</strong>.
      </p>
      <div class="tip">
        <StarIcon />
        <p>
          <strong>Tip:</strong> Though there are two connectors on select Electrify Canada chargers, only one connector
          can be used at a time.
        </p>
      </div>
    </Card>

    <Card
      headlineLevel="h3"
      heading="Which connector should I use?"
      :maxWidth="1000"
      headlineDisplayClass="headline4"
      :isReverse="!isPhone"
    >
      <template #image>
        <picture>
          <source
            srcset="
              @/assets/images/GettingStarted/connector-types-desktop.webp,
              @/assets/images/GettingStarted/connector-types-desktop@2x.webp 2x
            "
            media="(min-width: 750px)"
          />
          <source
            srcset="
              @/assets/images/GettingStarted/connector-types-mobile.webp,
              @/assets/images/GettingStarted/connector-types-mobile@2x.webp 2x
            "
          />
          <img loading="lazy" src="@/assets/images/GettingStarted/connector-types-mobile.webp" alt="" />
        </picture>
      </template>
      <p>
        Our DC fast chargers include two connector types: Combined Charging System (<strong>CCS</strong>) and
        <strong>CHAdeMO</strong>. Both connectors can be found at most Electrify Canada charging stations and identified
        by the teal, green, and blue labels on the chargers.
      </p>
      <p>
        Our CCS connectors can charge at speeds up to 350 kW, while CHAdeMO connectors can charge up to 50 kW. Your
        vehicle’s connector type and maximum charging speed are determined by your vehicle.
      </p>
      <div class="tip">
        <StarIcon />
        <p>
          <strong>Tip:</strong> Check with your vehicle’s manufacturer or owner’s manual to learn your electric
          vehicle’s charging capabilities.
        </p>
      </div>
    </Card>

    <div class="lede lede--last">
      <div class="column">
        <section class="l-one l-one--has-bg">
          <div class="l-one__col">
            <h2 class="headline3">What happens when I plug in?</h2>
            <p>
              First, your electric vehicle (EV) requests power (kW) from the charger. Then, the charger delivers power
              directly to your vehicle’s battery. The amount of power requested may be affected by temperature, battery
              health, and battery level.
            </p>
            <picture>
              <source
                srcset="
                  @/assets/images/GettingStarted/what-happens-desktop.webp,
                  @/assets/images/GettingStarted/what-happens-desktop@2x.webp 2x
                "
                media="(min-width: 750px)"
              />
              <source
                srcset="
                  @/assets/images/GettingStarted/what-happens-mobile.webp,
                  @/assets/images/GettingStarted/what-happens-mobile@2x.webp 2x
                "
              />
              <img
                loading="lazy"
                src="@/assets/images/GettingStarted/what-happens-mobile.webp"
                alt="Dialog bubble from car states 'I need 270 kW.' Dialog bubble from charging station states 'Coming right up!'"
              />
            </picture>
          </div>
        </section>
        <div class="l-one">
          <div class="l-one__col">
            <h3 class="headline4">What happens when I charge?</h3>
            <p>
              At the beginning of a charging session, most electric vehicles send an initial State of Charge (SOC) to
              the charger. SOC indicates how “full” your vehicle’s battery is and—if sent to our chargers—can be found
              on the charger’s screen or in the Electrify Canada app. If available, the SOC will be updated as your
              vehicle charges, though some EVs are not capable of sending our chargers this information.
            </p>
            <p>
              Toward the end of your session, you may notice charging speeds (kW) slow down. Many vehicles “bulk charge”
              and slow their charging speed when they reach about 80%*. Because of this, we recommend unplugging your
              vehicle after bulk charging has occurred. To receive updates about your SOC and other charging
              information,
              <router-link class="link medium" :to="{ name: `mobile-app-${$i18n.locale}` }">{{
                $t('downloadTheApp')
              }}</router-link
              >.
            </p>
          </div>
        </div>
        <section>
          <picture>
            <source
              srcset="@/assets/images/GettingStarted/charging-session-infograph-desktop.webp"
              media="(min-width: 750px)"
            />
            <source
              srcset="
                @/assets/images/GettingStarted/charging-session-infograph-mobile.webp,
                @/assets/images/GettingStarted/charging-session-infograph-mobile@2x.webp 2x
              "
            />
            <img
              loading="lazy"
              src="@/assets/images/GettingStarted/charging-session-infograph-mobile.webp"
              alt="Infograph depicting the energy delivered and charging speed increasing at the beginning of charge, reaching maximum delivery for the middle portion of the charge and slowing toward the end of the charge."
            />
          </picture>
          <p class="disclaimer">
            *Exact battery percentage may vary. Check with your vehicle’s manufacturer to learn when bulk charge occurs
            for your vehicle.
          </p>
        </section>
      </div>
    </div>
    <HeroWithContentOverlay :overlayBG="false" :darkBG="false" fullWidthMobile>
      <template #image>
        <picture>
          <source
            srcset="
              @/assets/images/WhatToExpect/charging-station-hyper-fast-desktop.webp,
              @/assets/images/WhatToExpect/charging-station-hyper-fast-desktop@2x.webp 2x
            "
            media="(min-width: 750px)"
          />
          <img loading="lazy" src="@/assets/images/WhatToExpect/charging-station-hyper-fast-mobile.webp" alt="" />
        </picture>
      </template>

      <router-link class="button-link" :to="{ name: 'charging-your-ev-en' }">
        How To<span class="sr-only">charge at Electrify Canada stations</span><arrow class="arrow"></arrow>
      </router-link>
      <h3>How to charge at Electrify Canada stations</h3>
      <p>Start with a charge at one of our stations or with our app.</p>
    </HeroWithContentOverlay>
  </div>
</template>

<script>
import Arrow from '@/assets/images/Global/arrow_dark.svg';
import Card from '@/components/Card/Card.vue';
import Hero from '@/components/Hero/Hero.vue';
import HeroWithContentOverlay from '@/components/HeroWithContentOverlay/HeroWithContentOverlay.vue';
import TwoColumnContentBlock from '@/components/TwoColumnContentBlock/TwoColumnContentBlock.vue';
import StarIcon from '@/assets/images/Mobile-App/StarIcon.svg';

import lang from '@/lang';

export default {
  name: 'getting-started',
  components: { Arrow, Card, Hero, HeroWithContentOverlay, TwoColumnContentBlock, StarIcon },
  metaInfo: {
    title: "How to Use Electrify Canada's EV Charging Stations",
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/getting-started/',
      },
    ],
    meta: [
      {
        name: 'description',
        content:
          "The Electrify America charging experience was created with you in mind. We've made EV charging fast and easy. Learn how it works here.",
      },
    ],
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
  computed: {
    isPhone() {
      return this.$resize && this.$mq.below(750);
    },
  },
};
</script>
