<template>
  <article class="disclosure">
    <h1>Social Media House Rules</h1>
    <p>
      Welcome to Electrify Canada! The comments posted by users on this site or any other social network page sponsored
      or administered by Electrify Canada, Inc., ("User Content") do not necessarily reflect the opinions or ideals of
      Electrify Canada, its employees or affiliates. We encourage you to leave comments; however, we reserve the right
      to remove any User Content that we consider to be inappropriate or offensive at our discretion. Electrify Canada
      is not responsible for any User Content and Electrify Canada does not endorse any opinions expressed by users.
    </p>

    <p>
      Below are the rules for communications and general behaviour on social-network pages and online channels sponsored
      or administered by Electrify Canada:
    </p>

    <ol>
      <li>
        you will fully comply with
        <router-link class="link" :to="{ name: 'terms-en' }">Electrify Canada’s Terms of Use</router-link>;
      </li>
      <li>you will not make any indecent or otherwise inappropriate posts;</li>
      <li>you will not make any libelous, defamatory, bullying, harassing, threatening, or abusive posts;</li>
      <li>
        you are the legal age of majority or older, unless posting by or with permission of a parent or legal guardian;
      </li>
      <li>
        you will not invade another person’s privacy or publicity rights, and will not distribute either yours, or
        anyone else’s personal information;
      </li>
      <li>
        any User Content you provide:
        <ul>
          <li>is your own original work;</li>
          <li>does not contain or reference any third party trademarks;</li>
          <li>does not infringe the copyright or any other rights of any third party;</li>
          <li>does not contain any illegal or otherwise inappropriate content or material; and</li>
          <li>
            you have the permission of all persons (and any such person’s parent or legal guardian if they are a minor)
            shown in the User Content to post their image and/or their likeness.
          </li>
        </ul>
      </li>
      <li>
        you acknowledge that any User Content you submit will appear viewable by visitors to our page(s) and may appear
        on Electrify Canada’s other websites or in its social media network pages;
      </li>
      <li>
        you grant Electrify Canada a royalty-free, fully-paid, perpetual non-exclusive license to use and display any
        User Content you submit throughout the world in any type of electronic or digital media for commercial,
        marketing and/or public relations.
      </li>
    </ol>
    <p>
      Remember: We reserve the right, in our discretion, to remove any User Content found to be in violation of these
      rules.
    </p>
    <p>
      For more information, or to contact customer service, please call our Customer Care Center at 1-833-231-3226, or
      email us at
      <a href="mailto:support@electrify-canada.ca" class="link"> support@electrify-canada.ca.</a>
    </p>
  </article>
</template>

<script>
export default {
  name: 'social-media-house-rules',
  metaInfo: {
    title: 'Social Media House Rules',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/social-media-house-rules/',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/social-media-house-rules/',
        hreflang: 'en-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/fr/regles-internes-concernant-les-reseaux-sociaux/',
        hreflang: 'fr-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/social-media-house-rules/',
        hreflang: 'x-default',
      },
    ],
    meta: [
      {
        name: 'description',
        content:
          'Electrify America encourages you to leave comments on our websites and social media. Learn how we engage with your comments by reading our Social Media House Rules.',
      },
    ],
  },
};
</script>
