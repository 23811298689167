<template>
  <article class="about-us">
    <Hero
      heading="About Electrify Canada"
      subheading="Electrify Canada, Inc., was formed in July 2018, and is based in Ajax, Ontario, Canada."
    >
      <template #image
        ><picture>
          <source media="(min-width: 750px)" :srcset="require(`@/assets/images/AboutUs/about_us_hero.webp`)" />
          <img alt="" :src="require('@/assets/images/AboutUs/about_us_hero_mobile.webp')" />
        </picture>
      </template>
    </Hero>
    <div class="l-one--has-bg l-one--padding-bottom">
      <div class="l-one__col">
        <h2 class="headline4">Our mission</h2>
        <p>
          Electrify Canada’s goal is to promote greater Zero-Emission Vehicle (ZEV) adoption by offering an Ultra-Fast
          and convenient charging network. This will fuel today’s EVs, as well the rapid growth in EV models expected
          from many car companies.
        </p>
        <h2 class="headline4">Who we are</h2>
        <p>
          Electrify Canada, Inc., is building transformative, Ultra-Fast electric vehicle (EV) direct current (DC)
          charging infrastructure to give Canadians the speed and reliability they need to confidently make the switch
          to electric.
        </p>
      </div>
    </div>
  </article>
</template>

<script>
import Hero from '../../components/Hero/Hero.vue';

export default {
  name: 'about-us-en',
  components: { Hero },
  metaInfo: {
    title: 'About Electrify Canada',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/about-us/',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/about-us/',
        hreflang: 'en-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/fr/a-propos/',
        hreflang: 'fr-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/about-us/',
        hreflang: 'x-default',
      },
    ],
    meta: [
      {
        name: 'description',
        content:
          "Electrify Canada's goal is to promote greater Zero-Emission Vehicle (ZEV) adoption by offering an ultrafast and convenient charging network. Learn about us.",
      },
    ],
  },
};
</script>
