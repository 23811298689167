<template>
  <div class="disclosure">
    <h1>HomeStation Installation and Operation manual</h1>
    <p>
      The HomeStation&trade; Installation and Operation Manual, with operating instructions, can be found
      <a
        href="/pdf/Homestation/HomeStation-Installation-and-Operation-Manual EN_FR_2.0.1_091721.pdf/"
        class="link"
        target="_blank"
        rel="noopener"
        aria-label="HomeStation Installation and Operation Manual opens in a new window"
        >here.</a
      >
    </p>
    <h2>Warranty</h2>
    <h3 class="headline4">Warranty Claims</h3>
    <p>
      To start the warranty claim process, please call
      <a class="link" target="_blank" rel="noopener" href="tel: 1-833-531-3226">(833) 531-3226</a> and a customer
      service agent will work with you to troubleshoot any issues you may be experiencing and assist in placing a
      warranty claim if needed.
    </p>
    <p>
      Once your warranty claim has been submitted, you’ll receive a return shipping label and instructions on packing
      the charger.
    </p>
    <p><strong>Please see our warranty policy for further details:</strong></p>
    <a
      class="link"
      target="_blank"
      rel="noopener"
      href="/pdf/Homestation/062023-HomeStation-Limited-Warranty-Canada.pdf/"
      >HomeStation&trade; Level 2 Home Charger Warranty</a
    >
  </div>
</template>

<script>
export default {
  name: 'homestation',
  metaInfo: {
    title: 'HomeStation | Electrify Home',
    meta: [
      {
        name: 'description',
        content: 'Electrify Home Level 2 Charger HomeStation product and warranty information',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrify-canada.ca/homestation/' }],
  },
};
</script>
