<template>
  <article class="eGolf">
    <Hero
      heading="Get back on the road fast with Electrify Canada"
      subheading="Enjoy DC Fast Charging for your 2020 Volkswagen e-Golf"
    >
      <template #image> <img src="@/assets/images/eGolf/19_87_957_ELCA_VW_LandingPage_Image1.jpg" alt="" /></template>
    </Hero>

    <TwoColumnContentBlock heading="An exclusive charging plan for 2020 e-Golf owners">
      <p>
        All VW e-Golf models are fully equipped to take advantage of DC fast chargers from Electrify Canada. That means
        Electrify Canada can charge your e-Golf at up to 3-4 km per minute.
        <reference-link num="1">{{ $t('chargingTimesMayVary') }}</reference-link>
        Electrify Canada plans to increase its current commitment of 32 stations to an expected more than 100 charging
        locations and 500 chargers by 2026. The 68 additional planned charging stations will continue Electrify Canada’s
        focus on providing electric vehicle charging at strategic locations in metropolitan centers, along major
        highways and near popular amenities.
      </p>
      <p>
        What’s more, 2020 e-Golf owners are eligible to receive the Electrify Canada 2020 e-Golf Charging Plan. This
        exclusive Charging Plan includes two years of complimentary 30-minute charging sessions on the Electrify Canada
        high-powered charging network.
        <reference-link num="2"
          >Applies to VW 2020 e-Golf owners only who purchase a new 2020 e-Golf from an authorized Canadian VW
          dealership. Program offer applies to VW 2020 e-Golf vehicles purchased on or before December 31, 2020,
          effective until December 31st 2022. For a VW 2020 e-Golf purchased on or after January 01, 2021, program is
          effective for twenty-four (24) months from purchase date. The first 30 minutes of every charge is
          complimentary. Sixty (60) minutes must elapse between complimentary sessions. Taxes and idling fees are not
          included. Learn more, <a href="https://www.electrify-canada.ca/faqs">https://www.electrify-canada.ca/faqs</a>.
        </reference-link>
        With complimentary charging that’s both fast and convenient, you’ll be ready to enjoy the exciting handling and
        performance of your VW e-Golf in no time.
      </p>

      <router-link class="button" :to="{ name: 'locate-charger-en' }">LOCATE A CHARGER</router-link>
    </TwoColumnContentBlock>
    <div class="l-one l-one--has-bg l-one--centered-col l-one--padding-bottom">
      <img
        class="l-one__col--wide l-one__col l-one__col--no-side-padding"
        loading="lazy"
        :alt="$t('canadaEgolfExpansion')"
        src="@/assets/images/eGolf/egolf_expansion.png"
      />
    </div>

    <TwoColumnContentBlock heading="Apps make it easy">
      <template #image>
        <img
          loading="lazy"
          alt=""
          data-entity-type="file"
          data-entity-uuid="a6b0d191-3592-48fc-846b-ca0479d02df7"
          src="@/assets/images/eGolf/egolf2.webp"
        />
      </template>
      <p>
        With the Electrify Canada app, you can easily locate Electrify Canada DC fast chargers and find out if they're
        available. Most important of all, the Electrify Canada app provides access to your complimentary charging plan.
      </p>
      <p>To charge your 2020 e-Golf, using your complimentary Electrify Canada Charging Plan, follow these steps:</p>
      <ul>
        <li>
          <strong>Step 1:</strong> {{ $t('downloadTheApp')
          }}<reference-link :num="3">{{ $t('messagingAndDataRates') }}</reference-link> , create your account and select
          “Set Up Public Charging.”
        </li>
        <li>
          <strong>Step 2:</strong> Select “Choose Membership Plan” then “Premium Offers.”
          <ul>
            <li>Or, search for Premium Offer by vehicle information.</li>
            <li>
              Existing Electrify Canada members may select “Premium Offers” in the Account tab, then “I have an
              enrollment code.”
            </li>
          </ul>
        </li>
        <li>
          <strong>Step 3:</strong> Input your 10-digit enrollment code, which you will receive from your Volkswagen
          dealer, and select the 2020 e-Golf Charging Plan. Input your vehicle’s 17-digit VIN<reference-link :num="4">{{
            $t('vinLocation')
          }}</reference-link>
          for verification.
        </li>
        <li><strong>Step 4:</strong> Accept Plan Disclosure, then tap "Select this plan."</li>
        <li>
          <strong>Step 5:</strong> Enter payment info<reference-link :num="5">{{
            $t('autoReloadDisclaimer')
          }}</reference-link>
          and set up auto-reload.
          <ul>
            <li>
              You will need to enter a payment method<reference-link num="5">{{
                $t('autoReloadDisclaimer')
              }}</reference-link>
              to cover any incurred Idle Fees<reference-link num="6"
                >Drivers who do not unplug and move their vehicle ten minutes after their charging session is finished
                will be charged a $0.40 per-minute Idle Fee. This fee encourages drivers to move their car promptly so
                that others can use the charger. You can be notified when your charging session is complete by text
                message² or you’ll see it on the charger screen.</reference-link
              >
              or applicable taxes.
            </li>
          </ul>
        </li>
      </ul>
      <p>
        Offer enrollment can be as smooth as your journeys, but if you encounter any roadblocks, call 1-833-231-3226 to
        contact Electrify Canada Customer Assistance, available 24/7.
      </p>
      <span class="partner-page__cta">
        <router-link class="button partner-page__cta__link" :to="{ name: 'mobile-app-en' }"
          >DOWNLOAD THE APP</router-link
        >
        <a
          rel="noopener"
          target="_blank"
          class="partner-page__cta__link"
          href="https://apps.apple.com/ca/app/electrify-canada/id1472391910"
        >
          <img loading="lazy" :alt="$t('downloadInTheAppStore')" src="@/assets/images/Global/app-store-logo-en.png" />
        </a>
        <a
          rel="noopener"
          target="_blank"
          class="partner-page__cta__link"
          href="https://play.google.com/store/apps/details?id=com.ec.evowner&hl=en_CA"
        >
          <img loading="lazy" :alt="$t('getItOnGooglePlay')" src="@/assets/images/Global/google-play-badge-en.png" />
        </a>
      </span>
    </TwoColumnContentBlock>

    <TwoColumnContentBlock heading="A new and innovative network">
      <template #image>
        <img
          loading="lazy"
          alt=""
          data-entity-type="file"
          data-entity-uuid="caeed537-e17d-4c18-a7e7-55e7c5fc9ad3"
          src="@/assets/images/eGolf/19_87_957_ELCA_VW_LandingPage_Image3.jpg"
        />
      </template>
      <p>
        Experience fast charging as it was meant to be with DC fast-charging stations from Electrify Canada. This
        convenient and reliable network of high-powered chargers is being constructed in well-traveled metropolitan
        areas and along busy Canadian highways. With their slim and tall design, ample night-time illumination and
        first-of-its-kind liquid cooled cables, Electrify Canada fast charging is easier than ever. Find out more about
        Electrify Canada’s new network, built to give Canadians the speed and reliability they need.
      </p>
      <router-link class="button" :to="{ name: 'faqs-en' }">LEARN MORE</router-link>
    </TwoColumnContentBlock>
  </article>
</template>

<script>
import lang from '@/lang';
import ReferenceLink from '@/components/ReferenceLink.vue';
import Hero from '@/components/Hero/Hero.vue';
import TwoColumnContentBlock from '@/components/TwoColumnContentBlock/TwoColumnContentBlock.vue';

export default {
  name: 'eGolf',
  metaInfo: {
    title: 'e-Golf',
    link: [{ rel: 'canonical', href: 'https://www.electrify-canada.ca/e-golf/' }],
  },
  components: { ReferenceLink, TwoColumnContentBlock, Hero },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
};
</script>
