<template>
  <div class="home">
    <!-- Use of this pixel is subject to the Amazon ad specs and policies at http://www.amazon.com/b/?&node=7253015011 -->
    <img
      height="1"
      width="1"
      border="0"
      alt
      src="https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3D1ba54650-bb4f-9741-9fdf-697694b30237%26type%3DUNKNOWN%26m%3D1&ex-fch=416613&ex-src=https://www.electrifyamerica.com/index&ex-hargs=v%3D1.0%3Bc%3D6544328470501%3Bp%3D1BA54650-BB4F-9741-9FDF-697694B30237"
    />

    <div :class="{ 'wrapper wrapper--xx-large-min': !isPhone }">
      <HeroWithContentOverlay :max-width="1920" full-width-mobile>
        <template #image>
          <picture>
            <source :srcset="require('../assets/images/Home/homepage_hero_image.webp')" :media="`(min-width: 750px)`" />
            <img src="../assets/images/Home/HP_hero_mobile.webp" alt="" />
          </picture>
        </template>
        <div>
          <h1>Trouver une station de recharge <br /><i>rapide</i></h1>
          <p>
            Explorez le réseau à l’avant-garde de la disponibilité des bornes de recharge ultrarapides (150 kW) et hyper
            rapides (350 kW).
          </p>
        </div>
        <form class="search-container" role="search" @submit.prevent="goToLocate()">
          <input
            v-model="searchParam"
            class="search-input"
            placeholder="ENTREZ LA VILLE OU LE CODE POSTAL"
            type="search"
          />
          <button aria-label="submit" class="green-circle" type="submit" value=""></button>
        </form>
      </HeroWithContentOverlay>
    </div>

    <div class="home__container">
      <section :class="['wrapper wrapper--extra-large-min', { 'wrapper--no-side-padding': !isPhone }]">
        <h2>
          Apprenez-en plus sur la recharge <br v-if="!isPhone" />
          auprès d’Electrify Canada
        </h2>
        <div class="l-three-col l-three-col--tablet-two">
          <HomepageCard
            path="pricing-fr"
            label="Devenez membre"
            :src="
              isPhone
                ? require('../assets/images/Home/member-mobile-fr.webp')
                : require('../assets/images/Home/member-fr.webp')
            "
            alt=""
            dataText="Women paying with mobile tile-fr"
          >
            <p>
              Vous devez faire le plein alors que vous êtes sur la route? Profitez de tarifs réduits en adhérant à notre
              formule d’abonnement mensuel Pass+.
            </p>
          </HomepageCard>

          <HomepageCard
            path="mobile-app-fr"
            label="Appli mobile"
            :src="
              isPhone
                ? require('../assets/images/Home/home_image_2-mobile-fr.png')
                : require('../assets/images/Home/home_image_2_fr.png')
            "
            alt=""
            dataText="Mobile app tile-fr"
          >
            <p>
              Trouvez une station, rechargez, suivez et terminez votre séance à l’aide de votre téléphone équipé de
              notre appli pour iOS et Android.
              <reference-link :num="1">Des frais de données pourraient s’appliquer.</reference-link>
            </p>
          </HomepageCard>

          <HomepageCard
            v-if="winterCharging"
            path="faqs-fr"
            :pathQuery="{ loadOuter: 1, loadInner: 14 }"
            label="Conseils de recharge par temps froid"
            :src="require('../assets/images/Home/winter-charging-fr.png')"
            alt="5 conseils pour la recharge cet hiver. Préparez-vous à rechardger cet hiver. En savoir plus."
            dataText="Cold weather charging tile-fr"
          >
            <p>
              Alors que les températures chutent, voici quelques conseils pour vous aider à planifier vos déplacements
              l’hiver au volant de votre véhicule électrique.
            </p>
          </HomepageCard>

          <HomepageCard
            path="contact-us-fr"
            label="Soutien et dépannage"
            :src="
              isPhone
                ? require('../assets/images/Home/home_image_4-mobile.webp')
                : require('../assets/images/Home/home_image_4.webp')
            "
            alt=""
            dataText="Support and troubleshooting tile-fr"
          >
            <p>
              Vous avez des questions ou éprouvez un problème à une station de recharge? Notre équipe est là pour vous
              aider. Appelez-nous au 1 833 231-3226 ou utilisez notre formulaire de contact.
            </p>
          </HomepageCard>

          <HomepageCard label="Paiement sans contact au moyen de l’application">
            <p>
              Téléchargez l’application mobile Electrify Canada pour payer votre recharge à l’aide de votre téléphone.
            </p>
            <a
              href="https://apps.apple.com/ca/app/electrify-canada/id1472391910?l=fr"
              rel="noopener"
              target="_blank"
              data-text="Download the Electrify Canada mobile app tile"
            >
              <img
                loading="lazy"
                class="apple"
                :src="require('@/assets/images/Global/app-store-logo-fr.png')"
                :alt="$t('downloadInTheAppStore')"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.ec.evowner&hl=fr_CA"
              rel="noopener"
              target="_blank"
            >
              <img
                loading="lazy"
                class="google-play"
                :src="require('@/assets/images/Global/google-play-badge-fr.png')"
                :alt="$t('getItOnGooglePlay')"
              />
            </a>
          </HomepageCard>
        </div>
      </section>

      <HeroWithContentOverlay
        :maxWidth="1920"
        :mobileResponsive="isPhone ? 'below' : 'overlay'"
        :maxContentWidth="!isPhone ? 644 : unset"
      >
        <template #image>
          <img
            loading="lazy"
            alt=""
            :src="
              isPhone
                ? require('../assets/images/Home/homepage_about_us_mobile.webp')
                : require('../assets/images/Home/homepage_about-us.webp')
            "
          />
        </template>
        <router-link class="button-link" :to="{ name: 'about-us-fr' }">
          Qui sommes-nous?
          <span class="arrow" />
        </router-link>
        <h2>Nous sommes les chefs de file dans le domaine de la recharge rapide.</h2>
        <p>
          Nous construisons un réseau pratique, fiable et centré sur le client de bornes de recharge de véhicules
          électriques à proximité des principales autoroutes et dans les zones métropolitaines.
        </p>
      </HeroWithContentOverlay>
    </div>
  </div>
</template>

<script>
import lang from '@/lang';
import ReferenceLink from '../components/ReferenceLink.vue';
import HeroWithContentOverlay from '../components/HeroWithContentOverlay/HeroWithContentOverlay.vue';
import HomepageCard from '../components/HomepageCard/HomepageCard.vue';
import timestamp from '../helpers/timestamp';

export default {
  name: 'home',
  metaInfo: {
    title: 'Electrify Canada : Réseau public de recharge des VE',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/fr/',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/',
        hreflang: 'en-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/fr/',
        hreflang: 'fr-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/',
        hreflang: 'x-default',
      },
    ],
    meta: [
      {
        name: 'description',
        content:
          'La prochaine génération de recharge de véhicule électrique (VE). Le réseau public d’Electrify Canada est conçu pour offrir les plus puissantes stations de recharge rapide CC.',
      },
    ],
    script: [
      {
        type: 'application/ld+json',
        json: {
          '@context': 'http://schema.org',
          '@type': 'Corporation',
          name: 'Electrify Canada',
          url: 'https://www.electrify-canada.ca/',
          sameAs: [
            'https://twitter.com/CanadaElectrify',
            'https://www.facebook.com/Electrify-Canada-677205735992875/',
            'https://www.instagram.com/electrifycanada/',
            'https://www.linkedin.com/company/electrify-canada-ca-eng/about/',
          ],
          logo: 'https://twitter.com/CanadaElectrify/photo',
          parentOrganization: {
            '@type': 'Corporation',
            name: 'Electrify Canada',
            url: 'https://www.electrify-canada.ca/',
          },
          contactPoint: [
            {
              '@type': 'ContactPoint',
              telephone: '+1-833-231-3226',
              contactType: 'customer support',
              contactOption: 'TollFree',
              areaServed: 'CA',
            },
          ],
        },
      },
    ],
  },

  components: {
    ReferenceLink,
    HeroWithContentOverlay,
    HomepageCard,
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
  data() {
    return {
      searchParam: null,
      winterCharging: timestamp.get() <= 1708750799000,
    };
  },
  methods: {
    goTo(path, pathQuery) {
      if (pathQuery) {
        this.$router.push({ name: path, query: pathQuery });
      } else {
        this.$router.push({ name: path });
      }
    },
    goToLocate() {
      if (this.searchParam) {
        const zoom = window.innerWidth >= 1200 ? 5 : 4;

        this.$store.commit('map/resetFilters');
        this.$store.commit('map/setIsFilterPanelExpanded', false);
        this.$store.commit('map/setIsStationDetailExpanded', false);
        this.$store.commit('map/setIsStationListExpanded', false);
        this.$store.commit('locations/setUniqueLocation', null);
        this.$store.commit('locations/setUniqueRoute', null);
        this.$store.dispatch('map/updateMapZoomAndCenter', {
          zoom,
          center: { lat: 50, lng: -96.8 },
        });
        this.$router.push({ name: 'locate-charger-fr', query: { search: this.searchParam } });
      } else {
        this.$router.push({ name: 'locate-charger-fr' });
      }
    },
  },
  computed: {
    isIE11() {
      return navigator.userAgent.toUpperCase().indexOf('TRIDENT/') !== -1;
    },
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    isPhone() {
      return this.$resize && this.$mq.below(750);
    },
  },
};
</script>
