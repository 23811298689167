<template>
  <div class="mobile-app">
    <Hero heading="L’application mobile Electrify Canada">
      <template #image>
        <picture>
          <source
            srcset="@/assets/images/Mobile-App/mobile_app_banner_fr.png"
            media="(min-width: 750px)"
            alt="une expérience d’application entièrement repensée"
          />
          <img
            src="@/assets/images/Mobile-App/Mobile/mobile_app_banner_mobile_fr.png"
            alt="une expérience d’application entièrement repensée"
          />
        </picture>
      </template>
      <h2 class="hero-title headline3">Recharge facile à la maison et en déplacement</h2>
      <div class="hero-content">
        <p>
          Utilisez l’application mobile Electrify Canada pour trouver une borne de recharge publique. Créez un compte
          pour profiter de fonctionnalités et de tarifs de recharge publics exclusifs réservés aux membres.
        </p>
        <div class="inline">
          <a rel="noopener" target="_blank" href="https://apps.apple.com/ca/app/electrify-canada/id1472391910?l=fr">
            <img
              loading="lazy"
              :src="require('@/assets/images/Global/app-store-logo-fr.png')"
              :alt="$t('downloadInTheAppStore')"
            />
          </a>
          <a
            rel="noopener"
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.ec.evowner&hl=fr_CA"
          >
            <img
              loading="lazy"
              :src="require('@/assets/images/Global/google-play-badge-fr.png')"
              :alt="$t('getItOnGooglePlay')"
            />
          </a>
        </div>
      </div>
    </Hero>
    <section class="locate-charger">
      <Card
        heading="Localisez une borne de recharge"
        headlineLevel="h2"
        headlineDisplayClass="headline3"
        isReverse
        maxWidth="1037"
      >
        <template #image>
          <img loading="lazy" src="@/assets/images/Mobile-App/station_expanded_pin.png" alt="" />
        </template>
        <p>
          Ouvrez l’appli pour trouver rapidement les bornes de recharge Electrify Canada les plus près de chez vous.
        </p>
        <p>
          Vous pouvez également voir le nombre de bornes de recharge de chaque type (CCS et CHAdeMO). Devenez membre
          pour voir la disponibilité d’une borne de recharge en temps réel et recevoir une alerte lorsqu’ une borne
          occupé se libère.
        </p>
      </Card>
    </section>
    <section class="app-features">
      <div class="l-two-col">
        <Card
          heading="Paiement sans contact"
          headlineLevel="h2"
          isSmall
          :isReverse="isPhone"
          headlineDisplayClass="headline4"
        >
          <template #image>
            <img
              loading="lazy"
              src="@/assets/images/Mobile-App/Mobile/contactless_mobile_fr@2x.png"
              alt="Écran de l’application de paiement sans contact Electrify Canada"
            />
          </template>
          <p>
            Devenez membre par l’entremise de l’application et payez votre recharge en touchant simplement la borne de
            recharge ou en ouvrant l’application et en faisant glisser l’écran.
          </p>
        </Card>
        <Card
          heading="Suivez vos progrès"
          alt=""
          headlineLevel="h2"
          isSmall
          :isReverse="isPhone"
          headlineDisplayClass="headline4"
        >
          <template #image>
            <img
              loading="lazy"
              src="@/assets/images/Mobile-App/Mobile/progress_mobile_fr@2x.png"
              alt="Écran Suivez vos progrès de l’application Electrify Canada"
            />
          </template>
          <p>
            Pour les véhicules électriques compatibles, l’application affiche votre
            <router-link class="link" :to="{ name: 'glossary-fr', query: { load: 15 } }"
              >état de charge (SOC)</router-link
            >
            et vous envoie des notifications personnalisées sur votre session de recharge.
          </p>
        </Card>
      </div>
    </section>
    <section class="membership">
      <div class="container">
        <h2>Profitez des avantages de l’adhésion</h2>
        <p class="tag-line">
          Téléchargez l’application et inscrivez-vous pour tirer le meilleur parti de votre expérience de recharge
        </p>
        <p class="disclaimer">Des frais de messagerie et de données pourraient s’appliquer</p>
        <div class="l-three-col">
          <div class="col">
            <div class="col-body">
              <h3>Recharge des clients</h3>
              <span>Payez sur-le-champ</span>
              <decorative-list>
                <li>
                  <p>Trouver une borne de recharge</p>
                </li>
                <li>
                  <p>
                    Glissez ou insérez votre carte de débit ou de crédit, ou appuyez-la sur le lecteur, pour payer
                    facilement
                  </p>
                </li>
              </decorative-list>
            </div>
          </div>
          <div class="col">
            <div class="special-header">UNIQUEMENT DANS L’APPLI</div>
            <div class="col-body">
              <h3>Membres Passe</h3>
              <span>Aucuns frais mensuels</span>
              <decorative-list>
                <li>
                  <p>Voyez la disponibilité des bornes en temps réel</p>
                </li>
                <li>
                  <p>Payez sans contact au moyen de l’appli</p>
                </li>
                <li>
                  <p>Stations de recharge préférées dans l’appli</p>
                </li>
                <li>
                  <p>Commencez la recharge à l’aide de l’application et suivez vos progrès</p>
                </li>
                <li>
                  <p>
                    Recevez une notification lorsqu’une borne s’ouvre ou que votre VE a atteint l’état de charge
                    souhaité
                  </p>
                </li>
                <li>
                  <p>Trouver une borne de recharge</p>
                </li>
              </decorative-list>
            </div>
          </div>
          <div class="col">
            <div class="special-header">UNIQUEMENT DANS L’APPLI</div>
            <div class="col-body">
              <h3>Membres Passe+</h3>
              <span
                >7 $/mois <reference-link num="2">{{ $t('plusFees') }}</reference-link></span
              >
              <decorative-list>
                <li class="flex-row unique-row">
                  <div>
                    <p>Bénéficiez de coûts de recharge réduits</p>
                    <div class="flex-row">
                      <StarIcon class="image" />
                      <p class="special-list">LES MEMBRES PASSE+ ÉCONOMISENT 20 % LORS DE LA RECHARGE</p>
                    </div>
                  </div>
                </li>
                <li>
                  <p>Voyez la disponibilité des bornes en temps réel</p>
                </li>
                <li>
                  <p>Payez sans contact au moyen de l’appli</p>
                </li>
                <li>
                  <p>Stations de recharge préférées dans l’appli</p>
                </li>
                <li>
                  <p>Commencez la recharge à l’aide de l’application et suivez vos progrès</p>
                </li>
                <li>
                  <p>
                    Recevez une notification lorsqu’une borne s’ouvre ou que votre VE a atteint l’état de charge
                    souhaité
                  </p>
                </li>
                <li>
                  <p>Trouver une borne de recharge</p>
                </li>
              </decorative-list>
            </div>
          </div>
        </div>
      </div>
    </section>
    <MobileAppFaq />
  </div>
</template>

<script>
import lang from '@/lang';
import MobileAppFaq from '@/components/MobileAppFaq.vue';
import DecorativeList from '@/components/DecorativeList/DecorativeList.vue';
import ReferenceLink from '@/components/ReferenceLink.vue';
import StarIcon from '@/assets/images/Mobile-App/StarIcon.svg';
import Hero from '@/components/Hero/Hero.vue';
import Card from '@/components/Card/Card.vue';

export default {
  name: 'mobile-app',
  metaInfo: {
    title: 'Téléchargez notre application mobile pour iOS ou Android',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/application-mobile/',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/mobile-app/',
        hreflang: 'en-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/fr/application-mobile/',
        hreflang: 'fr-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/mobile-app/',
        hreflang: 'x-default',
      },
    ],
    meta: [
      {
        name: 'description',
        content:
          'Gérez votre session de recharge Electrify Canada directement à partir de votre téléphone. Téléchargez notre application et découvrez une façon plus facile de recharger votre véhicule électrique (VE).',
      },
    ],
    script: [
      {
        type: 'application/ld+json',
        json: {
          '@context': 'https://schema.org',
          '@type': 'SoftwareApplication',
          name: 'Electrify Canada',
          applicationCategory: 'DriverApplication',
          installUrl:
            'https://apps.apple.com/ca/app/electrify-canada/id1472391910?l=fr, https://play.google.com/store/apps/details?id=com.ec.evowner&hl=fr_CA',
          operatingSystem: 'iOS, Android',
          aggregateRating: { '@type': 'AggregateRating', ratingValue: '3', ratingCount: '22' },
          offers: { '@type': 'Offer', price: '0.00', priceCurrency: 'USD' },
        },
      },
    ],
  },
  components: {
    MobileAppFaq,
    StarIcon,
    DecorativeList,
    Hero,
    Card,
    ReferenceLink,
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
  computed: {
    isPhone() {
      return this.$resize && this.$mq.below(749);
    },
  },
};
</script>
