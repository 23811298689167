const CityPage = () => import('@/views/CityPage.vue');
const EmailVerification = () => import('@/views/EmailVerification.vue');
const EmailVerificationFR = () => import('@/views/EmailVerification-fr.vue');
const ExpiredEmail = () => import('@/views/ExpiredEmail.vue');
const ExpiredEmailFR = () => import('@/views/ExpiredEmail-fr.vue');
const Home = () => import('@/views/Home.vue');
const HomeFR = () => import('@/views/Home-fr.vue');
const FourOhFour = () => import('@/views/404.vue');
const LocateCharger = () => import('@/views/LocateCharger.vue');
const Locations = () => import('@/views/Locations.vue');
const Privacy = () => import('@/views/Privacy.vue');
const PrivacyFR = () => import('@/views/Privacy-fr.vue');
const SocialMediaHouseRules = () => import('@/views/SocialMediaHouseRules.vue');
const SocialMediaHouseRulesFR = () => import('@/views/SocialMediaHouseRules-fr.vue');
const StatePage = () => import('@/views/StatePage.vue');
const SubscriptionAgreement = () => import('@/views/SubscriptionAgreement.vue');
const SubscriptionAgreementFR = () => import('@/views/SubscriptionAgreement-fr.vue');
const ThankYou = () => import('@/views/ThankYou.vue');
const Unsubscribe = () => import('@/views/Unsubscribe.vue');
const VerifyEmailContactUs = () => import('@/views/VerifyEmailContactUs.vue');
const VerifyEmailContactUsFR = () => import('@/views/VerifyEmailContactUs-fr.vue');
const VerifyEmailSignup = () => import('@/views/VerifyEmailSignup.vue');
const VerifyEmailSignupFR = () => import('@/views/VerifyEmailSignup-fr.vue');
const PremiumOffers = () => import('@/views/PremiumOffers.vue');
const PremiumOffersFr = () => import('@/views/PremiumOffers-fr.vue');
const PlugAndCharge = () => import('@/views/PlugAndCharge.vue');
const PlugAndChargeFr = () => import('@/views/PlugAndCharge-fr.vue');

/**
 * Static Routes
 */
const routes = [
  {
    path: '/',
    name: 'home-en',
    component: Home,
  },

  {
    path: '/email-verification/',
    name: 'verify-email-signup-en',
    pathToRegexpOptions: { strict: true },
    component: VerifyEmailSignup,
  },
  {
    path: '/fr/email-verification/',
    name: 'verify-email-signup-fr',
    pathToRegexpOptions: { strict: true },
    component: VerifyEmailSignupFR,
  },

  {
    path: '/email-verification-contact-us/',
    name: 'verify-email-contact-us-en',
    pathToRegexpOptions: { strict: true },
    component: VerifyEmailContactUs,
  },
  {
    path: '/fr/email-verification-contact-us/',
    name: 'verify-email-contact-us-fr',
    pathToRegexpOptions: { strict: true },
    component: VerifyEmailContactUsFR,
  },

  {
    path: '/expired-email/',
    name: 'expired-email-en',
    pathToRegexpOptions: { strict: true },
    component: ExpiredEmail,
  },
  {
    path: '/fr/expired-email/',
    name: 'expired-email-fr',
    pathToRegexpOptions: { strict: true },
    component: ExpiredEmailFR,
  },

  {
    path: '/inapp/validate-email/',
    name: 'email-validate-en',
    pathToRegexpOptions: { strict: true },
    component: EmailVerification,
    meta: {
      title: '',
    },
  },
  {
    path: '/fr/inapp/validate-email/',
    name: 'email-validate-fr',
    pathToRegexpOptions: { strict: true },
    component: EmailVerificationFR,
    meta: {
      title: '',
    },
  },

  {
    path: '/404/',
    name: '404-en',
    pathToRegexpOptions: { strict: true },
    component: FourOhFour,
  },
  {
    path: '/fr/404/',
    name: '404-fr',
    pathToRegexpOptions: { strict: true },
    component: FourOhFour,
  },

  {
    path: '/locate-charger/',
    name: 'locate-charger-en',
    pathToRegexpOptions: { strict: true },
    component: LocateCharger,
  },
  {
    path: '/locate-charger/:state?/:city?/:address?/:id?/',
    name: 'locate-charger-unique-url-en',
    pathToRegexpOptions: { strict: true },
    component: LocateCharger,
  },
  {
    path: '/fr/trouver-une-borne/',
    name: 'locate-charger-fr',
    pathToRegexpOptions: { strict: true },
    component: LocateCharger,
  },
  {
    path: '/fr/trouver-une-borne/:state?/:city?/:address?/:id?/',
    name: 'locate-charger-unique-url-fr',
    pathToRegexpOptions: { strict: true },
    component: LocateCharger,
  },

  {
    path: '/privacy/',
    name: 'privacy-en',
    pathToRegexpOptions: { strict: true },
    component: Privacy,
  },
  {
    path: '/fr/confidentialite/',
    name: 'privacy-fr',
    pathToRegexpOptions: { strict: true },
    component: PrivacyFR,
  },

  {
    path: '/social-media-house-rules/',
    name: 'social-media-house-rules-en',
    pathToRegexpOptions: { strict: true },
    component: SocialMediaHouseRules,
  },
  {
    path: '/fr/regles-internes-concernant-les-reseaux-sociaux/',
    name: 'social-media-house-rules-fr',
    pathToRegexpOptions: { strict: true },
    component: SocialMediaHouseRulesFR,
  },

  {
    path: '/subscription-agreement/',
    name: 'subscription-agreement-en',
    pathToRegexpOptions: { strict: true },
    component: SubscriptionAgreement,
  },
  {
    path: '/fr/contrat-dabonnement/',
    name: 'subscription-agreement-fr',
    pathToRegexpOptions: { strict: true },
    component: SubscriptionAgreementFR,
  },

  {
    path: '/thank-you/',
    name: 'thank-you-en',
    pathToRegexpOptions: { strict: true },
    component: ThankYou,
  },
  {
    path: '/fr/merci/',
    name: 'thank-you-fr',
    pathToRegexpOptions: { strict: true },
    component: ThankYou,
    meta: {
      title: 'Thank you',
      metaTags: [
        {
          description: 'Thank you',
        },
      ],
    },
  },

  {
    path: '/unsubscribe/',
    name: 'unsubscribe-en',
    pathToRegexpOptions: { strict: true },
    component: Unsubscribe,
  },
  {
    path: '/fr/unsubscribe/',
    name: 'unsubscribe-fr',
    pathToRegexpOptions: { strict: true },
    component: Unsubscribe,
  },
  {
    path: '/premium-offers/',
    name: 'premium-offers-en',
    pathToRegexpOptions: { strict: true },
    component: PremiumOffers,
  },
  {
    path: '/fr/offres-premium/',
    name: 'premium-offers-fr',
    pathToRegexpOptions: { strict: true },
    component: PremiumOffersFr,
  },
  {
    path: '/plug-and-charge/',
    name: 'plug-and-charge-en',
    pathToRegexpOptions: { strict: true },
    component: PlugAndCharge,
  },
  {
    path: '/fr/plug-and-charge/',
    name: 'plug-and-charge-fr',
    pathToRegexpOptions: { strict: true },
    component: PlugAndChargeFr,
  },
  {
    path: '/locations/',
    name: 'locations-en',
    pathToRegexpOptions: { strict: true },
    component: Locations,
    children: [
      {
        path: ':state/',
        name: 'locations-state-en',
        pathToRegexpOptions: { strict: true },
        component: StatePage,
        children: [
          {
            path: ':city/',
            name: 'locations-state-cities-en',
            pathToRegexpOptions: { strict: true },
            component: CityPage,
          },
        ],
      },
    ],
  },
  {
    path: '/fr/emplacements/',
    name: 'locations-fr',
    pathToRegexpOptions: { strict: true },
    component: Locations,
    children: [
      {
        path: ':state/',
        name: 'locations-state-fr',
        pathToRegexpOptions: { strict: true },
        component: StatePage,
        children: [
          {
            path: ':city/',
            name: 'locations-state-cities-fr',
            pathToRegexpOptions: { strict: true },
            component: CityPage,
          },
        ],
      },
    ],
  },
];

routes.push({
  path: '/fr/',
  name: 'home-fr',
  pathToRegexpOptions: { strict: true },
  component: HomeFR,
});

const exportedRoutes = routes;
export default exportedRoutes;
