<template>
  <div class="station-results" v-if="isStationListExpanded">
    <h2 class="sr-only">{{ $t('stationsList') }}</h2>
    <div class="station-results__wrapper" :data-results="visibleMarkers.length">
      <div class="error" v-if="isError">
        <div v-if="!getErrorMsg() || getErrorMsg() === 'oops'">
          <h3>{{ $t('oops') }}</h3>
          <p>{{ $t('oopsErrorMsg') }}</p>
        </div>
        <div v-else-if="getErrorMsg() === 'action_unavailable'">
          <h3>{{ $t('actionUnavailable') }}</h3>
          <p>{{ $t('actionUnavailableMsg') }}</p>
        </div>
      </div>
      <button v-if="isMobile" class="map-close-button" @click="closeStationList">
        <CloseIcon />
      </button>
      <div class="station-results__filters-applied" v-if="appliedFilters.length > 0">
        <button v-for="(filter, index) in appliedFilters" :key="index" @click="removeFilter(filter)">
          {{ filterDisplayName[filter] }} <CloseIcon />
        </button>
      </div>
      <ul class="station-results__list">
        <li v-if="visibleMarkers.length < 1" class="station-results__list__no-chargers">
          <p v-if="!markersLoading">{{ $t('noStationsInView') }}</p>
        </li>
        <!-- INITIAL SEARCH RESULTS -->
        <li
          v-else
          class="station-results__list-item"
          v-for="(marker, $index) in visibleMarkers"
          :key="$index"
          :id="marker.id"
        >
          <button
            @click="stationDetailOpen(marker)"
            class="station-results__list__location"
            ref="stationBox"
            :id="marker.id"
          >
            <div class="station-results__list__pin">
              <template v-if="marker.isCommercialChargerLocation">
                <span class="sr-only">{{ $t('commercialStation') }}</span>
                <Commercial />
              </template>
              <template v-else-if="marker.comingSoon === false">
                <span class="sr-only">{{ $t('openStation') }}</span>
                <Live />
              </template>
              <template v-else-if="marker.comingSoon === true">
                <span class="sr-only">{{ $t('comingSoonStation') }}</span>
                <ComingSoon />
              </template>
              <template v-else>
                <span class="sr-only">{{ $t('l2Stations') }}</span>
                <L2 />
              </template>
              <!-- TODO: add distance logic to lambda -->
              <!-- <span class="station-results__list__pin__distance">{{ marker.distance }} mi</span> -->
            </div>
            <div class="station-results__list__charger-address">
              <p class="station-results__list__recently-viewed" v-if="recentlyViewed.includes(marker.id)">
                <RecentlyViewedIcon /> {{ $t('recentlyViewed') }}
              </p>
              <h3>{{ marker.name }}</h3>
              <p>{{ marker.address }}</p>
              <p>{{ marker.city }}, {{ marker.state }}, {{ marker.zipCode }}</p>
            </div>
            <div class="station-results__list__directions">
              <IconDirections />
            </div>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/assets/images/Map/close-icon.svg';
import ComingSoon from '@/components/LocateCharger/LocateChargerSVGs/StationComingSoonSVG.vue';
import Commercial from '@/components/LocateCharger/LocateChargerSVGs/CommercialStationSVG.vue';
import IconDirections from '@/assets/images/Map/direction-icon.svg';
import L2 from '@/components/LocateCharger/LocateChargerSVGs/L2StationSVG.vue';
import Live from '@/components/LocateCharger/LocateChargerSVGs/LiveStationSVG.vue';
import RecentlyViewedIcon from '@/assets/images/Map/circular-arrow.svg';
import lang from '@/lang/LocateCharger';

export default {
  name: 'StationResults',
  components: {
    CloseIcon,
    ComingSoon,
    Commercial,
    IconDirections,
    L2,
    Live,
    RecentlyViewedIcon,
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
  methods: {
    closeStationList() {
      if (this.isStationListExpanded) {
        this.$store.commit('map/setIsStationListExpanded', false);
        this.$root.$emit('stationReset');
      }
    },
    expandStationList() {
      this.$root.$emit('updateChargerList', true);
    },
    removeFilter(filter) {
      this.$store.state.map.filters[filter] = false;

      // If all filters are removed, reset to show all locations
      if (
        this.$store.state.map.filters.comingSoon === false &&
        this.$store.state.map.filters.siteIsLive === false &&
        this.$store.state.map.filters.ccsChademoDC === false &&
        this.$store.state.map.filters.ccsDC === false
      ) {
        this.$store.state.map.filters.comingSoon = true;
        this.$store.state.map.filters.siteIsLive = true;
        this.$store.state.map.filters.ccsChademoDC = true;
        this.$store.state.map.filters.ccsDC = true;
      }
    },
    stationDetailOpen(station) {
      this.$emit('stationDetailOpen', station);
    },
    getErrorMsg() {
      return sessionStorage.getItem('error');
    },
  },
  computed: {
    appliedFilters() {
      if (
        this.$store.state.map.filters.comingSoon === true &&
        this.$store.state.map.filters.siteIsLive === true &&
        this.$store.state.map.filters.ccsChademoDC === true &&
        this.$store.state.map.filters.ccsDC === true
      ) {
        return [];
      }

      return Object.keys(this.$store.state.map.filters).filter((val) => this.$store.state.map.filters[val] === true);
    },
    isMobile() {
      return this.$resize && this.$mq.below(750);
    },
    isStationListExpanded() {
      return this.$store.state.map.isStationListExpanded;
    },
    recentlyViewed() {
      return this.$store.state.map.recentlyViewed;
    },
    visibleMarkers() {
      return this.$store.state.map.visibleMarkers;
    },
    markersLoading() {
      return this.$store.state.map.markersLoading;
    },
    filterDisplayName() {
      return {
        // Connector filters
        ccsChademoDC: this.$t('cHAdeMO'),
        ccsDC: this.$t('ccs'),
        l2: this.$t('level2'),
        // Station filters
        comingSoon: this.$t('comingSoon'),
        commercial: this.$t('commercial'),
        siteIsLive: this.$t('station'),
      };
    },
    isError() {
      if (
        !(
          Object.keys(this.$store.state.locations.error).length === 0 &&
          this.$store.state.locations.error.constructor === Object
        )
      ) {
        if (!this.getErrorMsg()) {
          sessionStorage.setItem('error', 'oops');
        }
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.$root.$on('closeStationList', () => {
      this.closeStationList();
    });
    this.$root.$on('expandStationList', () => {
      this.expandStationList();
    });

    // set recentlyViewed array to the values saved in sessionStorage
    // eslint-disable-next-line no-restricted-syntax
    for (const [, value] of Object.entries(sessionStorage)) {
      this.$store.commit('map/setRecentlyViewed', value);
    }

    if (this.getErrorMsg() === 'oops') {
      sessionStorage.setItem('error', 'action_unavailable');
    }
  },
};
</script>

<style lang="scss" scoped>
.station-results {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__filters-applied {
    padding: 16px;

    button {
      align-items: center;
      appearance: unset;
      background: #eef0f4;
      border: 0;
      border-radius: $global-border-radius;
      color: $c-primary;
      display: inline-flex;
      font-size: 16px;
      font-weight: $fw-medium;
      margin-right: 10px;
      padding: 9px;

      svg {
        width: 24px;
      }
    }
  }

  &__list {
    border-top: 1px solid #c9cfdc;
    list-style: none;
    margin: 0px;
    padding: 0px;
    position: relative;

    &-item {
      border-bottom: 1px solid #c9cfdc;
      padding: 4px;
      min-height: 120px;
      &:last-of-type {
        border-bottom: none;
      }
    }

    &__recently-viewed {
      align-items: center;
      background-color: $c-gray-dark;
      border-radius: 11.8px;
      color: $c-primary-background;
      display: flex;
      font-size: 10px !important;
      font-weight: 500 !important;
      justify-content: center;
      letter-spacing: 0.2px;
      margin-bottom: 8px !important;
      text-transform: uppercase;
      width: 165px;

      svg {
        height: 9px;
        padding-left: 2px;
        transform: rotate(180deg);
        width: 10px;
        path {
          fill: $c-primary-background;
        }
      }
    }

    &__charger-address {
      color: $c-gray-dark;
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      justify-content: flex-start;
      text-align: left;
      padding: 0 20px;
      font-family: $ff-primary;

      h3 {
        font-size: 16px;
        font-weight: $fw-medium;
        line-height: 1.44;
        margin: 0;
      }

      p {
        margin: 0;
        font-size: 12px;
        font-weight: $fw-regular;
      }
    }

    &__location {
      align-items: center;
      background: transparent;
      border: 0;
      display: flex;
      justify-content: space-between;
      outline-offset: 0;
      padding: 20px;
      width: 100%;
    }

    &__directions {
      color: $c-primary;

      svg {
        height: 40px;
        width: 40px;
      }
    }

    &__pin {
      color: $c-primary;
      font-size: 16px;

      svg {
        height: 40px;
        width: 40px;
      }

      &__distance {
        font-size: 12px;
        color: $c-gray-dark;
      }
    }

    &__no-chargers {
      border-bottom: 1px solid #c9cfdc;
      padding: 45px 0;
      text-align: center;

      p {
        font-weight: $fw-regular;
        margin: 0;
      }
    }
  }

  &__wrapper {
    background-color: $c-primary-background;
    margin-top: calc(40vh);
    position: relative;

    @media (min-width: 750px) {
      height: fit-content;
      margin-top: 0;
    }

    @media (max-width: 749px) {
      &[data-results='0'],
      &[data-results='1'] {
        margin-top: calc(100vh - 200px);
      }
      &[data-results='2'] {
        margin-top: calc(100vh - 310px);
      }
      &[data-results='3'] {
        margin-top: calc(100vh - 420px);
      }
      &[data-results='4'] {
        margin-top: calc(100vh - 530px);
      }
    }
  }
  .error {
    border-top: 1px solid #c9cfdc;
    margin-top: 5px;
    font-size: 16px;
    padding: 18px 24px;
    background: $c-secondary-background;
    p {
      margin-bottom: 0;
      margin-top: 8px;
    }
  }
}
</style>
