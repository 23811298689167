<template>
  <div class="earth-week-promo-over">
    <section class="l-one__col l-one__col--x-wide">
      <Card
        heading="La Semaine de la Terre est terminée, mais notre engagement envers celle-ci demeure entier."
        headlineLevel="h2"
        :maxWidth="1200"
      >
        <template #image>
          <img src="@/assets/images/EarthWeekPromoOver/green-plug.webp" alt="" />
        </template>
        <p>
          Electrify Canada a toujours eu pour objectif de promouvoir une plus grande adoption des véhicules zéro
          émission (VZE) en offrant un réseau de recharge ultrarapide et pratique qui donne aux Canadiens la vitesse et
          la fiabilité dont ils ont besoin pour utiliser un véhicule électrique en toute tranquillité.
        </p>
        <p>
          Suivez-nous sur les médias sociaux pour avoir l’information sur l’ouverture des nouvelles stations et d’autres
          sujets d’actualité.
        </p>
        <nav role="navigation" class="img-container" aria-label="les médias sociaux">
          <a
            style="margin-left: 8px; margin-right: 8px"
            v-for="(image, index) of images.elca"
            :key="index"
            :href="image.linkTo"
            rel="noopener"
            target="_blank"
          >
            <img loading="lazy" :src="image.content" :alt="image.alt" style="width: 20px" />
          </a>
        </nav>
      </Card>
    </section>
  </div>
</template>

<script>
import Card from '@/components/Card/Card.vue';

export default {
  components: { Card },
  metaInfo: {
    title: 'Celebrating Earth Week | Electrify America',
    meta: [
      {
        name: 'description',
        content:
          "We're committed to making the planet a more sustainable place. Here's how we're serving our communities this Earth Week and year-round.",
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/earth-week/' }],
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(750);
    },
  },
  data() {
    return {
      images: {
        elca: [
          {
            content: require('@/assets/images/EarthWeekPromoOver/facebook.svg?external'),
            linkTo: 'https://www.facebook.com/Electrify-Canada-677205735992875/',
            alt: 'Facebook',
          },
          {
            content: require('@/assets/images/EarthWeekPromoOver/instagram.svg?external'),
            linkTo: 'https://www.instagram.com/electrifycanada/',
            alt: 'Instagram',
          },
          {
            content: require('@/assets/images/EarthWeekPromoOver/x.svg?external'),
            linkTo: 'https://x.com/CanadaElectrify',
            alt: 'X',
          },
          {
            content: require('@/assets/images/EarthWeekPromoOver/linkedin.svg?external'),
            linkTo: 'https://www.linkedin.com/company/electrify-canada-ca-eng/about',
            alt: 'LinkedIn',
          },
        ],
      },
    };
  },
};
</script>
