<template>
  <article class="partner-page bmw">
    <Hero heading="Profitez du plein potentiel de votre BMW i7" :subheading="true">
      <template #subheading>
        <p>
          Accédez à une infinité de possibilités grâce à trois années de recharge gratuite aux bornes de recharge en
          courant continu ultrarapide d’Electrify Canada, offertes gracieusement par
          <span class="wrap-last-two-words"
            >BMW
            <reference-link :num="1"
              >Offert pour les modèles de véhicules BMW i7 2023, 2024 et 2025. Trois années de recharge gratuite à
              l’achat du véhicule. Les modèles BMW i7s achetés avant la date de lancement du programme (06 avril 2023)
              profiteront de trois années de recharge gratuite à compter du 06 avril 2023. Non transférable. Ne peut
              être utilisé à des fins commerciales, comme le covoiturage. Nécessite un compte Electrify Canada, une
              application, l’inscription au forfait de recharge BMW i7 et l’acceptation des conditions d’utilisation.
              Lorsque la session de recharge amorcée par l’utilisateur est terminée, que le véhicule atteint sa pleine
              charge ou que la recharge s’arrête pour une autre raison, l’utilisateur est responsable des frais
              d’inactivité après un délai de grâce de 10 minutes. En cas de soupçon de fraude, d’abus ou d’autres
              événements imprévus, Electrify Canada peut, à sa seule discrétion, mettre fin à l’offre ou la modifier.
            </reference-link></span
          >
          à l’achat du véhicule.
        </p>
      </template>
      <template #image>
        <picture>
          <source media="(min-width: 750px)" srcset="@/assets/images/BMW/bmw-hero.png" />
          <img src="@/assets/images/BMW/bmw-hero-mobile.png" alt="" />
        </picture>
      </template>
    </Hero>

    <TwoColumnContentBlock heading="Évoluez avec Electrify Canada">
      <p>
        Electrify Canada s’engage à construire un réseau de stations de recharge pour véhicules électriques pratiques et
        fiables à des emplacements stratégiques dans les centres métropolitains, le long des principales autoroutes et à
        proximité des lieux très fréquentés pour aider les utilisateurs de véhicules électriques (VE) à se rendre là où
        ils le souhaitent. Parce que nous savons que votre BMW i7 n’est pas faite pour rester dans le garage.
      </p>
      <p>
        Avec trois années de recharge gratuite dans toutes les stations de recharge rapide en courant continu
        d’Electrify Canada à l’achat du véhicule et après s’être inscrits
        <ReferenceLink :num="1">
          Offert pour les modèles de véhicules BMW i7 2023, 2024 et 2025. Trois années de recharge gratuite à l’achat du
          véhicule. Les modèles BMW i7s achetés avant la date de lancement du programme (06 avril 2023) profiteront de
          trois années de recharge gratuite à compter du 06 avril 2023. Non transférable. Ne peut être utilisé à des
          fins commerciales, comme le covoiturage. Nécessite un compte Electrify Canada, une application, l’inscription
          au forfait de recharge BMW i7 et l’acceptation des conditions d’utilisation. Lorsque la session de recharge
          amorcée par l’utilisateur est terminée, que le véhicule atteint sa pleine charge ou que la recharge s’arrête
          pour une autre raison, l’utilisateur est responsable des frais d’inactivité après un délai de grâce de 10
          minutes. En cas de soupçon de fraude, d’abus ou d’autres événements imprévus, Electrify Canada peut, à sa
          seule discrétion, mettre fin à l’offre ou la modifier.</ReferenceLink
        >, les conducteurs de BMW i7 peuvent se concentrer sur les routes et les possibilités qui s’offrent à eux et
        oublier les stations-service. Les utilisateurs du forfait de recharge pour véhicules BMW i7 pourront
        <strong> activer la fonctionnalité Brancher et recharger</strong> et l’utiliser avec ce forfait (voir les étapes
        d’inscription au forfait ci-dessous pour en savoir plus).
      </p>
      <p>Grâce à ces avantages, les conducteurs de BMW i7 peuvent profiter pleinement de la liberté électrifiée</p>
      <router-link class="button" :to="{ name: 'locate-charger-fr' }">TROUVER UNE BORNE DE RECHARGE</router-link>
    </TwoColumnContentBlock>

    <TwoColumnContentBlock heading="Partez en toute liberté">
      <template #image>
        <picture>
          <source media="(min-width: 750px)" srcset="@/assets/images/BMW/bmw-image1.png" />
          <img src="@/assets/images/BMW/bmw-image1-mobile.png" alt="" />
        </picture>
      </template>
      <p>
        Prenez la route grâce à la recharge rapide en courant continu (CC) gratuite aux chargeurs d’Electrify Canada
        pendant trois ans. Suivez simplement les étapes suivantes :
      </p>
      <div>
        <ul>
          <li>
            <strong>Étape 1 :</strong> Téléchargez l’application Electrify Canada
            <reference-link :num="2"
              >Les frais standard de messagerie et de données, et les autres tarifs et frais prélevés par votre
              opérateur de réseau mobile s’appliqueront à votre utilisation de l’application Electrify Canada. </reference-link
            >, créez votre compte et sélectionnez « Configurer la recharge publique ».
          </li>
          <li>
            <strong>Étape 2 :</strong> Sélectionnez « Choisir le forfait d’adhésion », puis « Offres Premium ».
            <ul>
              <li>Ou recherchez une offre Premium selon les informations sur le véhicule.</li>
              <li>
                Les membres actuels d’Electrify Canada peuvent sélectionner « Offres Premium » à l’onglet « Compte »,
                puis « J’ai un code d’inscription ».
              </li>
            </ul>
          </li>
          <li>
            <strong>Étape 3 :</strong> Entrez le code d’inscription à dix caractères que vous recevrez de votre
            concessionnaire BMW et sélectionnez le forfait de recharge BMW i7. Saisissez le NIV
            <reference-link :num="3"
              >Le NIV se trouve habituellement sur le tableau de bord du côté du conducteur, près de la partie
              inférieure du pare-brise ou sous celle-ci.</reference-link
            >
            à 17 chiffres de votre véhicule pour la vérification.
          </li>
          <li>
            <strong>Étape 4 :</strong> Acceptez la divulgation du forfait, puis touchez « Sélectionner ce forfait ».
          </li>
          <li>
            <strong>Étape 5 :</strong> Entrez les informations de paiement
            <reference-link :num="4"
              >Votre compte Electrify Canada est automatiquement approvisionné lorsque le solde est de moins de 5 $. Le
              montant de la recharge automatique que vous aurez choisi dans l’application vous sera facturé lors de la
              première charge à une station Electrify Canada. Le montant facturé sera retenu dans votre compte pour
              couvrir les coûts non inclus dans ce forfait, comme les frais d’inactivité et les taxes
              applicables.</reference-link
            >
            et configurez la recharge automatique.

            <ul>
              <li>
                Vous devrez entrer un mode de paiement
                <reference-link :num="4"
                  >Votre compte Electrify Canada est automatiquement approvisionné lorsque le solde est de moins de 5 $.
                  Le montant de la recharge automatique que vous aurez choisi dans l’application vous sera facturé lors
                  de la première charge à une station Electrify Canada. Le montant facturé sera retenu dans votre compte
                  pour couvrir les coûts non inclus dans ce forfait, comme les frais d’inactivité et les taxes
                  applicables.</reference-link
                >
                pour couvrir les frais d’occupation
                <reference-link :num="5"
                  >Les utilisateurs qui ne débranchent pas et ne déplacent pas leur véhicule dans les dix minutes qui
                  suivent la fin de leur session de recharge se verront facturer des frais d’inactivité de 0,40 $ par
                  minute. Ces frais visent à inciter les conducteurs à déplacer leur voiture rapidement afin que
                  d’autres puissent utiliser le chargeur. Vous pouvez être avisé de la fin de votre session de recharge
                  par un message texte<sup>2</sup> ou vous pouvez consulter l’écran du chargeur.
                </reference-link>
                éventuels et les taxes applicables.
              </li>
            </ul>
          </li>
          <li>
            <strong>Étape 6 :</strong> Pour pouvoir utiliser la fonctionnalité Brancher et recharger, connectez-vous à
            l’application Electrify Canada, naviguez jusqu’à votre « Compte » sous vos « Forfaits » puis suivez les
            instructions de l’application pour « Activer la fonctionnalité Brancher et recharger ».
          </li>
        </ul>
        <p>
          L’inscription à l’offre peut être aussi aisée que vos déplacements, mais si vous rencontrez des obstacles,
          composez le 1 833 231-3226 pour communiquer avec le service à la clientèle d’Electrify Canada 24 heures sur
          24, 7 jours sur 7.
        </p>
      </div>
      <div class="partner-page__cta">
        <router-link class="button partner-page__cta__link" :to="{ name: 'mobile-app-fr' }"
          >TÉLÉCHARGER L’APPLICATION</router-link
        >
        <a
          href="https://apps.apple.com/ca/app/electrify-canada/id1472391910?l=fr"
          rel="noopener"
          target="_blank"
          class="partner-page__cta__link"
          ><img loading="lazy" :alt="$t('downloadInTheAppStore')" src="@/assets/images/Global/app-store-logo-fr.png"
        /></a>
        <a
          href="https://play.google.com/store/apps/details?id=com.ec.evowner&hl=fr_CA&pli=1"
          rel="noopener"
          target="_blank"
          class="partner-page__cta__link"
          ><img loading="lazy" :alt="$t('getItOnGooglePlay')" src="@/assets/images/Global/google-play-badge-fr.png"
        /></a>
      </div>
    </TwoColumnContentBlock>

    <TwoColumnContentBlock heading="Recharger avec la fonctionnalité Brancher et recharger">
      <template #image>
        <picture>
          <source media="(min-width: 750px)" srcset="@/assets/images/BMW/bmw-image2.jpg" />
          <img src="@/assets/images/BMW/bmw-image2.jpg" alt="" />
        </picture>
      </template>

      <p>
        La fonctionnalité Brancher et recharger simplifie le paiement de votre recharge. Assurez-vous d’adhérer à votre
        forfait de recharge BMW i7 et d’activer la fonctionnalité Brancher et recharger dans l’application (voir l’étape
        d’activation de l’application ci-dessus).
      </p>
      <div>
        <ul>
          <li>
            <strong>Étape 1 :</strong> Ajoutez un mode de paiement. Vous devrez entrer un mode de paiement dans votre
            application Electrify Canada pour couvrir d’éventuels frais ainsi que les taxes applicables.
          </li>
          <li>
            <strong>Étape 2 :</strong> Présentez-vous à n’importe quelle borne de recharge rapide en CC d’Electrify
            Canada.
          </li>
          <li><strong>Étape 3 :</strong> Branchez le pistolet de recharge à votre véhicule électrique.</li>
          <li>
            <strong>Étape 4 :</strong> Vérifiez la recharge. Consultez l’écran de la borne de recharge pour vous assurer
            que votre recharge a commencé.
          </li>
          <li>
            <strong>Étape 5 :</strong> Détendez-vous. Pendant que votre véhicule se recharge, surveillez la progression
            de la recharge dans l’application Electrify Canada.
          </li>
        </ul>
      </div>
      <router-link class="button" :to="{ name: 'locate-charger-fr' }">TROUVER UNE BORNE DE RECHARGE</router-link>
    </TwoColumnContentBlock>
  </article>
</template>

<script>
import lang from '@/lang';
import Hero from '@/components/Hero/Hero.vue';
import ReferenceLink from '@/components/ReferenceLink.vue';
import TwoColumnContentBlock from '@/components/TwoColumnContentBlock/TwoColumnContentBlock.vue';

export default {
  name: 'bmw',
  components: {
    ReferenceLink,
    Hero,
    TwoColumnContentBlock,
  },
  metaInfo: {
    title: 'BMW landing page | Electrify Canada',
    link: [{ rel: 'canonical', href: 'https://www.electrify-canada.ca/fr/bmw-i7/' }],
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
};
</script>
