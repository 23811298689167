import { render, staticRenderFns } from "./Etron-fr.vue?vue&type=template&id=6bd9c36d&"
import script from "./Etron-fr.vue?vue&type=script&lang=js&"
export * from "./Etron-fr.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports