<template>
  <article class="faq-column">
    <h1 id="glossary-fr">Glossaire</h1>
    <accordion header-id="glossary-fr">
      <accordion-item title="Recharge en CA">
        <p>
          Le courant alternatif (CA) est généralement la façon dont les gens rechargent leur véhicule électrique pendant
          la nuit. La recharge en courant alternatif utilise une tension inférieure, soit de niveau 1 (120 volts ou
          courant domestique normal) ou de niveau 2 (240 volts ou la tension équivalente d’un séchoir électrique). Bien
          que les faibles niveaux de tension entraînent une recharge plus lente, la recharge en CA peut être facilement
          installée dans la plupart des foyers. C’est une excellente solution pour les résidences, les lieux de travail,
          les immeubles à logements multiples et le autres lieux de stationnement à long terme comme les hôtels et les
          garages de stationnement municipaux ou aéroportuaires.
        </p>
      </accordion-item>
      <accordion-item title="Bornes à alimentation symétrique">
        <p>
          Aux bornes Electrify Canada, la mention « Alimentation symétrique » peut figurer sur l’étiquette ou sur
          l’écran de la borne. Les bornes à alimentation symétrique sont généralement situées côte à côte et partagent
          une baie d’alimentation, qui peut fournir jusqu’à 350 kW sur les deux bornes.
        </p>
        <p>
          Une borne à alimentation symétrique peut fournir des vitesses ultrarapides allant jusqu’à 150 kW aux véhicules
          capables de prendre en charge cette puissance, et peut fournir des vitesses hyper rapides allant jusqu’à 350
          kW aux véhicules compatibles lorsque la borne adjacente n’est pas utilisée.
        </p>
      </accordion-item>
      <accordion-item title="VEB (véhicule électrique à batterie)">
        <p>
          Un véhicule électrique à batterie (VEB) est un type de véhicule électrique. Les VEB emmagasinent l’énergie
          dans les batteries à l’intérieur du véhicule. Un VEB n’a pas de moteur à combustion interne, mais dépend
          uniquement d’un système de batterie électrique pour son énergie. Il doit être branché à une source de recharge
          pour recharger sa batterie. Depuis 2018, de nombreux VEB peuvent offre une portée de 160 à 480 kilomètres avec
          une seule recharge.
        </p>
      </accordion-item>
      <accordion-item title="CCS (Combined Charging System, système de recharge combiné)">
        <p>
          Le CCCS est un protocole de recharge rapide en courant continu (CC) certifié par la Society of Automotive
          Engineers (SAE), et est présent sur les véhicules produits par des entreprises automobiles européennes et
          américaines. Le terme « combiné » dans le nom CCS désigne sa capacité à incorporer la prise de niveau 2 (norme
          J1772™) et le connecteur de charge rapide CC dans la même prise plus grande. L’embout CCS est aussi
          communément appelé « prise combo. » Vous devrez vous renseigner auprès du constructeur de votre voiture pour
          savoir si votre véhicule est compatible avec une prise CCS ou CHAdeMO. Toutes les stations d’Electrify Canada
          offrent des connecteurs CCS et CHAdeMO.
        </p>
      </accordion-item>
      <accordion-item title="CHAdeMO">
        <p>
          CHAdeMO est un protocole de recharge rapide en CC qui a été développé pour la première fois sur le marché
          japonais des VE; aux États-Unis, il est actuellement capable de recharger des véhicules comme Nissan Leaf et
          Mitsubishi iMiEV. Le protocole CHAdeMO est officiellement reconnu comme une norme internationale de recharge
          en CC par la Commission électrotechnique internationale (CEI) au même titre que les prises CCS aux États-Unis
          et en Europe, et la fiche GB/T chinoise. Toutes les stations Electrify Canada sont équipées à la fois de
          bornes CHAdeMO et CCS.
        </p>
      </accordion-item>
      <accordion-item title="Recharger un VE">
        <p>
          Ravitaillement électrique d’une batterie de véhicule électrique. Le temps de recharge d’une batterie dépend de
          la taille de la batterie en kWh et de la quantité de courant électrique fournie.
        </p>
      </accordion-item>
      <accordion-item title="ID de la borne de recharge">
        <p>
          Chaque borne de recharge Electrify Canada porte un identifiant unique, qui se trouve sur la borne, sur l’écran
          et dans l’application Electrify Canada.
        </p>
        <img
          src="@/assets/images/Glossary/chargerId-fr.svg?external"
          alt="Chaque borne Electrify Canada porte un identifiant unique, qui se trouve sur la borne, sur l’écran du borne et dans l’application Electrify Canada."
          class="charger-id"
        />
      </accordion-item>
      <accordion-item title="CC (courant continu)">
        <p>
          Le courant continu est un courant électrique de direction constante. Toutes les bornes de recharge rapide
          d’Electrify Canada prennent en charge le CC.
        </p>
      </accordion-item>
      <accordion-item title="Recharge rapide en CC">
        <p>
          La charge en courant continu (CC) pour véhicules électriques permet des vitesses de charge plus élevées,
          puisque le CC peut être fourni directement à la batterie du véhicule électrique à des niveaux de puissance
          normalement supérieurs à la charge en CA. Plus la puissance CC est élevée, plus le véhicule électrique peut
          être rechargé rapidement (à condition que le VE prenne en charge cette puissance). La recharge ralentira vers
          la fin de votre session afin de préserver la batterie de votre véhicule. Cela se produit généralement autour
          de 80 % du remplissage, mais cela dépend du modèle de votre VE.
        </p>
        <p>
          Pour illustrer la différence de puissance de recharge entre la recharge rapide en CA et en CC, une borne de
          recharge CA de niveau 2, 7,2 kW, peut prendre une heure pour fournir environ 27 milles d’autonomie des VE. Une
          borne de recharge rapide en CC de 50 kW peut fournir la même autonomie de 27 milles en 10 minutes environ.
        </p>
      </accordion-item>
      <accordion-item title="Passe numérique pour membres">
        <p>
          Une « carte » virtuelle sur votre téléphone intelligent qui utilise la fonction de communication en champ
          proche (CCP) de votre téléphone pour transmettre les renseignements de votre compte à la borne, ce qui vous
          permet d’utiliser les fonds du solde de votre compte Electrify Canada.
        </p>
        <p>
          Cela signifie que vous pouvez payer une recharge en tenant simplement votre téléphone à proximité du lecteur
          RFID de la borne (et non du lecteur de carte de crédit).
        </p>
      </accordion-item>
      <accordion-item title="VE (véhicule électrique)">
        <p>
          Un véhicule électrique utilise des moteurs électriques et des contrôleurs de moteur pour alimenter le véhicule
          au lieu de le propulser par l’intermédiaire d’un moteur à combustion interne. Les VE stockent l’électricité
          dans une batterie qui alimente les roues du véhicule par l’intermédiaire d’un moteur électrique. Différents
          types de véhicules électriques comprennent le véhicule électrique à batterie (BEV), le véhicule électrique
          hybride rechargeable (PHEV), le véhicule électrique hybride (HEV) et le véhicule électrique à pile à
          combustible (FCEV).
        </p>
      </accordion-item>
      <accordion-item title="Hyper rapide">
        <p>
          Hyper rapide est un terme descriptif qui désigne les connecteurs CCS procurant jusqu’à 350 kW. Les connecteurs
          CCS hyper rapides sont ceux qui portent une étiquette verte dans les stations Electrify Canada.
        </p>
      </accordion-item>
      <accordion-item title="Temps d’inactivité">
        <p>
          La politique de tarification d’Electrify Canada comprend des frais d’inactivité de 0,40 $ par minute à compter
          de dix minutes après la fin de votre séance de recharge, si vous n’avez pas débranché et déplacé votre
          véhicule. Cette fonction est conçue pour encourager les conducteurs à déplacer leur voiture une fois qu’ils
          ont fini de recharger pour laisser de la place aux autres qui ont besoin de recharger.
        </p>
      </accordion-item>
      <accordion-item title="J1772™">
        <p>
          L’un des connecteurs les plus courants est le niveau 2 J1772™. Une norme de la Society of Automotive Engineers
          (SAE) qui a été adoptée en Amérique du Nord et au Japon.
        </p>
      </accordion-item>
      <accordion-item title="kWh (kilowattheure)">
        <p>
          Unité d’énergie équivalente à l’énergie transférée ou dépensée en une heure par un kilowatt de puissance. La
          taille de la batterie d’une voiture électrique se mesure en kilowattheures, alors considérez-la comme
          l’équivalent de la taille du réservoir d’essence d’un véhicule à combustion interne.
        </p>
      </accordion-item>
      <accordion-item title="OCPP">
        <p>
          OCPP est la norme développée pour fournir une communication puissante, ouverte et interopérable entre les
          différentes sociétés d’infrastructure, de matériaux et de réseaux de recharge de véhicules électriques. L’Open
          Charge Alliance (OCA) est le consortium mondial de chefs de file de l’infrastructure de VE publics et privés
          qui se sont réunis pour promouvoir cette norme.
        </p>
      </accordion-item>
      <accordion-item title="PHEV (véhicule électrique hybride rechargeable)">
        <p>
          Un PHEV est un type de véhicule électrique hybride qui combine un moteur à combustion interne avec un moteur
          électrique et une grande batterie qui peut être rechargée en se branchant sur une prise électrique ou, dans
          certains cas, une borne de recharge de véhicule électrique. Les hybrides rechargeables peuvent généralement
          fonctionner dans au moins deux modes : « tout électrique », où le moteur et la batterie fournissent toute
          l’énergie de la voiture, et « hybride », où l’électricité et l’essence sont toutes deux utilisées.
        </p>
      </accordion-item>
      <accordion-item title="Plug&Charge">
        <p>
          Cette technologie comprend la norme IEC/ISO 15118 conçue pour faciliter le paiement d’une recharge. Après
          avoir créé un compte Electrify Canada et saisi un mode de paiement, la borne pourra reconnaître les véhicules
          compatibles et effectuera automatiquement le paiement de la recharge. Pour les véhicules compatibles, nul
          besoin de cartes ou d’applications à la station; il suffit de brancher et de charger. Ce système novateur est
          maintenant offert sur tous les bornes Electrify Canada à travers le Canada et peut être utilisé avec les VE
          compatibles avec la technologie Plug&Charge d’Electrify Canada, y compris la Porsche Taycan.
        </p>
      </accordion-item>
      <accordion-item title="SOC (état de charge)">
        <p>
          Le SOC est l’équivalent d’une jauge de carburant pour la batterie d’un VE. Les unités de SOC sont des points
          de pourcentage, 0 % signifiant aucune charge restante et 100 % signifiant entièrement rechargée. Si votre
          voiture met ces informations à votre disposition, nos bornes de recharge vous indiqueront le SOC de votre
          véhicule au fur et à mesure de votre recharge.
        </p>
      </accordion-item>
      <accordion-item title="ID de la borne">
        <p>
          Chaque borne de recharge Electrify Canada porte un identifiant unique, qui se trouve sur la borne, sur l’écran
          et dans l’application Electrify Canada.
        </p>
        <img
          src="@/assets/images/Glossary/stationId-fr.svg?external"
          alt="Chaque station Electrify Canada porte un identifiant unique, qui se trouve sur la borne, sur l’écran du borne et dans l’application Electrify Canada."
          class="charger-id"
        />
      </accordion-item>
      <accordion-item title="Ultrarapide ">
        <p>
          Ultrarapide est un terme descriptif qui désigne les connecteurs CCS procurant jusqu’à 150 kW. Les connecteurs
          CCS ultrarapides sont ceux qui portent une étiquette turquoise dans les stations Electrify Canada.
        </p>
      </accordion-item>
    </accordion>
  </article>
</template>

<script>
import Accordion from '../../components/Accordion.vue';
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  name: 'glossary',
  metaInfo: {
    title: 'Glossaire de la recharge des VE',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/fr/glossary/',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/glossary/',
        hreflang: 'en-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/fr/glossary',
        hreflang: 'fr-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/glossary/',
        hreflang: 'x-default',
      },
    ],
    meta: [
      {
        name: 'description',
        content:
          'Consultez notre glossaire pour apprendre les termes importants relatifs à la recharge de votre véhicule électrique (VE) sur notre réseau public de recharge d’Electrify Canada.',
      },
    ],
  },

  components: { Accordion, AccordionItem },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    load() {
      return this.$route.query.load;
    },
  },

  mounted() {
    if (this.load) {
      const item = document.querySelectorAll('[class~=accordion-trigger]');
      const itemID = item[this.load].id.split('-')[0];
      const accordion = document.querySelectorAll('[class~=accordion]');
      const accordionId = accordion[0].id.split('-')[0];

      this.$root.$emit('toggleAccordion', {
        accordionID: accordionId,
        uniqueID: itemID,
      });
      setTimeout(() => {
        const el = document.getElementById(item[this.load].id);
        el.scrollIntoView({ behavior: 'smooth' });
      }, 300);
    }
  },
};
</script>

