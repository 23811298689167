<template>
  <div id="charge-your-ev-tabs">
    <div class="picture-container">
      <picture>
        <source
          media="(min-width:750px)"
          :srcset="require(`@/assets/images/ChargingYourEv/AtTheCharger/hero.webp`)"
        />
        <img
          alt=""
          :src="require(`@/assets/images/ChargingYourEv/AtTheCharger/hero-mobile.webp`)"
        />
      </picture>
    </div>
    <h1 id="tabs-title">{{ $t('chargingYourEv') }}</h1>
    <div id="ev-tabs-container" role="tablist" ref="tablist">
      <button
        v-for="label in labels"
        :key="label.value"
        v-text="label.text"
        :id="`tab-${label.value}`"
        class="ev-tab"
        role="tab"
        :tabindex="value === label.value ? null : '-1'"
        :aria-controls="`tabpanel-${label.value}`"
        :aria-selected="value === label.value ? 'true' : 'false'"
        :class="{ 'active-tab': value === label.value }"
        @click="handleTabClick(label.value)"
      />
    </div>
  </div>
</template>

<script>
import ChargingYourEv from '@/lang/ChargingYourEv';

export default {
  name: 'ChargingYourEvTabs',
  props: ['value'],

  i18n: {
    messages: ChargingYourEv,
    sharedMessages: {},
  },
  computed: {
    labels() {
      return [
        { text: this.$i18n.t('atTheChargerLabel'), value: 'AtTheCharger' },
        { text: this.$i18n.t('inTheAppLabel'), value: 'InTheApp' },
      ];
    },
  },
  mounted() {
    this.$refs.tablist.addEventListener('keydown', (e) => {
      switch (e.key) {
        case 'ArrowLeft':
          this.handleTabKeypress(e, this.getPreviousTab());
          break;

        case 'ArrowRight':
          this.handleTabKeypress(e, this.getNextTab());
          break;

        case 'Home':
          this.handleTabKeypress(e, this.labels[0].value);
          break;

        case 'End':
          this.handleTabKeypress(e, this.labels[this.labels.length - 1].value);
          break;

        default:
          break;
      }
    });
  },

  methods: {
    getPreviousTab() {
      const index = this.labels.findIndex((label) => label.value === this.value);
      if (index === 0) {
        return this.labels[this.labels.length - 1].value;
      }
      return this.labels[index - 1].value;
    },
    getNextTab() {
      const index = this.labels.findIndex((label) => label.value === this.value);
      if (index === this.labels.length - 1) {
        return this.labels[0].value;
      }
      return this.labels[index + 1].value;
    },
    /**
     * Send to the parent the clicked tab value
     * The parent will pass to TabContent, which will render the correct content
     */
    handleTabClick(value) {
      this.$emit('input', value);
    },
    handleTabKeypress(e, value) {
      this.handleTabClick(value);
      this.$refs.tablist.querySelector(`#tab-${value}`).focus();
      e.preventDefault();
    },
  },
};
</script>

<style lang="scss" scoped>
#charge-your-ev-tabs {
  background-color: #0d1a3f;

  .picture-container {
    @media (min-width: 750px) {
      display: flex;
      justify-content: center;
    }
  }

  img {
    @media (max-width: 749px) {
      width: 100%;
    }
    max-height: 513px;
  }
}
#tabs-title {
  color: white;
  line-height: 1;
  margin: 25px 0;
  font-weight: 600;
  padding-left: 32px;
  @media (min-width: 750px) {
    padding-left: 112px;
  }
}
#ev-tabs-container {
  display: inline-flex;
  max-width: 525px;
  position: relative;
  bottom: -1px;
  padding-left: 32px;
  @media (min-width: 750px) {
    padding-left: 112px;
  }
}
.ev-tab {
  align-items: center;
  appearance: none;
  background-color: white;
  border: 0;
  border-radius: 5px 5px 0 0;
  color: #1f2555;
  cursor: pointer;
  display: flex;
  font-family: Ubuntu, Arial, sans-serif;
  font-size: 16px;
  justify-content: center;
  line-height: 1.3;
  margin: 0 7px;
  min-height: 60px;
  width: 100px;
  opacity: 0.7;
  padding: 0 20px;
  text-align: center;

  &:first-child {
    margin-left: 0;
  }
  &.active-tab {
    opacity: 1;
    font-weight: 600;
  }
  @media (min-width: 750px) {
    margin: 0 12px;
    min-width: 200px;
    font-size: 22px;
    letter-spacing: 0.45px;
  }
}
</style>
