<template>
  <div class="pricing" :class="{ mobile: isMobile, 'is-big': isBig, phone: isPhone }">
    <section class="hero">
      <div class="container">
        <h1 class="header-title">Tarification et forfaits pour la recharge de VE</h1>
      </div>
    </section>
    <section class="plans">
      <div class="container">
        <img
          :src="
            isPhone
              ? require('@/assets/images/Pricing/pricing_hero_mobile.webp')
              : require('@/assets/images/Pricing/pricing_hero.webp')
          "
          alt=""
        />
      </div>
    </section>
    <section class="upsell">
      <img class="bolt" src="@/assets/images/Pricing/electric-bolt.webp" alt="" v-if="!isBig" />
      <p>Utilisez l’application Electrify Canada pour voir la tarification en temps réel</p>
      <div class="app-icon">
        <a href="https://apps.apple.com/ca/app/electrify-canada/id1472391910?l=fr" rel="noopener" target="_blank">
          <img
            loading="lazy"
            src="@/assets/images/Global/app-store-logo-fr.png"
            :alt="$t('downloadInTheAppStore')"
          /> </a
        ><br />
        <a href="https://play.google.com/store/apps/details?id=com.ec.evowner&hl=fr_CA" rel="noopener" target="_blank">
          <img loading="lazy" src="@/assets/images/Global/google-play-badge-fr.png" :alt="$t('getItOnGooglePlay')" />
        </a>
      </div>
    </section>
    <section class="wrapper l-one--has-bg">
      <div class="l-one__col">
        <h2>Information relative à la tarification</h2>
        <p>
          Les tarifs pour la recharge rapide en CC sont établis en fonction de l’emplacement de la borne de recharge et
          de votre forfait.
          <strong>La tarification en temps réel peut être obtenue dans l’application ou à la borne de recharge.</strong>
        </p>
        <span>Dans l’application :</span>
        <ul>
          <li>Pour afficher la tarification dans l’application, sélectionnez la station sur la carte</li>
        </ul>
        <span>À la borne :</span>
        <ul>
          <li>
            Consultez la tarification sur l’écran d’une borne de recharge de n’importe quelle station. Des frais
            d’occupation peuvent être imposés pour le temps de stationnement après la fin de votre session de recharge.
          </li>
        </ul>

        <div class="pricing-disclaimer">
          <p>
            Consultez l’écran de la borne de recharge pour obtenir les informations les plus récentes concernant les
            prix, les niveaux de puissance et les frais d’occupation pour votre session. Vous pouvez également consulter
            ces renseignements dans votre application Electrify Canada
          </p>
        </div>
      </div>
    </section>

    <section class="ways-to-pay">
      <div class="container">
        <h2 class="header">Façons de payer</h2>
        <div class="payments">
          <div class="payment">
            <div class="left">
              <h2 class="sub-header sub-header--primary">Invités</h2>
              <p class="how">
                Carte de crédit ou<br v-if="isBelow450" />
                de débit
              </p>
              <p :class="{ small: isSmall }">
                Faites glisser votre carte ou <br v-if="!isSmall" />appuyez votre carte sur le<br v-if="!isSmall" />
                lecteur de carte de crédit<br v-if="!isSmall" />
                de la borne de recharge.
              </p>
            </div>
            <img loading="lazy" class="card card-fr" src="@/assets/images/Pricing/Card.webp" alt="" />
          </div>
          <div class="payment">
            <div class="left">
              <h2 class="sub-header sub-header--primary">Membres Passe ou Passe+</h2>
              <p class="how">Payez sans contact au moyen de l’application</p>
              <p>
                Ajoutez simplement une carte à<br />
                votre profil dans l’application pour payer<br />en toute facilité.
              </p>
              <router-link to="/fr/application-mobile/" class="learn-more"
                >EN SAVOIR PLUS<span class="arrow"
              /></router-link>
            </div>
            <img loading="lazy" class="phone phone-fr" src="@/assets/images/Pricing/Phone.webp" alt="" />
          </div>
        </div>
      </div>
    </section>
    <div class="container">
      <pricingfaq />
    </div>
  </div>
</template>

<script>
import lang from '@/lang';
import AOS from 'aos';
import pricingfaq from '@/components/PricingFaq.vue';

export default {
  name: 'pricing',
  metaInfo: {
    title: 'Tarification et abonnement pour la recharge des VE',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/fr/tarification/',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/pricing/',
        hreflang: 'en-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/fr/tarification/',
        hreflang: 'fr-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/pricing/',
        hreflang: 'x-default',
      },
    ],
    meta: [
      {
        name: 'description',
        content:
          'Electrify Canada vise à maintenir les prix bas tout en fournissant de puissants bornes de véhicules électriques (VE). En savoir plus sur nos tarifs.',
      },
    ],
  },

  components: {
    pricingfaq,
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
  data() {
    return {
      selectedState: 'CA',
      country: 'US',
      currentPrices: false,
      noPricesAvailable: false,
      error: null,
      axiosCancel: null,
      staic: null,
    };
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    isBig() {
      return this.$resize && this.$mq.below(950);
    },
    isPhone() {
      return this.$resize && this.$mq.below(749);
    },
    isBelow450() {
      return this.$resize && this.$mq.below(450);
    },
    isSmall() {
      return this.$resize && this.$mq.below(350);
    },
  },
  mounted() {
    setTimeout(() => {
      AOS.refresh();
    }, 300);
  },
};
</script>
<style lang="scss" scoped>
.pricing-detail {
  .sub-container {
    @media only screen and (max-width: 750px) {
      margin: 0 20px;
    }
  }
}
</style>
