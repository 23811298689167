<template>
  <div class="earth-week-promo-over">
    <section class="l-one__col l-one__col--x-wide">
      <Card
        heading="Earth Week is over but our commitment to it remains in full bloom."
        headlineLevel="h2"
        :maxWidth="1200"
      >
        <template #image>
          <img src="@/assets/images/EarthWeekPromoOver/green-plug.webp" alt="" />
        </template>
        <p>
          Electrify Canada’s goal has always been to promote greater Zero-Emission Vehicle (ZEV) adoption by offering an
          Ultra-Fast and convenient charging network that gives Canadians the speed and reliability they need to
          confidently drive electric.
        </p>
        <p>Follow us on social media to stay up to date on new station openings and other current topics.</p>
        <nav role="navigation" class="img-container" aria-label="Social Media Site Links">
          <a
            style="margin-left: 8px; margin-right: 8px"
            v-for="(image, index) of images.elca"
            :key="index"
            :href="image.linkTo"
            rel="noopener"
            target="_blank"
          >
            <img loading="lazy" :src="image.content" :alt="image.alt" style="width: 20px" />
          </a>
        </nav>
      </Card>
    </section>
  </div>
</template>

<script>
import Card from '@/components/Card/Card.vue';

export default {
  components: { Card },
  metaInfo: {
    title: 'Celebrating Earth Week | Electrify America',
    meta: [
      {
        name: 'description',
        content:
          "We're committed to making the planet a more sustainable place. Here's how we're serving our communities this Earth Week and year-round.",
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/earth-week/' }],
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(750);
    },
  },
  data() {
    return {
      images: {
        elca: [
          {
            content: require('@/assets/images/EarthWeekPromoOver/facebook.svg?external'),
            linkTo: 'https://www.facebook.com/Electrify-Canada-677205735992875/',
            alt: 'Facebook',
          },
          {
            content: require('@/assets/images/EarthWeekPromoOver/instagram.svg?external'),
            linkTo: 'https://www.instagram.com/electrifycanada/',
            alt: 'Instagram',
          },
          {
            content: require('@/assets/images/EarthWeekPromoOver/x.svg?external'),
            linkTo: 'https://x.com/CanadaElectrify',
            alt: 'X',
          },
          {
            content: require('@/assets/images/EarthWeekPromoOver/linkedin.svg?external'),
            linkTo: 'https://www.linkedin.com/company/electrify-canada-ca-eng/about',
            alt: 'LinkedIn',
          },
        ],
      },
    };
  },
};
</script>
