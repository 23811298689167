const ContactUs = () => import('@/views/Contact/ContactUs.vue');
const Signup = () => import('@/views/Contact/Signup.vue');
const RealEstate = () => import('@/views/Contact/RealEstate.vue');
const RealEstateFr = () => import('@/views/Contact/RealEstate-fr.vue');
const RealEstateVerification = () => import('@/views/Contact/RealEstateVerification.vue');
const RealEstateVerificationFr = () => import('@/views/Contact/RealEstateVerification-fr.vue');

const routes = [
  {
    path: '/contact-us/',
    name: 'contact-us-en',
    pathToRegexpOptions: { strict: true },
    component: ContactUs,
  },
  {
    path: '/fr/pour-nous-joindre/',
    name: 'contact-us-fr',
    pathToRegexpOptions: { strict: true },
    component: ContactUs,
  },

  {
    path: '/sign-up/',
    name: 'sign-up-en',
    pathToRegexpOptions: { strict: true },
    component: Signup,
  },
  {
    path: '/fr/sinscrire/',
    name: 'sign-up-fr',
    pathToRegexpOptions: { strict: true },
    component: Signup,
  },
  {
    path: '/real-estate/',
    name: 'real-estate-en',
    pathToRegexpOptions: { strict: true },
    component: RealEstate,
  },
  {
    path: '/fr/real-estate/',
    name: 'real-estate-fr',
    pathToRegexpOptions: { strict: true },
    component: RealEstateFr,
  },
  {
    path: '/real-estate-verification/',
    name: 'real-estate-verification-en',
    pathToRegexpOptions: { strict: true },
    component: RealEstateVerification,
  },
  {
    path: '/fr/real-estate-verification/',
    name: 'real-estate-verification-fr',
    pathToRegexpOptions: { strict: true },
    component: RealEstateVerificationFr,
  },
];

const exportedRoutes = routes;
export default exportedRoutes;
