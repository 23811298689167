const BMW = () => import('@/views/Partners/BMW.vue');
const BMWFR = () => import('@/views/Partners/BMW-fr.vue');
const EGolf = () => import('@/views/Partners/EGolf.vue');
const EGolfFR = () => import('@/views/Partners/EGolf-fr.vue');
const EtronPartner = () => import('@/views/Partners/Etron.vue');
const EtronPartnerFR = () => import('@/views/Partners/Etron-fr.vue');
const Porsche = () => import('@/views/Partners/Porsche.vue');
const PorscheFR = () => import('@/views/Partners/Porsche-fr.vue');
const VWId = () => import('@/views/Partners/VWId.vue');
const VWIdFR = () => import('@/views/Partners/VWId-fr.vue');

const routes = [
  {
    path: '/bmw-i7/',
    name: 'bmw-i7-en',
    pathToRegexpOptions: { strict: true },
    component: BMW,
  },
  {
    path: '/fr/bmw-i7/',
    name: 'bmw-i7-fr',
    pathToRegexpOptions: { strict: true },
    component: BMWFR,
  },

  {
    path: '/e-golf/',
    name: 'e-golf-en',
    pathToRegexpOptions: { strict: true },
    component: EGolf,
  },
  {
    path: '/fr/e-golf/',
    name: 'e-golf-fr',
    pathToRegexpOptions: { strict: true },
    component: EGolfFR,
  },

  {
    path: '/e-tron/',
    name: 'e-tron-en',
    pathToRegexpOptions: { strict: true },
    component: EtronPartner,
    meta: {
      title: 'e-tron',
    },
  },
  {
    path: '/fr/e-tron/',
    name: 'e-tron-fr',
    pathToRegexpOptions: { strict: true },
    component: EtronPartnerFR,
    meta: {
      title: 'e-tron',
    },
  },

  {
    path: '/porsche/',
    name: 'porsche-en',
    pathToRegexpOptions: { strict: true },
    component: Porsche,
    meta: {
      title: 'Porsche',
    },
  },
  {
    path: '/fr/porsche/',
    name: 'porsche-fr',
    pathToRegexpOptions: { strict: true },
    component: PorscheFR,
    meta: {
      title: 'PorscheFR',
    },
  },

  {
    path: '/vw-id4/',
    name: 'vw-id4-en',
    pathToRegexpOptions: { strict: true },
    component: VWId,
    meta: {
      title: 'VW ID4',
    },
  },
  {
    path: '/fr/vw-id4/',
    name: 'vw-id4-fr',
    pathToRegexpOptions: { strict: true },
    component: VWIdFR,
    meta: {
      title: 'VW ID4',
    },
  },
];
const exportedRoutes = routes;
export default exportedRoutes;
