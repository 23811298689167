<template>
  <article class="single-col-page">
    <div class="l-one__col l-one__col--wide">
      <h1>{{ $t('signup') }}</h1>
      <p>
        {{ $t('furtherUpdates') }}
      </p>
      <h2>{{ $t('requiredInfo') }}</h2>
      <form id="signup" @submit.prevent="validate" ref="form" :aria-label="$t('signup')">
        <label ref="firstname" for="first_name"
          >{{ $t('firstName') }}<span class="require-mark">*</span>
          <input
            id="first_name"
            maxlength="39"
            name="first_name"
            type="text"
            pattern="^[a-zA-Z0-9]+(?:[a-zA-Z0-9-])+(?:[a-zA-Z0-9])$"
            required
            v-model="firstName"
          />
        </label>
        <label ref="lastname" for="last_name"
          >{{ $t('lastName') }}<span class="require-mark">*</span>
          <input
            id="last_name"
            maxlength="39"
            name="last_name"
            type="text"
            pattern="^[A-Za-z0-9$&+,:;=?@#|'<>.^*()%!-_]+(?: +[A-Za-z0-9$&+,:;=?@#|'<>.^*()%!-_]+)*$"
            title="This field only accepts letters and special characters (no trailing spaces)"
            required
            v-model="lastName"
          />
        </label>
        <label ref="email" for="email"
          >{{ $t('email') }}<span class="require-mark">*</span>
          <input
            id="email"
            maxlength="80"
            name="email"
            type="text"
            pattern="[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])"
            title="Please enter the correct email address"
            required
            v-model="email"
          />
        </label>
        <label for="phone"
          >{{ $t('phone') }}<span class="require-mark">*</span>
          <input
            id="phone"
            maxlength="10"
            name="phone"
            type="text"
            pattern="^\d+$"
            title="This field only accepts numbers"
            required
            v-model="phone"
          />
        </label>
        <h2>{{ $t('mailingAddress') }}</h2>
        <label for="address1"
          >{{ $t('street') }}<span class="require-mark">*</span>
          <textarea maxlength="100" id="address1" name="street" type="text" required v-model="street"></textarea>
        </label>
        <label for="city"
          >{{ $t('city') }}<span class="require-mark">*</span>
          <input id="city" maxlength="40" name="city" type="text" required v-model="city" />
        </label>
        <label for="country_code"
          >{{ $t('country') }}<span class="require-mark">*</span>
          <select id="country_code" name="country_code" v-model="country" required>
            <option value="" disabled selected hidden>{{ $t('selectACountry') }}</option>
            <option v-for="(item, index) in countryList" :value="item" :key="index">{{ item }}</option>
          </select>
        </label>
        <label for="state_code"
          >{{ isUs ? $t('states') : $t('provinces') }}<span style="color: red">*</span>
          <select id="state_code" name="state_code" :disabled="country === ''" v-model="state" required>
            <option value="" selected hidden>{{ $t('selection') }} {{ isUs ? $t('state') : $t('province') }}</option>
            <option v-for="item in StatesProvinceList" :value="item.name" :key="item.abbreviation">
              {{ item.name }}
            </option>
          </select>
        </label>
        <label for="zip">
          {{ isUs ? $t('zipCode') : $t('postalCode') }}<span class="require-mark">*</span>
          <input
            v-if="isUs"
            id="zip"
            maxlength="5"
            name="zip"
            type="text"
            pattern="^\d+$"
            title="This field only accepts numbers"
            required
            v-model="postalCode"
          />
          <input
            v-if="!isUs"
            id="zip"
            maxlength="7"
            name="zip"
            type="text"
            pattern="^[a-zA-Z0-9 ]+$"
            title="This field only accepts numbers"
            required
            v-model="postalCode"
          />
        </label>
        <h2>{{ $t('preference') }}</h2>
        <label for="topics"
          >{{ $t('topics') }}
          <select id="topics" name="topics" title="Topics" multiple v-model="topics" class="topics">
            <option v-for="(item, index) in topicList" :value="item" :key="index">{{ item }}</option>
          </select>
        </label>
        <label for="communication"
          >{{ $t('communication') }}
          <select id="communication" name="communication" title="Communication Preference" v-model="communicationPref">
            <option value>{{ $t('selectCommunicationMethod') }}</option>
            <option v-for="(item, index) in communicationMethods" :value="item" :key="index">{{ item }}</option>
          </select>
        </label>
        <label for="language"
          >{{ $t('language') }}
          <select id="language" name="language" title="Language Preference" v-model="languagePref">
            <option value>{{ $t('selectLanguage') }}</option>
            <option v-for="(item, index) in languageList" :value="item" :key="index">{{ item }}</option>
          </select>
        </label>
        <label for="future-comm" class="checkbox">
          <input id="future-comm" name="future-comm" type="checkbox" value="1" required v-model="optForFutureComm" />
          {{ $t('agreeFutureComms') }}*
        </label>
        <p :style="{ 'font-size': '12px' }"><sup>*</sup>{{ $t('agreeUnsubscribe') }}</p>
        <input ref="submitButton" class="button button--submit" name="Sign up" type="submit" :value="$t('submit')" />
        <p v-show="serverError !== ''" class="server-error">
          Error:
          <br />
          {{ serverError }}
        </p>
      </form>
    </div>
  </article>
</template>

<script>
import lang from '@/lang/Forms';
import errorMessages from '@/constants/errorMessages';
import States from '@/assets/json/us_states.json';
import Provinces from '@/assets/json/ca_provinces.json';
import ApiService from '@/services/Api';

export default {
  name: 'signup',
  metaInfo() {
    return {
      title: this.$i18n.t('meta.signUp.title'),
      link: [
        {
          rel: 'canonical',
          href:
            this.$i18n.locale === 'en'
              ? 'https://www.electrify-canada.ca/sign-up/'
              : 'https://www.electrify-canada.ca/fr/sinscrire/',
        },
        {
          rel: 'alternate',
          href: 'https://www.electrify-canada.ca/sign-up/',
          hreflang: 'en-ca',
        },
        {
          rel: 'alternate',
          href: 'https://www.electrify-canada.ca/fr/sinscrire/',
          hreflang: 'fr-ca',
        },
        {
          rel: 'alternate',
          href: 'https://www.electrify-canada.ca/sign-up/',
          hreflang: 'x-default',
        },
      ],
      meta: [
        {
          name: 'description',
          content: this.$i18n.t('meta.signUp.description'),
        },
      ],
    };
  },
  data() {
    return {
      StatesProvinceList: Provinces[this.$store.state.lang],
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      street: null,
      city: null,
      country: 'Canada',
      state: null,
      postalCode: null,
      topics: [],
      countryList: this.$i18n.t('countryList'),
      communicationPref: null,
      languagePref: null,
      optForFutureComm: null,
      serverError: '',
    };
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
  computed: {
    topicList() {
      return this.$i18n.t('signupTopicList');
    },
    communicationMethods() {
      return this.$i18n.t('communicationMethods');
    },
    languageList() {
      return this.$i18n.t('languageList');
    },
    isUs() {
      return this.country === 'United States';
    },
  },
  methods: {
    resetLableColor() {
      this.$refs.email.style.color = '#8089a8';
      this.$refs.firstname.style.color = '#8089a8';
      this.$refs.lastname.style.color = '#8089a8';
    },
    async validate(e) {
      e.preventDefault();
      this.$refs.submitButton.disabled = true;
      this.serverError = '';
      try {
        const params = {
          site: 'ELCA',
          recaptchaToken: await this.recaptcha(),
          body: {
            FirstName: this.firstName,
            LastName: this.lastName,
            Email: this.email,
            Phone: this.phone,
            Street: this.steet,
            City: this.city,
            State: this.state,
            PostalCode: this.postalCode,
            Country: this.country,
            Topics: this.topics.join(';'),
            CommunicationPref: this.communicationPref,
            LanguagePref: this.languagePref,
            OptForFutureComm: this.optForFutureComm,
            // OptForMarketResearch: $marketResearch.checked,
            OptForMarketResearch: false,
            LeadSource: this.$i18n.locale === 'en' ? 'Sign Up Web Form (EN)' : 'Sign Up Web Form (FR)',
          },
        };
        this.signUpPut(params);
      } catch (error) {
        this.$refs.submitButton.disabled = false;
      }
    },
    async recaptcha() {
      try {
        // Wait for recaptcha loading
        await this.$recaptchaLoaded();
        // Execute reCAPTCHA with action "login" and return the token
        return await this.$recaptcha('login');
      } catch (e) {
        throw new Error('recaptcha login failed');
      }
    },
    signUpPut(params) {
      const loader = this.$loading.show();
      this.$store.commit('setEmail', params.body.Email);
      ApiService.init();
      ApiService.put('/v1/salesforce/signupForm', params)
        .then((res) => {
          if (res.status === 'success') {
            this.$router.push({ name: `verify-email-signup-${this.$i18n.locale}` });
          } else {
            this.serverError = res.errorMsg;
          }
          loader.hide();
          this.$refs.submitButton.disabled = false;
        })
        .catch((error) => {
          // Commenting out surfacing Salesforce errors to end users until we've made messaging more robust
          /*
          if (error.response.status === 500) {
            this.serverError = errorMessages[this.$store.state.lang].default;
          } else {
            this.serverError = error.response.data.errMsg;
          }
          */
          this.serverError = errorMessages[this.$store.state.lang].default;

          this.$refs.submitButton.disabled = false;
          loader.hide();
          console.error(error);
        });
    },
  },
  watch: {
    serverError(newValue) {
      this.resetLableColor();
      if (newValue !== '') {
        if (newValue.includes('Email')) {
          this.$refs.email.style.color = 'red';
          this.$refs.email.scrollIntoView();
        } else if (newValue.includes('Duplicate')) {
          this.$refs.firstname.style.color = 'red';
          this.$refs.lastname.style.color = 'red';
          this.$refs.firstname.scrollIntoView();
        }
      }
    },
    country(newCountry) {
      switch (newCountry) {
        case 'United States':
          this.StatesProvinceList = States[this.$store.state.lang];
          break;
        case 'États-Unis':
          this.StatesProvinceList = States[this.$store.state.lang];
          break;
        case 'Canada':
          this.StatesProvinceList = Provinces[this.$store.state.lang];
          break;
        default:
          this.StatesProvinceList = Provinces[this.$store.state.lang];
      }
      this.state = '';
    },
    '$store.state.lang': function () {
      this.StatesProvinceList =
        this.country === 'Canada' ? Provinces[this.$store.state.lang] : States[this.$store.state.lang];
      this.countryList = this.$i18n.t('countryList');
    },
  },
  mounted() {
    this.$recaptchaLoaded().then(() => {
      this.$recaptchaInstance.showBadge();
    });
  },
  beforeDestroy() {
    this.$recaptchaInstance.hideBadge();
  },
};
</script>

<style scoped lang="scss">
.topics {
  background-image: none;
}
</style>
