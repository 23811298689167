export default {
  en: {
    atTheChargerLabel: 'At the charger',
    charge: 'Charge',
    chargingYourEv: 'Charging Your EV',
    chargingYourEvAlt:
      'Person plugging charger into EV. Electrify Canada charger in the background with green Hyper-Fast label.',
    checkConnectorType: 'Check your connector type and maximum charge power',
    contactUs: 'CONTACT US',
    steps: 'Steps',
    getThe: 'Get the ',
    inTheAppLabel: 'In the app',
    meta: {
      title: 'How to Charge an Electric Car',
      description:
        'Electrify Canada | Learn how to charge your electric car in a few easy steps with Electrify America. Simply choose your power level, plug in your connector & start charging.',
    },
    mobileApp: 'Electrify Canada mobile app',
    park: 'Park',
    pay: 'Pay',
    plugAndClick: 'Plug in the connector until it clicks',
    selectCharger: 'Select charger number',
    selectStation: 'Select a charging station',
    startYourCharge: 'Start your charge',
    swipeToStartCharge: 'Swipe to start',
    atTheCharger: {
      step1: {
        a: 'Park on the side where your vehicle’s charging port is located. Notice the connector type indicated on the parking spot.',
        b: 'Park close enough to the charger so the connector does not overextend when plugged in.',
      },
      step2: {
        a: 'View the charger’s screen to ensure the connector is compatible with your vehicle’s charging port.',
        b: 'Our CCS connectors offer two charging speeds* ',
        c: 'Hyper-Fast',
        d: ' (up to 350 kW) and',
        e: 'Ultra-Fast',
        f: ' (up to 150 kW).',
        g: 'For reference, your charging port should resemble the icon at the top of the charger’s labels.',
        h: '*Not all EVs are capable of charging at the charger’s maximum power level. Check with your vehicle’s manufacturer to find out your vehicle’s charging capabilities.',
      },
      step3: {
        a: 'Remove the connector from the charger with two hands.',
        b: 'Press and hold the button on top of the connector as you plug it into your vehicle’s charging port.',
        c: 'Release the button on top of the connector. You will hear a ',
        d: '‘click’',
        e: ' when your vehicle is fully connected.',
      },
      step4: {
        a: 'To pay as a guest...',
        b: 'Pricing will appear on the charger’s screen.',
        c: 'Insert, tap, or swipe your credit card.',
        d: 'Or use your mobile wallet* and tap your phone against the credit card reader.',
        e: '*Compatible with Apple, Google, and Samsung Pay.',
        f: 'To pay as a member...',
        g: 'Pricing will appear in the app.',
        h: 'Access your digital membership pass in your mobile wallet. Then tap your phone against the contactless reader.',
        i: 'Or use',
        j: 'Swipe to Start ',
        k: 'to start a charge in the app.',
        l: 'Pass, Pass+, or Premium Offer members can pay for their charging session with the Electrify Canada app or select vehicle manufacturer apps.',
        m: 'Pass+ members save UP TO 20% on charging. download our app to become a member.',
      },
      step5: {
        a: 'Session details will appear on the charger’s screen.',
        b: 'For updates about your session and to receive a text receipt:',
        c: "Or enter your cell phone's number on the charger screen",
        d: 'To manually end your charging session, press ',
        e: 'Stop',
      },
    },
    inTheApp: {
      step1: 'After you’ve parked at the charger, select your charging station in the app.',
      step2: {
        a: 'Select charger number in the app, under the ',
        b: 'Start a Charge',
        c: ' section.',
        d: 'The charger’s number can be found ',
        e: 'above the charger screen',
      },
      step3: {
        a: 'Remove the connector from the charger with two hands.',
        b: 'Press and hold the button on top of the connector as you plug it into your vehicle’s charging port.',
        c: 'Release the button on top of the connector. You will hear a ',
        d: '‘click’',
        e: ' when your vehicle is fully connected.',
      },
      step4: {
        a: 'Confirm your payment method or selected membership plan.',
        b: 'Slide your finger across your phone screen to start a charge.',
        c: 'To receive detailed updates about your session, tap ',
        d: 'One-time notifications',
      },
      step5: {
        a: 'Once you’ve initiated your charge, session details will appear at the top of your phone’s screen and on the charger.',
        b: 'To manually end your charging session, press ',
        c: 'Stop',
        d: ' in the app. Your preferred payment will be charged at the end of your session.',
      },
    },
  },
  fr: {
    atTheChargerLabel: 'À la borne',
    charge: 'Recharger',
    chargingYourEv: 'Recharger votre VE',
    chargingYourEvAlt: 'À la borne',
    checkConnectorType: 'Vérifiez le type de connecteur et la puissance de charge maximale de votre véhicule',
    steps: 'Comment recharger son véhicule',
    contactUs: 'NOUS JOINDRE',
    getThe: 'Téléchargez l’application mobile',
    inTheAppLabel: 'Dans l’application',
    meta: {
      title: 'Charger votre VE avec nous',
      description:
        'Presque tous les véhicules électriques (VE) sur la route peuvent se recharger sur le réseau public d’Electrify Canada. Nos options de paiement flexibles facilitent le paiement de la recharge.',
    },
    mobileApp: 'Electrify Canada',
    park: 'Stationnez votre voiture',
    pay: 'Payez',
    plugAndClick: 'Branchez le connecteur (il s’enclenchera en place)',
    selectCharger: 'Sélectionnez le numéro de la borne',
    selectStation: 'Sélectionnez une station de recharge',
    startYourCharge: 'Commencez la recharge',
    swipeToStartCharge: 'Balayez pour commencer la recharge',
    atTheCharger: {
      step1: {
        a: 'Stationnez-vous du côté où se trouve le connecteur de recharge de votre véhicule. Remarquez le type de connecteur indiqué sur la place de stationnement.',
        b: 'Stationnez-vous suffisamment près de la borne pour que le connecteur ne soit pas trop tendu lorsqu’il est branché.',
      },
      step2: {
        a: 'Consultez l’écran de la borne pour vous assurer que le connecteur est compatible avec le port de recharge de votre véhicule.',
        b: 'Nos connecteurs CCS offrent deux vitesses de recharge* : ',
        c: 'Hyper rapide',
        d: ' (jusqu’à 350 kW) et ',
        e: 'ultrarapide',
        f: ' (jusqu’à 150 kW).',
        g: 'À titre de référence, votre port de charge devrait ressembler à l’icône figurant dans le haut des étiquettes de la borne.',
        h: '*Ce ne sont pas tous les véhicules électriques qui prennent en charge le niveau de puissance maximal des la bornes. Pour connaître les capacités de recharge de votre véhicule, adressez-vous à son fabricant.',
      },
      step3: {
        a: 'Retirez le connecteur de la borne avec vos deux mains.',
        b: 'Maintenez enfoncé le bouton qui se trouve sur le dessus du connecteur pendant que vous le branchez sur le port de recharge de votre véhicule.',
        c: 'Relâchez le bouton sur le dessus du connecteur. Vous entendrez un ',
        d: '« clic »',
        e: ' lorsque la connexion avec votre véhicule sera établie.',
      },
      step4: {
        a: 'Pour payer en tant qu’invité...',
        b: 'Le prix s’affichera sur l’écran de la borne.',
        c: 'Insérez, tapez ou glissez votre carte de crédit.',
        d: 'Vous pouvez aussi utiliser votre portefeuille mobile* et poser votre téléphone cellulaire contre le lecteur de carte de crédit.',
        e: '*Compatible avec Apple Pay, Google Pay et Samsung Pay.',
        f: 'Pour payer en tant que membre...',
        g: 'Les prix s’afficheront dans l’application.',
        h: 'Accédez à votre passe numérique dans votre portefeuille mobile. Approchez ensuite votre téléphone du lecteur sans contact.',
        i: 'Vous pouvez aussi utiliser la fonctionnalité',
        j: ' « Balayer pour commencer la recharge » ',
        k: 'pour lancer la session de recharge dans l’application.',
        l: 'Les membres qui possèdent un abonnement Passe, Passe+ ou Premium peuvent payer leur session de recharge au moyen de l’application Electrify Canada ou de certaines applications fournies par les constructeurs automobiles.',
        m: 'Les membres Passe+ économisent jusqu’à 20 % lors de la recharge. Téléchargez notre application pour vous inscrire.',
      },
      step5: {
        a: 'Les renseignements relatifs à la session s’afficheront sur l’écran de la borne.',
        b: 'Pour consulter l’état de votre session de recharge et pour recevoir un reçu :',
        c: 'Vous pouvez aussi entrer votre numéro de téléphone cellulaire sur l’écran de la borne.',
        d: 'Pour mettre fin manuellement à votre session de recharge, appuyez sur ',
        e: 'Arrêter',
      },
    },
    inTheApp: {
      step1:
        'Lorsque vous vous êtes stationné près de la borne, sélectionnez la station de recharge dans l’application.',
      step2: {
        a: 'Sélectionnez le numéro de la borne dans ',
        b: 'l’application, sous la',
        c: ' section Commencer une recharge.',
        d: 'Le numéro de la borne se trouve ',
        e: ' au-dessus de l’écran de la borne',
      },
      step3: {
        a: 'Retirez le connecteur de la borne avec vos deux mains.',
        b: 'Maintenez enfoncé le bouton qui se trouve sur le dessus du connecteur pendant que vous le branchez sur le port de recharge de votre véhicule.',
        c: 'Relâchez le bouton sur le dessus du connecteur. Vous entendrez un ',
        d: '« clic »',
        e: ' lorsque la connexion avec votre véhicule sera établie.',
      },
      step4: {
        a: 'Confirmez votre mode de paiement ou le forfait que vous avez souscrit.',
        b: 'Faites glisser votre doigt sur l’écran de votre téléphone pour démarrer la recharge.',
        c: 'Pour recevoir des mises à jour détaillées concernant votre session, ',
        d: 'touchez Notifications uniques',
      },
      step5: {
        a: 'Une fois que vous avez démarré la recharge, les renseignements relatifs à la session s’afficheront dans le haut de l’écran de votre téléphone ainsi que sur la borne.',
        b: 'Pour mettre fin manuellement à votre session de recharge, touchez ',
        c: 'Arrêter',
        d: ' dans l’application. Votre mode de paiement sélectionné sera débité à la fin de la session.',
      },
    },
  },
};
