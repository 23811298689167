<template>
  <div class="connector">
    <div class="connector__icon">
      <img src="../../assets/images/Map/CCS@3x.png?external" alt="CCS Connector" v-if="type == 'CCS'" />
      <img src="../../assets/images/Map/CHAdeMO@3x.png?external" alt="CHAdeMO Connector" v-if="type == 'CHAdeMO'" />
      <img src="../../assets/images/Map/L2@3x.png?external" alt="Level 2 Connector" v-if="type == 'Level2'" />
    </div>
    <p class="connector__detail">
      <strong>{{ title }}</strong
      ><br />
      {{ detail }}<br />
      <img src="../../assets/images/Map/bolt-dark.svg?external" alt="" />
      <img src="../../assets/images/Map/bolt-dark.svg?external" :class="{ dimmed: speed < 2 }" alt="" />
      <img src="../../assets/images/Map/bolt-dark.svg?external" :class="{ dimmed: speed < 3 }" alt="" />
    </p>
  </div>
</template>

<script>
import lang from '@/lang/LocateCharger';

export default {
  name: 'StationDetailConnector',
  props: ['connector'],
  data() {
    return {
      detail: '',
      speed: null,
      type: '',
      powerLevel: Math.trunc((this.connector.amperage * this.connector.voltage) / 1000),
    };
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
  computed: {
    title() {
      // Determine this connectors title and speed
      if (this.type === 'CHAdeMO') {
        return this.$t('cHAdeMO');
      }
      if (this.type === 'Level2') {
        return this.$t('level2');
      }
      if (this.powerLevel > 150) {
        return this.$t('hyperFast');
      }
      return this.$t('ultraFast');
    },
  },
  mounted() {
    // Determine this connectors type
    if (this.connector.standard.toLowerCase().indexOf('chademo') > -1) {
      this.type = 'CHAdeMO';
    } else if (this.connector.standard.toLowerCase() === 'iec_62196_t1') {
      this.type = 'Level2';
    } else {
      this.type = 'CCS'; // IEC_62196_T1_COMBO
    }

    // Determine this connectors title and speed
    if (this.type === 'CHAdeMO') {
      this.speed = 1;
    } else if (this.type === 'Level2') {
      this.speed = null;
    } else if (this.powerLevel > 150) {
      this.speed = 3;
    } else {
      this.speed = 2;
    }

    // Determine this connectors detail
    if (this.type === 'CHAdeMO' || this.type === 'Level2') {
      this.detail = `${this.powerLevel} kW`;
    } else {
      this.detail = `CCS, ${this.powerLevel} kW`;
    }
  },
};
</script>

<style lang="scss" scoped>
.connector {
  align-items: center;
  display: flex;
  float: left;
  padding: 3px 12px 0 0;
  width: calc(50% - 12px);

  img.dimmed {
    opacity: 0.3;
  }

  &__icon {
    height: 48px;
    width: 48px;

    img {
      max-width: 100%;
    }
  }

  &__detail {
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
}
</style>
