export default {
  en: {
    aboutUs: 'About',
    closeSearchAltText: '“X” indicating close feature',
    contactUs: 'Contact us',
    glossary: 'Glossary',
    home: 'Home',
    locateACharger: 'Locate a charger',
    mobileApp: 'Mobile App',
    news: 'News & Updates',
    ourTeam: 'Our Team',
    pricing: 'Pricing',
    privacy: 'Privacy',
    realEstate: 'Become a Site Host',
    signUp: 'Sign Up For Updates',
    socialMediaRules: 'Social Media House Rules',
    support: 'Support',
    termsOfUse: 'Terms of use',
    whatToExpect: 'What To Expect',
    whistleblower: 'Whistleblower System',
    forDrivers: 'For Drivers',
    forBusiness: 'For Businesses',
    gettingStarted: {
      name: 'Getting Started',
      description: 'Learn the basics of electric vehicle charging',
    },
    whatToExpectNav: {
      name: 'What to expect',
      description: 'Learn what to expect when you charge with Electrify Canada',
    },
    premiumOffers: {
      name: 'Premium Offers',
      description: 'Get complimentary charging or other benefits with your electric vehicle',
    },
    getTheApp: {
      name: 'Get the App',
      description: 'Find a public charging station, schedule home charging, and more',
    },
    plugAndCharge: {
      name: 'Plug&Charge',
      description: 'Discover the simplicity and convenience of Plug&Charge',
    },
    chargingEv: {
      name: 'Charging your EV',
      description: 'Get step-by-step instructions on how to charge ',
    },
    pricingPayments: {
      name: 'Pricing and payments',
      description: 'Learn about pricing information and payment options',
    },
    signUpForUpdates: {
      name: 'Sign up for updates',
      description: 'Stay up-to-date on Electrify Canada',
    },
    becomeSiteHost: {
      name: 'Become a site host',
      description: 'Work with us to potentially host an Electrify Canada charging station',
    },
    aboutElectrify: {
      title: 'About Us',
      name: 'About Electrify Canada',
      description: 'Learn about what drives us',
    },
    ourTeamNav: {
      name: 'Our team',
      description: 'Get to know our people',
    },
    newsRoom: {
      name: 'Newsroom',
      description: 'Stay up to date with company news and updates',
    },

    // ? New Footer Translation english
    description:
      'Electrify Canada offers transformative, customer-centric infrastructure and energy management solutions.',
    help: 'Help',
    company: 'Company',

    // ! New Footer Translation ends here
  },
  fr: {
    aboutUs: 'à propos de nous',
    closeSearchAltText: '« X » indiquant une fonction de proximité',
    contactUs: 'Pour Nous Joindre',
    glossary: 'Glossaire',
    home: 'Accueil',
    locateACharger: 'emplacements',
    mobileApp: 'application mobile',
    news: 'Nouvelles',
    ourTeam: 'Notre équipe',
    pricing: 'tarification',
    privacy: 'Confidentialité',
    realEstate: 'Héburgez un Site',
    signUp: 's`inscrire',
    socialMediaRules: 'Règles internes concernant les réseaux sociaux',
    support: 'Assistance',
    termsOfUse: 'Mentions légales',
    whatToExpect: "à-quoi-s'attendre",
    whistleblower: 'Système de dénonciation',
    forDrivers: 'Pour les conducteurs',
    forBusiness: 'Pour les entreprises',
    gettingStarted: {
      name: 'Premiers pas',
      description: 'Apprenez les principes de base de la recharge d’un véhicule électrique',
    },
    whatToExpectNav: {
      name: 'À quoi s’attendre',
      description: 'Apprenez à quoi vous attendre lorsque vous rechargez votre véhicule avec Electrify Canada',
    },
    premiumOffers: {
      name: 'Offres Premium',
      description: 'Obtenez une recharge gratuite ou d’autres avantages avec votre véhicule électrique',
    },
    getTheApp: {
      name: 'Téléchargez l’application',
      description: 'Trouvez une station de recharge publique, planifiez une recharge à domicile et plus encore',
    },
    plugAndCharge: {
      name: 'Brancher et recharger',
      description: 'Découvrez la simplicité et la commodité de la fonctionnalité Brancher et recharger',
    },
    chargingEv: {
      name: 'Rechargez votre VE',
      description: 'Obtenez des instructions détaillées sur la façon de recharger',
    },
    pricingPayments: {
      name: 'Tarification et paiements',
      description: 'Apprenez-en plus sur la tarification et les options de paiement',
    },
    signUpForUpdates: {
      name: 'Inscrivez-vous aux mises à jour',
      description: 'Restez au courant des nouvelles d’Electrify Canada',
    },
    becomeSiteHost: {
      name: 'Hébergez un site',
      description: 'Travaillez avec nous pour potentiellement héberger une station de recharge Electrify Canada',
    },
    aboutElectrify: {
      title: 'à propos de nous',
      name: 'À propos d’Electrify Canada',
      description: 'Découvrez ce qui nous anime',
    },
    ourTeamNav: {
      name: 'Notre équipe',
      description: 'Apprenez-en plus sur notre équipe',
    },
    newsRoom: {
      name: 'Nouvelles',
      description: 'Restez à jour avec les nouvelles et les mises à jour de l’entreprise',
    },

    // ? New Footer Translation Fr
    description:
      'Electrify Canada offre des solutions transformatrices de structure et de gestion de l’énergie centrées sur le client.',
    help: 'Aide',
    company: 'Entreprise',

    //! New Footer Translation ends here
  },
};
