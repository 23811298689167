<template>
  <div class="email-verification">
    <div v-show="loading" class="loading">
      <span></span>
    </div>
    <div v-show="!loading" class="container" :class="brand">
      <img src="@/assets/images/EmailVerification/LogoBolt.png" alt="Electrify Canada bolt logo" />
      <h1 v-if="verified">Thanks! Your account is verified.</h1>
      <h1 v-else>Oops! Something went wrong.</h1>
      <template v-if="verified">
        <p v-if="brand === 'jeep-4xe'">
          Feel free to close this page and continue using the Jeep 4xe Charging Network App.<br />
          You may need to sign in again.
        </p>
        <p v-else>
          Feel free to close this page and continue using the Electrify Canada app.<br />
          You may need to sign in again.
        </p>
      </template>
      <template v-else>
        <template v-if="brand === 'jeep-4xe'">
          <p>Please return to the Jeep 4xe Charging Network app and select “Send again” to retry.</p>
        </template>
        <template v-else>
          <p>Please return to the Electrify Canada app and select “Send again” to retry.</p>
        </template>
        <p>Still not working? Call our Support team at 1-833-231-3226.</p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'email-validate-en',
  metaInfo: {
    title: `Validate email error`,
    meta: [
      { name: 'robots', content: 'noindex' },
      {
        name: 'description',
        content:
          'Oops! Something went wrong. Please return to the Electrify Canada app and select “Send again” to retry. Still not working? Call Support at 1-833-231-3226.',
      },
    ],
  },
  created() {
    setTimeout(() => {
      const isSafari = window.safari !== undefined;
      const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      if (isSafari && !iOS) {
        this.loading = false;
      } else {
        if (this.brand === 'jeep-4xe') {
          window.location = 'eajeepapp://validated_email';
        } else {
          window.location = 'ecapp://validated_email';
        }
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    }, 1000);
    const parameters = this.$route.query.success;
    if (parameters === 'true') {
      this.verified = !this.verified;
    }
  },
  data() {
    return {
      loading: true,
      verified: null,
    };
  },
  computed: {
    brand() {
      switch (this.$route.name) {
        case 'email-validate-jeep-4xe-en':
          return 'jeep-4xe';
        default:
          return 'ea';
      }
    },
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    userEmail() {
      return this.$store.state.email;
    },
  },
};
</script>
