<template>
  <div class="wrapper">
    <section class="column">
      <lottie-animation
        ref="anim"
        :animationData="require('@/assets/animations/Search.json')"
        :loop="!reducedMotion"
        :autoPlay="!reducedMotion"
        :speed="1"
        @loopComplete="loopComplete"
        class="search-animation"
      />
      <h1>{{ $t('pageUnavailable') }}</h1>
      <p class="headline5">{{ $t('useSearchBar') }}</p>
      <input
        ref="search"
        :aria-label="$t('searchTerm')"
        autocomplete="off"
        :placeholder="$t('search')"
        class="st-default-search-input search"
        type="search"
        name="keys"
        value
        size="15"
        maxlength="128"
      />
      <p>{{ $t('error404') }}</p>
    </section>
  </div>
</template>

<script>
import LottieAnimation from 'lottie-web-vue';
import lang from '@/lang/error';
import formsLang from '@/lang/Forms';

export default {
  metaInfo: {
    meta: [{ name: 'robots', content: 'noindex' }],
  },
  i18n: {
    messages: lang,
    sharedMessages: formsLang,
  },
  components: {
    LottieAnimation,
  },
  data() {
    return {
      reducedMotion: false,
    };
  },
  mounted() {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    if (mediaQuery.matches) {
      this.$refs.anim.stop();
      this.reducedMotion = true;
    }
    mediaQuery.addEventListener('change', () => {
      if (mediaQuery.matches) {
        this.reducedMotion = true;
        this.$refs?.anim.stop();
      } else {
        this.reducedMotion = false;
        this.$refs?.anim.play();
      }
    });
    // swiftype
    // need to disable linter rules for this outside script
    /* eslint-disable */
    if (!window.PRERENDER_INJECTED) {
      (function (w, d, t, u, n, s, e) {
        w['SwiftypeObject'] = n;
        w[n] =
          w[n] ||
          function () {
            (w[n].q = w[n].q || []).push(arguments);
          };
        s = d.createElement(t);
        e = d.getElementsByTagName(t)[0];
        s.async = 1;
        s.src = u;
        e.parentNode.insertBefore(s, e);
      })(window, document, 'script', '//s.swiftypecdn.com/install/v2/st.js', '_st');

      _st('install', process.env.VUE_APP_SWIFTYPE_KEY, '2.0.0');
    }
    /* eslint-enable */
  },
  methods: {
    loopComplete() {
      this.$refs?.anim.stop();
      if (!this.reducedMotion) {
        setTimeout(() => {
          this.$refs?.anim.play();
        }, 3000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  justify-content: center;
  padding: $spacing-unit * 10 $global-spacing $spacing-unit * 22;
  @media (max-width: 750px) {
    padding: $spacing-unit * 11 $spacing-unit $spacing-unit * 14;
  }
  .column {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    @media (min-width: $bp_medium_min) {
      max-width: 600px;
    }

    h1 {
      font-weight: $fw-medium;
      font-size: $global-font-size * 1.5;
      margin-bottom: 0;
      @media (min-width: $bp_medium_min) {
        line-height: 44px;
        font-size: 34px;
      }
    }
    p {
      color: $c-gray-dark;
      font-size: 14px;
    }
    p.headline5 {
      font-size: 22px;
      line-height: 34px;
      letter-spacing: 0.22px;
      font-weight: $fw-regular;
    }
    .search-animation {
      max-width: 150px;
      max-height: 150px;
      padding-bottom: $global-spacing;
    }
    .search {
      background-color: #eef0f4;
      background-position: 15px;
      border: none;
      box-sizing: border-box;
      font-size: 18px;
      height: 60px;
      padding-left: 40px;
      width: 100%;
      @media (min-width: $bp_medium_min) {
        max-width: 420px;
      }
    }
    input::placeholder {
      font-size: 18px;
      font-weight: $fw-regular;
    }
    input:focus {
      outline: 3px solid $c-focus;
      outline-offset: 2px;
      border-radius: 2px;
    }
  }
}
</style>
