<template>
  <article class="disclosure">
    <h1>Subscription Agreement</h1>
    <p>
      By selecting “Confirm” below to sign up for this subscription, you agree that Electrify Canada can charge the
      monthly fee specified under “Plan Details” above to the credit card or other payment method identified under
      “Payment Method” above. While subscribed, you will receive discounted pricing at Electrify Canada’s network of
      electric vehicle charging stations, in the amounts set forth under “Plan Details” for each region. Discounts may
      vary depending on the region, so please check the Plan Details for more information regarding the discounts
      offered for charging stations located in your region.
    </p>
    <p>
      This subscription will continue until you cancel it, and will automatically renew each month for the same monthly
      subscription fee, charged to you on the first day of each new month that you are subscribed.
    </p>
    <p>You may cancel this subscription at any time as follows:</p>
    <p>
      To cancel your Subscription (Pass+) plan and change to a non-subscription Retail (Pass) plan in the Electrify
      Canada Mobile app:
    </p>
    <ol>
      <li>
        After logging into the Electrify Canada Mobile app, navigate to
        <strong>Profile</strong> icon in top right hand corner of map.
      </li>
      <li>
        Select the
        <strong>Plan details</strong> menu item.
      </li>
      <li>
        Click on the
        <strong>Choose a different plan</strong> button.
      </li>
      <li>
        A screen will appear with tabs for each plan option available. Select the
        <strong>Pass</strong> tab and click the button that says <strong>Change to Pass plan</strong>.
      </li>
      <li>
        A text box will appear titled
        <strong>Confirm change</strong> and will include text advising that the requested change to Retail memberships
        will go into effect on the 1st day of the next billing cycle. Select <strong>Confirm</strong>.
      </li>
      <li>
        Your
        <strong>Plan details</strong> will now display a text box advising of the date your switch to Pass membership
        will occur. After that date, the monthly subscription fee will not be charged to your card on file and you will
        not receive the relevant discounts.
      </li>
    </ol>
    <p>
      Subscription fees already paid for the current month are non-refundable. There is no minimum purchase obligation
      for this subscription, so the specified discounts will remain in effect regardless of how many charging sessions
      you purchase during the subscription term.
    </p>
  </article>
</template>

<script>
export default {
  name: 'SubscriptionAgreement',
  metaInfo: {
    title: 'Electrify Canada Pass + charging subscription agreement',
    meta: [
      {
        name: 'description',
        content:
          'Electrify Canada App Pass + charging program subscription agreement and plan details, saving up to 20% on membership',
      },
    ],
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/subscription-agreement/',
      },
    ],
  },
};
</script>
