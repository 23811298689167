<template>
  <article class="disclosure">
    <div>
      <h1>BMW i7 Charging Plan</h1>
      <h2>{{ $t('termsAndConditions') }}</h2>
      <p class="primary-color">
        <strong>Effective as of February 20, 2024</strong>
      </p>
      <p>
        Electrify Canada and BMW Canada are pleased to provide this promotional charging plan (“Plan”) to drivers of new
        2023, 2024, and 2025 model year BMW i7 vehicles purchased from an authorized BMW Canada retailer. By clicking
        “Agree” below, you agree to participate in this promotion, subject to your compliance with these terms and
        conditions (“Plan Terms”).
      </p>
      <p>
        Participation in the Plan requires an Electrify Canada account, the Electrify Canada mobile app, enrollment in
        the Plan on the app, and acceptance of the
        <router-link class="link" :to="{ name: 'terms-en' }">Electrify Canada Terms of Use</router-link> governing your
        use of the app and Electrify Canada’s network. Your mobile carrier’s normal messaging, data, and other rates and
        fees may apply to your use of the Electrify Canada app.
      </p>
      <p>
        Immediately upon your registration and acceptance of these Plan Terms, you will be enrolled in the Plan that
        provides 36 months of complimentary DC fast (CCS) sessions for your 2023-2025 BMW i7 of DC fast (CCS) for your
        BMW i7 on Electrify Canada’s network of electric vehicle charging stations. This Plan shall continue for a
        36-month period starting from the date of vehicle purchase (provided to Electrify Canada by your vehicle’s
        manufacturer) until the benefit is depleted or unless earlier terminated by Electrify Canada as described below.
        BMW i7’s purchased prior to program launch (April 06, 2023) will have 3 years of complimentary charging starting
        from April 06, 2023.
      </p>
      <p>
        After enrollment in this Plan, you can redeem the 36 months of complimentary DC fast (CCS) sessions for your
        2023, 2024, or 2025 BMW i7 charging under this Plan at any Electrify Canada charging station, by using the
        Electrify Canada app “Swipe to start” or NFC Pass feature with the
        <strong class="primary-color">BMW i7 Charging Plan</strong> selected.
        <strong
          >The charging included under this Plan may not be initiated using the interface screen or buttons on the
          charger.</strong
        >
        Plug&Charge functionality will also be available for use with this plan. Plug&Charge can help i7 owners have a
        better charging experience by creating a secure, convenient, and effortless way to pay for charging sessions on
        the Electrify Canada network.
      </p>
      <p>
        There is no additional fee, purchase, or subscription required for your access to this promotion.
        <strong
          >Upon depletion or expiration of this benefit, charging will continue uninterrupted at the prevailing
          Electrify Canada Pass charging price.
        </strong>
        Subject to the restrictions described below, there is no limit on the number of eligible charging sessions or
        the kWh amount of charging available in each session during the Plan.
      </p>
      <strong>PLEASE NOTE THE FOLLOWING IMPORTANT LIMITATIONS ON YOUR USE OF THIS PROMOTION:</strong>
      <p>
        You may <strong>only</strong> use this complimentary credit for charging the BMW i7 whose VIN you submitted when
        signing up for this promotion on your Electrify Canada account.
        <strong>No other vehicle is eligible to receive this credit through your account</strong>, whether owned by you
        or others.
      </p>
      <p>
        You may <strong>not</strong> use this complimentary charging in connection with any taxi, ride-share, or other
        similar service, or for any other commercial purpose.
        <strong>This promotion is for your personal, non-commercial use only. </strong>
      </p>
      <p>
        Complimentary charging <strong>does not include</strong> any idle fees and associated applicable taxes assessed
        at the charging station. You will be responsible for paying such fees through your account if you incur them
        during a charging session.
      </p>
      <p>
        You must follow all product, vehicle, safety, and technical documentation included with the BMW i7 when charging
        at an Electrify Canada charging station.
      </p>
      <p>You must use the correct connector type for the BMW i7 at the charging station.</p>
      <p>
        Once the 36 months complimentary charging is depleted, you will not be able to reload, renew, or add more credit
        to the <strong class="primary-color">BMW i7 Charging Plan</strong>. Please refer to the information on the
        <strong class="primary-color">“Plan Details”</strong> section of the app to keep track of the amount of time
        remaining in this promotion.
      </p>
      <p>
        This credit is not returnable or redeemable for any cash value, and may not be sold, loaned, or otherwise
        distributed to any third party for any reason. You may not transfer any portion of the credit to any other
        Electrify Canada account.
      </p>
      <p>
        You acknowledge that your use of Electrify Canada’s services is subject to the Electrify Canada Terms of Use
        available at
        <router-link :to="{ name: 'terms-en' }" class="link">https://www.electrify-canada.ca/terms</router-link> and
        that your use of the Electrify Canada mobile app is subject to the Terms of Use accessible by navigating to the
        <strong class="primary-color">Account</strong>
        icon in bottom right hand corner, selecting <strong class="primary-color">“Legal”</strong> and then selecting
        <router-link class="medium" :to="{ name: 'terms-en' }">“Terms of Use”</router-link> &
        <router-link class="medium" :to="{ name: 'privacy-en' }">“Privacy Policy.”</router-link> Please read these Terms
        of Use documents carefully for information about how you can pay through your account for idle fees and
        applicable taxes as well as for charging session time outside of the complimentary charging offered under this
        promotion.
      </p>
      <p>
        You agree to comply with all applicable laws and regulations when using Electrify Canada services and when
        charging your vehicle at Electrify Canada charging stations.
      </p>
      <p>
        Electrify Canada reserves the right to withhold, revoke, reduce, terminate, or suspend your access to all or any
        portion of this promotion, without notice, if Electrify Canada determines or suspects, in its sole discretion,
        that you: (a) are in violation of the Electrify Canada Terms of Use, these {{ $t('termsAndConditions') }}, or
        any other contract between you and Electrify Canada; (b) have engaged in charging sessions that are fraudulent,
        or otherwise indicate an unauthorized or unintended use of this complimentary promotion; or (c) have engaged in
        any illegal, fraudulent, tortious, injurious, harmful, or abusive conduct in your use of the Electrify Canada
        services, the Electrify Canada mobile app, or an Electrify Canada charging station.
      </p>
      <p>
        Electrify Canada reserves the right to end this complimentary promotion at any time before its automatic
        termination date for any reason, in its sole discretion, provided that Electrify Canada will send an email to
        the address associated with your account in the event of any change to the scheduled end date of this promotion.
        Following the termination or expiration of this Plan for any reason, your access to the complimentary promotion
        will immediately cease, and you may not use or redeem any of the related benefits. At that time, your account
        will automatically convert to a basic Electrify Canada Pass account. You may, but are not obligated to, sign up
        for a different type of subscription via the Electrify Canada mobile app.
      </p>
    </div>
  </article>
</template>

<script>
import lang from '@/lang';

export default {
  name: 'bmw-i7-unlimited-plan-disclosure',
  metaInfo: {
    title: 'BMW i7 Charging Plan Disclosure',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/bmw-i7-unlimited-plan-disclosure/',
      },
    ],
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
};
</script>
