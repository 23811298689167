<template>
  <header class="header" data-swiftype-index="false" ref="headerElement">
    <div class="main-list">
      <div class="container">
        <div class="left-nav">
          <div class="search-trigger">
            <button aria-label="Search Menu" class="search-button" @click="togglePanel('search')">
              <searchSVG v-if="!panelIsOpen.search" style="height: 20px" aria-hidden="true" />
              <searchCloseSVG v-else style="height: 20px" />
            </button>
          </div>
        </div>

        <div class="middle-nav">
          <router-link
            class="logo-container"
            :to="{ name: `home-${this.$i18n.locale}` }"
            @click.native="togglePanel('nav')"
            aria-label="Electrify Canada Home page"
          >
            <logo style="width: 4.8rem" />
          </router-link>
        </div>

        <div class="right-nav">
          <button
            aria-label="navigation menu"
            ref="navTrigger"
            @click="togglePanel('nav')"
            class="nav-trigger"
            :aria-expanded="panelIsOpen.nav ? 'true' : 'false'"
          >
            <searchCloseSVG v-if="panelIsOpen.nav" />

            <span v-if="!panelIsOpen.nav" class="hamburger">
              <span class="line" />
              <span class="line" />
              <span class="line" />
            </span>
          </button>
        </div>

        <nav class="panels" role="navigation" aria-label="Header Primary Site Links">
          <ul class="nav" ref="nav">
            <ul class="lang-toggle">
              <li>
                <button @click="goToEC()" :class="{ opaque: this.$i18n.locale === 'en' }">EN</button>
              </li>
              <li>
                <button @click="goToFC()" :class="{ opaque: this.$i18n.locale === 'fr' }">FR</button>
              </li>
            </ul>

            <HeaderMobileItem
              v-for="(navItem, index) of headerNavItems"
              :key="index"
              :navItem="navItem"
              :toggleNav="() => togglePanel('nav')"
            />
          </ul>

          <div class="search" ref="search">
            <HeaderSearch :headerNavItems="headerNavItems" :searchActive="true" :isMobile="true" />

            <div class="search__quick-links">
              <h3>Quick Links</h3>

              <ul class="search__quick-link-list">
                <li v-for="(link, index) of quickLinks" :key="index">
                  <component
                    :is="link.isExternal ? 'a' : 'router-link'"
                    v-bind="link.isExternal ? { href: link.linkTo } : { to: { name: `${link.linkTo}` } }"
                    :target="link.isExternal ? '_blank' : null"
                    class="search__quick-link"
                    @click.native="togglePanel('search')"
                  >
                    <span>{{ link.name }}</span>
                    <Arrow />
                  </component>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import lang from '@/lang';
import Navigation from '@/lang/Navigation';
import logo from '@/assets/images/Header/logoCa.svg';
import searchSVG from '@/assets/images/Header/searchIconWhite.svg';
import searchCloseSVG from '@/assets/images/Global/searchCloseIcon.svg';
import Arrow from '@/assets/images/Global/arrow.svg';
import HeaderMobileItem from './HeaderMobileItem.vue';
import HeaderSearch from './HeaderSearch.vue';

export default {
  name: 'MobileHeader',
  i18n: {
    messages: Navigation,
    sharedMessages: lang,
  },
  components: {
    logo,
    searchSVG,
    searchCloseSVG,
    HeaderMobileItem,
    HeaderSearch,
    Arrow,
  },
  props: {
    headerNavItems: { required: true },
  },
  data() {
    return {
      panelIsOpen: {
        search: false,
        nav: false,
      },
      searchActive: false,
      quickLinks: [
        {
          name: 'How to charge',
          linkTo: `charging-your-ev-${this.$i18n.locale}`,
          isExternal: false,
        },
        {
          name: this.$i18n.t('locateACharger'),
          linkTo: `locate-charger-${this.$i18n.locale}`,
          isExternal: false,
        },
        {
          name: this.$i18n.t('getTheApp.name'),
          linkTo: `mobile-app-${this.$i18n.locale}`,
          isExternal: false,
        },
      ],
    };
  },
  computed: {
    getLang() {
      return this.$store.state.lang;
    },
  },
  methods: {
    togglePanel(panel) {
      if (this.panelIsOpen[panel]) {
        this.closePanel(panel);
      } else {
        this.openPanel(panel);
      }

      Object.keys(this.panelIsOpen).forEach((panelType) => {
        if (panelType !== panel) {
          this.closePanel(panelType);
        }
      });
    },
    openPanel(panel) {
      this.$refs[panel].classList.add(`${panel}--open`);
      this.$refs[panel].classList.add(`${panel}--open-animate`);

      this.panelIsOpen[panel] = true;
    },
    closePanel(panel) {
      this.$refs[panel].classList.remove(`${panel}--open-animate`);

      this.$refs[panel].ontransitionend = () => {
        if (!this.panelIsOpen[panel]) {
          this.$refs.nav.classList.remove(`${panel}--open`);
        }
      };

      this.panelIsOpen[panel] = false;
    },
    goTo(path) {
      this.$router.push({ name: path });
    },
    goToEC() {
      if (this.getLang === 'fr') {
        let newName = this.$route.name;
        const newParams = this.$route.params;
        if (newName.includes('-fr')) {
          newName = this.$route.name.replace('-fr', '-en');
          delete newParams.lang;
          this.$router.push({ name: newName, params: newParams });
        }
        this.$store.commit('setLang', 'en');
      }
    },
    goToFC() {
      if (this.getLang !== 'fr') {
        let newName = this.$route.name;
        let newParams = this.$route.params;
        if (newName.includes('-en')) {
          newName = this.$route.name.replace('-en', '-fr');
          newParams = {
            ...newParams,
            lang: 'fr',
          };
          this.$router.push({ name: newName, params: newParams });
        }
        this.$store.commit('setLang', 'fr');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  height: 60px;

  box-sizing: border-box;

  .main-list {
    box-sizing: border-box;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;

    height: 60px;
    width: 100vw;

    padding: 0px 24px;

    background: linear-gradient(#000, $c-primary);
    color: white;
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      height: 100%;
      width: 100%;

      svg {
        cursor: pointer;
      }
      .left-nav {
        display: flex;
        align-items: center;

        height: 100%;
        width: 50px;

        svg {
          width: 22px;
        }
      }

      .middle-nav {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;

        .logo-container {
          height: 33px;
        }
      }

      .right-nav {
        display: flex;
        align-items: center;
        justify-content: center;
        .nav-trigger {
          width: 50px;
          height: 50px;

          padding: 0;

          border: none;
          background: transparent;
          .hamburger {
            box-sizing: border-box;
            display: block;
            width: 50px;
            height: 50px;

            padding: 13px 10px;

            background: transparent;
            border: none;

            .line {
              height: 2px;
              width: 100%;
              display: block;
              margin-bottom: 7px;

              background-color: $c-primary-background;
              &:last-child {
                width: 60%;

                margin-bottom: 0;
              }
            }
          }

          svg {
            width: 20px;
            height: 20px;

            margin: 13px 10px;
          }
        }
      }

      .nav,
      .search {
        box-sizing: border-box;

        list-style-type: none;

        position: fixed;
        top: 60px;
        z-index: 9999999;

        visibility: hidden;
        overflow-y: scroll;

        width: 100vw;
        height: 100%;

        margin-top: 0;

        background: $c-primary;
        color: #fff;

        &--open {
          visibility: visible;
        }
      }

      .nav {
        left: calc(100vw + 100%);
        transition: left 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;

        padding: 32px 16px;

        &--open-animate {
          left: 0;
        }
      }

      .search {
        left: calc(-100vw - 100%);
        transition: left 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;

        padding: 32px;

        &--open-animate {
          left: 0;
        }

        &__quick-links {
          padding: 0 16px;
          margin-top: 64px;

          h3 {
            margin-bottom: 40px;

            color: $c-primary-background;
            font-size: 16px;
            font-weight: $fw-light;
          }
        }

        &__quick-link-list {
          list-style-type: none;

          padding: 0;
        }

        &__quick-link {
          display: flex;
          align-items: center;
          justify-content: space-between;

          width: 100%;
          height: 20px;

          margin-bottom: 42px;

          color: $c-primary-background;

          font-weight: $fw-medium;

          svg {
            color: $c-primary-background;
            width: 20px;
            height: 20px;
          }
        }
      }

      .lang-toggle {
        display: flex;
        list-style-type: none;
        margin: 0;
        padding: 0 0 0 20px;
        margin-left: -18px;
        justify-content: flex-start;
        li {
          padding: 15px;
          line-height: 10px;

          button {
            cursor: pointer;
            background: transparent;
            border: none;
            color: #ffffff;
            padding: 0;
            font-family: Ubuntu;
            font-weight: $fw-medium;
            font-size: 16px;
            line-height: 10px;
          }
          .opaque {
            color: #c4c4c4;
            padding-bottom: 3px;
            border-bottom: 1px solid #c4c4c4;
          }
          &:first-child {
            border-right: 1px solid #ffffff;
          }
        }
      }

      .search-button {
        display: flex;
        align-items: center;

        padding: 0;

        background-color: transparent;
        border: none;
      }
    }
  }
}
</style>
