<template>
  <div class="mobile-app">
    <Hero heading="The Electrify Canada Mobile App">
      <template #image>
        <picture>
          <source
            srcset="@/assets/images/Mobile-App/mobile_app_banner_en.png"
            media="(min-width: 750px)"
            alt="A completely redesigned experience"
          />
          <img
            src="@/assets/images/Mobile-App/Mobile/mobile_app_banner_mobile_en.png"
            alt="A completely redesigned experience"
          />
        </picture>
      </template>
      <h2 class="hero-title headline3">Easy charging at home & on the go</h2>
      <div class="hero-content">
        <p>
          Use the Electrify Canada mobile app to find a public charging station. Sign up for an account to enjoy
          exclusive, members-only public charging features and pricing.
        </p>
        <div class="inline">
          <a href="https://apps.apple.com/ca/app/electrify-canada/id1472391910" rel="noopener" target="_blank">
            <img
              loading="lazy"
              :src="require('@/assets/images/Global/app-store-logo-en.png')"
              :alt="$t('downloadInTheAppStore')"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.ec.evowner&hl=en_CA"
            rel="noopener"
            target="_blank"
          >
            <img
              loading="lazy"
              :src="require('@/assets/images/Global/google-play-badge-en.png')"
              :alt="$t('getItOnGooglePlay')"
            />
          </a>
        </div>
      </div>
    </Hero>
    <section class="locate-charger">
      <Card heading="Locate a charger" headlineLevel="h2" headlineDisplayClass="headline3" isReverse maxWidth="1037">
        <template #image>
          <img loading="lazy" src="@/assets/images/Mobile-App/station_expanded_pin.png" alt="" />
        </template>
        <p>Open the app to quickly find the Electrify Canada charging stations nearest to you.</p>
        <p>
          You can also see the number of chargers of each type (CCS and CHAdeMO) at the station. Become a member to see
          real-time charger availability and receive an alert when an occupied charger becomes available.
        </p>
      </Card>
    </section>
    <section class="app-features">
      <div class="l-two-col">
        <Card
          heading="Contactless payment"
          headlineLevel="h2"
          isSmall
          :isReverse="isPhone"
          headlineDisplayClass="headline4"
        >
          <template #image>
            <img
              loading="lazy"
              src="@/assets/images/Mobile-App/Mobile/contactless_mobile@2x.png"
              alt="Contactless payment with the mobile app or Apple Wallet by tapping phone on the contactless reader on the right side of the charger"
            />
          </template>
          <p>
            Sign up for a membership in the app and pay for your charge by simply tapping the charger or opening the app
            and swiping.
          </p>
        </Card>
        <Card
          heading="Track your progress"
          alt=""
          headlineLevel="h2"
          isSmall
          :isReverse="isPhone"
          headlineDisplayClass="headline4"
        >
          <template #image>
            <img
              loading="lazy"
              src="@/assets/images/Mobile-App/Mobile/progress_mobile@2x.png"
              alt="The current session on the mobile app displays percentage charged, charging cost, charging time, and energy delivered"
            />
          </template>
          <p>
            For compatible EVs, the app shows your
            <router-link class="link" :to="{ name: 'glossary-en', query: { load: 15 } }"
              >state of charge (SOC)</router-link
            >
            and sends you custom notifications about your session.
          </p>
        </Card>
      </div>
    </section>
    <section class="membership">
      <div class="container">
        <h2>Enjoy the benefits of membership</h2>
        <p class="tag-line">Download the app and sign up to get the most out of your charging experience</p>
        <p class="disclaimer">Messaging and data rates may apply</p>
        <div class="l-three-col">
          <div class="col">
            <div class="col-body">
              <h3>Guest Charging</h3>
              <span>Pay as you go</span>
              <decorative-list>
                <li>
                  <p>Find a charging station</p>
                </li>
                <li>
                  <p>Swipe or tap your debit or credit card to pay easily</p>
                </li>
              </decorative-list>
            </div>
          </div>
          <div class="col">
            <div class="special-header">ONLY IN THE APP</div>
            <div class="col-body">
              <h3>Pass Membership</h3>
              <span>No monthly fee</span>
              <decorative-list>
                <li>
                  <p>See real-time charger availability</p>
                </li>
                <li>
                  <p>Pay contact-free from the app</p>
                </li>
                <li>
                  <p>Favourite charging stations in the app</p>
                </li>
                <li>
                  <p>Start charging from the app and track your progress</p>
                </li>
                <li>
                  <p>Get notified when a charger opens up or your EV reaches your desired state of charge</p>
                </li>
                <li>
                  <p>Find a charging station</p>
                </li>
              </decorative-list>
            </div>
          </div>
          <div class="col">
            <div class="special-header">ONLY IN THE APP</div>
            <div class="col-body">
              <h3>Pass+ Membership</h3>
              <span class="wrap-last-two-words" style="margin-bottom: 0px"
                >$7/month <reference-link num="2">{{ $t('plusFees') }}</reference-link></span
              >
              <decorative-list>
                <li class="flex-row unique-row">
                  <div>
                    <p>Enjoy reduced costs for charging</p>
                    <div class="flex-row">
                      <StarIcon class="image" />
                      <p class="special-list">Pass+ Members save 20% on charging</p>
                    </div>
                  </div>
                </li>
                <li>
                  <p>See real-time charger availability</p>
                </li>
                <li>
                  <p>Pay-contact free from the app</p>
                </li>
                <li>
                  <p>Favourite charging stations in the app</p>
                </li>
                <li>
                  <p>Start charging from the app and track your progress</p>
                </li>
                <li>
                  <p>Get notified when a charger opens up or your EV reaches your desired state of charge</p>
                </li>
                <li>
                  <p>Find a charging station</p>
                </li>
              </decorative-list>
            </div>
          </div>
        </div>

        <div class="flex-column">
          <p>Visit a charging station often? Consider our Pass+ membership plan.</p>
          <router-link :to="{ name: 'pricing-en' }" class="button">See Pricing Details</router-link>
        </div>
      </div>
    </section>
    <MobileAppFaq />
  </div>
</template>

<script>
import lang from '@/lang';
import MobileAppFaq from '@/components/MobileAppFaq.vue';
import DecorativeList from '@/components/DecorativeList/DecorativeList.vue';
import ReferenceLink from '@/components/ReferenceLink.vue';
import StarIcon from '@/assets/images/Mobile-App/StarIcon.svg';
import Hero from '@/components/Hero/Hero.vue';
import Card from '@/components/Card/Card.vue';

export default {
  name: 'mobile-app',
  metaInfo: {
    title: 'Download our mobile app for iOS or Android',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/mobile-app/',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/mobile-app/',
        hreflang: 'en-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/fr/application-mobile/',
        hreflang: 'fr-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/mobile-app/',
        hreflang: 'x-default',
      },
    ],
    meta: [
      {
        name: 'description',
        content:
          'Manage your Electrify Canada charging session right from your phone. Download our app and discover a seamless way to charge your electric vehicle (EV).',
      },
    ],
    script: [
      {
        type: 'application/ld+json',
        json: {
          '@context': 'https://schema.org',
          '@type': 'SoftwareApplication',
          name: 'Electrify Canada',
          applicationCategory: 'DriverApplication',
          installUrl:
            'https://apps.apple.com/ca/app/electrify-canada/id1472391910, https://play.google.com/store/apps/details?id=com.ec.evowner&hl=en_CA',
          operatingSystem: 'iOS, Android',
          aggregateRating: { '@type': 'AggregateRating', ratingValue: '3', ratingCount: '22' },
          offers: { '@type': 'Offer', price: '0.00', priceCurrency: 'USD' },
        },
      },
    ],
  },
  components: {
    MobileAppFaq,
    StarIcon,
    DecorativeList,
    Hero,
    Card,
    ReferenceLink,
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
  computed: {
    isPhone() {
      return this.$resize && this.$mq.below(749);
    },
  },
};
</script>
