import Vue from 'vue';
import VueGtm from 'vue-gtm';
import VueI18n from 'vue-i18n';
import { MediaQueries } from 'vue-media-queries';
import AOS from 'aos';
import 'vue-loading-overlay/dist/vue-loading.css';
import Loading from 'vue-loading-overlay';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import App from './App.vue';
import store from './store';
import router from './router';
import 'aos/dist/aos.css';
import '@/assets/fonts/fonts.css';

const mediaQueries = new MediaQueries();

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV === 'development';
Vue.config.warnHandler = (msg) => {
  if (msg !== 'The .native modifier for v-on is only valid on components but it was used on <a>.') {
    // eslint-disable-next-line
    console.error(msg);
  }
};

Vue.use(Loading);

Vue.use(mediaQueries);
if (!window.PRERENDER_INJECTED) {
  Vue.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_RECAPTCHA_KEY,
    loaderOptions: {
      autoHideBadge: true,
      explicitRenderParameters: {
        badge: 'bottomleft',
      },
    },
  });
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_CODE,
    enabled: true,
    debug: true,
    vueRouter: router,
  });
}

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: store.state.lang, // set locale
});

const app = new Vue({
  created() {
    AOS.init({
      duration: 700,
    });
  },
  i18n,
  store,
  router,
  mediaQueries,
  render: (h) => h(App),
}).$mount('#app');

store.$app = app;
app.$mount('#app');
