<template>
  <article class="disclosure">
    <h1>Conditions d’utilisation</h1>
    <h2>En vigueur à compter du 9 janvier 2024</h2>
    <p class="primary-color">
      <strong>Important</strong><br />
      <strong
        >CECI EST UNE ENTENTE JURIDIQUE ENTRE CHAQUE UTILISATEUR FINAL (ci-après « VOUS » ou l’« UTILISATEUR » ou avec «
        VOTRE » ou « VOS ») ET Electrify Canada, Inc., (« Electrify Canada » et l’« ENTREPRISE »). VOUS DEVEZ LIRE
        ATTENTIVEMENT LES CONDITIONS GÉNÉRALES SUIVANTES FIGURANT DANS LA PRÉSENTE ENTENTE (« Entente »).
      </strong>
      Ces Conditions générales d’utilisation régissent votre utilisation des bornes de véhicules électriques exploitées
      par Electrify Canada (chacune étant appelée « Borne »; collectivement, elles sont appelées « Réseau », et le terme
      « Réseau » désigne également tout sous-ensemble de l’ensemble des Bornes, y compris les Bornes individuelles);
      votre utilisation de l’application mobile offerte par Electrify Canada (l’« Application »), ainsi que du site Web
      et du portail Web en ligne fournis par Electrify Canada (collectivement, le « Site » et, avec l’Application, la «
      Solution »).
    </p>

    <p>
      <strong
        >SI VOUS NE CONSENTEZ PAS À CES CONDITIONS GÉNÉRALES D’UTILISATION (CGU), VOUS N’AVEZ PAS L’AUTORISATION
        D’ACCÉDER AU SITE WEB, À L’APPLICATION OU À TOUT COMPOSANT DE CEUX-CI, NI DE LES UTILISER AUTREMENT, ET VOUS
        DEVEZ QUITTER L’APPLICATION IMMÉDIATEMENT.
      </strong>
    </p>

    <p>
      <strong>1. Utilisation des Bornes. </strong>Vous êtes autorisé à utiliser le Réseau, de la manière autorisée par
      les présentes CGU, et sous réserve des conditions des présentes CGU, notamment le paiement pour chaque fois que
      Vous utilisez une Borne. Lorsque vous utilisez une Borne, vous acceptez de respecter toute la documentation
      technique relative au produit, au véhicule et à la sécurité applicables à la Born et à tout véhicule que vous
      connectez à la Borne. Vous acceptez également d’utiliser le bon équipement et le bon type de connecteur pour le
      véhicule. Electrify Canada autorise uniquement l’utilisation d’adaptateurs fabriqués par les constructeurs
      automobiles sur ses Bornes. Il vous est interdit d’utiliser tout autre adaptateur sur le Réseau. Vous reconnaissez
      et convenez que les Bornes sont destinées à être utilisées et doivent être utilisées uniquement pour des véhicules
      automobiles terrestres standard de fabrication industrielle, et qu’il vous est interdit d’utiliser une Borne avec
      tout autre type de véhicule ou tout autre moyen de transport électrique. Cette interdiction comprend, sans s’y
      limiter, les bateaux électriques, les véhicules électriques de fabrication domestique, les Bornes embarquées de
      fabrication domestique et les véhicules qui comportent des Bornes personnellement modifiées. Dans la pleine mesure
      permise par la loi applicable, Electrify Canada n’a aucune responsabilité envers vous pour tout dommage ou perte
      découlant de votre non-respect du présent paragraphe, y compris, sans s’y limiter, tout dommage à des biens
      personnels ou découlant de blessures corporelles ou de décès. Vous acceptez et convenez en outre d’indemniser, de
      défendre et d’exonérer Electrify Canada de toute responsabilité en lien avec tout dommage, toute responsabilité ou
      toute perte subie par quiconque découlant de votre non-respect des modalités du présent paragraphe.
    </p>

    <p>
      <strong>2. Octroi de licence. </strong>Cette Entente vous accorde une licence personnelle, révocable, limitée, non
      exclusive, non cessible et non transférable vous permettant d’utiliser la Solution, sous réserve de votre respect
      continu de la présente Entente. Vous pouvez imprimer une seule copie ou faire une seule capture d’écran des
      documents et des informations que contient la Solution, et ce, uniquement à des fins personnelles, à condition que
      les copies papier affichent tous les avis de droit d’auteur et autres avis applicables que contiennent lesdits
      documents et lesdites informations.
    </p>

    <p>
      <strong>3. Restrictions. </strong>Sauf disposition expresse des présentes, l’Entreprise n’accorde aucun autre
      droit explicite ou implicite à vous, ni à toute autre personne. Par conséquent, vous ne pouvez pas modifier,
      traduire, décompiler, copier, distribuer, désassembler, diffuser, transmettre, publier, supprimer ou altérer tout
      avis ou toute étiquette de droits d’auteur, octroyer de licence ou de sous-licence, transférer, vendre, projeter
      vers un site miroir, cadrer, exploiter, louer, donner à bail, accorder de marque privée, accorder une sûreté sur
      la Solution ou le Réseau, créer des œuvres dérivées à partir de ceux-ci ou procéder à l’ingénierie inverse de
      ceux-ci ou les utiliser de quelque manière que ce soit qui n’est pas expressément autorisée par les présentes. De
      plus, vous n’êtes pas autorisé à désactiver, contourner ou éviter de quelque manière que ce soit tout dispositif,
      mécanisme, protocole ou procédure de sécurité mis en œuvre par l’Entreprise aux fins d’utilisation de la Solution
      ou du Réseau, ni ne permettrez à un tiers ou à quiconque de le faire.
    </p>

    <p>
      <strong>4. Obligations de l’utilisateur. </strong>Vous déclarez que vous avez au moins dix-huit ans ou l’âge légal
      de la majorité dans votre province ou votre territoire (selon le plus élevé des deux) et que vous fournirez en
      tout temps des renseignements véridiques, exacts, à jour et complets (pour lesquels vous avez tous les droits,
      autorisation(s) ou autorité nécessaires) lorsque vous enverrez des renseignements par l’entremise de la Solution
      ou du Réseau, y compris, sans s’y limiter, lorsque vous fournirez des renseignements dans un formulaire
      d’inscription ou de présentation. En outre, vous accédez à la Solution de votre plein gré et vous êtes responsable
      du respect de toutes les lois, règles et réglementations applicables en lien avec votre utilisation de la Solution
      (y compris l’utilisation de toute copie autorisée des documents et des informations de la Solution). Si vous
      accédez à la Solution pour le compte d’une organisation, votre organisation sera liée par les présentes CGU et
      tenue responsable de toute violation commise par vous. Vous déclarez que vous avez tous les droits et pouvoirs
      ainsi que l’autorité d’accepter les présentes CGU au nom de votre organisation.
    </p>

    <p>
      <strong>5. Compte. </strong>Il se peut que vous deviez vous inscrire avant de pouvoir utiliser la Solution ou
      certaines fonctionnalités de la Solution. Chaque inscription (« Compte ») est réservé(e) à un seul utilisateur,
      sauf si l’Entreprise en convient expressément autrement. L’inscription à l’accès à la Solution et à son
      utilisation peut également nécessiter des justificatifs d’accès, comme un nom d’utilisateur et un mot de passe, ou
      le respect d’autres exigences d’accès particulières désignées par l’Entreprise à son entière discrétion de temps à
      autre. Vous acceptez par les présentes de considérer vos justificatifs d’accès, par exemple un nom d’utilisateur
      et un mot de passe, comme des informations confidentielles, et de ne pas divulguer ces informations à un tiers
      sans le consentement écrit exprès préalable de l’Entreprise, lequel pourrait être refusé à sa seule discrétion.
      Vous devez immédiatement informer l’Entreprise si vous soupçonnez ou constatez la perte ou le vol de votre mot de
      passe ou l’utilisation non autorisée de votre nom d’utilisateur et de votre mot de passe. L’Entreprise ne sera pas
      tenue responsable de toute perte ou de tout dommage découlant de votre non-respect (involontaire ou délibéré) de
      ces obligations. En soumettant les renseignements demandés au formulaire d’inscription sur la Solution, vous
      déclarez et garantissez que les renseignements que vous soumettez aux fins d’inscription sont complets,
      véridiques, exacts et à jour à tous égards. Vous devez entretenir et mettre à jour rapidement les renseignements
      fournis en lien avec votre compte pour vous assurer que ces renseignements sont complets, véridiques, exacts et à
      jour. L’Entreprise se réserve le droit de suspendre, de résilier ou de supprimer votre compte ou votre inscription
      en attente si l’Entreprise a des motifs raisonnables de soupçonner que les renseignements que vous avez fournis
      sont faux, erronés, périmés ou incomplets, ou que votre inscription, votre compte, ou l’utilisation que vous
      faites de la Solution contrevient à la loi, à la réglementation ou aux modalités des présentes CGU.
    </p>

    <p>
      <strong>6. Présentations. </strong>L’Entreprise peut vous autoriser à fournir certains renseignements,
      propositions ou documents à l’Entreprise par l’entremise de la Solution en ce qui concerne certains projets ou
      programmes de recharge électrique liés aux automobiles, aux infrastructures et à d’autres questions associées
      (chacun, une(des) « Présentation(s) »). Vous acceptez de ne pas téléverser, publier, afficher ou transmettre des
      Présentations si ces Présentations, ou une quelconque partie de celles-ci, ou l’action consistant à produire la ou
      les Présentations, seraient considérées comme diffamatoires ou injurieuses, ou enfreindraient les droits de
      propriété intellectuelle, les droits économiques ou les droits de propriété d’un tiers, ou seraient illégales de
      quelque autre manière que ce soit. L’Entreprise se réserve le droit de filtrer, de modifier ou de surveiller vos
      Présentations ou celles d’un autre utilisateur, et encourage tous ses utilisateurs à faire preuve de discernement
      et de prudence au moment d’évaluer ou d’examiner une ou des Présentations. Néanmoins, si vous enfreignez les
      présentes CGU, l’Entreprise pourrait, à sa seule discrétion, prendre toutes les mesures nécessaires pour protéger
      les droits que l’Entreprise possède en vertu de la loi, en common law ou en equity, ou faire exécuter autrement
      les modalités des présentes CGU. De plus, l’Entreprise a pour politique de prendre toutes les mesures à sa
      disposition en vertu des lois applicables en matière de propriété intellectuelle. Si Vous prenez connaissance de
      Présentations qui contreviennent à ces règles relatives aux comportements et aux contenus acceptables, vous pouvez
      en faire part à l’Entreprise de la façon prévue dans les présentes.
    </p>

    <p>
      <strong>7. Autorisation d’utilisation des Présentations. </strong>En utilisant la Solution pour transmettre,
      fournir, distribuer ou afficher une ou des Présentations, vous accordez automatiquement à l’Entreprise et à toutes
      ses sociétés mères et filiales actuelles et futures, ainsi qu’à toute société, entité commerciale, fiducie,
      société en propriété commune actuelle ou future, ainsi qu’à toute autre entité détenant, appartenant à,
      contrôlant, contrôlée par, sous le contrôle conjoint de, ou appartenant conjointement à une entité (que ce soit
      légalement, en tant que bénéficiaire, par convention ou par le biais de la propriété d’actions ou d’autres
      actions, directement ou indirectement) et ses successeurs et ayants droit autorisés, avec ou sans mention de la
      source, un droit et une licence libres de droits, perpétuels, irrévocables et non exclusifs à elle-même ou à toute
      autre partie d’utiliser, d’exploiter, de fabriquer, de faire fabriquer, de vendre, d’offrir à la vente,
      d’importer, de reproduire, de modifier, de publier, de distribuer et d’afficher ces Présentations ou de créer des
      œuvres dérivées à partir de celles-ci (en tout ou en partie) dans le monde entier et/ou de les incorporer dans
      d’autres œuvres sous quelque forme que ce soit ou à l’aide de tout média ou technologie maintenant connu ou plus
      tard développé pour toute la durée des droits qui peuvent exister dans ce contenu ou cette information. Vous
      reconnaissez également que ces Présentations (sous la forme reçue ou fournie par vous) sont non confidentielles à
      toutes fins et que l’Entreprise n’a aucune obligation en matière de confidentialité, de mention de la source ou
      d’approbation, et n’a aucun contrôle sur la mesure dans laquelle toute idée ou information pourrait être utilisée
      par toute autre partie ou personne. Vous ne recevrez aucune rémunération pour quelque Présentation que ce soit et
      renoncez par la présente à tout droit de faire valoir tout droit de propriété intellectuelle ou de propriété
      contre l’Entreprise et sa société mère, ses sociétés affiliées et ses filiales à la suite ou en vertu de toute
      utilisation ou exploitation de toute Présentation.
    </p>

    <p>
      <strong>8. Achats et abonnements. </strong>La Solution peut vous donner la possibilité de vous abonner à un plan
      de paiement, un Forfait d'abonnement, un Forfait de réduction (« Forfait »), ou d’acheter d’autres produits et
      services connexes. Les frais applicables (et toute réduction applicable, le cas échéant), la période d’un Forfait,
      les conditions de renouvellement et les modes de paiement autorisés (p. ex. crédit ou débit) (collectivement, les
      « Informations sur le Forfait ») seront précisés dans la Solution au moment où vous vous abonnez à un forfait ou
      effectuez un achat. Sous réserve de la loi applicable, un Forfait peut inclure une fonction de renouvellement
      automatique dont les modalités sont énoncées dans la Solution dans le cadre de votre adhésion initiale au Forfait.
      Si la loi applicable l’exige, ou à notre discrétion, nous vous ferons parvenir un avis avant votre renouvellement,
      en utilisant les coordonnées dont nous disposons. Si vous ne souhaitez pas renouveler votre adhésion, vous pouvez
      l’annuler avant la date de renouvellement. Tout abonnement est définitif pour la période achetée, et tout achat
      est définitif au moment de l’achat, et aucun remboursement ou crédit ne sera accordé, sauf disposition contraire
      dans la présente Entente, indiquée par écrit par l’Entreprise, ou de la manière requise par la loi applicable.
      Toutes les transactions sont nulles là où la loi l’interdit et l’Entreprise peut demander des renseignements afin
      de confirmer la commande et le mode de paiement. L’Entreprise se réserve le droit de résilier ou de suspendre
      l’accès à la Solution ou de résilier tout Forfait auquel vous êtes abonné si vous ne payez pas les montants dus en
      vertu de la présente Entente lorsque ceux-ci sont exigibles.
    </p>

    <p>
      Les conditions générales énoncées dans la Solution dans le cadre de votre adhésion à un Forfait ou de l’achat d’un
      produit ou d’un service sont considérées comme faisant partie des présentes CGU.
    </p>

    <p>
      <strong>9. Paiements par l’entremise de la Solution. </strong>La Solution peut vous donner la possibilité de
      fournir des informations de paiement (« Informations de paiement ») afin d’effectuer le paiement d’un achat ou
      d’une adhésion ou de verser des fonds dans votre compte (« Transaction »). Vous convenez que l’Entreprise peut
      exécuter le paiement pour les Transactions que vous avez demandées en utilisant les Informations de paiement que
      vous avez fournies, à moins que vous n’ayez demandé à l’Entreprise, par l’entremise de la Solution, de cesser
      d’utiliser des Informations de paiement particulières. Vous accordez l’autorisation à l’Entreprise et/ou au
      fournisseur de services de traitement des paiements tiers concerné de débiter un compte pour lequel vous avez
      fourni des Informations de paiement, à moins que vous n’ayez ordonné à l’Entreprise, par l’entremise de la
      Solution, de cesser d’utiliser un compte particulier. Vous serez responsable du paiement de toutes les taxes et de
      tous les tarifs, prélèvements ou droits applicables à votre paiement. Tous les montants portés à votre compte
      seront libellés dans la devise du Canada et toutes les transactions indiquées dans la Solution seront libellées en
      dollars canadiens. Vous êtes responsable de l’exactitude de toutes les informations relatives aux cartes de crédit
      et de débit que vous nous fournissez.
    </p>

    <p>
      <strong>10. Fonds pour les Paiements prélevés sur le compte. </strong>
      Fonds pour les Paiements prélevés sur le compte. Vous êtes responsable du maintien de la confidentialité et de la
      sécurité des informations relatives à votre Compte. Vous ne devez pas divulguer l’information relative à votre
      compte à quiconque. Si l’information relative à votre compte est perdue ou volée, toute personne qui entre en
      possession de cette information pourrait utiliser votre compte. Vous êtes responsable de toutes les transactions
      dans votre compte, y compris les transactions non autorisées.
    </p>

    <ul>
      <li>
        <strong>10.1. Chargement des fonds.</strong> La Solution peut vous permettre de verser des fonds dans votre
        Compte et d’effectuer des paiements pour l’utilisation des Bornes en débitant votre Compte (« Paiements prélevés
        sur le compte »). L’Entreprise se réserve le droit de cesser d’offrir les Paiements prélevés sur le compte à
        tout moment, à sa discrétion raisonnable; si l’Entreprise choisit de mettre un terme aux Paiements prélevés sur
        le compte, elle vous remboursera le solde figurant dans votre Compte, selon la façon prévue au paragraphe 10(e)
        ci-dessous. Pour effectuer des Paiements prélevés sur le compte, vous devez d’abord verser un montant minimal
        dans votre compte. L’Entreprise peut, à sa seule discrétion, réviser le montant minimal de temps à autre et, si
        le montant minimal augmente, le nouveau montant minimal devra être présent dans votre compte pour effectuer des
        Paiements prélevés sur le compte. Tout montant que vous versez dans votre Compte est un paiement préalable pour
        les produits et services que vous pouvez acheter auprès de l’Entreprise, ou les Forfaits auxquels vous pouvez
        vous abonner par l’entremise de la Solution. Bien que vous puissiez être tenu de fournir vos Informations de
        paiement pour vous inscrire à la Solution, l’Entreprise pourrait ne pas débiter votre mode de paiement jusqu’à
        votre première tentative de paiement pour l’utilisation d’une Borne au moyen d’un Paiement prélevé sur le
        compte, nonobstant tous frais d’abonnement applicables. La somme que vous versez à votre compte n’est pas
        assurée par la Société d’assurance-dépôts du Canada (SADC) ou tout autre assureur et ne rapporte aucun intérêt.
        Il peut y avoir un délai entre le moment où vous versez le montant et le moment où le montant est accessible
        pour les Paiements prélevés sur le compte par l’entremise de la Solution.
      </li>
      <li>
        <strong>10.2. Versement de fonds supplémentaires.</strong> Si vous participez à la fonction Paiements prélevés
        sur le compte, vous pourrez verser des fonds supplémentaires dans votre Compte en précisant le montant de ces
        versements par l’entremise de la Solution. L’Entreprise peut imposer et modifier, à sa discrétion raisonnable et
        sans vous en aviser, des restrictions quant au versement de fonds dans votre compte. Ces restrictions peuvent
        inclure, sans s’y limiter, des limites quant au nombre de fois que vous pouvez verser des fonds pendant une
        période donnée, des limites quant au montant de chaque versement, des limites quant au montant total que vous
        pouvez verser pendant une période donnée, ou des limites quant au solde total de votre Compte.
      </li>
      <li>
        <strong>10.3. Versements automatiques.</strong> Pour effectuer des Paiements prélevés sur le compte, vous
        pourriez être tenu de permettre des versements automatiques. Si vous autorisez les versements automatiques, vous
        préciserez un montant à verser et un seuil de versement, dans les limites que l’Entreprise pourrait fixer pour
        ces montants. L’Entreprise versera dans votre Compte, à l’aide de vos Informations de paiement, le montant de
        versement automatique indiqué et ce, chaque fois que le solde de votre Compte est inférieur au seuil de
        versement automatique. Vous pouvez modifier le montant de versement automatique ou le seuil de versement
        automatique, et vous pouvez annuler les versements automatiques (mais l’Entreprise pourrait alors vous interdire
        d’effectuer des Paiements prélevés sur le compte). Ces modifications peuvent prendre jusqu’à 24 heures pour
        entrer en vigueur. L’Entreprise vous enverra un courriel de confirmation après chaque transaction de versement
        automatique. Aucune transaction de versement automatique ne peut être remboursée ou annulée. Vous pouvez annuler
        le rechargement automatique en supprimant votre compte, soit en appelant l'assistance client au 1 833 231-3226,
        soit (sur le site) en sélectionnant Paramètres -> Profil -> Supprimer le compte, ou (sur l’Appli) en
        sélectionnant Paramètres -> Modifier le profil -> Supprimer le compte, mais vous ne pourrez peut-être pas
        utiliser l’Appli pour lancer une session de recharge lorsque le rechargement automatique est désactivé.
      </li>
      <li>
        <strong>10.4. Historique des transactions.</strong> Vous êtes responsable de vérifier votre historique de
        transactions pour vous assurer que celui-ci et le solde de votre compte sont exacts. Vous pouvez vérifier
        l’historique de vos transactions ainsi que votre solde dans la Solution. Si vous avez des questions concernant
        l’historique de vos transactions ou votre solde ou si vous souhaitez contester une transaction, veuillez
        communiquer avec le service à la clientèle en composant le 1 833 231-3226. L’Entreprise examinera votre
        réclamation et prendra une décision raisonnable quant à l’opportunité d’apporter une correction à votre Compte
        en réponse. L’Entreprise n’a aucune obligation d’examiner ou de corriger une erreur, à moins que vous ne
        fournissiez un avis concernant l’erreur alléguée dans les soixante (60) jours suivant la date de la transaction
        en question.
      </li>
      <li>
        <strong>10.5. Remboursements.</strong> Le solde de votre Compte n’est pas transférable à un autre Compte, ni à
        une autre personne. Sauf lorsque la loi applicable l’exige, le montant que vous avez versé dans votre Compte ne
        peut être remboursé ni vous être restitué tant que vous n’avez pas fermé votre Compte et annulé votre
        inscription à la Solution. Si vous fermez votre Compte et annulez votre inscription à la Solution, l’Entreprise
        restituera tout solde figurant alors dans votre Compte. L’Entreprise effectuera ce remboursement selon le mode
        de paiement que vous avez utilisé pour verser les fonds. Si ce mode de paiement ne peut être utilisé,
        l’Entreprise ne sera pas tenue de rembourser ou de restituer les fonds par tout autre moyen, à moins que la loi
        applicable ne l’exige.
      </li>
    </ul>

    <p>
      <strong>11. Forfaits d’abonnement. </strong>La Solution peut vous donner la possibilité de vous inscrire à un
      Forfait d'abonnement mensuel qui prévoit des réductions pour l’utilisation du Réseau moyennant des frais mensuels.
      Les détails de ces réductions et le montant des frais sont indiqués dans les Informations sur le Forfait.
      L’Entreprise peut modifier les Informations sur le Forfait pour tout Forfait, sur présentation d’un avis préalable
      à vous et après vous avoir donné la possibilité de décider d’annuler ou de renouveler votre Forfait. L’Entreprise
      peut annuler un Forfait, mais votre Forfait se poursuivra jusqu’à la fin du dernier mois pour lequel vous avez
      payé.
    </p>

    <ul>
      <li>
        <strong>11.1. Renouvellement automatique/continuation.</strong> Votre Forfait d'abonnement se poursuivra de mois
        en mois jusqu’à sa résiliation. Vous devez annuler votre adhésion avant qu’elle ne soit renouvelée chaque mois
        afin d’éviter que les frais d’adhésion du mois suivant ne soient imputés au mode de paiement au dossier.
      </li>
      <li>
        <strong>11.2. Paiement.</strong> Vous devrez fournir à l’Entreprise des Informations de paiement et
        l’autorisation d’utiliser les Informations de paiement pour les frais d’abonnement récurrents afin de vous
        abonner à un Forfait. Si vous vous êtes inscrit aux Paiements prélevés sur le compte, l’Entreprise utilisera les
        fonds de votre Compte pour les paiements d’abonnement, puis effectuera des versements automatiques dans votre
        compte, au besoin, pour réapprovisionner votre compte au solde minimal indiqué. Les frais d’abonnement sont
        entièrement prélevés dès le paiement. Dans certains cas, votre date de paiement peut changer, par exemple si
        votre mode de paiement n’a pas accepté le paiement ou si votre adhésion payée a commencé un jour non compris
        dans un mois donné. Vous pouvez trouver les renseignements relatifs à votre prochaine date de paiement en
        consultant vos Détails de facturation dans la Solution. Vous autorisez l’Entreprise à débiter tout mode de
        paiement lié à votre compte dans l’éventualité où votre mode de paiement principal serait refusé ou ne serait
        plus accessible à l’Entreprise pour le paiement de vos frais d’abonnement. Vous restez responsable de tout
        montant non encaissé. Si un paiement n’est pas perçu en raison d’une expiration, de fonds insuffisants ou pour
        toute autre raison et que vous ne supprimez pas votre Compte, l’Entreprise peut suspendre votre Forfait jusqu’à
        ce que l’Entreprise ait réussi à imputer le montant à un mode de paiement valide.
      </li>
      <li>
        <strong>11.3. Annulation.</strong> Vous pouvez annuler ou résilier votre Forfait en utilisant les options
        appropriées fournies dans l’Application, ou en utilisant les options appropriées fournies sur le Site. Vous
        devez être connecté à votre Compte pour annuler un Forfait en utilisant l’Application ou le Site. L’Entreprise
        ne fournira aucun remboursement ni aucun crédit pour les frais d’abonnement pour toute partie de mois.
      </li>
      <li>
        <strong>11.4. Limites.</strong> Un Forfait n’est pas transférable et il ne vous est pas permis d’autoriser toute
        autre personne à utiliser votre Forfait. La vente, le troc, le transfert ou la cession de tout avantage du
        forfait d’abonnement sont strictement interdits. Sans limiter tout autre recours, l’Entreprise peut suspendre ou
        résilier votre Forfait si elle soupçonne, à sa discrétion raisonnable, que vous ou toute autre personne s’est
        livrée à des activités frauduleuses en lien avec votre Forfait, y compris, sans s’y limiter, en fournissant des
        renseignements personnels qui sont faux, erronés ou périmés.
      </li>
    </ul>

    <p>
      <strong>12. Paiements pour la recharge. </strong>Vous acceptez de payer, à chaque fois que vous utilisez une
      Borne, le coût déterminé pour une telle utilisation. Les prix unitaires pour votre utilisation d’une Borne donnée
      seront les prix unitaires indiqués sur la face de la Borne et applicables aux caractéristiques de votre session de
      recharge, y compris le taux de recharge maximal, au moment où vous commencerez une session en utilisant cette
      Borne, moins toute réduction applicable à votre utilisation de la Borne, par exemple dans le cadre d’un Forfait
      d'abonnement que vous avez acheté (« Prix indiqués »). Les Prix indiqués peuvent inclure, sans s’y limiter, un
      prix par kilowattheure d’énergie fournie par la Borne; un prix par minute de recharge; et un prix par minute
      lorsque votre véhicule est connecté dans un état inactif et sans recharge. Le coût de votre utilisation d’une
      Borne peut également inclure les taxes applicables ajoutées au coût calculé à partir des Prix indiqués, et peut
      également inclure des frais fixes, indiqués sur la face de la Borne au moment où vous lancez la session. Vous
      reconnaissez que les prix indiqués pour les Bornes dans l’Application ou sur le Site ne lient pas Electrify Canada
      et ne font pas autorité, et que le coût que vous payez pour l’utilisation d’une Borne sera basé sur les Prix
      indiqués. Dans le cas des Bornes où les Prix indiqués peuvent varier en fonction du taux de recharge maximal pour
      une session, vous convenez que le taux de recharge maximal est basé sur le maximum demandé par votre véhicule.
      Vous reconnaissez que le taux de recharge réel peut varier au cours d’une session et que le taux le plus élevé
      atteint au cours d’une session peut être inférieur à ce maximum, en raison de facteurs tels que la capacité du
      véhicule au début de la recharge, la température, l’âge de la batterie, l’efficacité du véhicule, l’utilisation du
      véhicule et la puissance de sortie de la Borne. Si vous ne payez pas les montants dus en vertu de la présente
      Entente lorsque le paiement est exigible, vous rembourserez à l’Entreprise tous les frais raisonnables engagés (y
      compris les honoraires d’avocat et les frais raisonnables) pour recouvrer les montants en souffrance. Sauf
      indication contraire dans la présente Entente, toutes vos obligations relatives aux montants dus à l’Entreprise en
      vertu des CGU persisteront après l’expiration ou la résiliation des CGU pour quelque raison que ce soit.
    </p>

    <p>
      <strong>13. Communications et mises à jour. </strong> En créant un compte ou en fournissant votre numéro de
      téléphone à l’Entreprise, vous acceptez de recevoir des communications de l’Entreprise, y compris, mais sans s’y
      limiter, des courriels, des notifications poussées ou des messages texte. Ces communications pourraient inclure
      des notifications de sessions de recharge et des reçus finaux. Ces communications font partie de votre relation
      avec l’Entreprise et vous les recevez dans le cadre de votre utilisation de la Solution ou du Réseau. Par
      conséquent, vous reconnaissez et convenez que ces avis, ententes, divulgations ou autres communications que
      l’Entreprise vous envoie par voie électronique sont conformes à toute loi applicable qui exige votre consentement
      pour une communication donnée. De plus, vous comprenez et acceptez que l’installation de l’Appli permet le
      téléchargement de mises à jour et de mises à niveau dans le but de corriger les défauts du produit ou de fournir
      des améliorations. Vous recevrez un avis et la possibilité d’accepter ou de refuser toute mise à jour ou mise à
      niveau fournie par l’Entreprise. Cependant, vous reconnaissez que votre refus d’une mise à jour ou d’une mise à
      niveau pourrait avoir une incidence sur la performance de l’Application. L’Entreprise se réserve le droit de
      mettre fin à votre utilisation de l’Application si vous refusez une mise à jour que l’Entreprise peut imposer à sa
      seule discrétion.
    </p>

    <p>
      <strong>14. Politique de confidentialité d’Electrify Canada. </strong> Vous comprenez, reconnaissez et convenez
      que le fonctionnement de certaines zones de la Solution et la création d’un compte pourraient nécessiter ou
      comporter la soumission, l’utilisation et la diffusion de certains renseignements personnels identifiables, y
      compris, sans s’y limiter, votre numéro de téléphone, votre adresse courriel ou votre adresse postale. Veuillez
      vous reporter à la
      <router-link :to="{ name: 'privacy-fr' }">Politique de confidentialité</router-link> d’Electrify Canada pour
      obtenir un résumé des pratiques de l’Entreprise concernant la collecte et l’utilisation de renseignements
      personnels identifiables. Tout renseignement que vous présentez par l’entremise de la Solution est assujetti à la
      Politique de confidentialité et à toute divulgation que la Politique de confidentialité incorpore par renvoi. Vous
      acceptez de permettre à l’Entreprise de conserver, d’utiliser et de divulguer vos renseignements personnels comme
      décrit dans la Politique de confidentialité et ses divulgations incorporées, et vous autorisez par la présente de
      telles utilisations et divulgations de vos renseignements personnels.
    </p>

    <p>
      <strong>15. Droits de propriété. </strong>ous les droits, titres et intérêts relatifs à la Solution sont la
      propriété de l’Entreprise. La Solution est protégée par les lois américaines et canadiennes sur le droit d’auteur
      ainsi que les dispositions des traités internationaux régissant les droits de propriété, y compris, sans s’y
      limiter, la Convention de Berne. Les présentes CGU fournissent seulement une licence limitée pour accéder à la
      Solution et l’utiliser. Par conséquent, vous reconnaissez et convenez expressément que l’Entreprise ne transfère
      aucun droit, titre ou intérêt de propriété intellectuelle ou de propriété sur la Solution à vous ou à quiconque.
      Tous les textes, marques de commerce, marques de services, illustrations, en-têtes, icônes, interfaces
      d’utilisateur, interfaces visuelles, photographies, sons, œuvres d’art, codes informatiques (y compris HTML, CSS,
      XML et JavaScript), programmes, logiciels, produits, informations et documents, ainsi que toute conception,
      structure, sélection, coordination, expression, « apparence et texture » et disposition de tout renseignement
      contenu existant dans la Solution, ou rendu accessible par celle-ci, à moins d’indication contraire, sont détenus,
      contrôlés et utilisés sous licence par l’Entreprise et ses concédants de licence. En particulier, sans s’y
      limiter, le logo de l’Entreprise, les expressions « Electrify Canada », « Electrify America », tous les noms de
      produits ou de services et les slogans publicitaires de l’Entreprise sont des marques de commerce. Rien dans la
      Solution ne doit être interprété comme accordant, par implication, préclusion ou autrement, une licence ou un
      droit d’utiliser les noms commerciaux, les marques de commerce ou les marques de service de l’Entreprise sans
      consentement écrit préalable exprès. Tous les droits non expressément octroyés dans la présente entente sont
      réservés à l’Entreprise.
    </p>

    <p>
      <strong>16. Liens vers des contenus tiers. </strong>L’Entreprise peut fournir des liens, à sa seule discrétion,
      vers d’autres applications, sites Web ou réseaux pour vous permettre de trouver de l’information ou des services
      connexes et d’y accéder. Ces autres applications, sites Web ou réseaux sont mis à jour par des tiers sur lesquels
      l’Entreprise n’exerce aucun contrôle. Votre correspondance ou toute autre activité avec des tiers concernent
      uniquement vous et ledit tiers. Par conséquent, l’Entreprise décline expressément toute responsabilité concernant
      ces applications, sites Web ou réseaux, ou en lien avec toute activité connexe entre vous et le tiers.
    </p>

    <p>
      <strong>17. Autres conditions générales. </strong>D’autres avis, modalités et conditions peuvent s’appliquer aux
      produits, aux services, à la réception de certains documents (ou à l’accès à ceux-ci), à la participation à un
      programme particulier ou à des parties ou des caractéristiques particulières de la Solution, y compris, sans s’y
      limiter, les modalités des magasins d’applications, des services de distribution numérique et/ou des tiers qui
      traitent les paiements. Sans limiter ce qui précède, vous acceptez par la présente que (a) les présentes CGU
      s’appliquent en complément de toute condition d’utilisation imposée ou requise par toute plateforme d’interface
      utilisateur ou de téléchargement numérique à partir de laquelle vous téléchargez ou accédez à la Solution («
      Modalités du fournisseur »); et (b) les modalités des présentes CGU complètent, mais ne modifient pas ces
      Modalités du fournisseur.
    </p>

    <p>
      <strong>18. Conditions générales supplémentaires pour les utilisateurs de produits Apple. </strong>REMARQUE – Les
      conditions générales de ce paragraphe s’appliquent à vous uniquement si vous avez téléchargé l’Appli par
      l’entremise de l’App Store d’Apple Inc. Vous reconnaissez que ces conditions d'utilisation sont entre vous et
      Electrify Canada, Inc., et qu'Apple Inc. (« Apple ») n'assume aucune responsabilité à l'égard de l’Appli et de son
      contenu. L'octroi de licence en vertu du paragraphe 1 des présentes Conditions d'utilisation est une licence non
      transférable permettant d'utiliser l'Appli sur tout produit de marque Apple que vous possédez ou contrôlez, comme
      le permettent les présentes Conditions d'utilisation et les règles d'utilisation énoncées dans les Conditions
      générales des services multimédias Apple, sauf que l'Appli peut être consultée et utilisée par d'autres comptes
      qui Vous sont associés via le « Partage familial » (tel que défini dans les Conditions générales des services
      Apple Media) ou l'achat en volume. Vous reconnaissez qu’Apple n’a aucune obligation de fournir des services de
      maintenance et d’assistance concernant l’Appli. En cas de non-conformité de l’Appli à toute garantie applicable,
      vous pouvez en informer Apple, et Apple vous remboursera le prix d’achat (le cas échéant) de l’Appli; étant
      entendu que, dans la pleine mesure permise par la loi applicable, Apple n’aura aucune autre obligation de garantie
      à l’égard de l’Appli. Apple n’est pas responsable du traitement de toute réclamation de votre part ou de la part
      d’un tiers concernant l’Appli ou votre utilisation de l’Appli, y compris, sans s’y limiter : (i) les réclamations
      relatives à la responsabilité associée aux produits; (ii) toute réclamation selon laquelle l’Appli ne se conforme
      pas aux obligations juridiques ou aux exigences réglementaires applicables; et (iii) les réclamations découlant de
      la protection du consommateur, de la protection de la vie privée ou de lois similaires. Dans le cas d’une
      réclamation d’un tiers selon laquelle l’Appli ou votre possession et votre utilisation de celle-ci enfreignent les
      droits de propriété intellectuelle d’un tiers, Apple ne sera pas responsable de toute enquête, défense, libération
      ou tout règlement en lien avec une telle réclamation. Apple et ses filiales sont des tiers bénéficiaires de ces
      CGU, et dès votre acceptation de ces CGU, Apple aura le droit (et sera réputée avoir accepté le droit) de faire
      valoir ces CGU contre vous en tant que tiers bénéficiaire de celles-ci.
    </p>

    <p>
      <strong>19. Utilisation mobile. </strong>La Solution offre divers outils ou fonctionnalités d’affichage auxquels
      vous avez accès à l’aide d’un téléphone cellulaire ou d’un autre dispositif informatique mobile. Veuillez noter
      que les tarifs et les frais habituels de messagerie, de données et autres de votre opérateur de téléphonie mobile
      s’appliqueront à votre utilisation de la Solution. De plus, le téléchargement, l’installation ou l’utilisation de
      la Solution peuvent être interdits ou restreints par votre opérateur de téléphonie mobile, et toutes les
      fonctionnalités de la Solution ne fonctionneront pas avec tous les opérateurs ou appareils ou dans tous les lieux.
      Par conséquent, vous êtes seul responsable de vérifier auprès de votre opérateur de téléphonie mobile si la
      Solution peut être utilisée avec vos appareils mobiles, les restrictions qui pourraient s’appliquer à votre
      utilisation de la Solution, le cas échéant, et ce qu’une telle utilisation vous coûtera. Votre utilisation de la
      Solution devra néanmoins être strictement conforme aux présentes CGU.
    </p>

    <p>
      <strong>20. Limitation de garanties. </strong>L’ENTREPRISE NE DÉCLARE NI NE GARANTIT QUE LA SOLUTION OU LES BORNES
      DE L’ENTREPRISE FONCTIONNERONT SANS ERREUR OU SANS INTERRUPTION. DANS LA PLEINE MESURE PERMISE PAR LA LOI
      APPLICABLE ET SAUF LORSQUE LA LOI L’INTERDIT DANS LE CAS DES UTILISATEURS RÉSIDANT DANS LA PROVINCE DE QUÉBEC, LA
      SOLUTION ET LE RÉSEAU SONT FOURNIS « TELS QUELS » ET « TELS QU’ACCESSIBLES ». EN OUTRE, L’ENTREPRISE NE DÉCLARE NI
      NE GARANTIT QUE LES BORNES FONCTIONNERONT SANS ERREUR OU SANS INTERRUPTION; QUE LES BORNES FOURNIRONT DES TAUX
      SPÉCIFIÉS DE TRANSFERT D’ÉNERGIE; OU QUE LES BORNES FOURNIRONT DE L’ÉNERGIE CONFORMÉMENT AUX SPÉCIFICATIONS DES
      FABRICANTS DE BORNES OU DES CONSTRUCTEURS DE VÉHICULES. L’ENTREPRISE DÉCLINE PAR LA PRÉSENTE TOUTE GARANTIE,
      EXPRESSE OU TACITE, CONCERNANT LA SOLUTION ET CONCERNANT LES BORNES, Y COMPRIS, SANS S’Y LIMITER, TOUTE GARANTIE
      TACITE DE TITRE, D’ABSENCE DE CONTREFAÇON, DE QUALITÉ MARCHANDE OU D’ADÉQUATION À UNE UTILISATION PARTICULIÈRE. EN
      OUTRE, L’ENTREPRISE DÉCLINE EXPRESSÉMENT TOUTE RESPONSABILITÉ À L’ÉGARD DE TOUT MATÉRIEL, PROGRAMME, PRODUIT ET
      SERVICE FOURNI PAR UN TIERS PRÉSENTÉ OU DÉCRIT PAR LE BIAIS DE LA SOLUTION OU AUQUEL LA SOLUTION A DONNÉ ACCÈS, ET
      VOUS CONCÉDEZ QUE L’ENTREPRISE NE SAURA ÊTRE TENUE RESPONSABLE PERTES OU DES DOMMAGES DE QUELQUE SORTE QUE CE SOIT
      DÉCOULANT DE TELLES RELATIONS ENTRE VOUS ET UN TIERS.
    </p>

    <p>
      <strong>21. Limitation de responsabilité. </strong>L’ENTREPRISE NE SERA PAS RESPONSABLE DE TOUTE RÉCLAMATION EN
      CAS DE DOMMAGES DÉCOULANT D’UNE CAUSE INDÉPENDANTE DE SA VOLONTÉ. PAR AILLEURS, ET DANS LA PLEINE MESURE PERMISE
      PAR LA LOI (ET SAUF LORSQUE LA LOI L’INTERDIT DANS LE CAS DES UTILISATEURS RÉSIDANT DANS LA PROVINCE DE QUÉBEC),
      L’ENTREPRISE NE SERA EN AUCUN CAS TENUE RESPONSABLE DE TOUS DOMMAGES INDIRECTS, PUNITIFS, ACCESSOIRES,
      PARTICULIERS OU CONSÉCUTIFS DÉCOULANT DE OU LIÉS DE QUELQUE MANIÈRE QUE CE SOIT À L’UTILISATION DE LA SOLUTION OU
      À L’UTILISATION DES BORNES, OU DÉCOULANT DE TOUTE INFORMATION, TOUT MATÉRIEL ACCESSIBLE PAR L’ENTREMISE DE LA
      SOLUTION, QUE DE TELS DOMMAGES SOIENT FONDÉS SUR LA RESPONSABILITÉ CONTRACTUELLE OU DÉLICTUELLE, LA RESPONSABILITÉ
      STRICTE OU AUTRE, ET CE, MÊME SI L’ENTREPRISE A ÉTÉ INFORMÉE DE LA POSSIBILITÉ DE TELS DOMMAGES. DE PLUS, ET DANS
      LA PLEINE MESURE PERMISE PAR LA LOI APPLICABLE ET SAUF LORSQUE LA LOI L’INTERDIT DANS LE CAS DES UTILISATEURS
      RÉSIDANT DANS LA PROVINCE DE QUÉBEC, LA RESPONSABILITÉ TOTALE DE L’ENTREPRISE POUR QUELQUE RAISON QUE CE SOIT LIÉE
      À L’UTILISATION DE LA SOLUTION OU À L’UTILISATION DES BORNES NE DÉPASSERA PAS LE MONTANT PAYÉ PAR VOUS À ELECTRIFY
      CANADA (AU COURS DES DOUZE [12] DERNIERS MOIS PRÉCÉDANT LA RÉCLAMATION) OU LA SOMME DE DIX DOLLARS CANADIENS (10 $
      CAD), SELON LE PLUS ÉLEVÉ DES DEUX MONTANTS.
    </p>

    <p>
      <strong>22. Aucune responsabilité pour négligence. </strong>DANS LA PLEINE MESURE PERMISE PAR LA LOI (ET SAUF
      LORSQUE LA LOI L’INTERDIT DANS LE CAS DES UTILISATEURS RÉSIDANT DANS LA PROVINCE DE QUÉBEC), VOUS CONVENEZ PAR LA
      PRÉSENTE QUE, EN L’ABSENCE DE PREUVE DE NÉGLIGENCE GROSSIÈRE OU DE FAUTE INTENTIONNELLE DE L’ENTREPRISE,
      L’ENTREPRISE NE SERA PAS RESPONSABLE DE TOUS DOMMAGES À LA PROPRIÉTÉ, DE TOUTE BLESSURE OU DE TOUT DÉCÈS, NI DE
      TOUT AUTRE PRÉJUDICE, ENVERS VOUS OU ENVERS TOUTE AUTRE PERSONNE, RÉSULTANT OU DÉCOULANT D’ACTES DE NÉGLIGENCE OU
      D’ACTES DE NÉGLIGEANCE PAR OMISSION DE L’ENTREPRISE OU DE TOUTE AUTRE PERSONNE, Y COMPRIS DE TELS ACTES DE
      NÉGLIGENCE OU DE TELS ACTES DE NÉGLIGEANCE PAR OMISSION DE VOTRE PART. VOUS CONVENEZ EXPLICITEMENT QUE, EN
      L’ABSENCE DE PREUVE DE NÉGLIGENCE GROSSIÈRE OU DE FAUTE INTENTIONNELLE DE L’ENTREPRISE, L’ENTREPRISE NE SERA PAS
      TENUE RESPONSABLE DE TOUT PRÉJUDICE RÉSULTANT OU DÉCOULANT DE TOUT ACCÈS NON AUTORISÉ À DES INFORMATIONS QUE VOUS
      AVEZ FOURNIES À L’ENTREPRISE OU QUE L’ENTREPRISE A OBTENUES SUR VOUS AUPRÈS DE TIERS, OU DE LA DIVULGATION NON
      AUTORISÉE DE TELLES INFORMATIONS.
    </p>

    <p>
      <strong>23. Indemnisation. </strong>Vous acceptez d’indemniser, de défendre et de protéger l’Entreprise (et ses
      filiales, entrepreneurs, employés, agents, fournisseurs et partenaires) contre toute réclamation, toute poursuite,
      toute action, toute responsabilité, toute perte, tout coût, tout dommage, toute dépense et toute autre
      responsabilité, y compris, sans s’y limiter, les honoraires d’avocat, découlant de ou liés de quelque façon que ce
      soit à votre violation ou violation alléguée des présentes CGU ou à toute violation ou violation alléguée des
      droits de toute autre personne ou entité.
    </p>

    <p>
      <strong>24. Droit applicable. </strong>ANS LA MESURE PERMISE PAR LA LOI APPLICABLE ET POUR LES UTILISATEURS QUI NE
      SONT PAS DES PERSONNES RÉSIDANT DANS LA PROVINCE DE QUÉBEC : Les présentes CGU ont été établies et seront
      interprétées et appliquées uniquement conformément aux lois de la province de l’Ontario et aux lois fédérales du
      Canada qui s’y appliquent, telles qu’elles s’appliquent aux ententes conclues et entièrement exécutées dans la
      province de l’Ontario. Tout litige sera résolu par arbitrage, et la partie perdante en paiera tous les coûts et
      frais, dans la mesure permise par les lois applicables.
    </p>
    <p>
      POUR LES PERSONNES RÉSIDANT DANS LA PROVINCE DE QUÉBEC : Les présentes CGU ont été établies et seront interprétées
      et appliquées uniquement conformément aux lois de la province de Québec et aux lois fédérales du Canada qui s’y
      appliquent, telles qu’elles s’appliquent aux ententes conclues et entièrement exécutées dans la province de
      Québec.
    </p>
    <p>
      <strong>25. ARBITRAGE. </strong>LE PRÉSENT ARTICLE 25 S’APPLIQUE DANS LA PLEINE MESURE PERMISE PAR LA LOI
      APPLICABLE. CET ARTICLE NE S’APPLIQUE PAS AUX UTILISATEURS QUI SONT DES PERSONNES RÉSIDANT DANS LA PROVINCE DE
      QUÉBEC. VEUILLEZ LIRE ATTENTIVEMENT CETTE ENTENTE POUR VOUS ASSURER QUE VOUS EN COMPRENEZ CHAQUE DISPOSITION.
      CETTE ENTENTE CONTIENT UNE DISPOSITION D’ARBITRAGE INDIVIDUEL OBLIGATOIRE ET DE RENONCIATION AUX ACTIONS
      COLLECTIVES ET AUX PROCÈS DEVANT JURY QUI EXIGE, DANS LA MESURE PERMISE PAR LA LOI APPLICABLE, LE RECOURS À
      L’ARBITRAGE SUR UNE BASE INDIVIDUELLE POUR RÉSOUDRE LES LITIGES, PLUTÔT QUE DES PROCÈS DEVANT JURY OU DES ACTIONS
      COLLECTIVES.
    </p>

    <ul>
      <li>
        <strong>25.1.</strong> Sauf dans la mesure où ceux-ci sont régis ou interdits par la loi applicable, cet
        arbitrage et cette renonciation aux actions collectives et aux procès devant jury seront régis et interprétés
        conformément aux lois de l’État de Virginie, conformément à la Loi fédérale américaine sur l’arbitrage (« United
        States Federal Arbitration Act »), sans donner effet aux principes qui prévoient l’application de la loi d’un
        autre pays, État ou territoire ou d’une autre province.
      </li>
      <li>
        <strong>25.2.</strong> Dans la mesure permise par la loi applicable, pour toutes les controverses, tous les
        litiges, toutes les demandes, toutes les réclamations ou toutes les causes d’action entre vous et nous (y
        compris l’interprétation et la portée du présent article et l’arbitrabilité de la controverse, du litige, de la
        demande, de la réclamation ou de la cause d’action) concernant votre Compte, concernant votre utilisation de la
        Solution ou votre utilisation du Réseau, ou concernant la présente Entente (ou toute entente connexe ou
        préalable que vous auriez pu avoir avec l’Entreprise), vous et l’Entreprise acceptez de régler toute
        controverse, tout litige, toute demande, toute réclamation ou toute cause d’action exclusivement au moyen d’un
        arbitrage contraignant et confidentiel. L’arbitrage aura lieu en Virginie ou, à défaut, pourrait être effectué
        par téléphone à votre demande.
      </li>
      <li>
        <strong>25.3.</strong> L’arbitrage sera assujetti à la Federal Arbitration Act (loi fédérale américaine sur
        l’arbitrage) et non aux lois sur l’arbitrage des États américains. L’arbitrage aura lieu devant un arbitre et
        sera administré par l’Association américaine d’arbitrage (American Arbitral Association, ou « AAA ») en vertu de
        ses Règles d’arbitrage relatives aux consommateurs, telles qu’elles sont modifiées de temps à autre. Si l’AAA
        n’est pas disponible, l’arbitrage sera administré par un autre administrateur que vous et l’Entreprise choisirez
        conjointement, en utilisant la plus récente version existante des Règles d’arbitrage relatives aux consommateurs
        de l’AAA.
      </li>
      <li>
        <strong>25.4.</strong> Vous devez lire cette disposition attentivement. Dans la mesure permise par la loi
        applicable, vous RENONCEZ À VOTRE DROIT DE VOUS ADRESSER À UN TRIBUNAL pour faire valoir ou défendre vos droits,
        SAUF pour les affaires que vous déposez devant la Cour des petites créances de votre pays, État, province,
        territoire ou municipalité et qui se situent dans les limites tombant sous la juridiction de la Cour des petites
        créances et ce, tant que l’affaire est en cours devant cette cour. De plus, nonobstant la présente entente
        stipulant le recours à l’arbitrage, l’une ou l’autre des parties peut demander une réparation en equity
        d’urgence devant un tribunal situé en Virginie, et vous acceptez par les présentes de vous soumettre à la
        compétence personnelle des tribunaux de Virginie à de telles fins. Vous et l’Entreprise convenez chacun qu’une
        telle demande de réparation en equity d’urgence ne constitue pas une renonciation à quelque droit que ce soit
        d’avoir recours à l’arbitrage en vertu de la présente Entente.
      </li>
      <li>
        <strong>25.5.</strong> Vous et l’Entreprise devez respecter les règles suivantes : (a) TOUTE RÉCLAMATION DÉPOSÉE
        PAR VOUS OU PAR L’ENTREPRISE DOIT ÊTRE DÉPOSÉE PAR LA PARTIE À TITRE PERSONNEL, ET NON EN TANT QUE DEMANDEUR OU
        MEMBRE D’UN GROUPE DANS TOUTE PROCÉDURE COLLECTIVE OU REPRÉSENTATIVE PRÉSUMÉE; (b) L’ARBITRE NE PEUT CONSOLIDER
        LES RÉCLAMATIONS DE PLUS D’UNE PERSONNE, NE PEUT PRÉSIDER AUCUNE FORME DE PROCÉDURE REPRÉSENTATIVE OU
        COLLECTIVE, ET NE PEUT ACCORDER DE RÉPARATION À L’ENSEMBLE D’UN GROUPE; (c) dans le cas où vous êtes en mesure
        de démontrer que les coûts de l’arbitrage seraient prohibitifs par rapport aux coûts d’une action en justice,
        l’Entreprise paiera autant de vos frais de dépôt et d’audience dans le cadre de l’arbitrage que l’arbitre le
        jugera nécessaire pour éviter que l’arbitrage soit d’un coût prohibitif par rapport au coût d’une action en
        justice; (d) l’arbitre honorera les revendications de secret professionnel et de confidentialité reconnues par
        la loi; (e) l’arbitrage sera confidentiel et ni vous ni l’Entreprise ne pourrez divulguer l’existence, le
        contenu ou les résultats de tout arbitrage, sauf si la loi l’exige ou à des fins d’exécution de la sentence
        arbitrale; (f) l’arbitre peut accorder toute réparation individuelle ou tout recours individuel expressément
        autorisé par la loi applicable, sous réserve des limitations énoncées ailleurs dans la présente Entente.
      </li>
    </ul>

    <p>
      <strong>26. Application de la sécurité dans la Solution. </strong>L’Entreprise se réserve le droit d’afficher, de
      surveiller et d’enregistrer l’activité dans la Solution sans avis ou permission de votre part. Toute information
      obtenue par surveillance, examen, ou enregistrement est assujettie à un examen par les organismes d’application de
      la loi dans le cadre d’une enquête ou d’une poursuite pour activité criminelle ou illégale possible liée à la
      Solution, ainsi qu’aux divulgations requises par la loi applicable ou en vertu de mesures gouvernementales
      connexes. L’Entreprise respectera aussi toute ordonnance d’un tribunal ou citation à comparaître impliquant des
      demandes de telles informations.
    </p>
    <p>
      <strong>27. Mesure injonctive. </strong>Vous acceptez que toute violation, réelle ou potentielle, des présentes
      CGU, y compris, sans s’y limiter, liée à l’utilisation non autorisée des droits de propriété ou des actifs de
      l’Entreprise, causera des dommages irréparables à l’Entreprise, et que de tels dommages ne seraient pas
      quantifiables en données monétaires, et que l’Entreprise n’aurait pas de recours judiciaire acceptable. Vous
      consentez par conséquent que l’Entreprise sera autorisée, outre les autres recours dont elle peut se prévaloir, à
      requérir et à obtenir une injonction ou une autre compensation équitable d’un tribunal compétent limitant toute
      violation, réelle ou potentielle, de vos obligations en vertu de toute disposition des présentes CGU. Par
      conséquent, vous renoncez par les présentes à toute exigence pour l’Entreprise de verser une caution ou une autre
      garantie dans le cas où une mesure injonctive ou une réparation en equity serait demandée ou obtenue par
      l’Entreprise pour faire respecter une disposition des présentes CGU.
    </p>
    <p>
      <strong>28. Durée et résiliation. </strong>L’Entreprise se réserve le droit, en tout temps et pour tout motif, ce
      qui comprend, sans s’y limiter, toute croyance raisonnable d’activités frauduleuses ou illégales ou tout acte ou
      omission qui contrevient à toute condition des présentes CGU, de vous refuser l’accès au Réseau, à la Solution ou
      à toute partie de ceux-ci afin de protéger son nom et sa réputation, ses activités ou d’autres éléments. Ces CGU
      seront automatiquement résiliées si vous n’en respectez pas les modalités, sous réserve du maintien en vigueur de
      certaines dispositions énumérées ci-dessous. La résiliation prendra effet sans préavis et pourrait avoir des
      répercussions (et interdire) la soumission d’informations. Vous pouvez également résilier ces CGU en tout temps en
      envoyant à l’Entreprise une notification de cette résiliation par courriel. L’Entreprise peut également résilier
      ces CGU en tout temps, en vous envoyant un préavis raisonnable par courriel si vous avez fourni une adresse
      courriel ou, si vous n’avez pas fourni d’adresse courriel, en affichant un avis public sur le Site. Toutes les
      dispositions applicables des présentes CGU resteront en vigueur après la résiliation, comme indiqué ci-dessous. Si
      vous consentez à nouveau aux CGU en utilisant une Borne ou par l’entremise de la Solution, vous consentez aux CGU
      telles qu’elles sont énoncées à ce moment. En cas de résiliation des présentes CGU pour quelque raison que ce
      soit, vous devez détruire toutes les copies de tout aspect de la Solution en votre possession. Les dispositions
      concernant les droits de propriété de l’Entreprise, les Présentations, les exclusions de garanties, les limites de
      responsabilité, les renonciations et la divisibilité, l’entente intégrale, les mesures injonctives et le droit
      applicable resteront en vigueur après la résiliation des présentes CGU pour quelque motif que ce soit.
    </p>
    <p>
      <strong>29. Renonciation et divisibilité. </strong>
      Renonciation et divisibilité. Tout manquement à insister sur le strict respect des modalités des présentes CGU ne
      pourra être interprété comme une renonciation de tout défaut ou manquement ultérieurs. Aucune renonciation par
      l’Entreprise aux modalités des présentes CGU ne constitue une renonciation à tout autre droit ou disposition ou
      une renonciation au même droit ou disposition à un autre moment. Si une partie quelconque des présentes CGU est
      jugée non valable ou inexécutable conformément à la loi applicable, y compris, sans s’y limiter, les exclusions de
      garanties et les limites de responsabilité énoncées ci-dessus, alors la disposition non valable ou inexécutable
      sera remplacée par une disposition valable et applicable dont le contenu serait le plus fidèle possible au but
      poursuivi par la disposition initiale et la partie restante des présentes CGU demeurera en vigueur.
    </p>
    <p>
      <strong>30. Contrôles des exportations. </strong>Vous déclarez et affirmez que (a) vous n’êtes pas situé dans un
      pays qui est visé par un embargo des États-Unis ou du Canada, ou que le gouvernement des États-Unis ou du Canada a
      désigné comme pays « soutenant le terrorisme »; et que (b) vous ne figurez pas sur une liste de parties interdites
      ou assujetties à des restrictions du gouvernement des États-Unis ou du Canada. Vous convenez par les présentes que
      (i) vous respecterez toutes les lois applicables en matière de contrôle des exportations et de sanctions, (ii)
      vous êtes seul responsable de vous assurer que la Solution est utilisée, divulguée et/ou transportée uniquement
      conformément à toutes les lois applicables en matière de contrôle des exportations, et (iii) vous ne réexporterez
      ni ne transférez la Solution, sous quelque forme que ce soit, directement ou indirectement, à toute personne ou
      entité basée à Cuba, en Iran, en Syrie, au Soudan, au Soudan du Sud ou en Corée du Nord.
    </p>
    <p>
      <strong>31. Coordonnées. </strong>Si Vous avez des questions concernant la Solution ou les présentes CGU, ou si
      vous souhaitez fournir un avis à l’Entreprise en vertu des présentes CGU, veuillez communiquer avec Electrify
      Canada à :
    </p>

    <p>Adresse : 777 Bayly St. W, Ajax, Ontario, L1S 7G7</p>

    <p>Téléphone : 1 833 231-3226</p>

    <p>Courriel : <a href="mailto:support@electrify-canada.ca" class="link"> support@electrify-canada.ca.</a></p>

    <p>
      <strong>32. Divers. </strong>Il n’existe aucune relation de coentreprise, de partenariat, d’emploi, d’affiliation
      ou de mandataire entre vous et l’Entreprise en vertu des présentes CGU ou par votre utilisation de la Solution.
      Ces CGU, la <router-link :to="{ name: 'privacy-fr' }">Politique de confidentialité</router-link> de l’Entreprise
      et tous les Informations sur le Forfait applicables représentent l’intégralité de l’entente entre vous et
      l’Entreprise relativement à l’utilisation de la Solution et du Réseau, et elles remplacent toute communication ou
      proposition antérieure ou simultanée, électronique, verbale ou écrite entre vous et l’Entreprise concernant la
      Solution et le Réseau. Vous n’êtes pas autorisé à attribuer, déléguer ou transférer des droits en vertu des
      présentes CGU sans l’autorisation écrite de l’Entreprise.
    </p>
    <p>
      <strong>33. Modifications à la présente entente. </strong>Veuillez noter que l’Entreprise se réserve le droit de
      modifier sans préavis les modalités des présentes CGU en publiant des CGU modifiées ou en vous envoyant un avis à
      ce sujet par la poste ou par courriel. De plus, dans la pleine mesure permise par la loi applicable et sauf
      lorsque la loi l’interdit dans le cas des utilisateurs résidant dans la province de Québec, l’Entreprise peut
      ajouter, modifier ou supprimer tout aspect, programme ou fonctionnalité de la Solution, mais l’Entreprise n’est
      pas tenue d’ajouter une mise à niveau, une amélioration ou une modification. Lorsque la loi l’exige ou à notre
      discrétion, un tel avis sera donné au moins trente (30) jours avant l’entrée en vigueur de la modification (ou
      toute période plus longue qui peut être requise par la loi applicable) et, comme l’exige la loi, contiendra
      exclusivement la clause nouvelle ou modifiée ainsi que la clause telle qu’elle se lisait antérieurement et la date
      d’entrée en vigueur de la modification. Avant l’entrée en vigueur de la modification, vous pouvez annuler
      l’Entente au lieu d’accepter la modification. Dans la pleine mesure permise par la loi, à titre de seul recours et
      lorsque la loi l’exige, vous pouvez refuser la modification et annuler ou résilier l’Entente sans frais, pénalité,
      frais d’annulation ou indemnité d’annulation, en nous avisant au plus tard trente (30) jours après l’entrée en
      vigueur de la modification, en utilisant les renseignements contenus dans l’avis, si la modification a pour effet
      d’accroître vos obligations ou de réduire nos obligations. L’utilisation continue que vous faites de la Solution
      après toute modification annoncée ou après la date indiquée dans tout avis de modification sera irréfutablement
      réputée constituer votre acceptation de toute modification aux modalités des présentes CGU (et votre acceptation
      de la version des présentes CGU alors en vigueur). Par conséquent, veuillez réviser périodiquement les CGU
      trouvées à cet emplacement. Si vous n’acceptez pas une modification de l’Entente, vous devez cesser d’utiliser la
      Solution immédiatement.
    </p>
  </article>
</template>

<script>
export default {
  name: 'terms-en',
  metaInfo: {
    title: 'Terms of use',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/terms/',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/terms/',
        hreflang: 'en-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/fr/mentions-legales/',
        hreflang: 'fr-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/terms/',
        hreflang: 'x-default',
      },
    ],
    meta: [
      {
        name: 'description',
        content: 'Read our Terms of Use to understand the legal agreement between Electrify Canada and its users.',
      },
    ],
  },
};
</script>
