import provinces from '@/assets/json/ca_provinces.json';
/* eslint-disable no-shadow */
// initial state for map data
const state = () => ({
  currentLocCoords: null,
  mapZoom: window.innerWidth >= 1200 ? 5 : 4,
  priorMapZoom: window.innerWidth >= 1200 ? 5 : 4,
  priorMapCenter: { lat: 50, lng: -96.8 },
  recentlyViewed: [],
  searchInput: null,
  visibleMarkers: [],
  markersLoading: false,
  selectedStation: {},
  locationID: null,

  // All map filters
  filters: {
    // Connector filters
    ccsChademoDC: true,
    ccsDC: true,
    // Station filters
    comingSoon: true,
    siteIsLive: true,
  },

  // Status of the various panels and lists
  isSearchActive: true,
  isFilterPanelExpanded: false,
  isStationListExpanded: false,
  isStationDetailExpanded: false,
  isSelectedStationDetailsLoaded: false,

  // Keep track of the most recent status of the various panels and lists (defined above)
  // so we can revert back to them when needed. For example when opening and closing the filters
  previousPanelStatus: [],

  // Google API instantiated references
  googleAutocomplete: null,
  googleInstance: null,
  googleMapInstance: null,
  googlePlacesService: null,
  googleSessionToken: null,
});

/**
 * Getters
 *
 * Sometimes we may need to compute derived state based on store state.
 * For example filtering through a list of items and counting them
 */
const getters = {
  getPlacePredictions: (state) => async (searchInput) => {
    const searchResults = [];

    if (searchInput === null) {
      return searchResults;
    }

    let types = null;
    if (searchInput?.match(/^[\d]{5}$/g)) {
      types = ['(regions)'];
    }

    await state.googleAutocomplete.getPlacePredictions(
      {
        input: searchInput.toLowerCase(),
        sessionToken: state.googleSessionToken,
        componentRestrictions: {
          country: 'ca',
        },
        types,
      },
      (predictions, status) => {
        if (status !== state.googleInstance.maps.places.PlacesServiceStatus.OK) {
          //
        } else {
          predictions.forEach(async (prediction) => {
            const data = prediction.structured_formatting;
            const item = {
              id: prediction.place_id,
              main: data.main_text,
              secondary: data.secondary_text,
            };
            searchResults.push(item);
          });
        }
      }
    );

    return searchResults;
  },
};

/**
 * Actions
 *
 * Actions are similar to mutations, the differences being that:
 * - Instead of mutating the state, actions commit mutations.
 * - Actions can contain arbitrary asynchronous operations.
 */
const actions = {
  async goToLocation({ commit, state, rootState }, id) {
    let searchInput = null;

    await state.googlePlacesService.getDetails(
      {
        placeId: id,
        fields: ['name', 'formatted_address', 'place_id', 'geometry', 'types'],
      },
      (result, statusLoc) => {
        commit('updatePriorMapCenter', state.googleMapInstance?.getCenter().toJSON());
        commit('updatePriorZoom', state.googleMapInstance?.getZoom());

        if (statusLoc !== state.googleInstance.maps.places.PlacesServiceStatus.OK) {
          //
        } else {
          state.googleMapInstance.fitBounds(result.geometry.viewport);
          const url = rootState.lang === 'en' ? 'locate-charger' : 'fr/trouver-une-borne';
          const resultCity = result.formatted_address.split(',')[0].replace(/\s/g, '-').toLowerCase();
          let resultState;
          let route;
          if (result.types.includes('sublocality')) {
            // if the result type is sublocality (such as Etobicoke, ON) then set the route to that sublocality and zoom
            resultState = result.formatted_address.split(',')[2].trim().split(' ')[0].toLowerCase();
            route = `/${url}/${resultState}/${resultCity}/`;
          } else if (result.types.includes('locality')) {
            // if the result type is a city or locality then set the route to that city or locality and zoom
            resultState = result.formatted_address.split(',')[1].trim().split(' ')[0].toLowerCase();
            route = `/${url}/${resultState}/${resultCity}/`;
          } else if (result.types.includes('administrative_area_level_1')) {
            // if the result type is a state or province then set the route to that state or province and zoom
            resultState = result.formatted_address.split(',')[0].toLowerCase();
            const province = provinces.en.filter(
              (p) => p.name.toLowerCase() === resultState || p.abbreviation.toLowerCase() === resultState
            );
            route = `/${url}/${province[0].abbreviation.toLowerCase()}/`;
          }
          searchInput = result.name;
          if (route) {
            window.history.replaceState(window.history.state, '', encodeURI(route));
          } else if (!searchInput && !route) {
            window.history.replaceState(window.history.state, '', encodeURI(`/locate-charger/`));
          }
          commit('setSearchInput', searchInput);
        }
      }
    );
  },
  async setMapCenter({ state }, payload) {
    await state.googleMapInstance?.setCenter(payload);
  },
  async setZoom({ commit, state }, zoom) {
    if (state.googleMapInstance?.getZoom() !== zoom) {
      await state.googleMapInstance?.setZoom(zoom);
    }
    commit('updateZoom', zoom);
    if (state.mapZoom > 10) {
      commit('setIsStationListExpanded', true);
    } else if (state.mapZoom <= 10) {
      commit('setIsStationListExpanded', false);
    }
  },
  updateMapZoomAndCenter({ commit, dispatch, state }, payload) {
    commit('updatePriorMapCenter', state.googleMapInstance?.getCenter().toJSON());
    commit('updatePriorZoom', state.googleMapInstance?.getZoom());
    dispatch('setMapCenter', payload.center);
    dispatch('setZoom', payload.zoom);
  },
};

/**
 * Mutations
 *
 * The only way to actually change state in a Vuex store is by committing a mutation.
 * Vuex mutations are very similar to events: each mutation has a string type and
 * a handler. The handler function is where we perform actual state modifications,
 * and it will receive the state as the first argument
 */
const mutations = {
  resetFilters(state) {
    state.filters = {
      ccsChademoDC: true,
      ccsDC: true,
      l2: true,
      comingSoon: true,
      commercial: true,
      siteIsLive: true,
    };
  },
  setCurrentLocCoords(state, payload) {
    if (payload) {
      state.currentLocCoords = {
        lng: payload.coords.longitude,
        lat: payload.coords.latitude,
      };
      this.dispatch('map/setMapCenter', state.currentLocCoords);
    } else {
      state.currentLocCoords = null;
    }
  },
  setGoogleAutocomplete(state, payload) {
    state.googleAutocomplete = payload;
  },
  setGoogleInstance(state, payload) {
    state.googleInstance = payload;
  },
  setGoogleMapInstance(state, payload) {
    state.googleMapInstance = payload;
  },
  setGooglePlacesService(state, payload) {
    state.googlePlacesService = payload;
  },
  setGoogleSessionToken(state, payload) {
    state.googleSessionToken = payload;
  },
  setIsFilterPanelExpanded(state, payload) {
    state.isFilterPanelExpanded = payload;
  },
  setIsSearchActive(state, payload) {
    state.isSearchActive = payload;
  },
  setIsStationDetailExpanded(state, payload) {
    state.isStationDetailExpanded = payload;
  },
  setSelectedStation(state, payload) {
    state.selectedStation = payload;
  },
  setIsStationListExpanded(state, payload) {
    state.isStationListExpanded = payload;
  },
  setIsSelectedStationDetailsLoaded(state, payload) {
    state.isSelectedStationDetailsLoaded = payload;
  },
  setPanelStatusSnapshot(state) {
    // Copies the current value of all four status fields
    state.previousPanelStatus.push({
      isFilterPanelExpanded: state.isFilterPanelExpanded,
      isSearchActive: state.isSearchActive,
      isStationDetailExpanded: state.isStationDetailExpanded,
      isStationListExpanded: state.isStationListExpanded,
    });
  },
  setRestorePanelStatus(state) {
    // Copies the current values found in previousPanelStatus back to state
    if (state.previousPanelStatus.length > 0) {
      const previousState = state.previousPanelStatus.pop();
      state.isFilterPanelExpanded = previousState.isFilterPanelExpanded;
      state.isSearchActive = previousState.isSearchActive;
      state.isStationDetailExpanded = previousState.isStationDetailExpanded;
      state.isStationListExpanded = previousState.isStationListExpanded;
    }
  },
  setRecentlyViewed(state, payload) {
    sessionStorage.setItem(`locateCharger_RecentlyViewed${payload}`, payload);
    state.recentlyViewed.push(payload);
  },
  setSearchInput(state, payload) {
    state.searchInput = payload;
  },
  setLocationID(state, payload) {
    state.locationID = payload;
  },
  setVisibleMarkers(state, payload) {
    state.visibleMarkers = payload;
  },
  setMarkersLoading(state, payload) {
    state.markersLoading = payload;
  },
  updatePriorMapCenter(state, payload) {
    state.priorMapCenter = payload;
  },
  updatePriorZoom(state, payload) {
    state.priorMapZoom = payload;
  },
  updateZoom(state, payload) {
    state.mapZoom = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
