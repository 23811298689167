const redirects = [
  {
    path: '/about-ev-charging',
    redirect: {
      name: 'getting-started-en',
    },
  },
  {
    path: '/fr/a-propos-de-la-charge/',
    redirect: {
      name: 'getting-started-fr',
    },
  },
  {
    path: '/2021-22-vw-id4/',
    redirect: 'vw-id4-en',
  },
  {
    path: '/fr/2021-22-vw-id4/',
    redirect: {
      name: 'vw-id4-fr',
    },
  },
  {
    path: '/taycan',
    redirect: {
      name: 'porsche-en',
    },
  },
  {
    path: '/fr/taycan',
    redirect: {
      name: 'porsche-fr',
    },
  },
];

const exportedRedirects = redirects;
export default exportedRedirects;
