<template>
  <div class="getting-started">
    <Hero heading="Les premiers pas avec Electrify Canada">
      <template slot="image">
        <picture>
          <source
            srcset="
              @/assets/images/GettingStarted/hero-desktop.webp,
              @/assets/images/GettingStarted/hero-desktop@2x.webp 2x
            "
            media="(min-width: 750px)"
          />
          <source srcset="@/assets/images/GettingStarted/hero-mobile.webp" />
          <img src="@/assets/images/GettingStarted/hero-mobile.webp" alt="" />
        </picture>
      </template>
    </Hero>
    <TwoColumnContentBlock
      heading="Qu’est-ce que la recharge rapide en CC?"
      headlineDisplayClass="headline3"
      :maxWidth="1000"
    >
      <p>
        <strong>Les bornes à courant continu (CC) alimentent rapidement</strong> la batterie de votre véhicule de
        manière à réduire le temps de recharge et vous permettre de reprendre la route promptement. Souvent utilisées
        pour les voyages en voiture et la recharge sur le pouce, nos bornes ultrarapides CC offrent les vitesses les
        plus rapides de l’industrie, allant jusqu’à 350 kW pour les véhicules compatibles.
      </p>
      <p>
        Explorez le réseau à l’avant-garde de la disponibilité des bornes ultrarapides (150 kW) et hyper rapides (350
        kW).
      </p>
    </TwoColumnContentBlock>
    <h2 class="card-headline headline3">Les bases</h2>
    <Card headlineLevel="h3" heading="Fonctionnement" :maxWidth="1000" headlineDisplayClass="headline4">
      <template #image>
        <picture>
          <source
            srcset="
              @/assets/images/GettingStarted/charging-station-infographic-desktop-fr.webp,
              @/assets/images/GettingStarted/charging-station-infographic-desktop-fr@2x.webp 2x
            "
            media="(min-width: 750px)"
          />
          <source
            srcset="
              @/assets/images/GettingStarted/charging-station-infographic-mobile-fr.webp,
              @/assets/images/GettingStarted/charging-station-infographic-mobile-fr@2x.webp 2x
            "
          />
          <img loading="lazy" src="@/assets/images/GettingStarted/charging-station-infographic-mobile-fr.webp" alt="" />
        </picture>
      </template>

      <p>
        L’expérience de recharge d’Electrify Canada a été créée en fonction de vous. Lorsque vous arriverez à l’une de
        nos <strong>bornes de recharge</strong>, vous sélectionnerez d’abord une <strong>borne</strong>. Une fois que
        vous aurez branché le <strong>connecteur</strong> de la borne sur le port de recharge de votre véhicule, la
        borne tirera <strong>l’énergie de la baie d’alimentation</strong>.
      </p>
      <div class="tip">
        <StarIcon />
        <p>
          <strong>Astuce :</strong> Bien que certains bornes Electrify Canada soient munis de deux connecteurs, un seule
          peut être utilisé à la fois.
        </p>
      </div>
    </Card>

    <Card
      headlineLevel="h3"
      heading="Quel connecteur dois-je utiliser?"
      :maxWidth="1000"
      headlineDisplayClass="headline4"
      :isReverse="!isPhone"
    >
      <template #image>
        <picture>
          <source
            srcset="
              @/assets/images/GettingStarted/connector-types-desktop-fr.webp,
              @/assets/images/GettingStarted/connector-types-desktop-fr@2x.webp 2x
            "
            media="(min-width: 750px)"
          />
          <source
            srcset="
              @/assets/images/GettingStarted/connector-types-mobile-fr.webp,
              @/assets/images/GettingStarted/connector-types-mobile-fr@2x.webp 2x
            "
          />
          <img loading="lazy" src="@/assets/images/GettingStarted/connector-types-mobile-fr.webp" alt="" />
        </picture>
      </template>
      <p>
        Nos bornes rapides CC sont dotés de deux types de connecteurs : Système de recharge combiné
        (<strong>CCS</strong>) et prise de recharge (<strong>CHAdeMO</strong>). Les deux connecteurs se trouvent dans la
        plupart des stations de recharge Electrify Canada. On les reconnaît grâce à leur étiquette turquoise, verte et
        bleue sur les bornes.
      </p>
      <p>
        Nos connecteurs CCS peuvent recharger à des vitesses allant jusqu’à 350 kW, tandis que les connecteurs CHAdeMO
        peuvent recharger jusqu’à 50 kW. Le type de connecteur utilisé et la vitesse de recharge maximale sont
        déterminés par votre véhicule.
      </p>
      <div class="tip">
        <StarIcon />
        <p>
          <strong>Astuce :</strong> Pour connaître les capacités de recharge de votre véhicule, consultez son manuel ou
          adressez-vous à son fabricant.
        </p>
      </div>
    </Card>

    <div class="lede lede--last">
      <div class="column">
        <section class="l-one l-one--has-bg">
          <div class="l-one__col">
            <h2 class="headline3">Que se passe-t-il lorsque je branche le connecteur sur mon véhicule?</h2>
            <p>
              Tout d’abord, votre véhicule électrique (VE) effectue une demande d’alimentation (kW) à la borne. Ensuite,
              la borne alimente directement la batterie de votre véhicule. Quelques facteurs peuvent influer sur la
              demande d’énergie, comme la température, l’état de la batterie et le niveau de charge actuel.
            </p>
            <picture>
              <source
                srcset="
                  @/assets/images/GettingStarted/what-happens-desktop-fr.webp,
                  @/assets/images/GettingStarted/what-happens-desktop-fr@2x.webp 2x
                "
                media="(min-width: 750px)"
              />
              <source
                srcset="
                  @/assets/images/GettingStarted/what-happens-mobile-fr.webp,
                  @/assets/images/GettingStarted/what-happens-mobile-fr@2x.webp 2x
                "
              />
              <img
                loading="lazy"
                src="@/assets/images/GettingStarted/what-happens-mobile-fr.webp"
                alt="Dialog bubble from car states 'I need 150 kW.' Dialog bubble from charging station states 'Coming right up!'"
              />
            </picture>
          </div>
        </section>
        <div class="l-one">
          <div class="l-one__col">
            <h3 class="headline4">Que se passe-t-il lorsque je recharge mon véhicule?</h3>
            <p>
              Au début d’une session de recharge, la plupart des véhicules électriques envoient un message d’état de
              charge (EDC) à la borne. L’EDC indique le niveau de charge de la batterie de votre véhicule et,
              lorsqu’envoyé à nos bornes, il s’affiche sur l’écran de la borne ou dans l’application Electrify Canada.
              Si cette fonctionnalité est offerte sur votre véhicule, l’état de charge est mis à jour au fur et à
              mesure.
            </p>
            <p>
              Vers la fin de votre session, vous remarquerez peut-être que la vitesse de recharge (kW) ralentit. Bon
              nombre de véhicules ralentissent la vitesse de recharge quand ils atteignent environ 80 %*. Pour cette
              raison, nous vous recommandons de débrancher votre véhicule une fois que vous avez atteint ce point. Pour
              recevoir des mises à jour sur l’EDC et d’autres renseignements concernant la recharge,
              <router-link class="bold-underline" :to="{ name: `mobile-app-${$i18n.locale}` }">{{
                $t('downloadTheApp')
              }}</router-link
              >.
            </p>
          </div>
        </div>
        <section>
          <picture>
            <source
              srcset="@/assets/images/GettingStarted/charging-session-infograph-desktop-fr.webp, @/assets/images/GettingStarted/charging-session-infograph-desktop-fr@2x.webp @2x "
              media="(min-width: 750px)"
            />
            <source
              srcset="
                @/assets/images/GettingStarted/charging-session-infograph-mobile-fr.webp,
                @/assets/images/GettingStarted/charging-session-infograph-mobile-fr@2x.webp 2x
              "
            />
            <img
              loading="lazy"
              src="@/assets/images/GettingStarted/charging-session-infograph-mobile-fr.webp"
              alt="Infograph depicting the energy delivered and charging speed increasing at the beginning of charge, reaching maximum delivery for the middle portion of the charge and slowing toward the end of the charge."
            />
          </picture>
          <p class="disclaimer">
            *Le pourcentage exact peut varier. Pour savoir quand le point de ralentissement se produit sur votre
            véhicule, adressez-vous à son fabricant.
          </p>
        </section>
      </div>
    </div>
    <HeroWithContentOverlay :overlayBG="false" :darkBG="false" fullWidthMobile>
      <template #image>
        <picture>
          <source
            srcset="
              @/assets/images/WhatToExpect/charging-station-hyper-fast-desktop.webp,
              @/assets/images/WhatToExpect/charging-station-hyper-fast-desktop@2x.webp 2x
            "
            media="(min-width: 750px)"
          />
          <img loading="lazy" src="@/assets/images/WhatToExpect/charging-station-hyper-fast-mobile.webp" alt="" />
        </picture>
      </template>

      <router-link class="button-link" :to="{ name: 'charging-your-ev-fr' }">
        COMMENT<span class="sr-only">recharger son véhicule aux bornes Electrify Canada</span
        ><arrow class="arrow"></arrow>
      </router-link>
      <h3>Comment recharger son véhicule aux bornes Electrify Canada</h3>
      <p>Commencez par effectuer une recharge à l’une de nos bornes ou au moyen de notre application.</p>
    </HeroWithContentOverlay>
  </div>
</template>

<script>
import Arrow from '@/assets/images/Global/arrow_dark.svg';
import Card from '@/components/Card/Card.vue';
import Hero from '@/components/Hero/Hero.vue';
import HeroWithContentOverlay from '@/components/HeroWithContentOverlay/HeroWithContentOverlay.vue';
import TwoColumnContentBlock from '@/components/TwoColumnContentBlock/TwoColumnContentBlock.vue';
import StarIcon from '@/assets/images/Mobile-App/StarIcon.svg';

import lang from '@/lang';

export default {
  name: 'getting-started',
  components: { Arrow, Card, Hero, HeroWithContentOverlay, TwoColumnContentBlock, StarIcon },
  metaInfo: {
    title: 'Charger votre VE avec nous',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/fr/premiers-pas/',
      },
    ],
    meta: [
      {
        name: 'description',
        content:
          'Presque tous les véhicules électriques (VE) sur la route peuvent se recharger sur le réseau public d’Electrify Canada. Nos options de paiement flexibles facilitent le paiement de la recharge.',
      },
    ],
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
  computed: {
    isPhone() {
      return this.$resize && this.$mq.below(750);
    },
  },
};
</script>
