<template>
  <accordion-item :title="$t('startSession')" :onTrigger="onTrigger">
    <p>
      {{ $t('startSessionAnswer1') }}
    </p>
    <div class="flex">
      <div class="body">
        <p>
          1. {{ $t('startSessionAnswer2') }}
          <router-link
            class="link"
            :to="{ name: `glossary-${$i18n.locale}`, query: { load: 9 }, hash: '#digital-membership-pass' }"
            >{{ $t('startSessionAnswer3') }}</router-link
          >{{ $t('startSessionAnswer4') }}
        </p>
        <ul>
          <li>
            {{ $t('startSessionAnswer5') }}
          </li>
          <li>
            <strong>{{ $t('startSessionAnswer6') }}</strong> {{ $t('startSessionAnswer7') }}
          </li>
          <li>
            <strong>{{ $t('startSessionAnswer8') }}</strong> {{ $t('startSessionAnswer9') }}
          </li>
        </ul>
        <img loading="lazy" class="image-mobile" src="@/assets/images/Faq/faq_canada.png" :alt="$t('diagramShowing')" />
        <p>2. {{ $t('startSessionAnswer10') }}</p>
        <ul>
          <li>
            {{ $t('startSessionAnswer11') }}
          </li>
          <li>
            {{ $t('startSessionAnswer12') }}
          </li>
          <li>
            {{ $t('startSessionAnswer13') }}
          </li>
        </ul>
        <p>{{ $t('startSessionAnswer14') }}</p>
        <img loading="lazy" class="image-mobile" :src="img[$i18n.locale]" :alt="$t('swipeToStart')" />
        <p>*{{ $t('messagingAndDataRates') }}</p>
      </div>
      <div class="images">
        <img loading="lazy" class="image" src="@/assets/images/Faq/faq_canada.png" :alt="$t('diagramShowing')" />
        <img loading="lazy" class="image" :src="img[$i18n.locale]" alt="" />
      </div>
    </div>
  </accordion-item>
</template>

<script>
import lang from '@/lang/Faqs';
import SwipeToStart from '@/assets/images/Mobile-App/swipe to start.svg?external';
import SwipeToStartFr from '@/assets/images/Mobile-App/faq_swipe.png';
import AccordionItem from '../AccordionItem.vue';

export default {
  components: { AccordionItem },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
  props: {
    onTrigger: { type: Function, required: false, default: () => {} },
  },
  data() {
    return {
      img: {
        en: SwipeToStart,
        fr: SwipeToStartFr,
      },
      accordionID: this.$parent.accordionID,
    };
  },
};
</script>
<style scoped lang="scss">
.flex {
  display: flex;
  flex-direction: column;
  @media (min-width: 750px) {
    flex-direction: row;
  }
  li {
    margin-bottom: 12px;
  }
  .body {
    flex: 1;
  }
  .image-mobile {
    display: none;
    width: 240px;
    height: auto;
    @media (max-width: 750px) {
      display: block;
    }
  }
  .images {
    margin-left: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    @media (min-width: 750px) {
      flex-direction: column;
    }
    @media (max-width: 750px) {
      display: none;
    }
    .image {
      width: 240px;
      height: auto;
    }

    .image:nth-child(1) {
      @media (max-width: 750px) {
        margin-bottom: 30px;
      }
    }

    .image:nth-child(2) {
      max-height: 90px;
    }
  }
}
</style>
