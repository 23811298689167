<template>
  <article class="taycan">
    <Hero
      heading="Charge up fast with Electrify Canada"
      subheading="Enjoy Hyper-Fast DC charging for your Porsche Battery Electric Vehicle"
    >
      <template #image
        ><img
          alt=""
          :src="require('@/assets/images/PorscheTaycan/19_87_957_Porsche_Landing_Page_Image_1_EN.jpg')" /></template
    ></Hero>

    <TwoColumnContentBlock heading="Charging on the road just got more convenient">
      <p>
        Porsche battery electric vehicles (BEVs) are fully equipped to take advantage of the 350 kW DC fast chargers
        from Electrify Canada. That means you can recharge your Porsche Taycan (from MY2025) at Electrify Canada
        stations from 10% to 80% in about 18 minutes<reference-link num="1">
          Charging performance outputs are based on Porsche Taycan models equipped with the Performance Battery Plus,
          with an initial battery state of charge (SoC) of 9%, and a battery temperature of 23°C. Results achievable
          with a Combined Charging System (CCS) direct current (DC) fast charger, equipped with outputs equal to or
          exceeding 320 kW and 850 V. Charging times will vary depending on several factors including but not limited to
          temperature, charger type, the initial state of the battery’s charge and the battery and vehicle condition. </reference-link
        >.
      </p>

      <p>
        What’s more, all new Porsche BEV owners in Canada are eligible to receive up to two years of complimentary
        30-minute charging sessions on the Electrify Canada Hyper-Fast charging network.<reference-link num="2">
          The Inclusive Charging Term, provided through Porsche Charging Service, begins on an eligible Porsche
          vehicle’s New Car Limited Warranty start date. The New Car Limited Warranty start date is the same date that
          the vehicle ownership is transferred to the customer. The duration of the Inclusive Charging offer depends on
          your specific Porsche model and model year. You / customers are responsible for activating Porsche Charging
          Service. We assume no liability for any lost or unused time relating to the Inclusive Charging Period.
          Inclusive charging is available at Electrify Canada charging sites nationwide. This benefit includes unlimited
          30-minute charging sessions, with a mandatory 60-minute break required between charging sessions. If 60
          minutes have not passed between two separate charging sessions, the two sessions will be considered and
          charged as a single charging session. In the event that the vehicle charge is completed, you will have a
          10-minute grace period to relocate your vehicle. An idle fee may be charged following the grace period. If
          your vehicle battery is not fully charged at the end of the 30-minute Inclusive Charging period, additional
          charges will apply immediately for continued charging. We reserve the right to change the conditions of the
          Inclusive Charging Term, and the offer is subject to change or cancellation without notice.
        </reference-link>
        With inclusive charging that’s both fast and convenient, you’ll be back on the road in no time to enjoy the
        incredible performance and refinement of your Porsche BEV.
      </p>
    </TwoColumnContentBlock>

    <TwoColumnContentBlock heading="Right from your phone">
      <template #image
        ><img
          loading="lazy"
          alt=""
          :src="require('@/assets/images/PorscheTaycan/19_87_957_Porsche_Landing_Page_Image_2_RT_EN.jpg')"
      /></template>
      <p>
        The Porsche Charging Service provides access to up to two years of complimentary 30-minute charging sessions on
        the Electrify Canada network.<reference-link num="2">
          The Inclusive Charging Term, provided through Porsche Charging Service, begins on an eligible Porsche
          vehicle’s New Car Limited Warranty start date. The New Car Limited Warranty start date is the same date that
          the vehicle ownership is transferred to the customer. The duration of the Inclusive Charging offer depends on
          your specific Porsche model and model year. You / customers are responsible for activating Porsche Charging
          Service. We assume no liability for any lost or unused time relating to the Inclusive Charging Period.
          Inclusive charging is available at Electrify Canada charging sites nationwide. This benefit includes unlimited
          30-minute charging sessions, with a mandatory 60-minute break required between charging sessions. If 60
          minutes have not passed between two separate charging sessions, the two sessions will be considered and
          charged as a single charging session. In the event that the vehicle charge is completed, you will have a
          10-minute grace period to relocate your vehicle. An idle fee may be charged following the grace period. If
          your vehicle battery is not fully charged at the end of the 30-minute Inclusive Charging period, additional
          charges will apply immediately for continued charging. We reserve the right to change the conditions of the
          Inclusive Charging Term, and the offer is subject to change or cancellation without notice.
        </reference-link>
      </p>
      <ul>
        <li>
          <strong>Step 1:</strong>
          <a rel="noopener" target="_blank" href="https://www.porsche.com/canada/en/connect/">
            Activate Porsche Connect</a
          >
        </li>
        <li><strong>Step 2:</strong> Enroll in the Porsche Charging Service.</li>
        <li><strong>Step 3:</strong> Download the My Porsche App</li>
        <li><strong>Step 4:</strong> Locate a charging station</li>
        <li><strong>Step 5:</strong> Plug-in and start your charging session via the My Porsche App or Plug&Charge</li>
      </ul>
      <router-link :to="{ name: 'locate-charger-en' }" class="button">LOCATE A CHARGER</router-link>
    </TwoColumnContentBlock>

    <TwoColumnContentBlock heading="Take Charge with Plug&Charge">
      <template #image>
        <img
          loading="lazy"
          alt=""
          :src="require('@/assets/images/PorscheTaycan/19_87_957_Porsche_Landing_Page_Image_3_EN.jpg')"
      /></template>
      <p>
        Plug&Charge can provide a more streamlined way to pay for your charging experience. Please ensure that you
        enrolled in the Porsche Charging Service and activated Plug&Charge within your Porsche. Plug&Charge is not
        enabled by default.
      </p>
      <p>To activate Plug&Charge in your Porsche, follow these steps.</p>
      <ol>
        <li>
          Ensure vehicle has the following services activated:
          <ol type="a">
            <li>Porsche Connect</li>
            <li>Porsche Charging Service</li>
          </ol>
        </li>
        <li>Make sure you are signed in to the vehicle</li>
        <li>On the Home Screen, select the “Charging” tile</li>
        <li>Select “Options”</li>
        <li>Toggle “Activate Plug&Charge” to on</li>
      </ol>
      <p>
        It can take up to six (6) hours after activating Porsche Charging Services before Plug&Charge will work in the
        vehicle.
      </p>
      <p>
        Offer enrollment can be as smooth as your journeys, but if you encounter any roadblocks, call 1-833-231-3226 to
        contact Electrify Canada Customer Assistance, available 24/7.
      </p>
    </TwoColumnContentBlock>
  </article>
</template>

<script>
import lang from '@/lang';
import ReferenceLink from '@/components/ReferenceLink.vue';
import Hero from '@/components/Hero/Hero.vue';
import TwoColumnContentBlock from '@/components/TwoColumnContentBlock/TwoColumnContentBlock.vue';

export default {
  name: 'Porsche',
  metaInfo: {
    title: 'Porsche landing page | Electrify Canada',
    meta: [
      {
        name: 'description',
        content: 'Electrify Canada Porsche Ultra-Fast Charging Program description and App sign-up information.',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrify-canada.ca/porsche/' }],
  },
  components: { ReferenceLink, TwoColumnContentBlock, Hero },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
};
</script>
<style scoped lang="scss">
ol {
  font-size: 20px;
}
</style>
