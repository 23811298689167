export default {
    iconIsLive: require('@/assets/images/Map/live_updated.svg?external'),
    iconL2: require('@/assets/images/Map/live_L2_updated.svg?external'),
    iconComingSoon: require('@/assets/images/Map/comingSoon_updated.svg?external'),
    iconCommercial: require('@/assets/images/Map/commercial.svg?external'),

    // Selected Icons
    iconIsLiveSelected: require('@/assets/images/Map/live_selected_updated.svg?external'),
    iconL2Selected: require('@/assets/images/Map/L2_selected_New.svg?external'),
    iconComingSoonSelected: require('@/assets/images/Map/coming_soon_selected_updated.svg?external'),
    iconCommercialSelected: require('@/assets/images/Map/commercial_selected.svg?external'),

    // Larger Icons when zoomed in
    iconIsLiveLarge: require('@/assets/images/Map/live_updated.svg?external'),
    iconL2Large: require('@/assets/images/Map/live_L2_updated.svg?external'),
    iconComingSoonLarge: require('@/assets/images/Map/comingSoon_updated.svg?external'),
    iconCommercialLarge: require('@/assets/images/Map/commercial.svg?external'),
};
