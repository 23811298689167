<template>
  <section class="card" :id="heading">
    <div class="card__container">
      <div class="card__header">
        <span class="card__number"> {{ num }} </span>
        <h3>
          {{ heading }}
        </h3>
      </div>
      <div v-if="isMobile" class="card__image">
        <slot name="image" />
      </div>
      <div class="card__text">
        <slot />
      </div>
    </div>
    <div v-if="!isMobile" class="card__image">
      <slot name="image" />
    </div>
  </section>
</template>

<script>
export default {
  name: 'Charging-Your-EV-Card',
  props: {
    num: { type: String, required: true },
    heading: { type: String, required: false },
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 20px 0px 57.5px 0px;
  display: flex;
  max-width: 500px;
  margin: 0 auto;

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__header {
    margin-top: 0;
    display: flex;
    height: fit-content;
    box-sizing: border-box;
    align-items: flex-start;
    padding: 0 20px 0 20px;

    h3 {
      font-size: 24px;
      font-weight: 600;
      margin: 0 0 16px 0;
      max-width: 90%;
      line-height: 34.4px;
    }
  }

  &__number {
    align-self: flex-start;
    margin-right: 20px;
    font-size: 16px;
    background-color: #0a124a;
    color: white;
    padding: 4px 12px;
    border-radius: 21px;
    font-weight: 600;
  }
  &__image {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    img {
      width: 100%;
      max-width: 100%;

      @media (min-width: 500px) {
        max-width: 375px;
      }
      @media (min-width: 1000px) {
        max-width: 100%;
      }
    }
  }

  @media (min-width: 1000px) {
    padding: 60px 0;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    justify-content: space-between;

    &__container {
      max-width: 416px;
      padding-right: 20px;
      width: 45%;
      justify-content: center;
    }

    &__header {
      padding-left: 0;

      h3 {
        font-size: 32px;
        padding-right: 0;
      }
    }

    &__image {
      width: 50%;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      img {
        width: 100%;
      }
    }
    &__text {
      padding-left: 32px;
    }
  }
}
</style>
