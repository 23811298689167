<template>
  <article class="vwid">
    <Hero heading="Profitez du plein potentiel de votre Volkswagen ID.4" :subheading="true">
      <template slot="subheading">
        <span class="sub-heading"
          >Parcourez les routes grâce aux avantages inclus dans votre forfait de recharge gratuite ID.4.
        </span>
      </template>
      <template slot="image">
        <picture>
          <img alt="" :src="require('@/assets/images/VWId/vwid1-fr.webp')" />
        </picture>
      </template>
    </Hero>
    <section class="evolve">
      <div class="container">
        <div class="flex-row">
          <h2>Évoluez avec <br />Electrify Canada</h2>
          <div class="right">
            <p style="margin-bottom: 45px">
              Electrify Canada s’engage à construire un réseau de stations de recharge pour véhicules électriques
              pratiques et fiables&nbsp;—&nbsp;à des emplacements stratégiques dans les centres métropolitains, le long
              des principales autoroutes et à proximité des lieux très fréquentés&nbsp;— pour aider les utilisateurs de
              véhicules électriques (VE) à se rendre là où ils le souhaitent. Parce que nous savons que votre ID.4 n’est
              pas faite pour rester dans le garage.
            </p>
            <PartnerDropdown id="id4-select" v-model="id4YearChosen" :options="id4DropdownOptionsFR">
              <template slot="label">
                <div id="partner-label-container">
                  <label for="id4-select"> Sélectionnez l’année de votre modèle Volkswagen ID.4™&nbsp;:</label>
                </div>
              </template>
            </PartnerDropdown>
            <p v-if="id4YearChosen == '2021'">
              <strong
                >Votre modèle ID.4&nbsp;2021 ou 2022 comprend trois&nbsp;années de recharge gratuite illimitée à compter
                de l’achat du véhicule!
                <ReferenceLink num="1"
                  >Offert pour les modèles de véhicules ID.4 des années&nbsp;2021 et 2022. Trois&nbsp;(3)&nbsp;ans de
                  recharge illimitée à partir de la date d’achat du véhicule. Cette offre est non transférable. Ne peut
                  être utilisé à des fins commerciales, comme le covoiturage. Nécessite un compte Electrify Canada, une
                  application, l’inscription au forfait de recharge ID.4 et l’acceptation des conditions d’utilisation.
                  Après l’arrêt de la session de recharge amorcée par l’utilisateur, après que le véhicule ait atteint
                  sa pleine charge ou après que la recharge se soit arrêtée pour une autre raison, et après une période
                  de grâce de 10&nbsp;minutes, des frais d’inactivité s’appliqueront et l’utilisateur en sera
                  responsable. En cas de fraude ou d’abus présumé ou d’autres événements imprévus, Electrify Canada
                  pourrait interrompre ou modifier l’offre à sa seule discrétion.
                </ReferenceLink></strong
              >
            </p>
            <p v-if="id4YearChosen == '2023'">
              <strong
                >Votre modèle ID.4&nbsp;2023 ou 2024 comprend 1&nbsp;400&nbsp;kWh de recharge rapide en c. c. à utiliser
                dans les quatre ans à compter de l’achat du véhicule!
                <ReferenceLink num="2"
                  >Offert pour les modèles de véhicules ID.4 des années&nbsp;2023 et 2024. 1&nbsp;400&nbsp;kWh de
                  recharge rapide en c. c. pendant quatre&nbsp;ans à compter de la date d’achat du véhicule. Cette offre
                  est non transférable. Ne peut être utilisé à des fins commerciales, comme le covoiturage. Nécessite un
                  compte Electrify Canada, une application, l’inscription au forfait de recharge ID.4 et l’acceptation
                  des conditions d’utilisation. Après l’arrêt de la session de recharge amorcée par l’utilisateur, après
                  que le véhicule ait atteint sa pleine charge ou après que la recharge se soit arrêtée pour une autre
                  raison, et après une période de grâce de 10&nbsp;minutes, des frais d’inactivité s’appliqueront et
                  l’utilisateur en sera responsable. En cas de fraude ou d’abus présumé ou d’autres événements imprévus,
                  Electrify Canada pourrait interrompre ou modifier l’offre à sa seule discrétion.
                </ReferenceLink></strong
              >
            </p>
            <p v-if="id4YearChosen == '2023'">
              Les utilisateurs compatibles du forfait de recharge pour véhicules ID.4 2023 et 2024 pourront
              <strong>activer la fonctionnalité Brancher et recharger</strong>
              pour l’utiliser avec ce forfait (voir les étapes d’inscription au forfait ci-dessous pour en savoir plus).
            </p>
            <p>Grâce à ces avantages, les conducteurs d’ID.4 peuvent profiter pleinement de la liberté électrifiée.</p>
            <router-link class="button" :to="{ name: 'locate-charger-fr' }">TROUVER UN CHARGEUR</router-link>
          </div>
        </div>
      </div>
    </section>
    <section class="pt-20">
      <div class="img">
        <div class="container">
          <picture>
            <img loading="lazy" alt="" :src="require('@/assets/images/VWId/vwid2-fr.webp')" />
          </picture>
        </div>
      </div>
      <div class="content">
        <div class="container">
          <div class="flex-row">
            <h2>Roulez en toute<br />liberté</h2>
            <div class="right">
              <p>
                Déplacez-vous grâce au réseau de recharge rapide en c. c. d’Electrify Canada. Il est facile d’adhérer au
                forfait de recharge pour les véhicules&nbsp;ID.4. Il suffit de suivre les étapes suivantes&nbsp;:
              </p>
              <div>
                <b>Étape&nbsp;1:</b> Téléchargez l’application Electrify Canada<ReferenceLink num="3">{{
                  $t('messagingAndDataRates')
                }}</ReferenceLink
                >, créez votre compte, puis sélectionnez «&nbsp;Configurer la recharge publique &nbsp;».
              </div>
              <div class="mt-10">
                <b>Étape&nbsp;2:</b> Sélectionnez «&nbsp;Choisir le forfait d’adhésion &nbsp;», puis «&nbsp;Offres
                Premium &nbsp;».
                <ul class="sub-li step">
                  <li class="list-style">
                    Les membres actuels d’Electrify Canada peuvent sélectionner «&nbsp;Offres Premium &nbsp;» à l’onglet
                    «&nbsp;Compte &nbsp;», puis «&nbsp;J’ai un code d’inscription &nbsp;».
                  </li>
                </ul>
              </div>
              <div class="mt-10">
                <b>Étape&nbsp;3:</b>
                Entrez le code d’inscription à 6&nbsp;chiffres que vous aurez reçu de votre concessionnaire ID.4 et,
                selon l’année de votre modèle ID.4, sélectionnez le forfait de recharge ID.4, 2021-2022 ou 2023-2024.
                Entrez le NIV<ReferenceLink num="4">{{ $t('vinLocation') }}</ReferenceLink>
                à 17&nbsp;chiffres de votre véhicule aux fins de vérification.
              </div>
              <div class="mt-10">
                <b>Étape&nbsp;4&nbsp;</b> Acceptez la divulgation du forfait, puis touchez «&nbsp;Sélectionner ce
                forfait&nbsp;».
              </div>
              <div class="mt-10">
                <b>Étape&nbsp;5&nbsp;:</b> Entrez les données de paiement<ReferenceLink num="5"
                  >{{ $t('autoReloadDisclaimer') }}
                </ReferenceLink>
                puis configurez la recharge automatique.
                <ul class="sub-li step">
                  <li class="list-style">
                    Vous devrez entrer un mode de paiement<ReferenceLink num="5"
                      >{{ $t('autoReloadDisclaimer') }}
                    </ReferenceLink>
                    , pour couvrir d’éventuels frais d’occupation<ReferenceLink num="6"
                      >Les utilisateurs qui ne débranchent pas et ne déplacent pas leur véhicule dans les
                      10&nbsp;minutes qui suivent la fin de leur session de recharge se verront facturer des frais
                      d’inactivité de 0,40&nbsp;$ par minute. Ces frais visent à inciter les conducteurs à déplacer leur
                      voiture rapidement afin que d’autres puissent utiliser le chargeur. Vous pouvez être informé de la
                      fin de votre session de recharge par un message texte2 ou vous pouvez consulter l’écran du
                      chargeur.
                    </ReferenceLink>
                    ou les taxes applicables.
                    <span v-if="id4YearChosen == '2023'">
                      (
                      <router-link class="link" :to="{ name: '2023-2024-vw-id4-charging-plan-disclosure-fr' }"
                        ><strong>consultez la divulgation</strong>
                      </router-link>
                      de votre forfait ID.4&nbsp;2023-2024 pour en savoir plus)</span
                    >
                  </li>
                </ul>
              </div>
              <div class="mt-10">
                <b>Étape&nbsp;6&nbsp;:</b>
                Pour pouvoir utiliser la fonctionnalité Brancher et recharger, connectez-vous à l’application Electrify
                Canada, naviguez jusqu’à votre « Compte », sous vos « Forfaits » et suivez les instructions de
                l’application pour « Activer la fonctionnalité Brancher et recharger ».
              </div>
              <p>
                L’inscription à l’offre peut être aussi aisée que vos déplacements, mais si vous rencontrez des
                obstacles, composez le 1&nbsp;833&nbsp;231-3226 pour communiquer avec le service à la clientèle
                d’Electrify Canada, à votre disposition 24&nbsp;heures sur&nbsp;24, 7&nbsp;jours sur&nbsp;7.
              </p>
              <div class="flex-row cta">
                <router-link class="button cta-link" :to="{ name: 'mobile-app-fr' }"
                  >TÉLÉCHARGER L’APPLICATION</router-link
                >
                <a
                  href="https://apps.apple.com/ca/app/electrify-canada/id1472391910?l=fr"
                  rel="noopener"
                  target="_blank"
                  class="cta-link"
                  ><img
                    loading="lazy"
                    :alt="$t('downloadInTheAppStore')"
                    src="@/assets/images/Global/app-store-logo-fr.png"
                /></a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.ec.evowner&hl=fr_CA"
                  rel="noopener"
                  target="_blank"
                  class="cta-link"
                  ><img
                    loading="lazy"
                    :alt="$t('getItOnGooglePlay')"
                    src="@/assets/images/Global/google-play-badge-fr.png"
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="pt-20" v-if="id4YearChosen == '2023'">
      <div class="img">
        <div class="container">
          <picture>
            <img loading="lazy" alt="" :src="require('@/assets/images/VWId/vwid3-fr.webp')" />
          </picture>
        </div>
      </div>
    </section>
  </article>
</template>

<script>
import lang from '@/lang';
import Hero from '@/components/Hero/Hero.vue';
import ReferenceLink from '@/components/ReferenceLink.vue';
import PartnerDropdown from '@/components/PartnerDropdown.vue';

export default {
  name: 'VWId-fr',
  metaInfo: {
    title: 'Programme de recharge 2021-2024 VW ID.4 Electrify Canada',
    meta: [
      {
        name: 'description',
        content:
          'Description du programme de recharge ultrarapide 2021-2024 VW ID.4 d’Electrify Canada et renseignements sur l’inscription à l’application.',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrify-canada.ca/fr/vw-id4/' }],
  },
  components: { Hero, ReferenceLink, PartnerDropdown },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
  data() {
    return {
      id4DropdownOptionsFR: [
        { text: 'Sélectionnez...', value: null },
        { text: 'Modèles de véhicules ID.4 des années 2021 et 2022', value: '2021' },
        { text: 'Modèles de véhicules ID.4 des années 2023 et 2024', value: '2023' },
      ],
      id4YearChosen: null,
    };
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    isPhone() {
      return this.$resize && this.$mq.below(750);
    },
  },
};
</script>
