import axios from 'axios';
import VueAxios from 'vue-axios';
import Vue from 'vue';

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_SALESFORCE_URL;
    Vue.axios.defaults.headers.common['Content-Type'] = 'application/json';
  },
  get(resource, params) {
    return Vue.axios.get(`${resource}`, { params });
  },

  post(resource, params, queryParams) {
    return new Promise((resolve, reject) => {
      Vue.axios
        .post(`${resource}`, params, queryParams)
        .then((response) => {
          if (response.data) {
            const errorMessage = response.data.errMsg;
            if (errorMessage === null) {
              resolve({
                status: 'success',
              });
            } else {
              resolve({
                status: 'error',
                errorMsg: errorMessage,
              });
            }
          } else {
            resolve({
              status: 'error',
              errorMsg: 'Something went wrong, please try again later',
            });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  patch(resource, params) {
    return Vue.axios.patch(`${resource}`, params);
  },

  put(resource, params) {
    return new Promise((resolve, reject) => {
      Vue.axios
        .put(`${resource}`, params)
        .then((response) => {
          if (response.data) {
            const errorMessage = response.data.errMsg;
            if (errorMessage === null) {
              resolve({
                status: 'success',
              });
            } else {
              resolve({
                status: 'error',
                errorMsg: errorMessage,
              });
            }
          } else {
            resolve({
              status: 'error',
              errorMsg: 'Something went wrong, please try again later',
            });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  delete(resource, params) {
    return Vue.axios.delete(`${resource}`, params);
  },
};

export default ApiService;
