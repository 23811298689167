<template>
  <div class="station-filters">
    <div class="station-filters__wrapper">
      <button v-if="isMobile" class="map-close-button" @click="closeFilterMenu">
        <CloseIcon />
      </button>
      <h2 class="sr-only">Station List</h2>
      <div class="station-filters__connectors">
        <h3 class="map-panel-headline">{{ $t('connectorType') }}</h3>
        <ul>
          <li>
            <input type="checkbox" id="ccsDC" v-model="$store.state.map.filters.ccsDC" />
            <label for="ccsDC"><img src="../../assets/images/Map/CCS@3x.png?external" alt="" /> {{ $t('ccs') }}</label>
          </li>
          <li>
            <input type="checkbox" id="ccsChademoDC" v-model="$store.state.map.filters.ccsChademoDC" />
            <label for="ccsChademoDC"
              ><img src="../../assets/images/Map/CHAdeMO@3x.png?external" alt="" />{{ $t('cHAdeMO') }}</label
            >
          </li>
        </ul>
        <router-link
          class="station-filters__what-connector"
          :to="{ name: 'getting-started-en' }"
          rel="noopener"
          target="_blank"
        >
          {{ $t('whichConnector') }}
        </router-link>
      </div>
      <div class="station-filters__stations">
        <h3 class="map-panel-headline">{{ $t('stations') }}</h3>
        <ul>
          <li>
            <input type="checkbox" id="siteIsLive" v-model="$store.state.map.filters.siteIsLive" />
            <label for="siteIsLive">{{ $t('station') }}</label>
          </li>
          <li>
            <input type="checkbox" id="comingSoon" v-model="$store.state.map.filters.comingSoon" />
            <label for="comingSoon">{{ $t('comingSoon') }}</label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/assets/images/Map/close-icon.svg';
import lang from '@/lang/LocateCharger';

export default {
  name: 'StationFilters',
  components: {
    CloseIcon,
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
  methods: {
    closeFilterMenu() {
      this.$store.commit('map/setRestorePanelStatus');
      this.$store.commit('map/setIsFilterPanelExpanded', false);
    },
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(750);
    },
  },
};
</script>

<style scoped lang="scss">
.station-filters {
  width: 100%;

  &__connectors,
  &__stations {
    margin: 0 0 24px 0;

    &:last-child {
      margin-bottom: 0;
    }

    label {
      align-items: center;
      display: flex;
      min-height: 46px;
    }

    li {
      align-items: center;
      display: flex;
      min-height: 32px;
      margin: 0;
      padding: 0;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  &__connectors {
    img {
      margin-bottom: -10px;
      width: 40px;
    }
  }

  &__stations {
    label {
      margin-left: 12px;
    }
  }

  &__what-connector {
    display: block;
    margin-top: 20px;
    font-size: 12px;
  }

  &__wrapper {
    background-color: $c-primary-background;
    margin-top: calc(86vh - 300px);
    min-height: 300px;
    padding: 20px;
    position: relative;

    @media (min-width: 750px) {
      height: fit-content;
      margin-top: 0;
      min-height: inherit;
      padding: 50px;
      padding-top: 20px;
    }
  }
}
</style>
