<template>
  <article>
    <Hero :heading="$t('thankYou')" :subheading="true">
      <template #subheading>{{ $t('informationRecieved') }}</template>
    </Hero>
  </article>
</template>

<script>
import lang from '@/lang/Forms';
import Hero from '@/components/Hero/Hero.vue';

export default {
  name: 'thank-you',
  metaInfo: {
    meta: [{ name: 'robots', content: 'noindex' }],
  },
  components: { Hero },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
};
</script>
