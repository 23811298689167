import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';

import CompanyRoutes from '@/router/Company';
import ContactRoutes from '@/router/Contact';
import Disclosures from '@/router/Disclosures';
import EvChargingRoutes from '@/router/EvCharging';
import PartnersRoutes from '@/router/Partners';
import Store from '@/store/index';
import Redirects from '@/router/Redirects';
import Views from './Views';

Vue.use(VueRouter);
Vue.use(VueMeta);

let routes = [];
routes = routes.concat(EvChargingRoutes, CompanyRoutes, ContactRoutes, Disclosures, PartnersRoutes, Redirects, Views);

/**
 * All Routes redirect `/path` to `/path/`
 */
routes.forEach((route) => {
  if (route.name && route.path.substr(-1) === '/') {
    routes.push({
      path: route.path.slice(0, -1),
      redirect: {
        name: route.name,
      },
    });
  }
});

/**
 * All Routes redirect index.html URLs to the primary route instead
 */
routes.forEach((route) => {
  if (route.name) {
    routes.push({
      path: route.path.substr(-1) === '/' ? `${route.path}index.html` : `${route.path}/index.html`,
      redirect: {
        name: route.name,
      },
    });
  }
});

routes.push({
  path: '/fr/*',
  redirect: '/fr/404/',
});
routes.push({
  path: '*',
  redirect: '/404/',
});
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, savedPosition) {
    // If faq prevent scrolling to the top on route change
    if (to.query.load || (to.query.loadInner >= 0 && to.query.loadOuter >= 0)) {
      return savedPosition;
    }
    // scroll to anchor by returning the selector
    // eslint-disable-next-line no-else-return
    else if (to.hash) {
      const position = { selector: to.hash };

      // specify offset of the element to account for header
      position.offset = { y: 100 };

      return position;
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const pathContainsFr = to.path.includes('/fr/');

  if (to.params.lang === 'fr' || pathContainsFr) {
    Vue.nextTick(() => {
      Store.commit('setLang', 'fr');
    });
  } else if (to.params.lang) {
    router.push({ name: 'home-en' });
  }

  const defaultName = ' | Electrify Canada';

  if (to.meta && to.meta.title) document.title = `${to.meta.title}${defaultName}`;
  else document.title = 'Electrify Canada';

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el));

  if (!to.meta || !to.meta.metaTags) return next();

  to.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute('name', key);
        tag.setAttribute('content', tagDef[key]);
      });

      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    .forEach((tag) => document.head.appendChild(tag));

  return next();
});

export default router;
