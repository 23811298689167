<template>
  <div class="pricing" :class="{ mobile: isMobile, 'is-big': isBig, phone: isPhone }">
    <section class="hero">
      <div class="container">
        <h1 class="header-title">
          Pricing and Plans for EV <br v-if="!isPhone" />
          Charging
        </h1>
      </div>
    </section>
    <section class="plans">
      <div class="container">
        <img
          :src="
            isPhone
              ? require('@/assets/images/Pricing/pricing_hero_mobile.webp')
              : require('@/assets/images/Pricing/pricing_hero.webp')
          "
          alt=""
        />
      </div>
    </section>
    <section class="upsell">
      <img class="bolt" src="@/assets/images/Pricing/electric-bolt.webp" alt="" v-if="!isBig" />

      <p>Use the Electrify Canada app to see real-time pricing</p>
      <div class="app-icon">
        <a href="https://apps.apple.com/ca/app/electrify-canada/id1472391910" rel="noopener" target="_blank">
          <img
            loading="lazy"
            :src="require('@/assets/images/Global/app-store-logo-en.png')"
            alt="Download on the App Store"
          /> </a
        ><br />
        <a href="https://play.google.com/store/apps/details?id=com.ec.evowner&hl=en_CA" rel="noopener" target="_blank">
          <img
            loading="lazy"
            :src="require('@/assets/images/Global/google-play-badge-en.png')"
            alt="Get it on Google Play"
          />
        </a>
      </div>
    </section>

    <section class="wrapper l-one--has-bg">
      <div class="l-one__col">
        <h2>Pricing Information</h2>
        <p>
          Pricing for DC fast charging is determined by charger location and your plan.
          <strong>Real-time pricing is available in the app or at the charger.</strong>
        </p>
        <span>In the app:</span>
        <ul>
          <li>To view pricing in the app, select the station from the map</li>
        </ul>
        <span>At the charger:</span>
        <ul>
          <li>
            View pricing on the charger screen at any station.<strong>
              Idle fees may be imposed for parking time after your charging session has ended.</strong
            >
          </li>
        </ul>

        <div class="pricing-disclaimer">
          <p>
            Please refer to the charger screen for the most up-to-date information concerning pricing, power levels, and
            idle fees for your session. You can also view these details in your Electrify America app.
          </p>
        </div>
      </div>
    </section>

    <section class="ways-to-pay">
      <div class="container">
        <h2 class="header">Ways to pay</h2>
        <div class="payments">
          <div class="payment">
            <div class="left">
              <h2 class="sub-header sub-header--primary">Guests</h2>
              <p class="how">Credit or debit card</p>
              <p :class="{ small: isSmall }">
                Swipe or tap your card on the<br v-if="!isSmall" />
                credit card reader at the charger
              </p>
            </div>
            <img loading="lazy" class="card" src="@/assets/images/Pricing/Card.webp" alt="" />
          </div>
          <div class="payment">
            <div class="left">
              <h2 class="sub-header sub-header--primary">Pass or Pass+ Members</h2>
              <p class="how">Pay contact-free with the app</p>
              <p>
                Just add a card to your app<br />
                profile to pay seamlessly
              </p>
              <router-link to="/mobile-app" class="learn-more">Learn More <span class="arrow" /></router-link>
            </div>
            <img loading="lazy" class="phone" src="@/assets/images/Pricing/Phone.webp" alt="" />
          </div>
        </div>
      </div>
    </section>
    <pricingfaq />
  </div>
</template>

<script>
import lang from '@/lang';
import AOS from 'aos';
import pricingfaq from '@/components/PricingFaq.vue';

export default {
  name: 'pricing',
  metaInfo: {
    title: 'Pricing and membership for EV charging',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/pricing/',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/pricing/',
        hreflang: 'en-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/fr/tarification/',
        hreflang: 'fr-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/pricing/',
        hreflang: 'x-default',
      },
    ],
    meta: [
      {
        name: 'description',
        content:
          'Electrify Canada aims to keep prices low while providing powerful electric vehicle (EV) chargers. Learn about our pricing.',
      },
    ],
  },

  components: {
    pricingfaq,
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
  data() {
    return {
      selectedState: 'CA',
      country: 'US',
      currentPrices: false,
      noPricesAvailable: false,
      error: null,
      axiosCancel: null,
      staic: null,
    };
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    isBig() {
      return this.$resize && this.$mq.below(950);
    },
    isPhone() {
      return this.$resize && this.$mq.below(749);
    },
    isSmall() {
      return this.$resize && this.$mq.below(350);
    },
  },
  watch: {},
  mounted() {
    setTimeout(() => {
      AOS.refresh();
    }, 300);
  },
};
</script>
