<template>
  <accordion-item :title="$t('howToPay')" :onTrigger="onTrigger">
    <p>
      {{ $t('howToPayAnswerA') }}
    </p>
    <p>
      {{ $t('howToPayAnswerB') }}
    </p>
    <p>
      {{ $t('howToPayAnswerC') }}
    </p>
    <p>
      {{ $t('howToPayAnswerD') }}
    </p>
  </accordion-item>
</template>

<script>
import lang from '@/lang/Faqs';
import AccordionItem from '../AccordionItem.vue';

export default {
  components: { AccordionItem },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
  props: {
    onTrigger: { type: Function, required: false, default: () => {} },
  },
  data() {
    return {
      accordionID: this.$parent.accordionID,
    };
  },
};
</script>
