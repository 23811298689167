<template>
  <article class="faqs">
    <HeroWithContentOverlay
      :mobile-responsive="isPhone ? 'above' : 'overlay'"
      :overlay-b-g="false"
      :dark-b-g="!isPhone"
      :max-width="1130"
    >
      <template #image>
        <picture>
          <source media="(min-width: 750px)" :srcset="require(`@/assets/images/Pricing/premium_offers.webp`)" />
          <img
            :src="require(`@/assets/images/Pricing/premium_offers_mobile.webp`)"
            :alt="$t('getPremiumOffersAltText')"
          />
        </picture>
      </template>
      <h2>{{ $t('getPremiumOffers') }}</h2>
      <p>
        {{ $t('getPremiumOffersContent')
        }}<reference-link :is-white="!isPhone" :num="2">{{ $t('messagingAndDataRates') }}</reference-link>
      </p>
      <router-link class="button-link hero-with-overlay__button-link" :to="{ name: `mobile-app-${$i18n.locale}` }"
        >{{ $t('getTheApp') }}
        <span class="arrow" />
      </router-link>
    </HeroWithContentOverlay>
    <div class="faq-column">
      <h3 class="header" id="pricing-faq-heading">{{ $t('faqsPricing') }}</h3>
      <accordion header-id="pricing-faq-heading">
        <accordion-item :title="$t('whichPaymentMethods')">
          <p>{{ $t('whichPaymentMethodsAnswer') }}</p>
        </accordion-item>
        <accordion-item :title="$t('determinePricing')">
          <p>
            {{ $t('determinePricingAnswer') }}
          </p>
        </accordion-item>
        <HowToPay></HowToPay>
        <accordion-item :title="$t('outOfMoney')">
          <p>
            {{ $t('outOfMoneyAnswer') }}
          </p>
        </accordion-item>
        <accordion-item :title="$t('whyBecomeMember')">
          <p>
            {{ $t('whyBecomeMemberAnswer1') }}
          </p>
          <ul>
            <li>{{ $t('whyBecomeMemberAnswer2') }}</li>
            <li>{{ $t('whyBecomeMemberAnswer3') }}</li>
            <li>{{ $t('whyBecomeMemberAnswer4') }}</li>
            <li>
              {{ $t('whyBecomeMemberAnswer5') }}
            </li>
            <li>{{ $t('whyBecomeMemberAnswer6') }}</li>
            <li>{{ $t('whyBecomeMemberAnswer7') }}</li>
            <li>{{ $t('whyBecomeMemberAnswer8') }}</li>
          </ul>
        </accordion-item>
        <accordion-item :title="$t('differencePassPassPlus')">
          <p>{{ $t('differencePassPassPlusAnswer1') }}</p>
          <ul>
            <li>{{ $t('differencePassPassPlusAnswer2') }}</li>
            <li>{{ $t('differencePassPassPlusAnswer3') }}</li>
          </ul>
          <p>{{ $t('differencePassPassPlusAnswer4') }}</p>
          <ul>
            <li>{{ $t('differencePassPassPlusAnswer5') }}</li>
          </ul>
        </accordion-item>
        <accordion-item :title="$t('howToChangeMembershipType')">
          <p>
            {{ $t('howToChangeMembershipTypeAnswer') }}
          </p>
        </accordion-item>
        <accordion-item :title="$t('howToDeleteAccount')">
          <p>
            {{ $t('howToDeleteAccountAnswer') }}
          </p>
        </accordion-item>
      </accordion>
    </div>
  </article>
</template>

<script>
import lang from '@/lang/Faqs';
import ReferenceLink from './ReferenceLink.vue';
import Accordion from './Accordion.vue';
import AccordionItem from './AccordionItem.vue';
import HowToPay from './Faqs/HowToPay.vue';
import HeroWithContentOverlay from './HeroWithContentOverlay/HeroWithContentOverlay.vue';

export default {
  name: 'faqs',
  components: {
    ReferenceLink,
    Accordion,
    AccordionItem,
    HowToPay,
    HeroWithContentOverlay,
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    isPhone() {
      return this.$resize && this.$mq.below(750);
    },
  },
};
</script>

<style scoped lang="scss">
.faqs {
  padding-top: 60px;
  ::v-deep .hero-with-overlay__content {
    transform: none;
    top: 10%;
    @media (min-width: 1030px) {
      top: 20%;
    }
  }
  .hero-with-overlay__background {
    transform: none;
  }
}

.header {
  font-size: 24px;
  @media only screen and (min-width: 750px) {
    font-size: 32px;
  }
}
</style>
