<template>
  <article class="disclosure">
    <h1>Terms of Use</h1>
    <h2>Effective as of January 9, 2024</h2>
    <p class="primary-color">
      <strong>Important</strong><br />
      <strong
        >THIS IS A LEGAL AGREEMENT BETWEEN EACH END USER (“YOU” or “USER” or “YOUR”) AND Electrify Canada, Inc.,
        (“Electrify Canada” and “COMPANY”). YOU SHOULD READ CAREFULLY THE FOLLOWING TERMS AND CONDITIONS CONTAINED IN
        THIS AGREEMENT (“Agreement”).
      </strong>
      This Terms of Use governs your use of electric vehicle chargers operated by Electrify Canada (each of which is
      called a “Charger”; collectively they are called the “Network,” and the term “Network” also refers to any subset
      of the collection, including individual Chargers); your use of the mobile application made available by Electrify
      Canada (the “App”); and the website and online web portal provided by Electrify Canada (collectively the “Site”;
      and, together with the App, the “Solution”).
    </p>

    <p>
      <strong
        >IF YOU DO NOT AGREE WITH THIS TOU, YOU ARE NOT GRANTED PERMISSION TO ACCESS OR OTHERWISE USE THE WEBSITE AND
        APP OR ANY COMPONENT THEREOF, AND ARE INSTRUCTED TO EXIT THE APP IMMEDIATELY.
      </strong>
    </p>

    <p>
      <strong>1. Use of Chargers. </strong> You are permitted to use the Network, in the manner permitted by this TOU,
      and subject to the conditions of this TOU, including payment for each occasion on which you use a Charger. When
      you use a Charger, you agree to follow all applicable product, vehicle, safety, and technical documentation for
      the Charger and for any vehicle that you connect to the Charger. You also agree to use the correct equipment and
      connector type for the vehicle. Electrify Canada only permits the use, of adapters manufactured by automakers with
      its Chargers. Your use of any other adapter with the Network is prohibited. You acknowledge and agree that the
      Chargers are intended for use and are to be used solely for standard, industry-manufactured automotive land
      vehicles, and that you are prohibited from using a Charger with any other type of vehicle or electric
      transportation. This prohibition includes, without limitation, electric boats, home-built electric vehicles,
      home-built onboard chargers, and vehicles that contain personally modified chargers. To the maximum extent
      permitted under applicable law, Electrify Canada shall have no responsibility to you for any damages or losses
      resulting from your failure to comply with this paragraph, including without limitation any damages to personal
      property or arising from personal injury or death. You further acknowledge and agree to indemnify, defend, and
      hold Electrify Canada harmless for any damage, liability, or loss experienced by any person resulting from by your
      failure to comply with the terms of this paragraph.
    </p>

    <p>
      <strong>2. License Grant. </strong>This Agreement provides to You a personal, revocable, limited, non-exclusive,
      non-sublicensable, and non-transferable license to use the Solution, conditioned on Your continued compliance with
      this Agreement. You may print a single print copy or make a single screen shot of the materials and information
      from the Solution solely for Your personal use, provided that all hard copies contain all copyright and other
      applicable notices contained in such materials and information.
    </p>

    <p>
      <strong>3. Restrictions. </strong>Except as expressly provided herein, Company does not grant any other express or
      implied right to You or any other person. Accordingly, You may not modify, translate, decompile, create derivative
      work(s) of, copy, distribute, disassemble, reverse engineer, broadcast, transmit, publish, remove or alter any
      proprietary notices or labels, license, sublicense, transfer, sell, mirror, frame, exploit, rent, lease, private
      label, grant a security interest in, or otherwise use the Solution or the Network in any manner not expressly
      permitted herein. Moreover, You shall not, nor shall You permit any third party or person to, disable, circumvent,
      or otherwise avoid any security device, mechanism, protocol, or procedure implemented by Company for use of the
      Solution or the Network.
    </p>

    <p>
      <strong>4. User Obligations. </strong>You represent that You are at least eighteen years of age or the legal age
      of majority in Your province/territory (whichever is greater) and will, at all times, provide true, accurate,
      current, and complete information (for which You have all necessary rights, permission(s), or authority) when
      submitting information through the Solution or through the Network, including, without limitation, when You
      provide information via a registration or submission form. In addition, You access the Solution of Your own
      volition and are responsible for compliance with all applicable laws, rules, and regulations with respect to Your
      use of the Solution (including use of any permitted copies of the Solution materials and information). If You
      access the Solution on behalf of any organization, Your organization shall be bound to this TOU and liable for any
      breach by You. You represent that You have all rights, power, and authority to agree to this TOU on behalf of Your
      organization.
    </p>

    <p>
      <strong>5. Account. </strong>You may be required to register before you can use the Solution or certain features
      of the Solution. Each registration (“Account”) is for a single user only, unless otherwise expressly agreed upon
      by Company. Registration for access to and use of the Solution may also require access credentials, such as a user
      name and a password, or adherence to other particular access requirements as designated by Company in its sole
      discretion from time to time. You hereby agree to consider Your access credentials, such as a user name and
      password, as confidential information and not to disclose such information to any third party without the prior
      express written consent of Company, which may be withheld in its sole discretion. You shall immediately notify
      Company if You suspect or become aware of any loss or theft of Your password or any unauthorized use of Your user
      name and password. Company will not be liable for any loss or damage arising from Your failure (whether
      inadvertent or intentional) to comply with these obligations. By submitting the requested information to the
      registration form on the Solution, You represent and warrant that the information You submit for registration is
      complete, true, accurate, and current in all respects. You must maintain and promptly update Your submitted
      account information to ensure that such information is complete, true, accurate, and current. Company reserves the
      right to suspend, terminate, or otherwise discontinue Your account and/or pending registration if Company has
      reasonable grounds to suspect that any information You have submitted is untrue, inaccurate, not current, or
      incomplete, or that Your registration, account, or use of the Solution is in violation of applicable law,
      regulation, or the terms of this TOU.
    </p>

    <p>
      <strong>6. Submissions. </strong>Company may permit You to provide certain information, proposals, or materials to
      Company through the Solution with respect to certain electric charging projects or programs related to
      automobiles, infrastructure, and other associated matters (each, a “Submission(s)”). You agree not to upload,
      post, display, or transmit any Submission(s) if such Submission(s), or any part thereof, or the act of making the
      Submission(s) would be considered defamatory, abusive, violative of any intellectual property or other property or
      proprietary rights of any third party, or unlawful in any other way. Company is not responsible for screening,
      policing, editing, or monitoring Your or another user’s Submissions, and encourages all of its users to use
      reasonable discretion and caution in evaluating or reviewing any Submission(s). Nevertheless, if You violate this
      TOU, Company may, in its sole discretion, take whatever action is necessary to protect Company’s legal or
      equitable rights, or otherwise enforce the terms of this TOU. Moreover, it is a policy of Company to take
      appropriate actions under applicable intellectual property laws. If You become aware of Submissions that violate
      these rules regarding acceptable behavior or content, You may contact Company as provided herein.
    </p>

    <p>
      <strong>7. Permission to Use Submissions. </strong>By using the Solution to transmit, provide, distribute, or
      display a Submission(s), You automatically grant Company and any current or future parents, subsidiaries, as well
      as any current or future corporations, business entities, trusts, commonly-owned corporations, as well as any
      other entity owning, owned by, controlling, controlled by or under common control or ownership of an entity
      (whether legally, beneficially, by agreement or through stock ownership or other equity interest, directly or
      indirectly) and their permitted successors and assigns, with or without attribution, a royalty-free, perpetual,
      irrevocable, non-exclusive right and license to itself or any other party to use, act upon, make, have
      manufactured, sell, offer for sale, import, reproduce, modify, publish, create derivative works from, distribute
      and display such Submission(s) (in whole or in part) worldwide and/or to incorporate into other works in any form,
      media, or technology now known or later developed for the full term of any rights that may exist in such content
      or information. You also acknowledge that such Submissions (in the form received or provided by You) are
      non-confidential for all purposes and that Company owes no obligation of confidentiality, attribution, or
      approval, and has no control over the extent to which any idea or information may be used by any other party or
      person. You will receive no compensation for any Submission(s) and hereby waive any right to enforce any
      intellectual property or proprietary right, or moral rights, against Company and its parent, affiliates, and
      subsidiaries as a result of or on the basis of any use or exploitation of any Submission.
    </p>

    <p>
      <strong>8. Purchases and Subscriptions. </strong>The Solution may contain the option for You to subscribe to a
      payment plan, membership plan, discount plan (“Plan”), or purchase other related products and services. The
      applicable fees (and any applicable discounts, if available), the time period of a Plan, renewal terms, and
      permitted payment methods (e.g., credit or debit) (collectively “Plan Details”) will be specified through the
      Solution at the time you subscribe to a plan, or make a purchase. Subject to applicable law, a Plan may include an
      automatic renewal feature, the terms of which are those stated through the Solution in the course of your initial
      subscription to the Plan. If required by applicable law, or at our discretion, we will provide you with notice
      before your renewal, using any contact information we have available to us. If you do not wish to renew, you may
      cancel your subscription prior to the renewal date. Each subscription is final with respect to the time period
      purchased, and each purchase is final at the time of the purchase, with no refunds or credits provided except as
      otherwise stated in this Agreement, indicated by Company in writing, or as may be required under applicable law.
      All transactions are void where prohibited by law and Company may request information in order to confirm the
      order and method of payment. Company reserves the right to terminate or suspend access to the Solution or to
      terminate any Plan that you are subscribed to if You fail to pay any amounts owed under this Agreement when due.
    </p>

    <p>
      The terms stated through the Solution in the course of your subscribing to a Plan or purchasing a product or
      service are considered part of this TOU.
    </p>

    <p>
      <strong>9. Payments through the Solution. </strong>The Solution may offer You the opportunity to provide payment
      information (“Payment Information”) in order to complete payment for a purchase or a subscription or to load funds
      to your account (”Transaction”). You agree that Company may execute payment for Transactions that You order by
      using Payment Information that You have provided, unless you have directed Company, through the Solution, to
      discontinue use of particular Payment Information. You grant authorization to Company and/or the applicable third
      party payment-processor to charge an account for which you have provided Payment Information, unless you have
      directed Company, through the Solution, to discontinue use of a particular account. You shall be responsible for
      payment of all taxes, tariffs, levies, or duties applicable to Your payment. All amounts loaded to Your account
      will be denominated in the currency of Canada, and all transactions listed through the Solution are denominated in
      Canadian dollars. You are responsible for the accuracy of all credit and debit card information that You provide
      to us.
    </p>

    <p>
      <strong>10. Funds for Account Payments. </strong>You are responsible for maintaining the confidentiality and
      security of Your Account information. You should not disclose Your Account information to anyone. If Your Account
      information is lost or stolen, anyone who obtains possession of either could use Your Account. You are responsible
      for all transactions on Your Account, including unauthorized transactions.
    </p>

    <ul>
      <li>
        <strong>10.1. Loading of Funds.</strong> The Solution may enable you to load funds to your Account and to make
        payments for use of Chargers by means of debits to your Account (“Account Payments”). Company reserves the right
        to discontinue the availability of Account Payments at any time, in its reasonable discretion; if Company
        chooses to discontinue Account Payments, it will refund you the amount in your Account, in the manner of refunds
        set forth in paragraph 10(e) below. To make Account Payments, you must first load a minimum dollar value to Your
        Account. Company may in its sole discretion adjust the minimum dollar value from time to time, and if the
        minimum increases you will need to have the new minimum amount in Your Account to engage in Account Payments.
        Any value that You load to Your account is a prepayment for the goods and services that You may purchase from
        Company or the Plans to which you may subscribe through the Solution. While you may be required to provide your
        Payment Information to enroll in the Solution, Company might not charge your payment method until your first
        attempt to pay for use of a Charger using an Account Payment, notwithstanding any applicable subscription fee.
        The value You load to Your account is not insured by Canada Deposit Insurance Corporation (CDIC) or any other
        insurer, and does not earn interest. There may be a delay from the time You load value to when the value is
        available for Account Payments use through the Solution.
      </li>
      <li>
        <strong>10.2. Reloading of Funds.</strong> If you are participating in the Account Payments feature, you will be
        able to load additional funds into your Account, by specifying such loads through the Solution. Company may
        impose and may alter, in its reasonable discretion and without notice to you, limits on your loading of funds to
        your Account. Such restrictions may include, without limitation, limits on the number of times you may load
        funds during a given time period, limits on the dollar value of each load, limits on the total dollar value you
        may load during a given time period, or limits on the total amount in your Account.
      </li>
      <li>
        <strong>10.3. Automatic Reloads.</strong> To make Account Payments, You may be required to permit to automatic
        reloads. If you permit automatic reloads, you will specify a reload amount and a reload triggers, within limits
        that Company may set for such figures. Company will reload Your Account, using your Payment Information, by the
        specified reload amount whenever the value in your Account decreases below the reload trigger. You may change
        your reload amount or your reload trigger, and you may cancel automatic reloads (but Company may then bar You
        from making Account Payments). Such changes may take up to 24 hours to go into effect. Company will send You a
        confirmation email after each automatic reload transaction. No automatic reload transaction can be refunded or
        reversed. You may cancel automatic reloading by deleting your account, either by calling Customer Assistance at
        1-833-231-3226, or (on the Site) by selecting Settings -> Profile -> Delete account, or (on the App) by
        selecting Settings -> Edit Profile -> Delete account, but You may not be able to use the App to initiate a
        charging session when automatic reloading is disabled.
      </li>
      <li>
        <strong>10.4. Transaction History.</strong> You are responsible for checking Your transaction history to ensure
        that Your transaction history and account balance are accurate. You can check Your transaction history and
        balance through the Solution. If You have questions regarding Your transaction history or balance, or if You
        wish to dispute any transaction, please call Customer Assistance at 1-833-231-3226. Company will review Your
        claim and make a reasonable determination whether to adjust your Account in response. Company has no obligation
        to review or correct any error unless You provide notice of the claimed error within sixty (60) days of the date
        of the transaction in question.
      </li>
      <li>
        <strong>10.5. Refunds.</strong> The amount in your Account is not transferrable to any other Account or any
        other person. Except where required by applicable law, the amount you have loaded to your Account cannot be
        refunded or returned to you until you close your Account and cancel your registration with the Solution. If you
        do close your Account and cancel your registration with the Solution, Company will return any value then held in
        your Account. Company will make that refund to the payment method you used to load the funds. If such payment
        method is not available, Company will not be required to refund or return the funds by any other means, unless
        required by applicable law.
      </li>
    </ul>

    <p>
      <strong>11. Subscription Plans. </strong>The Solution may provide You the option to sign up for a monthly
      subscription plan that provides discounted pricing for use of the Network for a monthly fee. The details of such
      discounts and the amount of the fee are set forth in the Plan Details. Company may change the Plan Details for any
      Plan, upon prior notice to You and an opportunity for You to decide whether to cancel or to renew your Plan.
      Company may cancel a Plan, but your Plan will continue through the end of the last month for which you have paid.
    </p>

    <ul>
      <li>
        <strong>11.1. Automatic Renewal/Continuance.</strong> Your subscription plan will continue month-to-month until
        terminated. You must cancel Your membership before it renews each month in order to avoid billing of the next
        month's membership fee to Your payment method on file.
      </li>
      <li>
        <strong>11.2. Payment.</strong> You will need to provide Company with Payment Information, and authorization to
        use the Payment Information for recurring subscription fees, in order to subscribe to a Plan. If you have signed
        up for Account Payments, Company will use the funds in your Account for subscription payments, and then make
        automatic reloads into your Account if necessary to refill your account to the specified minimum balance.
        Subscription fees are fully earned upon payment. In some cases Your payment date may change, for example if Your
        payment method has not successfully settled or if Your paid membership began on a day not contained in a given
        month. You can find specific details on Your next payment date through the Solution by viewing your Billing
        Details. You authorize Company to charge any payment method associated to Your account in case Your primary
        payment method is declined or no longer available to Company for payment of Your subscription fee. You remain
        responsible for any uncollected amounts. If a payment is not successfully settled, due to expiration,
        insufficient funds, or otherwise, and You do not cancel Your account, Company may suspend Your Plan until
        Company has successfully charged a valid payment method.
      </li>
      <li>
        <strong>11.3. Cancellation.</strong> You can cancel or terminate Your Plan by using the appropriate options
        provided in the App, or by using the appropriate options provided on the Site. You must be logged into Your
        account to cancel a Plan using the App or the Site. Company will not provide refunds or credits for subscription
        fees for any partial-month subscription periods.
      </li>
      <li>
        <strong>11.4. Limitations.</strong> A Plan is non-transferable, and You are not permitted to allow any other
        person to use Your Plan. The sale, barter, transfer or assignment of any subscription plan benefits is strictly
        prohibited. Without limiting any other remedies, Company may suspend or terminate any Your Plan if it suspects,
        in its reasonable discretion, that Your or any other person has engaged in fraudulent activity in connection
        with Your Plan, including without limitation by providing personal information that is untrue, inaccurate or not
        current.
      </li>
    </ul>

    <p>
      <strong>12. Payments for Charging. </strong>You agree to pay, for any occasion that you use a Charger, the cost
      determined for such use. The unit prices for your use of a given Charger will be the unit prices stated on the
      face of the Charger, and applicable for the characteristics of your charging session including the maximum
      charging rate, at the time you initiate a session using that Charger, less any discount applicable to your use of
      the Charger such as from a subscription plan you have purchased (“Stated Prices”). Stated Prices may include, but
      are not limited to, a price per kilowatt-hour of energy delivered by the Charger; a price per minute of charging;
      and a price per minute that your vehicle is connected in an idle, non-charging state. The cost of your use of a
      Charger may also include applicable taxes added to the cost calculated from the Stated Prices, and may also
      included a fixed fee, stated on the face of the Charger at the time you initiate the session. You acknowledge that
      the prices displayed for Chargers in the App or on the Site are not binding on Electrify Canada or authoritative,
      and that the cost you pay for use of a Charger will be based on the Stated Prices. For Chargers where Stated
      Prices can vary depending on the maximum charging rate for a session, you agree that the maximum charging rate is
      based on the maximum requested by your vehicle. You acknowledge that the actual charging rate may vary during a
      session and that the highest rate achieved during a session may be lower than that maximum, due to factors such as
      vehicle capacity at start of charging, temperature, battery age, vehicle efficacy, vehicle usage, and power output
      of the charging station. If you fail to pay any amount owed under this Agreement when payment is due, You shall
      reimburse Company for all reasonable costs incurred (including reasonable attorney’s fees and costs) in collecting
      past-due amounts. Unless otherwise specified in this Agreement, all Your obligations with respect to the amounts
      due to Company under the TOU shall survive the expiration or termination of the TOU for any reason.
    </p>

    <p>
      <strong>13. Communications, and Updates. </strong> By creating an account, or by providing your phone number to
      Company, You agree to receive communications from Company including, but not limited to email, push notifications,
      and/or text messages. These communications may include charging session notifications and final receipts. These
      communications are part of Your relationship with Company and You receive them as part of Your use of the Solution
      or the Network. You therefore hereby acknowledge and agree that any such notices, agreements, disclosures or other
      communications that Company sends to You electronically are compliant with any applicable law that requires Your
      consent for a given communication. Furthermore, You understand and agree that installation of the App permits the
      downloading of updates and upgrades for the purpose(s) of fixing product defects or providing enhancements. You
      will be given notice and an opportunity to accept or refuse any update or upgrade that Company provides. However,
      You acknowledge that Your refusal of an update or upgrade may impact performance of the App. Company reserves the
      right to terminate your use of the App if You refuse an update that Company may impose at its sole discretion.
    </p>

    <p>
      <strong>14. Privacy Policy. </strong> You understand, acknowledge, and agree that the operation of certain areas
      of the Solution and the creation of an account may require or involve the submission, use, and dissemination of
      various personally identifiable information including, but not limited to, Your phone number, email address, and
      mailing address. Please see the Electrify Canada
      <router-link :to="{ name: 'privacy-en' }">Privacy Policy</router-link> for a summary of Company's practices
      regarding collection and use of personally identifiable information. Any information submitted by You through the
      Solution will be pursuant to the Privacy Policy and any disclosure that the Privacy Policy incorporates by
      reference. You agree to allow Company to maintain, use and disclose Your personal information as described in the
      Privacy Policy and its incorporated disclosures, and You hereby license such uses and disclosures of Your personal
      information.
    </p>

    <p>
      <strong>15. Proprietary Rights. </strong>All rights, titles, and interests in and to the Solution are owned by
      Company. The Solution is protected by United States and Canadian copyright law and international treaty provisions
      governing proprietary rights, including, without limitation the Berne Convention. This TOU provides only a limited
      license to access and use the Solution. Accordingly, You expressly acknowledge and agree that Company transfers no
      ownership or intellectual property right, title, or interest in and to the Solution to You or anyone else. All
      trademarks, service marks, text, graphics, headers, icons, user interfaces, visual interfaces, photographs,
      sounds, artwork, computer code (including HTML, CSS, XML, and JavaScript code), programs, software, products,
      information, and documentation as well as the design, structure, selection, coordination, expression, “look and
      feel,” and arrangement of any content contained on or available through the Solution, unless otherwise indicated,
      are owned, controlled, and licensed by Company and/or its licensors. In particular, without limitation, the
      Company logo, the words “Electrify Canada,” “Electrify America”, all Company product or service names, and Company
      advertising slogan(s) are trademarks. Nothing contained on the Solution should be construed as granting, by
      implication, estoppel or otherwise, any license or right to use any of Company's trade names, trademarks or
      service marks without express prior written consent. All rights not expressly licensed hereunder are reserved by
      Company.
    </p>

    <p>
      <strong>16. Links to Third Party Content. </strong>Company may provide links, in its sole discretion, to other
      applications, websites, or networks for Your convenience in locating or accessing related information and/or
      services. These other applications, websites or networks are maintained by third parties over which Company
      exercises no control. Your correspondence or any other dealings with third parties are solely between You and such
      third party. Accordingly, Company hereby expressly disclaims and shall not have any liability or responsibility
      for such applications, websites, or networks, or in connection with any such dealings between You and any third
      party.
    </p>

    <p>
      <strong>17. Other Terms and Conditions. </strong>Additional notices, terms, and conditions may apply to products,
      services, receipt of (or access to) certain materials, participation in a particular program, and/or to specific
      portions or features of the Solution, including without limitation the terms of app stores, digital distribution
      services, and/or third-party payment processors. Without limitation of the foregoing, You hereby agree that (a)
      this TOU operates in addition to any terms of use imposed or required by any digital download or user interface
      platform from which You download or access the Solution (“Provider Terms”); and (b) the terms of this TOU
      supplement and do not alter or amend any such Provider Terms.
    </p>

    <p>
      <strong>18. Additional Terms and Conditions for Apple Users. </strong>NOTE – The terms and conditions of this
      paragraph apply to You only if You downloaded the App through Apple Inc.’s App Store. You acknowledge that this
      TOU is between You and Electrify Canada, Inc., and that Apple Inc. (“Apple”) bears no responsibility for the App
      and its content. The license grant under paragraph 1 of this TOU is a non-transferable license to use the App on
      any Apple-branded Products that You own or control as permitted by this TOU and the Usage Rules set forth in the
      Apple Media Services Terms and Conditions, except that the App may be accessed and used by other accounts
      associated with You via “Family Sharing” (as defined in the Apple Media Services Terms and Conditions) or volume
      purchasing. You acknowledge that Apple has no obligation whatsoever to furnish any maintenance and support
      services with respect to the App. In the event of any failure of the App to conform to any applicable warranty,
      You may notify Apple, and Apple will refund the purchase price (if any) of the App to You; provided that, to the
      maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect
      to the App. Apple is not responsible for addressing any claims by You or a third party relating to the App or Your
      use of the App, including without limitation: (i) product liability claims; (ii) any claim that the App fails to
      conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection,
      privacy, or similar legislation. In the event of any third party claim that the App or Your possession and use
      thereof infringes a third party’s intellectual property rights, Apple will not be responsible for any
      investigation, defense, settlement, or discharge thereof. Apple and its subsidiaries are third-party beneficiaries
      of this TOU, and upon Your acceptance of this TOU, Apple will have the right (and will be deemed to have accepted
      the right) to enforce this TOU against You as a third-party beneficiary thereof.
    </p>

    <p>
      <strong>19. Mobile Usage. </strong>The Solution offers various tools or display functionality that are available
      to You via Your mobile phone or other mobile computing device. Please note that Your mobile carrier’s normal
      messaging, data, and other rates and fees will apply to Your use of the Solution. In addition, downloading,
      installing, or using the Solution may be prohibited or restricted by Your mobile carrier, and not all
      functionality on the Solution will work with all carriers or devices or in all locations. Therefore, You are
      solely responsible for checking with Your mobile carrier to determine if the Solution is available for Your mobile
      devices; what restrictions, if any, may be applicable to Your use of the Solution; and how much such use will cost
      You. Nevertheless, Your use of the Solution shall be strictly in accordance with this TOU.
    </p>

    <p>
      <strong>20. Disclaimer of Warranties. </strong>COMPANY DOES NOT REPRESENT OR WARRANT THAT THE SOLUTION OR
      COMPANY’S CHARGERS WILL OPERATE ERROR-FREE OR ON AN UNINTERRUPTED BASIS. TO THE FULLEST EXTENT PERMITTED BY
      APPLICABLE LAW AND EXCEPT WHERE PROHIBITED BY LAW FOR USERS RESIDENT IN THE PROVINCE OF QUEBEC, THE SOLUTION AND
      THE NETWORK ARE PROVIDED “AS IS” AND “AS AVAILABLE”. IN ADDITION, COMPANY DOES NOT REPRESENT OR WARRANT THAT
      CHARGERS WILL OPERATE ERROR-FREE OR ON AN UNINTERRUPTED BASIS; THAT CHARGERS WILL PROVIDE SPECIFIED RATES OF
      ENERGY TRANSFER; OR THAT CHARGERS WILL DELIVER ENERGY IN ACCORDANCE WITH THE SPECIFICATIONS OF MANUFACTURERS OF
      CHARGERS OR OF VEHICLES. COMPANY HEREBY DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE
      SOLUTION AND REGARDING THE CHARGERS, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF TITLE,
      NON-INFRINGEMENT, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE. MOREOVER, COMPANY EXPRESSLY DISCLAIMS
      RESPONSIBILITY AND LIABILITY FOR ANY THIRD PARTY PROVIDED MATERIALS, PROGRAMS, PRODUCTS, AND SERVICES SET FORTH,
      DESCRIBED ON, OR ACCESSED THROUGH THE SOLUTION, AND YOU AGREE THAT COMPANY SHALL NOT BE RESPONSIBLE FOR ANY LOSS
      OR DAMAGE OF ANY SORT INCURRED AS A RESULT OF ANY SUCH DEALINGS BETWEEN YOU AND A THIRD PARTY.
    </p>

    <p>
      <strong>21. Limitation of Liability. </strong>COMPANY SHALL NOT BE RESPONSIBLE FOR ANY CLAIM OF HARM RESULTING
      FROM A CAUSE BEYOND COMPANY’S CONTROL. MOREOVER, AND TO THE MAXIMUM EXTENT PERMITTED BY LAW (AND EXCEPT WHERE
      PROHIBITED BY LAW FOR USERS RESIDENT IN THE PROVINCE OF QUEBEC), IN NO EVENT SHALL COMPANY BE LIABLE FOR ANY
      INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE
      USE OF THE SOLUTION OR THE USE OF THE CHARGERS OR FOR ANY INFORMATION AND MATERIALS AVAILABLE THROUGH THE
      SOLUTION, WHETHER BASED IN CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE, AND EVEN IF COMPANY HAS BEEN ADVISED OF
      THE POSSIBILITY OF SUCH DAMAGES. IN ADDITION AND TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW AND EXCEPT
      WHERE PROHIBITED BY LAW FOR USERS RESIDENT IN THE PROVINCE OF QUEBEC, TOTAL LIABILITY OF COMPANY FOR ANY REASON
      WHATSOEVER RELATED TO USE OF THE SOLUTION OR THE USE OF THE CHARGERS SHALL NOT EXCEED THE AMOUNT PAID BY YOU TO
      ELECTRIFY CANADA (DURING THE TWELVE (12) MONTHS PRECEEDING THE CLAIM) OR TEN CANADIAN DOLLARS ($10 CDN), WHICHEVER
      IS GREATER.
    </p>

    <p>
      <strong>22. No Liability for Negligence. </strong>TO THE MAXIMUM EXTENT PERMITTED BY LAW (AND EXCEPT WHERE
      PROHIBITED BY LAW FOR USERS RESIDENT IN THE PROVINCE OF QUEBEC), YOU HEREBY AGREE THAT, ABSENT PROOF OF GROSS
      NEGLIGENCE OR WILLFUL MISCONDUCT BY COMPANY, COMPANY SHALL NOT BE LIABLE FOR ANY DAMAGE TO PROPERTY, INJURY OR
      DEATH, OR OTHER HARM, TO YOU OR TO ANY OTHER PERSON, RESULTING OR ARISING FROM THE NEGLIGENT ACTS OR OMISSIONS OF
      COMPANY OR OF ANY OTHER PERSON, INCLUDING SUCH NEGLIGENT ACTS OR OMISSIONS BY YOU. YOU SPECIFICALLY AGREE THAT,
      ABSENT PROOF OF GROSS NEGLIGENCE OR WILLFUL MISCONDUCT BY COMPANY, COMPANY SHALL NOT BE LIABLE FOR ANY HARM
      RESULTING OR ARISING FROM ANY UNAUTHORIZED ACCESS TO OR DISCLOSURE OF INFORMATION THAT YOU HAVE PROVIDED TO
      COMPANY OR THAT COMPANY HAS OBTAINED ABOUT YOU FROM THIRD PARTIES.
    </p>

    <p>
      <strong>23. Indemnification. </strong>You agree to indemnify, defend, and hold Company (and its affiliated
      companies, contractors, employees, agents, and suppliers and partners) harmless from any and all claims, suits,
      actions, liabilities, losses, costs, damages, expenses, and any other liabilities, including without limitation
      attorneys’ fees, arising out of or related in any manner to Your breach or alleged breach of this TOU or for any
      violation or alleged violation of the rights of any other person or entity.
    </p>

    <p>
      <strong>24. Governing Law. </strong>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW AND FOR USERS WHO ARE NOT
      INDIVIDUALS RESIDENT IN THE PROVINCE OF QUEBEC: This TOU has been made in and will be construed and enforced
      solely in accordance with the laws of the Province of Ontario and the federal laws of Canada applicable therein,
      as applied to agreements entered into and completely performed in the Province of Ontario. Any dispute will be
      resolved by arbitration, with the losing party to pay all costs and fees, insofar as permitted in accordance with
      applicable laws.
    </p>
    <p>
      FOR INDIVIDUALS RESIDENT IN THE PROVINCE OF QUEBEC: This TOU has been made in and will be construed and enforced
      solely in accordance with the laws of the Province of Quebec and the federal laws of Canada applicable therein, as
      applied to agreements entered into and completely performed in the Province of Quebec.
    </p>
    <p>
      <strong>25. ARBITRATION. </strong>THIS SECTION 25 APPLIES TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW. IT
      DOES NOT APPLY TO USERS WHO ARE INDIVIDUALS RESIDENT IN THE PROVINCE OF QUEBEC. PLEASE READ THIS AGREEMENT
      CAREFULLY TO ENSURE THAT YOU UNDERSTAND EACH PROVISION. THIS AGREEMENT CONTAINS A MANDATORY INDIVIDUAL ARBITRATION
      AND CLASS ACTION/JURY TRIAL WAIVER PROVISION THAT REQUIRES, TO THE EXTENT PERMITTED BY APPLICABLE LAW, THE USE OF
      ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS.
    </p>

    <ul>
      <li>
        <strong>25.1.</strong> Except to the extent governed or pre-empted by applicable law, this arbitration and class
        action/jury trial waiver will be governed by and construed in accordance with the laws of the State of Virginia,
        consistent with the United States Federal Arbitration Act, without giving effect to any principles that provide
        for the application of the law of another jurisdiction.
      </li>
      <li>
        <strong>25.2.</strong> To the extent permitted by applicable law, for any and all controversies, disputes,
        demands, claims, or causes of action between you and us (including the interpretation and scope of this Section
        and the arbitrability of the controversy, dispute, demand, claim, or cause of action) relating to your Account,
        relating to your use of the Solution or your use of the Network, or relating to this Agreement (or any related
        or prior agreement that you may have had with Company), you and Company agree to resolve any such controversy,
        dispute, demand, claim, or cause of action exclusively through binding and confidential arbitration. The
        arbitration will take place in Virginia, or, in the alternative, may be conducted telephonically at your
        request.
      </li>
      <li>
        <strong>25.3.</strong> Arbitration will be subject to the Federal Arbitration Act and not any state arbitration
        law. The arbitration will be conducted before one arbitrator and administered by the American Arbitration
        Association (“AAA”) under its Consumer Arbitration Rules as they are amended from time to time. If the AAA is
        unavailable, the arbitration will be administered by such other administrator as you and Company jointly agree,
        using the latest extant version of the AAA Consumer Arbitration Rules.
      </li>
      <li>
        <strong>25.4.</strong> You should review this provision carefully. To the extent permitted by applicable law,
        you are GIVING UP YOUR RIGHT TO GO TO COURT to assert or defend your rights EXCEPT for matters that you file in
        small claims court in your state, jurisdiction, or municipality within the jurisdictional limits of the small
        claims court and as long as such matter is only pending in that court. Additionally, notwithstanding this
        agreement to arbitrate, either party may seek emergency equitable relief in a court located in Virginia, and you
        hereby agree to submit to the personal jurisdiction of courts in Virginia for such purposes. You and Company
        each agree that such a request for such emergency equitable relief will not waive any rights to arbitrate under
        this Agreement.
      </li>
      <li>
        <strong>25.5.</strong> You and Company must abide by the following rules: (a) ANY CLAIMS BROUGHT BY YOU OR
        COMPANY MUST BE BROUGHT IN THE PARTY’S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
        PURPORTED CLASS OR REPRESENTATIVE PROCEEDING; (b) THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S
        CLAIMS, MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING, AND MAY NOT AWARD
        CLASS-WIDE RELIEF; (c) in the event that you are able to demonstrate that the costs of arbitration will be
        prohibitive as compared to the costs of litigation, Company will pay as much of your filing and hearing fees in
        connection with the arbitration as the arbitrator deems necessary to prevent the arbitration from being
        cost-prohibitive as compared to the cost of litigation; (d) the arbitrator will honor claims of privilege and
        privacy recognized at law; (e) the arbitration will be confidential, and neither you nor Company may disclose
        the existence, content, or results of any arbitration, except as may be required by law or for purposes of
        enforcement of the arbitration award; (f) the arbitrator may award any individual relief or individual remedies
        that are expressly permitted by applicable law, subject to the limitations set forth elsewhere in this
        Agreement.
      </li>
    </ul>

    <p>
      <strong>26. Enforcing Security on the Solution. </strong>Company reserves the right to view, monitor, and record
      activity on the Solution without notice or permission from You. Any information obtained by monitoring, reviewing,
      or recording is subject to review by law enforcement organizations in connection with investigation or prosecution
      of possible criminal or unlawful activity on the Solution as well as to disclosures required by or under
      applicable law or related government agency actions. Company will also comply with all court orders or subpoenas
      involving requests for such information.
    </p>
    <p>
      <strong>27. Injunctive Relief. </strong>You acknowledge that any breach, threatened or actual, of this TOU,
      including, without limitation, with respect to unauthorized use of Company proprietary rights or assets, will
      cause irreparable injury to Company, such injury would not be quantifiable in monetary damages, and Company would
      not have an adequate remedy at law. You therefore agree that Company shall be entitled, in addition to other
      available remedies, to seek and be awarded an injunction or other appropriate equitable relief from a court of
      competent jurisdiction restraining any breach, threatened or actual, of Your obligations under any provision of
      this TOU. Accordingly, You hereby waive any requirement that Company post any bond or other security in the event
      any injunctive or equitable relief is sought by or awarded to Company to enforce any provision of this TOU.
    </p>
    <p>
      <strong>28. Term &amp; Termination. </strong>Company reserves the right at any time and on any grounds, which
      shall include, without limitation, any reasonable belief of fraudulent or unlawful activity or actions or
      omissions that violate any term or condition of this TOU, to deny Your access to the Network, Solution or to any
      portion thereof in order to protect its name and goodwill, its business, and/or others. This TOU will also
      terminate automatically if You fail to comply with its terms and conditions, subject to the survival rights of
      certain provisions identified below. Such a termination will be effective without prior notice and may impact (and
      prohibit) submission of any information. You may also terminate this TOU at any time by notifying Company of such
      termination by email. Company may also terminate this TOU at any time, by providing You reasonable prior notice
      through email if you have provided an email address, or if you have not provided an email address by providing
      public notice on the Site. All applicable provisions of this TOU will survive termination, as identified below. If
      you consent to the TOU again through use of a Charger or by means of the Solution you will be consenting to the
      TOU in such form as is available at such time. Upon termination of this TOU for any reason, You must destroy all
      copies of any aspect of the Solution in Your possession. The provisions concerning Company’s proprietary rights,
      Submissions, disclaimers of warranty, limitations of liability, waiver and severability, entire agreement,
      injunctive relief, and governing law will survive the termination of this TOU for any reason.
    </p>
    <p>
      <strong>29. Waiver &amp; Severability. </strong>Failure to insist on strict performance of any of the terms and
      conditions of this TOU will not operate as a waiver of any subsequent default or failure of performance. No waiver
      by Company of any right under this TOU will be deemed to be either a waiver of any other right or provision or a
      waiver of that same right or provision at any other time. If any part of this TOU is determined to be invalid or
      unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and the liability
      limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid,
      enforceable provision that most clearly matches the intent of the original provision and the remainder of this TOU
      shall continue in effect.
    </p>
    <p>
      <strong>30. Export Controls. </strong>You represent and warrant that You are not (a) located in a country that is
      subject to a U.S. or Canadian Government embargo, or that has been designated by the U.S. Government or the
      Canadian as a “terrorist supporting” country; and (b) listed on any U.S. or Canadian Government list of prohibited
      or restricted parties. You hereby agree that (i) You will comply with all applicable Sanctions and Export Control
      Laws, (ii) You are solely responsible for ensuring that the Solution is used, disclosed, and/or transported only
      in accordance with all applicable Sanctions and Export Control Laws, and (iii) You will not re-export or transfer
      the Solution, in any form, directly or indirectly, to any person or entity based in Cuba, Iran, Syria, Sudan,
      South Sudan, or North Korea.
    </p>
    <p>
      <strong>31. Contact Information. </strong>If You have any questions about the Solution or this TOU, or you wish to
      provide a notice to Company under this TOU, please contact Electrify Canada at:
    </p>

    <p>Address: 777 Bayly St. W, Ajax, Ontario, L1S 7G7</p>

    <p>Phone: 1-833-231-3226</p>

    <p>Email: <a href="mailto:support@electrify-canada.ca" class="link"> support@electrify-canada.ca.</a></p>

    <p>
      <strong>32. Miscellaneous. </strong>No joint venture, partnership, employment, affiliate, or agency relationship
      exists between You and Company as a result of this TOU or Your utilization of the Solution. This TOU, Company’s
      <router-link :to="{ name: 'privacy-en' }">Privacy Policy</router-link> and any applicable Plan Details represent
      the entire agreement between You and Company with respect to use of the Solution, and the Network, and they
      supersede all prior or contemporaneous communications and proposals, whether electronic, oral, or written between
      You and Company with respect to the Solution and the Network. You may not assign, delegate, or transfer any rights
      under this TOU without the prior written consent of Company.
    </p>
    <p>
      <strong>33. Changes to this Agreement. </strong>Please note that Company reserves the right to change the terms
      and conditions of this TOU by posting a revised TOU or mailing and/or e-mailing notice thereof to You, where
      required by law or at our discretion. In addition, to the fullest extent permitted by applicable law and except
      where prohibited by law for users resident in the province of Quebec, Company may add, modify, or delete any
      aspect, program, or feature of the Solution, but Company is not under any obligation to add any upgrade,
      enhancement, or modification. Where required by law or at our discretion, such notice will be given at least
      thirty (30) days before the amendment comes into force (or such longer period as may be required by applicable
      law) and, as required by law, will exclusively contain the new or amended clause as well as the clause as it read
      formerly and the date of the coming into force of the amendment. Prior to the coming into force of the amendment,
      You may cancel the Agreement instead of accepting the amendment. To the fullest extent permitted by law, as Your
      sole remedy and where required by law, You may refuse the amendment and cancel or rescind the Agreement without
      cost, penalty, cancellation fee or cancellation indemnity, by giving us notice to that effect no later than thirty
      (30) days after the amendment comes into force, using the information contained in the notice, if the amendment
      entails an increase in Your obligations or a reduction in our obligations. Your continued use of the Solution
      following any announced change or after the date indicated in any notice of amendment will be conclusively deemed
      acceptance of any change to the terms and conditions of this TOU (and acceptance of the version of this TOU then
      in effect). Accordingly, please review the TOU found at this location on a periodic basis. If You do not accept
      any amendment to the Agreement, You must cease using the Solution immediately.
    </p>
  </article>
</template>

<script>
export default {
  name: 'terms-en',
  metaInfo: {
    title: 'Terms of use',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/terms/',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/terms/',
        hreflang: 'en-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/fr/mentions-legales/',
        hreflang: 'fr-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/terms/',
        hreflang: 'x-default',
      },
    ],
    meta: [
      {
        name: 'description',
        content: 'Read our Terms of Use to understand the legal agreement between Electrify Canada and its users.',
      },
    ],
  },
};
</script>
