<template>
  <article class="terms">
    <h1 class="field__item">News Updates</h1>
    <div
      class="clearfix text-formatted field field--name-field-ea-subtitle field--type-text-long field--label-hidden field__item"
    >
      <p>Rerouting you to News Updates.</p>
    </div>
  </article>
</template>

<script>
export default {
  name: 'news-updates-en',
  metaInfo: {
    title: 'News and Updates',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/news-updates/',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/news-updates/',
        hreflang: 'en-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/fr/nouvelles/',
        hreflang: 'fr-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/news-updates/',
        hreflang: 'x-default',
      },
    ],
    meta: [
      {
        name: 'description',
        content:
          "Breaking the limits of what electric vehicles can do. Get the latest updates about Electrify Canada's Zero Emission Vehicle (ZEV) public charging network.",
      },
    ],
  },

  created() {
    if (!window.PRERENDER_INJECTED) {
      if (process.env.VUE_APP_SITE === 'us') {
        window.location = 'https://media.electrifyamerica.com/';
      } else {
        window.location = 'https://media.electrify-canada.ca/';
      }
    }
  },
};
</script>

<style scoped lang="scss">
.field--name-field-ea-subtitle {
  max-width: 1160px;
  margin: 0 auto;
  font-size: 16px;
}
article {
  overflow: hidden;
  position: relative;
  min-height: 650px;
}
</style>
