<template>
  <article class="disclosure">
    <h1>Forfait de recharge ID.4 pour 2021-2022</h1>
    <h2>{{ $t('termsAndConditions') }}</h2>
    <p>
      <strong class="primary-color">En vigueur à partir du 1er octobre 2021</strong>
    </p>
    <p>
      En cliquant sur « Accepter » ci-dessous, vous acceptez de recevoir trois ans de sessions de recharge gratuites
      pour votre véhicule électrique Volkswagen ID.4 des années-modèles 2021 et 2022 nouvellement acheté sur le réseau
      de stations de recharge de véhicules électriques d’Electrify Canada, sous réserve des
      {{ $t('termsAndConditions') }}. Aucun frais supplémentaire, achat ou abonnement n’est requis pour accéder à cette
      offre.
    </p>
    <p>
      Après vous être inscrit, vous pouvez échanger ce temps de recharge gratuit pour votre véhicule lors du lancement
      d’une session de recharge à n’importe quelle station de recharge d’Electrify Canada, exclusivement en utilisant la
      fonction « Faire glisser pour commencer » de l’application Electrify Canada ou la fonction Passe CCP avec le
      forfait approprié sélectionné.
    </p>
    <p class="primary-color">
      <strong>VEUILLEZ NOTER LES LIMITATIONS IMPORTANTES SUIVANTES CONCERNANT L’UTILISATION DE CETTE OFFRE :</strong>
    </p>
    <ul>
      <li>
        Vous <strong>ne</strong> pouvez utiliser ce temps de recharge gratuit que pour le véhicule électrique Volkswagen
        ID.4 des années modèles 2021 et 2022 admissible, acheté chez un concessionnaire Volkswagen autorisé au Canada,
        dont vous avez fourni le NIV en vous inscrivant à cette offre sur votre compte Electrify Canada.
        <strong
          >Aucun autre véhicule n’est admissible à cette recharge gratuite par l’entremise de votre compte,</strong
        >
        qu’il vous appartienne ou non.
      </li>
      <li>
        Pour vous inscrire, vous devrez fournir une carte de crédit valide qui sera débitée d’un montant sélectionné
        lors de la première session de recharge d’Electrify Canada et, par la suite, chaque fois que le solde du
        portefeuille sera inférieur au seuil indiqué dans l’application lors de l’inscription. Nous vous recommandons de
        choisir un montant de remplissage automatique inférieur, par exemple 10 $, puisque la recharge gratuite fournie
        dans le cadre de ce forfait ne déduira aucuns frais du solde de ce portefeuille.
      </li>
      <li>
        Il y aura une période de grâce de 10 minutes immédiatement après la fin de votre session de recharge, pendant
        laquelle aucun frais de stationnement ou d’inactivité ne vous sera imposé. À l’exception de cette période de
        grâce, le temps de recharge gratuit <strong>ne comprend pas</strong> les frais de stationnement ou d’inactivité
        imposés à la station de recharge.
      </li>
      <li>
        Vous <strong>ne pouvez pas</strong> utiliser ce temps de chargement gratuit pour un taxi, un service de
        covoiturage (par exemple, Uber ou Lyft) ou tout autre service similaire, ou à toute autre fin commerciale.
        <strong>Cette offre est uniquement destinée à votre usage personnel et non commercial.</strong>
      </li>
      <li>
        Vous devez respecter toute la documentation technique relative au produit, au véhicule et à la sécurité fournie
        avec votre véhicule lorsque vous le rechargez à une station de recharge d’Electrify Canada.
      </li>
      <li>
        La recharge gratuite sera fournie dans le cadre de cette offre uniquement pour le véhicule spécifique Volkswagen
        ID.4 de 2021 et 2022 enregistré sur votre compte.
      </li>
    </ul>
    <p>
      Cette offre prendra automatiquement fin trente-six (36) mois après la date d’achat de votre véhicule électrique
      Volkswagen ID.4 2021 ou 2022. Après cette date, vous ne pourrez plus accéder à cette offre ni l’utiliser sur votre
      compte. Ce temps de recharge gratuit n’est ni remboursable ni échangeable contre une valeur en espèces, et ne peut
      être vendu, prêté ou distribué à un tiers pour quelque raison que ce soit.
    </p>
    <p>
      Vous reconnaissez que votre utilisation des services d’Electrify Canada est soumise aux conditions d’utilisation
      d’Electrify Canada consultables à l’adresse suivante
      <router-link class="link" :to="{ name: 'terms-fr' }"
        >https://www.electrify-canada.ca/fr/mentions-légales</router-link
      >
      Vous reconnaissez que votre utilisation des services d’Electrify Canada est soumise aux conditions d’utilisation
      consultables sur le site Web d’Electrify Canada, et que votre utilisation de l’application mobile d’Electrify
      Canada est soumise aux conditions d’utilisation accessibles en sélectionnant <strong>« Juridique »</strong> dans
      la section Gestion du compte de l’application mobile (accessible en naviguant vers l’icône
      <strong>Profil</strong> dans le coin supérieur droit de la carte), puis en sélectionnant
      <strong>« Conditions d’utilisation » et « Politique de confidentialité ».</strong>
    </p>
    <p>
      Vous acceptez de vous conformer à toutes les lois et à tous les règlements applicables lorsque vous utilisez les
      services d’Electrify Canada et lorsque vous rechargez votre véhicule aux stations de recharge d’Electrify Canada.
    </p>
    <p>
      Electrify Canada se réserve le droit de retenir, de révoquer, de réduire, de résilier ou de suspendre votre accès
      à la totalité ou à une partie de cette offre, sans préavis, si Electrify Canada détermine ou soupçonne, à sa seule
      discrétion, que vous : (a) enfreignez les conditions d’utilisation d’Electrify Canada, des présentes modalités de
      l’offre ou de tout autre contrat conclu entre vous et Electrify Canada; (b) avez participé à des sessions de
      recharge qui sont excessives, frauduleuses ou qui indiquent autrement une utilisation non autorisée ou non
      intentionnelle de cette offre gratuite; ou (c) avez eu une conduite illégale, frauduleuse, délictueuse, nuisible
      ou abusive dans votre utilisation des services d’Electrify Canada, de l’application mobile d’Electrify Canada ou
      d’une station de recharge d’Electrify Canada.
    </p>
    <p>
      À la suite de la résiliation ou de l’expiration de cette offre pour quelque raison que ce soit, votre accès à
      l’offre gratuite cessera immédiatement et vous ne pourrez pas utiliser ou échanger toute portion restante du temps
      de chargement gratuit. À ce moment-là, votre compte sera automatiquement converti en un compte d’abonnement de
      base <strong>Passe</strong> Electrify Canada, sans frais supplémentaires (ni uniques ni récurrents). Vous pouvez,
      sans y être obligé, vous inscrire à un autre type d’abonnement par l’intermédiaire du site Web ou de l’application
      mobile d’Electrify Canada.
    </p>
  </article>
</template>

<script>
import lang from '@/lang';

export default {
  name: 'ChargingPlan',
  metaInfo: {
    title: 'Divulgation VW ID.4 d’Electrify Canada pour 2021 et 2022',
    meta: [
      {
        name: 'description',
        content:
          'Divulgation des modalités de la promotion du forfait de recharge VW ID.4 d’Electrify Canada pour 2021 et 2022',
      },
    ],
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/fr/2021-2022-vw-id4-charging-plan-disclosure/',
      },
    ],
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
};
</script>
