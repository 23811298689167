<template>
  <article class="partner-page">
    <Hero heading="Accélérez vos « pleins » grâce à Electrify Canada" headlineLevel="h2" :subheading="true">
      <template #subheading> Profitez de la recharge rapide CC pour votre e-tron d’Audi </template>
      <template #image>
        <img
          :src="
            isBig
              ? require('@/assets/images/Etron/etron1_fr.webp')
              : require('@/assets/images/Etron/etron1_fr_mobile.webp')
          "
          alt=""
        />
      </template>
    </Hero>

    <TwoColumnContentBlock heading="Prenez les commandes" headlineLevel="h3">
      <p>
        Les véhicules e-tron<sup>&reg;</sup> d’Audi sont entièrement équipés pour profiter des chargeurs rapides CC de
        150 à 350 kW d’Electrify Canada. Reportez-vous au tableau ci-dessous pour déterminer le forfait de recharge pour
        e-tron d’Audi auquel vous être admissible.
      </p>
      <PartnerDropdown id="etron-select" v-model="modelChosen" :options="etronDropdownOptions">
        <template #label>
          <div>
            <label for="etron-select">Forfaits de recharge d’Electrify Canada pour véhicules e-tron d’Audi </label>
          </div>
        </template>
      </PartnerDropdown>
      <p><strong> Le programme de recharge Audi comprend: </strong></p>
      <p>
        1400 kWh de sessions de recharge rapide en courant continu, pendant quatre ans à compter de l’achat du véhicule
        <reference-link num="1"
          >Offert pour les nouveaux véhicules e-tron d’Audi des années-modèles 2023 et 2024. Le 1 400 kWh en sessions de
          recharge rapide en courant continu peut être utilisé sur une période de quatre ans à compter de l’achat du
          véhicule. L’offre est non transférable et ne peut être utilisée à des fins commerciales, comme le covoiturage.
          Nécessite un compte Electrify Canada, l’application correspondante, l’inscription au forfait de recharge pour
          véhicules e-tron d’Audi 2023-2024 et l’acceptation des conditions d’utilisation. Après l’arrêt de la session
          de recharge amorcée par l’utilisateur, après que le véhicule a atteint sa pleine charge ou après que la
          recharge se soit arrêtée pour une autre raison, et après un délai de grâce de 10 minutes, des frais
          d’occupation s’appliqueront, et l’utilisateur en sera responsable.</reference-link
        >
      </p>
      <p><strong> Réseau de recharge en CC d’Electrify Canada </strong></p>
      <p>
        Profitez de la vitesse du réseau à haute puissance d’Electrify Canada! Grâce à une recharge à la fois rapide et
        pratique, vous reprendrez la route en un rien de temps et profiterez des performances époustouflantes de votre
        véhicule e-tron d’Audi.
      </p>
      <p>
        Faites défiler l’écran vers le bas afin de consulter les étapes à suivre pour s’inscrire dans l’application et
        profiter pleinement du forfait de recharge à l’intention des véhicules e-tron d’Audi sur le réseau de chargeurs
        d’Electrify Canada.
      </p>
      <router-link :to="{ name: 'locate-charger-fr' }" class="button">TROUVER UN CHARGEUR</router-link>
    </TwoColumnContentBlock>

    <TwoColumnContentBlock heading="Allez-y avec la liberté" headlineLevel="h3" :secondaryBG="false">
      <p>
        Prenez la route grâce aux avantages inclus dans votre forfait de recharge Audi sur le réseau de recharge rapide
        en CC d’Electrify Canada.
      </p>
      <p>
        La recharge est simple. Il suffit de suivre les étapes
        <span class="wrap-last-two-words"
          >suivantes :<reference-link num="2">Les étapes de recharge peuvent changer</reference-link></span
        >
      </p>
      <ul>
        <li>
          <strong>Étape 1:</strong> Trouvez une station de recharge Electrify Canada en utilisant l’application myAudi
          <reference-link num="3"
            >Des frais de messagerie et de données pourraient s’appliquer. Soyez toujours attentif à la route et évitez
            les distractions. Les étapes de recharge peuvent changer.</reference-link
          >
          ou l’écran tactile MMI de votre véhicule Audi.
          <reference-link num="4"
            >Soyez toujours attentif à la route et évitez les distractions. Consultez le manuel du propriétaire pour
            plus d’informations et les limitations importantes.</reference-link
          >
        </li>
        <li><strong>Étape 2:</strong> Branchez votre véhicule Audi.</li>
        <li>
          <strong>Étape 3:</strong> Lancez l’application myAudi et entrez l’identifiant de la borne de recharge pour
          commencer votre session.
        </li>
        <li><strong>Étape 4:</strong> L’application myAudi vous avisera lorsque votre recharge sera terminée.</li>
      </ul>
      <p>
        Votre inscription au forfait devrait s’effectuer en douceur, tout comme vos déplacements. Toutefois, si vous
        rencontrez des obstacles en cours de route, composez le 1 833 231-3226 pour joindre le service à la clientèle
        d’Electrify Canada, accessible 24 heures par jour, 7 jours sur 7.
      </p>
    </TwoColumnContentBlock>
  </article>
</template>

<script>
import ReferenceLink from '@/components/ReferenceLink.vue';
import PartnerDropdown from '@/components/PartnerDropdown.vue';
import Hero from '@/components/Hero/Hero.vue';
import TwoColumnContentBlock from '@/components/TwoColumnContentBlock/TwoColumnContentBlock.vue';

export default {
  name: 'etron',
  components: {
    ReferenceLink,
    PartnerDropdown,
    Hero,
    TwoColumnContentBlock,
  },
  metaInfo: {
    title: 'Programme de recharge Audi etron Electrify Canada 2020-2024',
    meta: [
      {
        name: 'description',
        content:
          'Description et renseignements du programme de recharge ultrarapide Audi etron d’Electrify Canada 2020 à 2024',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrify-canada.ca/fr/e-tron/' }],
  },
  data() {
    return {
      etronDropdownOptions: [
        { text: 'Sélectionnez', value: null },
        { text: 'Q4 e-tron Sportback 2024 d’Audi', value: '12' },
        { text: 'Q4 e-tron 2024 d’Audi', value: '11' },
        { text: 'RS e-tron GT 2024 d’Audi', value: '10' },
        { text: 'e-tron GT 2024 d’Audi', value: '9' },
        { text: 'Q8 e-tron Sportback 2024 d’Audi / SQ8 e-tron Sportback 2024 d’Audi', value: '8' },
        { text: 'Q8 e-tron 2024 d’Audi / SQ8 e-tron 2024 d’Audi', value: '7' },
        { text: 'Q4 e-tron Sportback 2023 d’Audi', value: '6' },
        { text: 'Q4 e-tron 2023 d’Audi', value: '5' },
        { text: 'RS e-tron GT 2023 d’Audi', value: '4' },
        { text: 'e-tron GT 2023 d’Audi', value: '3' },
        { text: 'e-tron Sportback 2023 d’Audi', value: '2' },
        { text: 'e-tron 2023 d’Audi', value: '1' },
      ],
      modelChosen: null,
      isBig() {
        return this.$resize && this.$mq.above(750);
      },
    };
  },
};
</script>
