<template>
  <article class="disclosure">
    <h1>Confidentialité</h1>
    <p><strong>Date d’entrée en vigueur : 23 septembre 2023</strong></p>
    <p>
      Electrify Canada, Inc. (« Electrify Canada », « nous », « notre » et « nos »), recueille et traite des
      renseignements sur vous comme décrit dans le présent Avis de confidentialité (l’« Avis »). Le présent Avis
      s’applique à tous nos produits et services et contient des renseignements sur la façon dont nous recueillons,
      utilisons et partageons les renseignements personnels que nous obtenons de vous et à votre sujet lorsque vous
      interagissez avec nous par l’intermédiaire de notre site Web, de nos courriels, de nos applications mobiles et de
      nos comptes de médias sociaux (collectivement, nos « Propriétés numériques »), les stations de recharge ou le
      centre d’appels, et dans d’autres interactions en ligne et hors ligne.
    </p>
    <h2 class="h4">Collecte des données</h2>
    <p><strong>Renseignements personnels</strong></p>
    <p>
      Nous recueillons les renseignements personnels que vous nous fournissez volontairement, par exemple, lorsque vous
      consultez nos sites Web, utilisez notre application, créez un compte, rechargez votre véhicule à l’une de nos
      stations de recharge ou lorsque vous nous contactez.
    </p>
    <p>
      Les renseignements personnels que nous recueillons comprennent vos coordonnées, comme votre prénom et votre nom,
      votre adresse, votre adresse courriel, vos identifiants sur les réseaux sociaux ainsi que votre numéro de
      téléphone, les renseignements relatifs à votre carte de paiement (lorsque vous vous inscrivez à un compte
      Electrify Canada), vos renseignements professionnels, comme le nom de votre entreprise, votre titre et tout autre
      renseignement que vous nous fournissez directement, y compris les renseignements sur votre véhicule, comme le
      numéro d’identification du véhicule (le « NIV »).
    </p>
    <p>
      Nous recueillons également des enregistrements vidéo et audio à partir de nos stations de recharge (tels que des
      images de sécurité ou des commandes vocales) ainsi que des enregistrements provenant de centres d’appels à des
      fins de sécurité, d’assurance qualité et de formation.
    </p>
    <p>
      Si vous participez à un programme de recharge promotionnel par l’intermédiaire du fabricant de votre véhicule («
      FEO »), nous pourrions recueillir des informations auprès du FEO afin d’inscrire votre véhicule et de fournir nos
      Services. Ces informations pourraient inclure votre NIV, votre nom et les renseignements relatifs à votre contrat,
      ainsi que l’état de la recharge de votre véhicule. Nous recevons des informations sur vous et vos interactions
      avec nous de la part de tiers, tels que nos fournisseurs de services, les entités qui vendent et/ou administrent
      nos produits, les fournisseurs de bornes de recharge, les partenaires qui hébergent nos stations de recharge sur
      leur(s) site(s), les fournisseurs de services publics, les fournisseurs de marketing et de publicité, les services
      de réseaux sociaux, les plateformes de commerce électronique et les courtiers en données.
    </p>
    <p>
      Nous recueillons également automatiquement des renseignements techniques, ou des informations qui peuvent être
      tirés de vos interactions en ligne avec nous, comme votre adresse IP, votre historique de navigation, votre
      historique de recherche ou d’autres identifiants similaires.
    </p>
    <p>
      Ces renseignements peuvent être recueillis au moyen de diverses technologies, y compris, sans s’y limiter, les
      témoins et les balises Web.
    </p>
    <p>
      Nous pourrions aussi utiliser des fournisseurs de services, comme Google Analytics, qui pourraient utiliser des
      témoins pour recueillir des renseignements sur vos activités en ligne sur ce Site et sur d’autres sites au fil du
      temps à des fins non publicitaires. Pour en savoir plus sur la manière dont Google Analytics recueille et traite
      les données ainsi que les choix proposés par Google pour contrôler ces activités, veuillez consulter la page
      <a href="https://www.google.com/intl/fr/policies/privacy/partners/" class="link" rel="noopener" target="_blank"
        >https://www.google.com/intl/fr/policies/privacy/partners/</a
      >
    </p>
    <p><strong>Utilisation des données</strong></p>
    <p>
      Nous pourrions utiliser les renseignements personnels et non personnels que nous recueillons auprès de vous à des
      fins commerciales. Par exemple, nous pourrions utiliser vos renseignements de différentes manières :
    </p>
    <ol>
      <li>Pour vous présenter des publicités plus pertinentes et un contenu éducatif destiné aux consommateurs;</li>
      <li>Pour répondre à vos messages et à vos demandes;</li>
      <li>
        Pour assurer le fonctionnement, la maintenance et l’amélioration de nos produits et Services, notamment pour
        assurer la sécurité;
      </li>
      <li>À des fins de statistiques, de recherche, d’archivage et de production de rapports;</li>
      <li>Pour personnaliser et améliorer votre expérience lorsque vous utilisez nos Services;</li>
      <li>Pour contrôler la conformité de nos Services à nos conditions d’utilisation;</li>
      <li>À toute autre fin indiquée au moment de la collecte.</li>
    </ol>
    <p>
      Lorsque vous donnez votre autorisation en ligne ou par le biais de notre application, nous utilisons les
      renseignements relatifs à la localisation afin de localiser la station de recharge la plus près de vous et
      vérifier la possibilité de commencer la recharge une fois que vous vous trouvez à une certaine proximité de la
      borne de recharge. Avec votre autorisation, nous pouvons également utiliser les renseignements relatifs à la
      localisation pour vous proposer des publicités et des offres pertinentes.
    </p>
    <p>
      Les renseignements personnels peuvent être consultés par des personnes au sein de notre organisation, par nos
      fournisseurs de services tiers ainsi que par les personnes décrites dans la présente politique de confidentialité,
      qui ont besoin de cet accès pour réaliser les objectifs décrits dans la présente politique de confidentialité ou
      d’autres objectifs autorisés ou exigés par la loi applicable.
    </p>
    <h2 class="h4">Consentement</h2>
    <p>
      La législation sur la protection de la vie privée exige qu’Electrify Canada obtienne le consentement d’une
      personne pour la collecte, l’utilisation ou la divulgation de renseignements personnels dans de nombreuses
      circonstances.
    </p>
    <p>
      En nous fournissant vos renseignements personnels, vous acceptez que nous puissions les recueillir, les utiliser
      et les divulguer conformément à la présente politique de confidentialité et dans la mesure permise ou exigée par
      la loi.
    </p>
    <p>
      Lorsqu’Electrify Canada reçoit des renseignements personnels de votre part dans le but de vous fournir nos
      Services ou pour d’autres utilisations mentionnées par nous dans les présentes, vous nous donnez le consentement
      suivant :
    </p>
    <ol>
      <li>Pour nous permettre de traiter ces renseignements personnels d’une manière raisonnable;</li>
      <li>
        Pour nous permettre de fournir vos renseignements personnels à des tiers qui jouent un rôle dans la prestation
        ou le soutien de nos Services;
      </li>
      <li>
        Pour nous permettre d’utiliser et de stocker vos renseignements personnels en vue de vous fournir les produits
        et Services que vous achetez chez nous et pour vous permettre d’utiliser ces services;
      </li>
      <li>
        Pour nous permettre de partager vos renseignements personnels avec les fabricants de véhicules qui organisent ou
        paient une partie ou la totalité des services que vous recevez, les hôtes des sites des stations de recharge et
        les sociétés de services publics; et
      </li>
      <li>
        Pour nous permettre de transférer vos renseignements personnels à l’extérieur du Canada (où vos renseignements
        personnels seront traités conformément aux lois étrangères applicables) aux fins de stockage et d’utilisation de
        vos renseignements personnels par nous ou en notre nom ; et
      </li>
      <li>
        Pour nous permettre de transférer vos renseignements personnels entre les provinces et les territoires canadiens
        (où vos renseignements personnels seront traités conformément aux lois applicables des provinces et/ou des
        territoires auxquels ils sont transférés) aux fins de stockage et d’utilisation de ceux-ci par nous ou en notre
        nom.
      </li>
    </ol>
    <p>
      Si vous devez nous fournir des renseignements personnels sur d’autres personnes, vous déclarez et garantissez que,
      lorsque la loi l’exige et avant de nous les divulguer, vous obtiendrez le consentement de chaque personne à la
      collecte, à l’utilisation et à la divulgation de ses renseignements par nous, aux fins spécifiques pour lesquelles
      vous les divulguez.
    </p>
    <p>
      Vous êtes toujours libre de nous fournir ou non vos renseignements personnels. Lorsque vous nous demandez des
      produits et/ou des Services, que vous utilisez nos Services ou que vous vous inscrivez à nos Services, nous vous
      demandons de fournir des renseignements qui nous permettent de répondre à votre demande et/ou de vous fournir nos
      Services. Ce faisant, vous consentez à ce que nous recueillions, utilisions et divulguions ces renseignements
      personnels à des tiers compétents aux fins précitées.
    </p>
    <p>
      Il existe des exceptions juridiques en vertu desquelles nous n’aurons pas besoin d’obtenir le consentement ou
      d’expliquer les objectifs de la collecte, de l’utilisation ou de la divulgation des renseignements personnels.
      Parmi les exemples de situations qui ne nécessitent pas de consentement, citons une urgence qui menace la vie, la
      santé ou la sécurité d’une personne, ou si nous devons nous conformer à une décision d’un tribunal ou à un ordre
      gouvernemental.
    </p>
    <p>
      Si nous vendons notre organisation ou fusionnons avec une autre partie, votre consentement au transfert à notre
      successeur de vos renseignements sera implicite aux mêmes fins que celles énoncées dans la présente politique de
      confidentialité.
    </p>
    <h2 class="h4">Partage des données</h2>
    <p>Nous ne communiquons pas de renseignements personnels à des tiers non affiliés sauf :</p>
    <ul>
      <li>
        À des entités gouvernementales concernant l’évaluation de propositions, ou lorsque nécessaire pour offrir nos
        Services et lorsque la loi le prescrit;
      </li>
      <li>
        À nos sociétés affiliées, y compris les sociétés mères, les filiales, les unités commerciales et les autres
        sociétés qui partagent la même propriété et les mêmes fournisseurs de services. Ces tiers fournisseurs de
        services et sociétés affiliées pourraient être situés à l’extérieur du Canada. Lorsque nous faisons appel à
        d’autres organisations pour fournir des services d’assistance, celles-ci sont tenues de se conformer à nos
        normes de confidentialité. Ces tiers n’utiliseront les renseignements personnels qu’à des fins liées à la
        prestation de services pour notre compte et ils respecteront également des conditions contractuelles strictes
        qui les obligent à maintenir la confidentialité de tous les renseignements;
      </li>
      <li>
        À nos clients commerciaux, y compris pour des opérations de marketing de tiers. Dans certains cas, cela pourrait
        inclure le partage de vos renseignements avec les fabricants et les concessionnaires de véhicules qui organisent
        ou paient une partie ou la totalité des services que vous recevez, les hôtes des sites des stations de recharge
        et les sociétés de services publics;
      </li>
      <li>
        Dans le cadre d’une ordonnance à comparaître, d’une ordonnance du tribunal ou d’un autre processus judiciaire;
      </li>
      <li>
        Pour enquêter sur des problèmes ou des requêtes ou y répondre et les résoudre (y compris des requêtes
        d’application de la loi) ou pour protéger, faire respecter ou défendre nos droits, actifs ou intérêts;
      </li>
      <li>
        Dans le cadre d’une acquisition ou d’une fusion avec une autre entreprise, d’une vente partielle ou entière
        d’actifs ou de toute autre modification de l’Entreprise, y compris une faillite;
      </li>
      <li>Pour éviter la violation d’une loi, d’un règlement ou de nos modalités de services ou y répondre;</li>
      <li>Avec votre consentement ou sauf indication contraire au moment de la collecte.</li>
    </ul>
    <h2 class="h4">Sites Web et applications de tiers</h2>
    <p>
      Nos Services pourraient contenir des liens vers des sites Web ou des applications mobiles de tiers. Soyez
      conscient que nous ne contrôlons pas ces sites Web ou applications et que la présente politique de confidentialité
      ne s’applique pas à ces sites Web ou à ces applications. Nous vous recommandons de lire l’avis de confidentialité
      du site Web ou de l’application de tiers que vous consultez avant de fournir des renseignements à son
      propriétaire.
    </p>
    <h2 class="h4">Témoins</h2>
    <p>
      Nous recueillons également des renseignements (ou permettons à des tiers de recueillir des renseignements
      directement sur notre site Web, notre application mobile ou dans le cadre d’autres interactions en ligne), par le
      biais de témoins, de balises Web, de pixels, d’étiquettes ou d’autres technologies de suivi. Ces renseignements
      pourraient inclure votre fournisseur de services Internet et votre adresse IP, la date et l’heure auxquelles vous
      accédez à notre site Web, les pages auxquelles vous avez accédé en consultant notre site Web et l’adresse Internet
      à partir de laquelle vous avez accédé à notre site Web. Certains témoins n’existent que le temps d’une seule
      session et d’autres perdurent sur plusieurs sessions successives. Nous utilisons ces technologies pour mémoriser
      les préférences des utilisateurs, optimiser la performance de notre site Web et de nos services, vous proposer des
      offres susceptibles de vous intéresser, mesurer l’efficacité de nos campagnes par courrier électronique et
      personnaliser le contenu en ligne. Ces témoins et autres technologies pourraient être utilisés pour vous suivre
      sur différents appareils et sur différents sites Web au fil du temps.
    </p>
    <p>
      Pour vous offrir une expérience plus pertinente et plus intéressante, nous pourrions travailler avec des sociétés
      tierces afin d’afficher des publicités ou de personnaliser le contenu de notre site Web ou de notre application
      mobile et de ceux de tiers (comme sur les réseaux de médias sociaux). Ces sociétés pourraient utiliser des témoins
      et des technologies de suivi similaires, comme décrit dans la présente politique, pour recueillir des informations
      sur vos visites sur le site Web, ainsi que sur vos visites effectuées ailleurs sur Internet, afin de vous proposer
      des publicités plus pertinentes, connues sous le nom de publicité ciblée par centres d’intérêt (consulter la
      section « Marketing et choix de publicité ciblée par centres d’intérêt »).
    </p>
    <h2 class="h4">Marketing et choix de publicité ciblée par centres d’intérêt</h2>
    <p>
      Nous travaillons avec des partenaires publicitaires tiers qui pourraient recueillir des renseignements sur vos
      visites sur nos sites Web, nos propriétés numériques et d’autres sites Web ou applications non affiliés afin de
      vous proposer des publicités sur nos propriétés et sur d’autres sites Web et applications. Certaines de ces
      publicités pourraient être basées sur vos intérêts, signifiant qu’elles sont destinées à être pertinentes pour
      vous selon les renseignements que ces réseaux publicitaires recueillent sur vos visites sur nos sites Web et
      propriétés numériques et d’autres sites Web ou applications non affiliés au fil du temps.
    </p>
    <p>
      Pour en savoir plus sur la publicité ciblée par centre d’intérêt ou pour vous opposer à l’utilisation de vos
      renseignements personnels à cette fin, veuillez consulter la page
      <a href="https://youradchoices.ca/fr/" class="link" rel="noopener" target="_blank"
        >https://youradchoices.ca/fr/</a
      >
    </p>
    <p>
      Veuillez noter que si vous utilisez un navigateur Web différent ou désactivez les témoins dans votre navigateur,
      il est possible que vous deviez renouveler votre choix de retrait.
    </p>
    <p>
      Vous pouvez choisir la façon dont nous vous contactons pour les communications liées à la publicité. Vous pouvez
      demander à ne pas recevoir de publicités d’Electrify Canada en envoyant un courriel à
      <a href="mailto:privacy@electrify-canada.ca" class="link">privacy@electrify-canada.ca.</a> ou en utilisant le lien
      « Se désabonner » dans tout e-mail marketing que vous recevez de notre part. Nous pourrions toujours vous
      contacter à des fins non publicitaires, comme pour vous envoyer des reçus ou des messages concernant votre compte.
    </p>
    <h2 class="h4">Vie privée des enfants</h2>
    <p>
      Electrify Canada ne recueille pas sciemment les renseignements personnels des visiteurs de ses services en ligne
      âgés de moins de 13 ans. Si nous apprenons que nous avons recueilli des renseignements personnels d’un enfant de
      moins de 13 ans sans avoir vérifié le consentement parental, nous supprimerons ces renseignements.
    </p>
    <h2 class="h4">Mise à jour de cette politique</h2>
    <p>
      Nous pourrions modifier cette politique de temps à autre. Nous publierons les changements dans cet avis ou selon
      ce qu’exige la loi. Nous vous suggérons de visiter cet avis régulièrement pour vous tenir au courant d’éventuels
      changements.
    </p>
    <h2 class="h4">Sécurité, conservation et destruction des renseignements personnels</h2>
    <p>
      Nous prenons des mesures raisonnables pour protéger la sécurité et la confidentialité de vos renseignements
      personnels. Nous protégeons les renseignements personnels dont nous avons la garde ou le contrôle au moyen de
      mesures de protection organisationnelles, technologiques et physiques adéquates.
    </p>
    <p>
      Nous stockons les renseignements personnels dans des fichiers électroniques sécurisés et nos mesures de sécurité
      comprennent un stockage sécurisé sur et hors site, un accès restreint aux dossiers et aux équipements de
      traitement des données, des protocoles relatifs aux mots de passe et des logiciels de cryptage et de sécurité.
      Nous effectuons des audits et vérifions le respect de nos pratiques en matière de confidentialité.
    </p>
    <p>
      La durée de conservation des renseignements personnels varie en fonction des Services que nous avons fournis et de
      la nature des renseignements personnels que nous détenons. Nous conservons les renseignements personnels pendant
      la période qui nous est nécessaire pour maintenir des informations suffisantes afin de pouvoir répondre à toute
      question qui se pose ultérieurement ou comme l’exige la loi.
    </p>
    <p>
      Lorsque nous n’avons plus besoin des renseignements personnels ou que la loi l’exige, nous convertissons ces
      renseignements sous une forme agrégée non identifiable ou nous détruisons ou effaçons les renseignements
      personnels de manière appropriée, conformément à nos politiques et procédures actuelles.
    </p>
    <h2 class="h4">Communications électroniques</h2>
    <p>
      Nous nous efforçons de respecter la Loi canadienne antipourriel (« LCAP ») dans nos communications électroniques
      commerciales et nos téléchargements de logiciels avec vous. Si vous ne nous accordez pas le consentement requis
      pour vous envoyer des messages électroniques commerciaux ou télécharger des logiciels, ou si vous retirez ce
      consentement, nous pourrions ne pas être en mesure de fournir, ou de continuer à fournir, certains de nos produits
      ou Services qui ne peuvent être fournis que si nous recevons les renseignements personnels appropriés et
      nécessaires. Le consentement du LCAP peut être donné ou retiré à
      <a href="mailto:privacy@electrify-canada.ca" class="link"> privacy@electrify-canada.ca</a>.
    </p>
    <h2 class="h4">Retrait du consentement</h2>
    <p>
      Vous pouvez, à tout moment, retirer votre consentement à la collecte, l’utilisation et la divulgation de vos
      renseignements personnels, sous réserve d’un préavis raisonnable et de toute restriction juridique ou
      contractuelle.
    </p>
    <p>
      Si vous choisissez de retirer votre consentement, nous pourrions être dans l’incapacité de fournir, ou de
      continuer à fournir, tout ou partie de nos produits ou Services qui ne peuvent être fournis que si nous recevons
      les renseignements personnels appropriés et nécessaires. Par conséquent, si vous choisissez de ne pas nous fournir
      les renseignements personnels nécessaires, nous pourrions ne pas être en mesure de vous offrir ces produits ou
      Services.
    </p>
    <h2 class="h4">Accès, correction et responsabilité</h2>
    <p>
      Nous nous efforcerons de garder tous les renseignements personnels figurant à nos dossiers complets, à jour et
      exacts.
    </p>
    <p>
      Vous ou une personne autorisée en votre nom avez le droit de demander à voir et à obtenir une copie de vos
      renseignements personnels et de demander des changements ou des corrections aux renseignements inexacts ou
      incomplets. Veuillez noter que nous pouvons exiger des frais minimaux pour le traitement de telles demandes.
    </p>
    <p>
      Si vous avez des questions concernant notre avis de confidentialité ou nos pratiques en matière d’information,
      veuillez communiquer avec notre responsable de la confidentialité à l’adresse
      <a href="mailto:privacy@electrify-canada.ca" class="link">privacy@electrify-canada.ca.</a>
    </p>
  </article>
</template>

<script>
export default {
  name: 'privacy',
  metaInfo: {
    title: 'Politique de confidentialité',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/fr/confidentialite/',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/privacy/',
        hreflang: 'en-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/fr/confidentialite/',
        hreflang: 'fr-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/privacy/',
        hreflang: 'x-default',
      },
    ],
    meta: [
      {
        name: 'description',
        content:
          'Soyez informé. Lisez notre politique de confidentialité pour savoir comment Electrify Canada recueillera, consultera, utilisera et divulguera vos renseignements lorsque vous utilisez nos produits et services.',
      },
    ],
  },
};
</script>
