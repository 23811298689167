<template>
  <div>
    <section class="premium-offers">
      <section class="hero">
        <Card isReverse heading=" Go electric and unlock complimentary charging" headlineLevel="h1" :maxWidth="1200">
          <template #image>
            <picture
              ><source media="(min-width: 1200px)" srcset="@/assets/images/PremiumOffers/desert-charger.jpg" />
              <img class="image" src="@/assets/images/PremiumOffers/desert-charger.jpg" alt="" /></picture
          ></template>
          <p>Get complimentary charging when you purchase or lease an electric vehicle from a participating brand.*</p>
        </Card>
      </section>
      <section class="partners">
        <h2>Ride with the best</h2>
        <p>
          When you purchase an electric vehicle through a participating manufacturer, get
          <strong>complimentary Ultra-Fast charging or more membership benefits* </strong>at no additional cost.
        </p>
        <div class="partners__logo">
          <router-link :to="{ name: 'e-tron-en' }"
            ><img src="@/assets/images/PremiumOffers/audi-logo.webp" alt="Go to Audi page"
          /></router-link>
          <router-link :to="{ name: 'bmw-i7-en' }"
            ><img src="@/assets/images/PremiumOffers/bmw-logo.webp" alt="Go to BMW page"
          /></router-link>
          <!-- <a href="https://www.lucidmotors.com/en-ca/" rel="noopener" target="_blank"
            ><img src="@/assets/images/PremiumOffers/lucid-motors-logo.webp" alt="Lucid motors car logo"
          /></a> -->
          <router-link :to="{ name: 'porsche-en' }"
            ><img src="@/assets/images/PremiumOffers/porsche-logo.webp" alt="Go to Porsche page"
          /></router-link>
          <router-link :to="{ name: 'vw-id4-en' }"
            ><img src="@/assets/images/PremiumOffers/volkswagen-logo.webp" alt="Go to Volkswagen page"
          /></router-link>
        </div>
        <p><small>Brands are listed in alphabetical order.</small></p>
      </section>
      <section class="go-electric bg-gray">
        <h2 class="partners">Learn what it means to go electric</h2>
        <div class="l-three-col">
          <Card
            heading="Get familiar with the basics of EV charging"
            headlineLevel="h3"
            isSmall
            :isReverse="isPhone"
            headlineDisplayClass="headline2"
          >
            <template #image>
              <img loading="lazy" src="@/assets/images/PremiumOffers/bulb.svg?external" alt="" />
            </template>
            <p>
              Learn
              <router-link :to="{ name: 'what-to-expect-en' }" class="link" aria-label="what to expect at the charger"
                >what to expect</router-link
              >
              at the charger and
              <router-link :to="{ name: 'charging-your-ev-en' }" class="link">how to charge</router-link>
            </p>
          </Card>
          <Card
            heading="Find Ultra-Fast charging stations near you"
            headlineLevel="h3"
            isSmall
            :isReverse="isPhone"
            headlineDisplayClass="headline2"
          >
            <template #image>
              <img loading="lazy" src="@/assets/images/PremiumOffers/ea-bolt.svg?external" alt="" />
            </template>
            <p>
              <router-link :to="{ name: 'locate-charger-en' }" class="link">Locate a charger</router-link> on the
              website and see real-time availability
              <router-link
                :to="{ name: 'mobile-app-en' }"
                class="link"
                aria-label="See charger availability in real time in the app"
                >in the app</router-link
              >
            </p>
          </Card>
          <Card
            heading="Enroll in your Premium Offer with the app"
            headlineLevel="h3"
            isSmall
            :isReverse="isPhone"
            headlineDisplayClass="headline2"
          >
            <template #reference>
              <reference-link :num="1">
                Your mobile carrier's messaging, data, and other rates and fees will apply to your use of the Electrify
                Canada mobile application.
              </reference-link>
            </template>

            <template #image>
              <img loading="lazy" src="@/assets/images/PremiumOffers/electric-bolt.svg?external" alt="" />
            </template>
            <p>
              Learn how to
              <router-link :to="{ name: 'mobile-app-en' }" class="link">create an account</router-link> and use your
              complimentary charging*
            </p>
          </Card>
        </div>
      </section>
      <section class="partners app">
        <h2>Use the Electrify Canada app to enroll and save</h2>
        <p>
          If you own or lease a vehicle with a Premium Offer from one of these brands, use the Electrify Canada app to
          enroll.
          <reference-link :num="1">
            Your mobile carrier's messaging, data, and other rates and fees will apply to your use of the Electrify
            Canada mobile application.
          </reference-link>
        </p>
        <div class="app__logo">
          <div>
            <img src="@/assets/images/PremiumOffers/audi-logo.webp" :alt="isMobile ? 'Audi' : ''" />
            <strong v-if="!isMobile">Audi</strong>
          </div>
          <div>
            <img src="@/assets/images/PremiumOffers/bmw-logo.webp" :alt="isMobile ? 'BMW' : ''" />
            <strong v-if="!isMobile">BMW</strong>
          </div>
          <div>
            <img src="@/assets/images/PremiumOffers/volkswagen-logo.webp" :alt="isMobile ? 'Volkswagen' : ''" />
            <strong v-if="!isMobile">Volkswagen</strong>
          </div>
        </div>
      </section>
      <section class="redeem-charging">
        <Card
          headlineLevel="h2"
          heading="Use an enrollment code to redeem your complimentary charging"
          headlineDisplayClass="headline2"
          isReverse
          :maxWidth="1100"
        >
          <template #image>
            <picture>
              <img loading="lazy" class="image" src="@/assets/images/PremiumOffers/mobile.png" alt="" />
            </picture>
          </template>
          <p>
            <span class="redeem-charging__number"><strong>1</strong></span> Download the Electrify Canada
            app<reference-link :num="1">
              Your mobile carrier's messaging, data, and other rates and fees will apply to your use of the Electrify
              Canada mobile application
            </reference-link>
            and create your account
          </p>
          <p>
            <span class="redeem-charging__number"><strong>2</strong></span> Select <strong>Premium Offer</strong> in the
            Account tab
          </p>
          <p>
            <span class="redeem-charging__number"><strong>3</strong></span> Tap<strong>
              I have an enrollment code</strong
            >
          </p>
          <p>
            <span class="redeem-charging__number"><strong>4</strong></span> Enter your enrollment code and select your
            plan
          </p>
          <p class="flex-display">
            <span class="redeem-charging__number"><strong>5</strong></span>
            Accept the Plan Disclosure and add a backup payment method for any fees that are not covered by your
            complimentary charging**
          </p>
          <p class="redeem-charging__mr">
            <strong>Note: </strong>Some automakers have their own mobile app to enroll in complimentary charging. The
            following section provides more information.
          </p>
          <div v-if="!isMobile" class="inline">
            <a rel="noopener" target="_blank" href="https://apps.apple.com/ca/app/electrify-canada/id1472391910">
              <img
                loading="lazy"
                :src="require('@/assets/images/Global/app-store-logo-en.png')"
                :alt="$t('downloadInTheAppStore')"
              />
            </a>
            <a
              rel="noopener"
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.ec.evowner&hl=en_CA"
            >
              <img
                loading="lazy"
                :src="require('@/assets/images/Global/google-play-badge-en.png')"
                :alt="$t('getItOnGooglePlay')"
              />
            </a>
          </div>
        </Card>
      </section>
      <section class="ea-benefit bg-gray">
        <h2>Use your vehicle’s app to enroll</h2>
        <p>
          If you own or lease a vehicle with complimentary charging from any of these brands, use their app to enroll.
        </p>
        <div class="ea-benefit__logo">
          <!-- <div>
            <img src="@/assets/images/PremiumOffers/lucid.webp" alt="Lucid motors car logo" />
            <strong v-if="!isMobile">Lucid</strong>
            <arrow class="arrow"></arrow>
          </div> -->
          <!-- <router-link :to="{ name: 'porsche-en' }"> -->
          <div>
            <img src="@/assets/images/PremiumOffers/porsche.webp" :alt="isMobile ? 'Porsche' : ''" />
            <strong v-if="!isMobile">Porsche</strong>
            <!-- <arrow class="arrow"></arrow> -->
          </div>
          <!-- </router-link> -->
        </div>
        <!-- <p><small>Brands are listed in alphabetical order.</small></p> -->
      </section>
      <section class="pass-member">
        <Card
          headlineLevel="h2"
          heading="Become a Pass+ Member"
          :isReverse="true"
          headlineDisplayClass="headline3"
          class="no-bottom-padding"
          :maxWidth="1100"
        >
          <template #image>
            <picture>
              <source media="(min-width:750px)" srcset="@/assets/images/PremiumOffers/station.webp" />
              <source srcset="@/assets/images/PremiumOffers/station-mobile.webp" />
              <img loading="lazy" class="image" src="@/assets/images/PremiumOffers/station.webp" alt="" />
            </picture>
          </template>
          <p>
            Enjoy reduced costs on charging with the network that pioneered the availability of Ultra-Fast (150 kW) and
            Hyper-Fast (350 kW) chargers in Canada.
          </p>
          <router-link class="button-link" :to="{ name: 'mobile-app-en' }"
            >Learn more about membership options<arrow class="arrow"></arrow
          ></router-link>
        </Card>
      </section>
      <section class="partners disclaimer">
        <p>
          <small>
            *Program details vary, depending on the vehicle model and manufacturer and date of purchase. See the
            promotion terms and conditions for your specific vehicle for full information and restrictions, available at
            <router-link class="link" :to="{ name: 'e-tron-en' }">Audi</router-link>,
            <router-link class="link" :to="{ name: 'bmw-i7-en' }">BMW</router-link>,
            <!-- <a href="https://www.lucidmotors.com/en-ca/" rel="noopener" target="_blank" class="link">Lucid</a>, -->
            <router-link class="link" :to="{ name: 'porsche-en' }">Porsche</router-link>, and
            <router-link class="link" :to="{ name: 'vw-id4-en' }">Volkswagen</router-link>.
          </small>
        </p>
        <p>
          <small>
            **To help ensure you can charge uninterrupted, your Electrify Canada account balance automatically reloads
            when it falls below $5. You’ll be billed the auto-reload amount you select in the app, the first time you
            charge at an Electrify Canada station, and the billed amount will be held in your account to cover costs not
            covered by this plan, such as idle fees and applicable taxes. Drivers who do not unplug and move their
            vehicle ten minutes after their charging session is complete will be charged a $0.40 per-minute idle fee.
            This fee encourages drivers to move their car promptly so that others can use the charger. You can be
            notified when your charging session is complete by text message (your mobile carrier’s messaging, data and
            other rates may apply) or see it on the charger screen.</small
          >
        </p>
      </section>
    </section>
  </div>
</template>

<script>
import Card from '@/components/Card/Card.vue';
import ReferenceLink from '@/components/ReferenceLink.vue';

import arrow from '@/assets/images/Global/arrow.svg';

export default {
  components: { arrow, Card, ReferenceLink },
  metaInfo: {
    title: 'Electrify Canada | Premium Offers',
    meta: [
      {
        name: 'description',
        content:
          'Get complimentary charging with Electrify Canada when you purchase or lease an electric vehicle from a participating brand.',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrify-canada.ca/premium-offers/' }],
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(750);
    },
  },
};
</script>
