<template>
  <div id="app" :lang="$i18n.locale">
    <a class="sr-only sr-only-focusable skip-link" href="#main">Skip to content</a>
    <Header />
    <main id="main"><router-view /></main>
    <Footer
      :socialMediaLinks="socialMediaLinks"
      :footerLinks="footerLinks"
      :legalLinks="legalLinks"
      homepageAriaLabel="Electrify Canada Homepage"
      :logoSrc="logoSrc"
      :lang="$i18n.locale"
    >
      <template #description>
        <p>{{ $t('description') }}</p>
      </template>
    </Footer>
  </div>
</template>

<script>
import Header from '@/components/Header/Header.vue';
import Footer from '@/components/Footer.vue';
import lang from '@/lang/Navigation';

export default {
  name: 'App',
  components: { Header, Footer },
  i18n: {
    sharedMessages: lang,
  },
  data() {
    return {
      socialMediaLinks: [
        {
          src: require('@/assets/images/Footer/facebook_white.png'),
          route: 'https://www.facebook.com/Electrify-Canada-677205735992875/',
          alt: 'Facebook',
        },
        {
          src: require('@/assets/images/Footer/instagram_white.png'),
          route: 'https://www.instagram.com/electrifycanada/',
          alt: 'Instagram',
        },
        {
          src: require('@/assets/images/Footer/x_white.png'),
          route: 'https://x.com/CanadaElectrify',
          alt: 'X',
        },
        {
          src: require('@/assets/images/Footer/linkedin_white.png'),
          route: 'https://www.linkedin.com/company/electrify-canada-ca-eng/about/',
          alt: 'LinkedIn',
        },
      ],
      logoSrc: require('@/assets/images/Footer/logoCa.svg?external'),
    };
  },
  computed: {
    footerLinks() {
      return [
        {
          summary: this.$i18n.t('help'),
          details: [
            {
              name: this.$i18n.t('glossary'),
              route: `glossary`,
            },
            {
              name: 'FAQs',
              route: `faqs`,
            },
            {
              name: this.$i18n.t('contactUs'),
              route: 'contact-us',
            },
            {
              name: 'HomeStation',
              route: 'homestation',
            },
          ],
        },
        {
          summary: this.$i18n.t('company'),
          details: [
            {
              name: this.$i18n.t('news'),
              route: `news-updates`,
            },
          ],
        },
        {
          summary: 'Solutions',
          details: [
            {
              name: 'Electrify America',
              route: 'https://www.electrifyamerica.com/',
            },
          ],
        },
      ];
    },
    legalLinks() {
      return {
        levelOne: [
          {
            name: this.$i18n.t('privacy'),
            route: 'privacy',
          },
          {
            name: this.$i18n.t('termsOfUse'),
            route: 'terms',
          },
          {
            name: this.$i18n.t('whistleblower'),
            route: 'whistleblower',
          },
          {
            name: this.$i18n.t('socialMediaRules'),
            route: 'social-media-house-rules',
          },
        ],
        levelTwo: [
          {
            copyright: true,
            name: `&copy; ${new Date().getFullYear()} Electrify Canada`,
          },
          {
            support: true,
            name: 'Support',
            route: '1-833-231-3226',
          },
        ],
      };
    },
  },
  methods: {
    pushGAEvent(event, target, category, label) {
      if (target.getAttribute && target.getAttribute('target') === '_blank') {
        if (this.$gtag) {
          this.$gtag.event(label, {
            event_category: category,
            event_label: target.href,
          });
        }
      } else if (this.$gtagIsLoaded) {
        event.preventDefault();
        this.$gtag.event(label, {
          event_category: category,
          event_label: target.href,
          transport_type: 'beacon',
          event_callback: () => {
            document.location = target.href;
          },
        });
      }
    },
    trackLinkClick(event) {
      let { target } = event;
      while (target && target.tagName !== 'A') target = target.parentNode;

      // track PDF links
      if (target && target.matches('a[href$=".pdf"], a[href$=".PDF"]') && target.href) {
        this.pushGAEvent(event, target, 'Downloads', 'PDF');
        return;
      }

      // track only links that reference external resources)
      if (target && target.matches('a[href*="://"]') && target.href) {
        this.pushGAEvent(event, target, 'Outbound links', 'Click');
        return;
      }

      // track mailto links
      if (target && target.matches('a[href^="mailto"')) {
        this.pushGAEvent(event, target, 'Mails', 'Click');
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.trackLinkClick);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.trackLinkClick);
  },
};
</script>

<style lang="scss">
@import './assets/scss/base.scss';

#app {
  font-family: 'Ubuntu', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: $fw-light;
  letter-spacing: 0.02em;
  line-height: 1.5;
}

/* Text meant only for screen readers. */
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only-focusable {
  &:active,
  &:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}
.skip-link {
  background: #fff;
  color: #011352;
  font-weight: 700;
  top: 5px;
  left: 5px;
  padding: 4px;
  position: absolute;
  transform: translateY(50%);
  transition: transform 0.3s;

  &:active,
  &:focus {
    outline-color: #011352;
    z-index: 999;
    transform: translateY(0%);
  }
}

#main {
  overflow: hidden;
  position: relative;
  min-height: 650px;
}

article {
  min-height: 650px;
  p {
    font-weight: $fw-light;
  }
  h1 {
    line-height: 0.875em;
    margin-block-start: 0;
    max-width: 1160px;
    margin: 0 auto;
  }
  &.mobile {
    padding-top: 30px !important;
    h1 {
      margin: 0 30px 14px;
    }
  }
  &:not(.mobile) {
    padding-top: 100px;
    h1 {
      margin-bottom: 28px;
    }
  }
}

html,
body {
  margin: 0;
  overflow-x: hidden;
}
</style>
