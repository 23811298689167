<template>
  <li
    :class="{
      'nav-item': true,
      open: isOpen,
      'active-page': $route.name === `${navItem.linkTo}-${$i18n.locale}`,
      'on-nested-page': navItem.nestedLinks.map((link) => `${link.linkTo}-${$i18n.locale}`).includes($route.name),
    }"
  >
    <template v-if="navItem.nestedLinks && navItem.nestedLinks.length > 0">
      <button
        :class="{ 'nav-item__trigger': true }"
        @click="isOpen = !isOpen"
        :id="`${navItem.name.toLowerCase().replace(' ', '-')}-header`"
        :aria-control="`${navItem.name.toLowerCase().replace(' ', '-')}-panel`"
        :aria-expanded="isOpen"
      >
        <span>{{ navItem.name }}</span>
        <span class="nav-item__caret">
          <HeaderCaret
            :color="
              isOpen ||
              (navItem.nestedLinks.map((link) => `${link.linkTo}-${$i18n.locale}`).includes($route.name) && !isOpen)
                ? '#35bdd1'
                : '#fff'
            "
          />
        </span>
      </button>
      <div
        role="region"
        :class="{ 'nav-accordion': true, 'nav-accordion--open': isOpen }"
        :id="`${navItem.name.toLowerCase().replace(' ', '-')}-panel`"
        :aria-labelledby="`${navItem.name.toLowerCase().replace(' ', '-')}-header`"
      >
        <ul class="nav-accordion__list">
          <li :key="index" v-for="(accordionItem, index) of navItem.nestedLinks" class="nav-accordion__item">
            <component
              :is="accordionItem.isExternal ? 'a' : 'router-link'"
              v-bind="
                accordionItem.isExternal
                  ? { href: `${accordionItem.linkTo}` }
                  : { to: { name: `${accordionItem.linkTo}` } }
              "
              :target="accordionItem.isExternal ? '_blank' : null"
              :class="{ 'nav-accordion__link': true, 'active-page': $route.name === `${accordionItem.linkTo}` }"
              :tabindex="isOpen ? 0 : -1"
              @click.native="
                () => {
                  toggleNav();
                  isOpen = false;
                }
              "
            >
              <div class="nav-accordion__content">
                <span class="nav-accordion__icon"
                  ><img :src="require(`@/assets/images/Header/${accordionItem.icon}--white.png`)" alt=""
                /></span>
                <span>{{ accordionItem.name }}</span>
              </div>

              <div class="nav-accordion__external-icon" v-if="accordionItem.isExternal">
                <img src="@/assets/images/Global/external-link--white.png" alt="External link" />
              </div>
            </component>
          </li>
        </ul>
      </div>
    </template>
    <template v-else>
      <router-link
        class="nav-item__link"
        :to="{ name: `${navItem.linkTo}` }"
        :target="navItem.target || null"
        @click.native="toggleNav()"
      >
        <span>{{ navItem.name }}</span>
      </router-link>
    </template>
  </li>
</template>
<script>
import lang from '@/lang';
import Navigation from '@/lang/Navigation';
import HeaderCaret from './HeaderCaret.vue';

export default {
  name: 'HeaderMobileItem',
  components: { HeaderCaret },
  i18n: {
    messages: Navigation,
    sharedMessages: lang,
  },
  props: { navItem: { required: true }, toggleNav: { required: true } },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    handleLinkClick() {
      this.toggleNav();
      this.isOpen = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.nav-item {
  width: 100%;
  margin-bottom: 40px;
  &__caret {
    transform: rotate(0deg);
  }
  &__trigger,
  &__link {
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;
    padding: 0 16px;
    font-size: 16px;
    color: #fff;
    font-weight: $fw-medium;
    text-transform: uppercase;
    &:focus {
      width: 100%;
    }
  }
  &__link:first-of-type {
    margin-top: 30px;
  }
  &__trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(#fff, 0.1);
    border-radius: 8px;
    border: none;
  }
  .nav-accordion {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.36s ease-in-out;
    &--open {
      max-height: 2000px;
    }
    &__list {
      list-style-type: none;
      padding: 16px 0 40px 0;
    }
    &__item {
      box-sizing: border-box;
      height: 72px;
      padding: 16px;
      margin-bottom: 16px;
    }
    &__link {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      color: #fff;
      font-size: 16px;
      font-weight: $fw-medium;
      &.active-page {
        color: $c-highlights;
      }
    }
    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      min-width: 40px;
      margin-right: 16px;
      background-color: rgba($c-focus, 0.1);
    }
    &__content {
      flex-grow: 1;
      display: flex;
      align-items: center;
    }
    &__external-icon {
      display: flex;
      align-items: center;
    }
    img {
      height: 24px;
      width: 24px;
    }
  }
}
.active-page,
.on-nested-page {
  .nav-item__trigger,
  .nav-item__link {
    color: $c-highlights;
  }
}
.open {
  .nav-item__trigger,
  .nav-item__link {
    color: $c-highlights;
  }
  .nav-item__caret {
    transform: rotate(180deg);
  }
}
</style>
