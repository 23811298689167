<template>
  <article>
    <Hero heading="Thanks! Now check your email to finish submitting this form." :subheading="true">
      <template #subheading>
        <p>We received your request, and we have sent a verification email to {{ userEmail }}</p>
        <br />
        <p>
          Upon receiving the email, you will have 48 hours to complete your registration by clicking on the link within
          the mail. That way, we’ll keep you informed of all our updates.
        </p>
      </template>
    </Hero>
  </article>
</template>

<script>
import Hero from '@/components/Hero/Hero.vue';

export default {
  name: 'real-estate-verification-en',
  metaInfo: {
    title: 'Verify your email',
    meta: [{ name: 'robots', content: 'noindex' }],
  },
  components: { Hero },
  computed: {
    userEmail() {
      return this.$store.state.email;
    },
  },
};
</script>
