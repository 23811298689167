<template>
  <article class="disclosure">
    <h1>Notre système de dénonciation</h1>
    <p>
      La conformité aux règlements établis par la loi et aux règles internes, ainsi qu’aux principes énoncés dans notre
      Code de conduite et le Code de conduite des partenaires commerciaux, est une priorité absolue pour Electrify
      Canada. Le succès de notre entreprise repose sur l’intégrité et la conformité
      <strong>
        Pour respecter ces normes, il est important d’être informé de toute inconduite potentielle d’un employé ou d’un
        fournisseur et d’y mettre fin.
      </strong>
      Par conséquent, nous confions au Bureau central des enquêtes le soin de gérer en notre nom un système de
      dénonciation indépendant, impartial et confidentiel.
    </p>
    <p>
      L’un des principaux piliers de notre système de dénonciation est le principe de l’équité procédurale. Il garantit
      également la plus grande protection possible aux dénonciateurs, aux personnes en cause et aux employés qui
      contribuent aux enquêtes sur les cas d’inconduite signalés.
    </p>
    <p>
      Cela comprend également la possibilité d’effectuer des signalements et des communications anonymes. Nous ne
      prendrons aucune mesure qui permettrait d’identifier les dénonciateurs anonymes qui n’abusent pas de notre système
      de dénonciation. Les représailles contre les dénonciateurs et toutes les personnes qui contribuent aux enquêtes
      d’Electrify Canada ne seront pas tolérées. Les personnes en cause sont présumées innocentes jusqu’à ce que
      l’infraction soit prouvée. Les enquêtes seront menées dans la plus grande confidentialité. Les renseignements
      seront traités dans le cadre d’un processus équitable, rapide et protégé.
    </p>
    <h2 class="headline3">Comment traitons-nous votre signalement?</h2>
    <p>
      Les collègues qualifiés et expérimentés du Bureau des enquêtes examinent chaque signalement d’inconduite
      potentielle concernant un employé d’Electrify Canada de manière approfondie, et en assurent le suivi systématique.
      Tout d’abord, vous recevrez une confirmation de réception. Le Bureau central des enquêtes évalue ensuite votre
      signalement. Il s’agit notamment de recueillir les faits auprès du dénonciateur. Si l’évaluation initiale indique
      qu’il y a lieu de soupçonner une infraction, une enquête sera ouverte par une unité d’enquête spécialisée. Par la
      suite, les résultats de l’enquête seront évalués par le Bureau central des enquêtes, et des mesures seront
      recommandées.
    </p>
    <p>
      Les violations potentielles du Code de conduite des partenaires commerciaux par les fournisseurs, y compris les
      risques graves et les violations des droits de la personne et de l’environnement par les fournisseurs directs et
      indirects, peuvent également être signalées au Bureau central des enquêtes, ainsi que les signalements qui
      nécessitent des mesures immédiates. Le Bureau central des enquêtes informera les services responsables, qui
      traiteront la question en conséquence. Il s’agit notamment de prendre les mesures nécessaires pour réduire au
      minimum ou mettre fin aux violations et/ou aux risques.
    </p>
    <p>
      Vous trouverez de plus amples renseignements sur les principes de procédure
      <a
        class="link"
        rel="noopener"
        target="_blank"
        href="https://www.volkswagenag.com/presence/konzern/compliance-und-risikomanagement/compliance/Volkswagen_Group_Complaints_Procedure.pdf#page=24"
      >
        ici.
      </a>
    </p>
    <h2 class="headline3">Quel genre de signalement souhaitez-vous faire?</h2>
    <h2 class="headline4">Une plainte concernant des produits ou des services à notre service à la clientèle</h2>
    <p>Pour toute plainte ou tout commentaire concernant Electrify Canada, veuillez communiquer avec :</p>
    <p>
      <strong>Phone: </strong> 1-833-231-3226 <br />
      <strong>Online: </strong>
      <router-link :to="{ name: 'contact-us-fr' }" class="link">Electrify Canada</router-link> <br />
      <strong>Email: </strong>
      <a href="mailto:support@electrify-canada.ca" class="link">support@electrify-canada.ca</a> <br />
    </p>
    <p>
      Veuillez comprendre que nous ne serons pas en mesure de transmettre votre demande ou de prendre quelque mesure que
      ce soit pour des raisons de responsabilité.
    </p>
    <h2 class="headline4">Un signalement d’inconduite potentielle à notre système de dénonciation</h2>
    <p>
      Le système de dénonciation offre différentes voies de signalement des inconduites potentielles des employés
      d’Electrify Canada, des violations du Code de conduite des partenaires commerciaux ou des risques graves et des
      violations des droits de la personne et de l’environnement dans notre chaîne d’approvisionnement.
    </p>
    <h2 class="headline5">Courriel</h2>
    <p>
      Le Bureau central des enquêtes du groupe Volkswagen peut être joint par courriel (dans toutes les langues) :
      <a class="link" target="_blank" href="mailto:io@volkswagen.de">io@volkswagen.de</a>
    </p>
    <p>
      Pour les plaintes des clients, veuillez utiliser les voies de communication indiquées sous « Une plainte
      concernant des produits ou des services à notre service à la clientèle ».
    </p>
    <h2 class="headline5">Adresse postale/en personne :</h2>
    <p><strong>Adresse postale :</strong></p>
    <p>
      Volkswagen AG, Central Investigation Office <br />
      Mailbox 1717<br />
      Berliner Ring 2<br />
      38436 Wolfsburg, Germany<br />
    </p>
    <p><strong>En personne :</strong></p>
    <p>
      Veuillez prendre rendez-vous à l’avance en faisant parvenir un courriel à
      <a class="link" target="_blank" href="mailto:io@volkswagen.de">io@volkswagen.de</a>.
    </p>
    <h2 class="headline5">Médiateurs</h2>
    <p>
      Le Groupe Volkswagen a nommé des avocats externes pour agir en tant que médiateurs. Ils conseillent sur le système
      de dénonciation ou s’assurent que les signalements des dénonciateurs sont transmis de manière anonyme au Bureau
      central des enquêtes, au besoin.
    </p>
    <p>
      Si vous souhaitez communiquer avec le médiateur ou la médiatrice, vous pouvez trouver les coordonnées ici :
      <a class="link" target="_blank" href="http://www.ombudsmen-of-volkswagen.com/"
        >http://www.ombudsmen-of-volkswagen.com/</a
      >
    </p>
    <h2 class="headline5">Plateforme en ligne</h2>
    <p>
      Vous pouvez également utiliser une
      <a class="link" target="_blank" href="https://goto.speakup.report/volkswagen">
        plateforme de communication basée sur le Web
      </a>
      pour communiquer avec le Bureau des enquêtes dans plus de 65 langues. Ce système confidentiel et techniquement
      sécurisé vous permet d’effectuer un signalement de manière anonyme.
    </p>
    <p>
      Vous pouvez accéder à la voie de signalement en ligne par le lien suivant :
      <a class="link" target="_blank" href="https://goto.speakup.report/volkswagen">
        https://goto.speakup.report/volkswagen
      </a>
    </p>
    <p>
      Vous pouvez trouver des instructions détaillées sur la façon de faire un signalement en ligne, à l’aide
      l’application ou par téléphone
      <a class="link" href="/pdf/SpeakUp - How To Report.pdf/" rel="noopener" target="_blank">ici</a>.
    </p>

    <h2 class="headline5">Signalement vocal par téléphone</h2>
    <p>Vous pouvez nous laisser un message vocal par téléphone 24 heures sur 24, 7 jours sur 7.</p>
    <p>
      Après avoir composé le numéro de téléphone, il vous sera demandé d’entrer le code d’organisation. Ensuite, vous
      pourrez faire votre signalement en tant qu’enregistrement vocal. Le Bureau central des enquêtes recevra uniquement
      la transcription écrite du signalement. Recomposez pour écouter la réponse ou d’autres questions de notre équipe.
    </p>
    <div class="indent-list">
      <ol>
        <li>Composez le +1 669 288 7154</li>
        <li>Soyez prêt à communiquer le code d’organisation <strong>122203</strong></li>
      </ol>
      <p>
        <strong>ATTENTION: </strong> Si vous êtes à l’extérieur des États-Unis, vous pouvez sélectionner le numéro du
        pays ou de la région où vous vous trouvez
        <a class="link" href="/pdf/Volkswagen Group SpeakUp Line Phone Numbers_VW.pdf/" rel="noopener" target="_blank"
          >cette liste.</a
        >
      </p>
    </div>
    <h2 class="headline3">Avez-vous des questions?</h2>
    <p>
      Vous pouvez également adresser vos questions ou suggestions d’amélioration relatives au système de dénonciation au
      Bureau central des enquêtes.
    </p>
    <p>
      Vous pouvez aussi vous adresser à notre responsable local de la conformité pour toutes les questions relatives au
      système de dénonciation :
    </p>
    <ul>
      <li>Service d’assistance téléphonique pour les questions d’éthique : 888 228-7317</li>
      <li>
        En ligne :
        <a class="link" target="_blank" href="https://www.vwgoa.ethicspoint.com"> EthicsPoint </a>
      </li>
      <li>
        Courriel :
        <a class="link" target="_blank" href="mailto:vwgoacorporatecompliance@vw.com">
          Conformité et éthique d’Electrify Canada
        </a>
      </li>
    </ul>
  </article>
</template>

<script>
export default {
  metaInfo: {
    title: `Système de dénonciation| Electrify Canada`,
    meta: [
      {
        name: 'description',
        content: `Apprenez-en davantage sur notre système de dénonciation et sur la façon de signaler anonymement une violation d'un règlement ou du code de conduite.`,
      },
    ],
  },
};
</script>
<style lang="scss" scoped>
.indent-list {
  padding-left: 25px;
}

.headline3 {
  padding-top: 20px;
}

.headline4 {
  padding-top: 15px;
}
.headline5 {
  padding-top: 5px;
}
</style>
