export default {
  en: {
    appToUseHomestation: 'Do I need to have the Electrify Canada app to use HomeStation™?',
    appToUseHomestationAnswer:
      'No, the Electrify Canada app is not required to use your HomeStation™. However, in order to experience all features of the HomeStation™, the app is required.',
    determinePricing: 'How is Electrify Canada DC Fast charging pricing determined?',
    determinePricingAnswer:
      'You pay for the energy your vehicle receives, regardless of temperature, your battery’s capacity, the time it takes for your vehicle to charge, or other variable factors.',
    differencePassPassPlus: 'What are the differences between Pass and Pass+ membership?',
    differencePassPassPlusAnswer1: 'Electrify Canada Pass members enjoy all of the benefits above, and',
    differencePassPassPlusAnswer2: '$0 due at signup and no monthly fee',
    differencePassPassPlusAnswer3: 'Enjoy convenient charging access via your smart phone',
    differencePassPassPlusAnswer4: 'Electrify Canada Pass+ members enjoy all of the benefits above, and',
    differencePassPassPlusAnswer5: 'Pay a monthly fee and enjoy savings of up to 20% on charging.',
    faqsMobileApp: 'Frequently asked questions about the mobile app',
    faqsPricing: 'Frequently asked questions about payment and membership',
    getPremiumOffers: 'Get Premium Offers in the app',
    getPremiumOffersAltText: 'Closeup of a person tapping a smartphone to the charging station’s RFID reader.',
    getPremiumOffersContent:
      'Your vehicle could qualify for a Premium Offer. Download the Electrify Canada mobile app to see all plans.',
    getTheApp: 'GET THE APP ',
    homestationWithApp: 'Does the HomeStation™ work with the Electrify Canada mobile app?',
    homestationWithAppAnswer: 'Yes. The Electrify Canada app is available for iOS and Android.',
    howToChangeMembershipType: 'How do I change from one membership type to another?',
    howToChangeMembershipTypeAnswer:
      'In your account, go to Plan details and follow the prompts to change plan types. Please note that a request to change from Pass+ to Pass membership will only go into effect on the 1st day of the next billing cycle, which coincides with the monthly anniversary of your sign-up date.',
    howToDeleteAccount: 'How do I delete my account?',
    howToDeleteAccountAnswer:
      'Cancel your membership anytime in the app by navigating to your profile and selecting “Delete Account.”',
    howToPay: 'How do I pay for charging and for the Pass+ subscription fee?',
    howToPayAnswerA:
      'When you sign up for Electrify Canada Pass or Pass+ in the mobile app, follow the prompts to add your credit card to your account. Then select an amount to automatically load money to your Electrify Canada balance.',
    howToPayAnswerB:
      'This amount will be added to your account the first time you charge your vehicle at an Electrify Canada charging station after signing up. Every time your balance falls to or below $5, your account will reload with the amount you selected.',
    howToPayAnswerC:
      'The cost for charging, idling, and any applicable taxes will be deducted from your balance at the end of your charging session.',
    howToPayAnswerD:
      'If you’re a Pass+ member, your monthly subscription fee will be charged to your credit or debit card when you sign up and deducted from your Electrify Canada balance monthly thereafter.',
    messagingAndDataRates: 'Messaging and data rates may apply',
    needNewAccount: 'Will I need to create a new account to use the new app?',
    needNewAccountAnswer:
      'No, you can use your existing Electrify Canada account credentials to sign in to the new app.',
    outOfMoney: 'What happens if I run out of money in my Electrify Canada mobile app wallet?',
    outOfMoneyAnswer:
      'To ensure you can seamlessly charge your vehicle at an Electrify Canada charging station, every time your wallet balance falls to or below the minimum wallet threshold, we will automatically charge your card on file to auto-fill your wallet in the amount you select. If your payment method is declined during a charging session, please update your payment method on your account to allow Electrify Canada to auto-fill your wallet so that vehicle charging can continue. If the payment method is not updated, charging will stop when your wallet balance reaches $0.',
    redesignedApp: 'How does the redesigned app make charging at home easier?',
    redesignedAppAnswer:
      'The app will allow you to connect to your Electrify HomeStation™ charger. You’ll be able to start and stop charging, as well as set up a schedule for charging your EV.',
    startSession: 'How do I use my membership plan to start a charging session at an Electrify Canada charger?',
    startSessionAnswer1:
      ' As an Electrify Canada Pass or Pass+ member, if you’ve downloaded the Electrify Canada app* and added a valid payment method to your account, you have two ways to start a charge by smartphone:',
    startSessionAnswer2: ' Using the',
    startSessionAnswer3: 'digital membership pass',
    startSessionAnswer4: ', which requires tapping or holding the smartphone near the charger’s RFID reader.',
    startSessionAnswer5:
      'First, plug the connector into the vehicle. Follow all steps on the charger screen before tapping or holding your phone near the reader.',
    startSessionAnswer6: 'Android users:',
    startSessionAnswer7:
      'Ensure that NFC is turned on in your phone settings and that the smartphone screen is unlocked before tapping the smartphone to the reader—look for the symbol below. The Electrify Canada app does not need to be open.',
    startSessionAnswer8: 'iOS users:',
    startSessionAnswer9:
      'Open Apple Wallet or the Electrify Canada app. Tap the smartphone to the reader on the right side of the charger—look for the symbol shown here',
    startSessionAnswer10: ' Using “Swipe to Start Charge” from within the Electrify Canada app.',
    startSessionAnswer11:
      'Ensure that your smartphone’s location services are enabled, the Electrify Canada app is installed on your phone, and you are logged into your account.',
    startSessionAnswer12:
      'Open the app and from the map screen (or your Favourites), select the charging station where you are located. You must be near the charger to start a charge.',
    startSessionAnswer13:
      'Select the charger by referring to the Charger ID number at the top of the charger’s screen, and swipe to start a charging session.',
    startSessionAnswer14: 'Hint: Look for the last two digits on the charger your vehicle is plugged into.',
    startSessionSwipeToStartImg: '@/assets/images/Mobile-App/swipe to start.svg',
    whichPaymentMethods: 'Which payment methods do you accept?',
    whichPaymentMethodsAnswer: 'All Electrify Canada chargers accept Visa and MasterCard credit cards and debit cards',
    whyBecomeMember: 'Why should I become an Electrify Canada member?',
    whyBecomeMemberAnswer1:
      'Electrify Canada Pass and Pass+ members have access to the Electrify Canada mobile app which enables them to',
    whyBecomeMemberAnswer2: 'See real-time availability of chargers before arriving at a station',
    whyBecomeMemberAnswer3: 'Add favourite stations for faster searching and navigation',
    whyBecomeMemberAnswer4: 'Receive notifications when a charger becomes available',
    whyBecomeMemberAnswer5:
      'Set state of charge, cost, or time notifications for a charging session (swipe to start sessions only)',
    whyBecomeMemberAnswer6:
      'Start charging sessions using a smartphone (no more digging around for a credit or debit card)',
    whyBecomeMemberAnswer7: 'Track your vehicle’s charge status if you’re away from it',
    whyBecomeMemberAnswer8: 'Review charging and billing history',
  },
  fr: {
    appToUseHomestation: 'Ai-je besoin de l’appli Electrify Canada pour utiliser la borne domestique HomeStation™?',
    appToUseHomestationAnswer:
      'Non, l’appli Electrify Canada n’est pas nécessaire pour utiliser votre borne domestique HomeStation™. Cependant, l’appli est nécessaire pour profiter de toutes les fonctionnalités de HomeStation™.',
    determinePricing: 'Comment la tarification de la recharge rapide CC d’Electrify Canada est-elle déterminée?',
    determinePricingAnswer:
      'Vous payez pour l’énergie que votre véhicule reçoit, quelle que soit la température, la capacité de votre batterie, le temps requis pour la recharge de votre véhicule ou d’autres facteurs variables.',
    differencePassPassPlus: 'Quelles sont les différences entre les membres Passe et Passe+?',
    differencePassPassPlusAnswer1: 'Les membres Passe Electrify Canada profitent de tous les avantages ci-dessus, et',
    differencePassPassPlusAnswer2: 'Aucuns frais à l’inscription et aucuns frais mensuels',
    differencePassPassPlusAnswer3: 'Profitent d’un accès pratique à la recharge à l’aide de leur téléphone intelligent',
    differencePassPassPlusAnswer4: 'Les membres Passe+ Electrify Canada profitent de tous les avantages ci-dessus, et',
    differencePassPassPlusAnswer5:
      `Payez des frais mensuels pour profiter d'économies allant jusqu'à 20 % sur la recharge.`,
    faqsMobileApp: 'Questions fréquentes au sujet de l’application mobile',
    faqsPricing: "Questions fréquemment posées sur le paiement et l'adhésion",
    getPremiumOffers: 'Obtenez des offres Premium dans l’appli',
    getPremiumOffersAltText:
      'Plan rapproché d’une personne qui touche le lecteur d’IRF de la station de recharge avec son téléphone intelligent.',
    getPremiumOffersContent:
      'Votre véhicule pourrait bénéficier d’une offre Premium. Téléchargez l’appli mobile Electrify Canada pour voir tous les forfaits.',
    getTheApp: 'OBTENIR L’APPLI ',
    homestationWithApp: 'La borne HomeStation™ fonctionne-t-elle avec l’application mobile Electrify Canada?',
    homestationWithAppAnswer: 'Oui. L’application Electrify Canada est offerte pour iOS et Android.',
    howToChangeMembershipType: 'Comment puis-je passer d’un type d’adhésion à un autre?',
    howToChangeMembershipTypeAnswer:
      'Dans votre compte, allez aux détails du forfait et suivez les instructions pour changer de type de forfait. Veuillez noter qu’une demande de passage du statut de Passe+ à celui de Passe ne prendra effet que le premier jour du prochain cycle de facturation, qui coïncide avec la date d’anniversaire mensuelle de votre inscription.',
    howToDeleteAccount: 'Comment puis-je supprimer mon compte?',
    howToDeleteAccountAnswer:
      'Annulez votre adhésion n’importe quand dans l’application en accédant à votre profil et en sélectionnant « Supprimer le compte ».',
    howToPay: 'Comment puis-je payer la recharge et les frais d’adhésion du forfait Passe+?',
    howToPayAnswerA:
      'Lorsque vous adhérez à un forfait Passe ou Passe+ d’Electrify Canada dans l’application mobile, vous n’avez qu’à suivre les indications pour ajouter votre carte de crédit à votre compte. Sélectionnez ensuite un montant à ajouter automatiquement à votre solde Electrify Canada.',
    howToPayAnswerB:
      'Ce montant sera ajouté à votre compte la première fois que vous rechargerez votre véhicule à une borne de recharge d’Electrify Canada après vous être inscrit. Chaque fois que votre solde atteindra le seuil de 5 $ ou moins, votre compte sera rechargé du montant que vous avez sélectionné.',
    howToPayAnswerC:
      'Les frais de recharge, les frais d’occupation et toutes les taxes applicables seront déduits de votre solde à la fin de la session de recharge.',
    howToPayAnswerD:
      'Si vous êtes un membre Passe+, vos frais d’adhésion mensuels seront portés à votre carte de crédit ou de débit au moment de votre inscription puis déduits de votre solde Electrify Canada tous les mois par la suite.',
    messagingAndDataRates: 'Des frais de messagerie et de données pourraient s’appliquer',
    needNewAccount: 'Dois-je créer un nouveau compte pour utiliser la nouvelle application?',
    needNewAccountAnswer:
      'Non, vous pouvez utiliser vos identifiants de compte Electrify Canada actuels pour ouvrir une session dans la nouvelle application.',
    outOfMoney:
      'Que se passe-t-il si je manque d’argent dans mon portefeuille de l’application mobile Electrify Canada?',
    outOfMoneyAnswer:
      'Pour que vous puissiez recharger votre véhicule en toute facilité à une borne de recharge d’Electrify Canada, nous débitons automatiquement votre carte au dossier pour remplir automatiquement votre portefeuille du montant que vous sélectionnez chaque fois que le solde de votre portefeuille est égal ou inférieur au seuil minimum du portefeuille. Si votre mode de paiement est refusé lors d’une session de recharge, il vous faut mettre à jour votre mode de paiement dans votre compte pour permettre à Electrify Canada de remplir automatiquement votre portefeuille afin que la recharge du véhicule puisse continuer. Si le mode de paiement n’est pas mis à jour, la recharge s’arrêtera lorsque le solde de votre portefeuille atteindra 0 $.',
    redesignedApp: 'De quelle façon l’application redessinée facilite-t-elle la recharge à la maison?',
    redesignedAppAnswer:
      'L’application vous permettra de vous connecter à la borne domestique HomeStation™ Electrify. Vous pourrez commencer et arrêter la recharge, ainsi que configurer un horaire pour recharger votre VÉ.',
    startSession:
      'Comment puis-je utiliser mon forfait d’adhésion pour commencer une session de recharge à une borne de recharge Electrify Canada?',
    startSessionAnswer1:
      'En tant que membre Passe ou Passe+ Électrifie Canada, après avoir téléchargé l’application Électrifie Canada* et ajouté une méthode de paiement valide à votre compte, vous avez deux façons de démarrer une session de recharge en utilisant votre téléphone intelligent :',
    startSessionAnswer2: 'En utilisant la ',
    startSessionAnswer3: 'passe d’adhésion numérique,',
    startSessionAnswer4:
      ' qui nécessite de toucher ou de tenir le téléphone intelligent près du lecteur RFID de la borne de recharge.',
    startSessionAnswer5:
      'Branchez d’abord le connecteur au véhicule. Suivez toutes les étapes sur l’écran de la borne avant de toucher le lecteur ou de tenir votre téléphone près du lecteur.',
    startSessionAnswer6: 'Utilisateurs d’Android:',
    startSessionAnswer7:
      ' Assurez-vous que la CCP est activée dans les paramètres de votre téléphone et que l’écran du téléphone intelligent est déverrouillé avant de toucher le lecteur avec l’écran – recherchez le symbole ci-dessous. Il n’est pas nécessaire d’ouvrir l’application Electrify Canada.',
    startSessionAnswer8: 'Utilisateurs d’iOS:',
    startSessionAnswer9:
      ' Ouvrez Apple Wallet ou l’application Electrify Canada. Approchez le téléphone intelligent du lecteur situé du côté droit de la borne de recharge. Recherchez le symbole illustré ici.',
    startSessionAnswer10:
      'En utilisant la fonction « Swipe to Start Charge » (faire glisser pour commencer la charge) à partir de l’application Electrify Canada.',
    startSessionAnswer11:
      'Assurez-vous que les services de localisation de votre téléphone intelligent sont activés, que l’application Electrify Canada est installée sur votre téléphone et que vous êtes connecté à votre compte.',
    startSessionAnswer12:
      'Ouvrez l’application et, à partir de la carte (ou de vos Favoris), sélectionnez la station de recharge où vous vous trouvez. Vous devez vous trouver à proximité de la borne pour commencer une recharge.',
    startSessionAnswer13:
      'Sélectionnez la borne en vous référant à l’identifiant de la borne dans le haut de l’écran de la borne, et balayez pour lancer la session de recharge.',
    startSessionAnswer14:
      'Indice : Recherchez les deux derniers chiffres de la borne à laquelle votre véhicule est branché.',
    startSessionSwipeToStartImg: '@/assets/images/Mobile-App/swipe to start.svg',
    whichPaymentMethods: 'Quels modes de paiement acceptez-vous?',
    whichPaymentMethodsAnswer:
      'Toutes les bornes de recharge d’Electrify Canada acceptent les cartes de crédit Visa et Mastercard et les cartes de débit.',
    whyBecomeMember: 'Pourquoi devrais-je devenir membre d’Electrify Canada?',
    whyBecomeMemberAnswer1:
      'Les membres Passe et Passe+ d’Electrify Canada ont accès à l’application mobile Electrify Canada qui leur permet de',
    whyBecomeMemberAnswer2: 'Voir la disponibilité en temps réel avant d’arriver à une borne',
    whyBecomeMemberAnswer3: 'Désigner des bornes comme favorites pour une recherche et une navigation plus rapides',
    whyBecomeMemberAnswer4: 'Recevoir des notifications lorsqu’une borne se libère',
    whyBecomeMemberAnswer5:
      'Définir l’état de charge, le coût ou la durée d’une session de recharge (faire glisser pour commencer les sessions seulement)',
    whyBecomeMemberAnswer6:
      'Lancer les sessions de recharge à l’aide d’un téléphone intelligent (plus besoin de fouiller pour trouver une carte de crédit ou de débit)',
    whyBecomeMemberAnswer7: 'Suivre l’état de la recharge de votre véhicule si vous vous êtes éloigné(e) de la borne',
    whyBecomeMemberAnswer8: 'Consulter l’historique de recharge et de facturation',
  },
};
