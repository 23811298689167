const AboutUs = () => import('@/views/Company/AboutUs.vue');
const AboutUsFR = () => import('@/views/Company/AboutUs-fr.vue');
const EarthWeek = () => import('@/views/Company/EarthWeekPromoOver.vue');
const EarthWeekFR = () => import('@/views/Company/EarthWeekPromoOver-fr.vue');
const NewsUpdates = () => import('@/views/Company/NewsUpdates.vue');
const NewsUpdatesFR = () => import('@/views/Company/NewsUpdates-fr.vue');
const OurTeam = () => import('@/views/Company/OurTeam.vue');
const OurTeamFR = () => import('@/views/Company/OurTeam-fr.vue');
const WhistleBlower = () => import('@/views/Company/WhistleBlower.vue');
const WhistleBlowerFR = () => import('@/views/Company/WhistleBlower-fr.vue');

const routes = [
  {
    path: '/about-us/',
    name: 'about-us-en',
    pathToRegexpOptions: { strict: true },
    component: AboutUs,
  },
  {
    path: '/fr/a-propos/',
    name: 'about-us-fr',
    pathToRegexpOptions: { strict: true },
    component: AboutUsFR,
  },

  {
    path: '/earth-week/',
    name: 'earth-week-en',
    pathToRegexpOptions: { strict: true },
    component: EarthWeek,
  },
  {
    path: '/fr/semaine-de-la-terre/',
    name: 'earth-week-fr',
    pathToRegexpOptions: { strict: true },
    component: EarthWeekFR,
  },

  {
    path: '/news-updates/',
    name: 'news-updates-en',
    pathToRegexpOptions: { strict: true },
    component: NewsUpdates,
  },
  {
    path: '/fr/nouvelles/',
    name: 'news-updates-fr',
    pathToRegexpOptions: { strict: true },
    component: NewsUpdatesFR,
  },

  {
    path: '/our-team/',
    name: 'our-team-en',
    pathToRegexpOptions: { strict: true },
    component: OurTeam,
  },
  {
    path: '/fr/notre-equipe/',
    name: 'our-team-fr',
    pathToRegexpOptions: { strict: true },
    component: OurTeamFR,
  },

  {
    path: '/whistleblower/',
    name: 'whistleblower-en',
    pathToRegexpOptions: { strict: true },
    component: WhistleBlower,
  },
  {
    path: '/fr/whistleblower/',
    name: 'whistleblower-fr',
    pathToRegexpOptions: { strict: true },
    component: WhistleBlowerFR,
  },
];

export default routes;
