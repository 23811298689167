<template>
    <svg height="12.242" viewBox="0 0 20.242 12.242" width="10" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" :stroke="color" stroke-linecap="round" stroke-width="3">
        <path d="m2.121 2.121 8 8" />
        <path d="m18.121 2.121-8 8" />
      </g>
    </svg>
  </template>
  <script>
  export default {
    name: 'HeaderCaret',
    props: {
      color: { type: String, required: false, default: '#0a124a' },
    },
  };
  </script>