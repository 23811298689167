<template>
  <article class="partner-page bmw">
    <Hero heading="Power the full potential of your BMW i7" :subheading="true">
      <template #subheading>
        <p>
          Unlock infinite possibilities with 3 years of complimentary charging from the date of vehicle purchase at
          Electrify Canada Ultra-Fast DC chargers—compliments of
          <span class="wrap-last-two-words"
            >BMW.
            <reference-link :num="1"
              >Available on model year 2023-2025 BMW i7 vehicles. 3 years of complimentary charging begins upon vehicle
              purchase. BMW i7s purchased prior to program launch (April 6, 2023) will have 3 years of complimentary
              charging starting from April 6, 2023. Non-transferable. Not available for commercial use, such as
              ridesharing. Requires Electrify Canada account, app, enrollment in BMW i7 Charging Plan, and acceptance of
              Terms of Use. After user-initiated charge session stops or vehicle reaches full charge or charging
              otherwise stops, and 10-min grace period, Idle Fees will apply and user is responsible. In the event of
              suspected fraud or abuse or other unforeseen events, Electrify Canada may discontinue or modify the offer
              in its sole discretion.
            </reference-link></span
          >
        </p>
      </template>
      <template #image>
        <picture>
          <source media="(min-width: 750px)" srcset="@/assets/images/BMW/bmw-hero.png" />
          <img src="@/assets/images/BMW/bmw-hero-mobile.png" alt="" />
        </picture>
      </template>
    </Hero>

    <TwoColumnContentBlock heading="Evolve with Electrify Canada">
      <p>
        Electrify Canada is committed to building a network of convenient and reliable electric vehicle charging
        stations—at strategic locations in metropolitan centres, along major highways and near popular amenities—to help
        get electric vehicle (EV) drivers where they want to go. Because we know your BMW i7 wasn’t made to stay in the
        garage.
      </p>
      <p>
        With 3 years of complimentary charging across all Electrify Canada DC Fast charging stations after vehicle
        purchase and enrollment
        <ReferenceLink :num="1">
          Available on model year 2023-2025 BMW i7 vehicles. 3 years of complimentary charging begins upon vehicle
          purchase. BMW i7s purchased prior to program launch (April 6, 2023) will have 3 years of complimentary
          charging starting from April 6, 2023. Non-transferable. Not available for commercial use, such as ridesharing.
          Requires Electrify Canada account, app, enrollment in BMW i7 Charging Plan, and acceptance of Terms of Use.
          After user-initiated charge session stops or vehicle reaches full charge or charging otherwise stops, and
          10-min grace period, Idle Fees will apply and user is responsible. In the event of suspected fraud or abuse or
          other unforeseen events, Electrify Canada may discontinue or modify the offer in its sole
          discretion.</ReferenceLink
        >, BMW i7 drivers can focus on the roads and possibilities ahead, leaving gas stations behind. BMW i7 Charging
        Plan will be able to <strong>activate Plug&Charge functionality</strong> for use with this plan (see plan
        enrollment steps below for more information).
      </p>
      <p>With these benefits, BMW i7 drivers can enjoy the full potential of electric freedom.</p>
      <router-link class="button" :to="{ name: 'locate-charger-en' }">Locate a Charger</router-link>
    </TwoColumnContentBlock>

    <TwoColumnContentBlock heading="Go with freedom">
      <template #image>
        <picture>
          <source media="(min-width: 750px)" srcset="@/assets/images/BMW/bmw-image1.png" />
          <img src="@/assets/images/BMW/bmw-image1-mobile.png" alt="" />
        </picture>
      </template>
      <p>
        Get going with complimentary direct current (DC) fast charging, for 3 years at Electrify Canada chargers. Just
        follow these simple steps:
      </p>
      <div>
        <ul>
          <li>
            <strong>Step 1:</strong> Download the Electrify Canada app
            <reference-link :num="2"
              >Your mobile carrier’s normal messaging, data, and other rates and fees will apply to your use of the
              Electrify Canada mobile application. </reference-link
            >, create your account and select “Set Up Public Charging.”
          </li>
          <li>
            <strong>Step 2:</strong> Select “Choose Membership Plan” then “Premium Offers.”
            <ul>
              <li>Or, search for Premium Offer by vehicle information.</li>
              <li>
                Existing Electrify Canada members may select “Premium Offers” in the Account tab, then “I have an
                enrollment code.”
              </li>
            </ul>
          </li>
          <li>
            <strong>Step 3:</strong> Input your 10 character enrollment code, which you will receive from your BMW
            dealer, and select the BMW i7 Charging Plan. Input your vehicle 17-digit VIN
            <reference-link :num="3"
              >The VIN can usually be found on the driver's side dashboard, near or under the bottom portion of the
              windshield.</reference-link
            >
            for verification.
          </li>
          <li><strong>Step 4:</strong> Accept Plan Disclosure, then tap "Select this plan."</li>
          <li>
            <strong>Step 5:</strong> Enter payment info
            <reference-link :num="4"
              >To help ensure you can charge uninterrupted, your Electrify Canada account balance automatically reloads
              when it falls below $5. You’ll be billed the auto-reload amount you select in the app, the first time you
              charge at an Electrify Canada station, and the billed amount will be held in your account to cover costs
              not covered by this plan, such as Idle Fees and applicable taxes.</reference-link
            >
            and set up auto-reload.
            <ul>
              <li>
                You will need to enter a payment method
                <reference-link :num="4"
                  >To help ensure you can charge uninterrupted, your Electrify Canada account balance automatically
                  reloads when it falls below $5. You’ll be billed the auto-reload amount you select in the app, the
                  first time you charge at an Electrify Canada station, and the billed amount will be held in your
                  account to cover costs not covered by this plan, such as Idle Fees and applicable
                  taxes.</reference-link
                >
                to cover any incurred Idle Fees
                <reference-link :num="5"
                  >Drivers who do not unplug and move their vehicle ten minutes after their charging session is complete
                  will be charged a $0.40 per-minute idle fee. This fee encourages drivers to move their car promptly so
                  that others can use the charger. You can be notified when your charging session is complete by text
                  message<sup>2</sup> or see it on the charger screen.
                </reference-link>
                or applicable taxes.
              </li>
            </ul>
          </li>
          <li>
            <strong>Step 6:</strong> Plug&Charge, sign in to the Electrify Canada app, navigate to your “Account”, under
            your “Plans”, follow the prompts in the app to “Activate Plug&Charge”.
          </li>
        </ul>
        <p>
          Offer enrollment can be as smooth as your journeys, but if you encounter any roadblocks, call 1-833-231-3226
          to contact Electrify Canada Customer Assistance, available 24/7.
        </p>
      </div>
      <div class="partner-page__cta">
        <router-link class="button partner-page__cta__link" :to="{ name: 'mobile-app-en' }"
          >Download The App</router-link
        >
        <a
          href="https://apps.apple.com/ca/app/electrify-canada/id1472391910"
          rel="noopener"
          target="_blank"
          class="partner-page__cta__link"
          ><img loading="lazy" :alt="$t('downloadInTheAppStore')" src="@/assets/images/Global/app-store-logo-en.png"
        /></a>
        <a
          href="https://play.google.com/store/apps/details?id=com.ec.evowner&hl=en_CA"
          rel="noopener"
          target="_blank"
          class="partner-page__cta__link"
          ><img loading="lazy" :alt="$t('getItOnGooglePlay')" src="@/assets/images/Global/google-play-badge-en.png"
        /></a>
      </div>
    </TwoColumnContentBlock>

    <TwoColumnContentBlock heading="Take Charge with Plug&Charge">
      <template #image>
        <picture>
          <source media="(min-width: 750px)" srcset="@/assets/images/BMW/bmw-image2.jpg" />
          <img src="@/assets/images/BMW/bmw-image2.jpg" alt="" />
        </picture>
      </template>
      <p>
        Plug&Charge can provide a more streamlined way to pay for your charging experience. Please ensure that you
        enrolled in your BMW i7 Charging Plan and activated Plug&Charge within the app (see app activation step above).
      </p>
      <div>
        <ul>
          <li>
            <strong>Step 1:</strong> Add a payment method. You will need to enter a payment method into your Electrify
            Canada app to cover any incurred fees or applicable taxes.
          </li>
          <li><strong>Step 2:</strong> Drive to any Electrify Canada DC fast charging station.</li>
          <li><strong>Step 3:</strong> Plug in. Plug the charging connecter into your electric vehicle.</li>
          <li>
            <strong>Step 4:</strong> Check the charge. Look at the charger screen to ensure your charge has begun.
          </li>
          <li>
            <strong>Step 5:</strong> Relax. Now that your vehicle is charging, monitor your progress in the Electrify
            Canada app.
          </li>
        </ul>
      </div>
      <router-link class="button" :to="{ name: 'locate-charger-en' }">Locate a Charger</router-link>
    </TwoColumnContentBlock>
  </article>
</template>

<script>
import lang from '@/lang';
import Hero from '@/components/Hero/Hero.vue';
import ReferenceLink from '@/components/ReferenceLink.vue';
import TwoColumnContentBlock from '@/components/TwoColumnContentBlock/TwoColumnContentBlock.vue';

export default {
  name: 'bmw',
  components: {
    ReferenceLink,
    Hero,
    TwoColumnContentBlock,
  },
  metaInfo: {
    title: 'BMW landing page | Electrify Canada',
    link: [{ rel: 'canonical', href: 'https://www.electrify-canada.ca/bmw-i7/' }],
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
};
</script>
