<template>
  <div :class="{ 'menu-open': isStationListExpanded, 'menu-closed': !isStationListExpanded }" class="zoom">
    <button
      @click="zoomIn"
      class="zoom-in"
      :aria-label="$t('zoomIn')"
      :tabindex="isMobile && isStationListExpanded ? '-1' : '0'"
      :disabled="this.$store.state.map.mapZoom > 14"
    >
      <img src="../../assets/images/Map/zoom-in.svg?external" :alt="$t('zoomInAltText')" />
    </button>
    <button
      @click="zoomOut"
      class="zoom-out"
      :aria-label="$t('zoomOut')"
      :tabindex="isMobile && isStationListExpanded ? '-1' : '0'"
      :disabled="this.$store.state.map.mapZoom < 5"
    >
      <img src="../../assets/images/Map/zoom-out.svg?external" :alt="$t('zoomOutAltText')" />
    </button>
  </div>
</template>

<script>
import lang from '@/lang/LocateCharger';

export default {
  name: 'MapZoom',
  props: ['map'],
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
  methods: {
    zoomIn() {
      if (this.$store.state.map.mapZoom <= 14) {
        this.$store.dispatch('map/setZoom', this.$store.state.map.mapZoom + 1);
      }
    },
    zoomOut() {
      if (this.$store.state.map.mapZoom >= 4) {
        this.$store.dispatch('map/setZoom', this.$store.state.map.mapZoom - 1);
      }
    },
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(750);
    },
    isStationListExpanded() {
      return this.$store.state.map.isStationListExpanded;
    },
  },
};
</script>

<style lang="scss" scoped>
.zoom {
  bottom: 100px;
  flex-direction: column;
  position: absolute;
  right: 15px;
  z-index: 99;

  @media (min-width: 750px) {
    right: 36px;
    top: 92px;
  }

  button {
    align-items: center;
    background-color: $c-primary-background;
    border: none;
    border-radius: 16px;
    box-shadow: 0 5px 17px 0 rgba(20, 25, 43, 0.3);
    color: $c-primary;
    display: flex;
    flex: 1;
    font-size: 30px;
    height: 50px;
    padding: 11px;
    justify-content: center;
    width: 50px;

    img,
    svg {
      height: 28px;
      width: 28px;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  .zoom-in {
    margin-bottom: 6px;
  }
}

.menu-open {
  @media (max-width: 750px) {
    transform: translateX(85px);
    transition: 500ms ease all;
  }
}

.menu-closed {
  @media (max-width: 750px) {
    transform: translateX(0px);
    transition: 500ms ease all;
  }
}
</style>
