<template>
  <article class="disclosure">
    <h1>Conditions D'abonnement</h1>
    <p>
      En sélectionnant « Confirmer » ci-dessous pour choisir cet abonnement, vous acceptez qu'Electrify Canada puisse
      prélever les frais mensuels spécifiés sous « Détails du plan » ci-dessus à la carte de crédit ou autre mode de
      paiement identifié sous « Mode de paiement » ci-dessus. Pendant la durée de votre abonnement, vous bénéficierez
      d'un tarif réduit sur le réseau de bornes de recharge pour véhicules électriques d'Electrify Canada, selon les
      montants indiqués à la section « Détails du plan » pour chaque région. Les remises peuvent varier selon la région,
      alors veuillez consulter les Détails du plan pour en savoir plus sur les remises offertes pour les bornes de
      chargement situées dans votre région.
    </p>
    <p>
      Cet abonnement continuera jusqu'à sa résiliation de votre part et se renouvellera automatiquement à tous les mois
      pour le même montant d'abonnement mensuel, qui vous sera facturé le premier jour de chaque nouveau mois de
      l'abonnement.
    </p>
    <p>Vous pouvez annuler votre abonnement à tout moment comme suit :</p>
    <p>
      Pour annuler votre abonnement (Passe+) et passer à un plan de détail (Passe) sans abonnement sur l'application
      mobile d'Electrify Canada :
    </p>
    <ol>
      <li>
        Une fois sur l'application Electrify Canada Mobile, utilisez l'icône <strong>Profili</strong> en haut à droite
        de la carte.
      </li>
      <li>Sélectionnez l'élément du menu, <strong>Détails de plan</strong>.</li>
      <li>Appuyez sur <strong>Choisir un plan différent.</strong></li>
      <li>
        Un écran s'affiche avec des onglets pour chaque option disponible. Sélectionnez l'onglet
        <strong>Passe</strong>, puis cliquez sur le bouton <strong>Passer au plan Passe</strong>.
      </li>
      <li>
        Une zone de texte <strong>Confirmer changement</strong> s'affiche et signale que le changement demandé pour
        l'adhésion Détail prendra effet le 1er jour du prochain cycle de facturation. Sélectionnez
        <strong>Confirmer</strong>.
      </li>
      <li>
        Vos <strong>Détails de plan</strong> s'affichent alors en signalant la date de transfert à l'abonnement Passe.
        Après cette date, le montant de l'abonnement mensuel ne sera pas prélevé sur votre carte et vous ne recevrez pas
        les remises applicables.
      </li>
    </ol>
    <p>
      Les frais d'abonnement payés pour le mois en cours ne seront pas remboursés. Il n'y a pas d'obligation d'achat
      minimum pour cet abonnement, donc les remises spécifiées resteront en vigueur quel que soit le nombre de sessions
      de charge que vous achetez pendant la durée de l'abonnement.
    </p>
  </article>
</template>

<script>
export default {
  name: 'SubscriptionAgreement',
  metaInfo: {
    title: 'Accord d’abonnement à la recharge Passe + d’Electrify Canada',
    meta: [
      {
        name: 'description',
        content:
          'Information sur le forfait Passe + de l’application Electrify Canada, économisant jusqu’à 20 % sur l’adhésion',
      },
    ],
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/fr/contrat-dabonnement/',
      },
    ],
  },
};
</script>
