<template>
  <div>
    <section
      class="locations-page"
      v-if="this.$route.params.state == undefined && this.$route.params.city == undefined"
    >
      <h1>{{ $t('locationsPage.locationsHeading') }}</h1>
      <div class="locations-page-wrapper">
        <ul v-for="(value, key) in filterStateLocations" :key="key">
          <li @click="redirectToState(key)" v-if="value.length > 0">
            <a
              ><strong>{{ key }}</strong> ({{ value.length }})</a
            >
          </li>
          <li v-else>
            <span
              ><strong>{{ key }}</strong> ({{ value.length }})</span
            >
          </li>
        </ul>
      </div>
    </section>
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import lang from '@/lang/LocateCharger';

export default {
  name: 'Locations',
  metaInfo() {
    return {
      title: this.pageTitle,
      meta: [
        {
          name: 'description',
          content: this.pageDescription,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: this.canonicalUrl,
        },
      ],
    };
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
  data() {
    return {
      pageTitle: '',
      pageDescription: '',
    };
  },
  async created() {
    this.pageTitle = `${
      this.$route.name.includes('-fr') ? 'Stations de recharge pour VE au Canada' : 'EV Charging Stations in Canada'
    } | Electrify Canada`;

    this.pageDescription = this.$route.name.includes('-fr')
      ? 'Le répertoire des stations de recharge comprend tous les emplacements des stations de recharge Electrify Canada au Canada.'
      : 'The charging station directory includes all Electrify Canada charging station locations in Canada.';

    await this.$store.dispatch('locations/getAllLocations', this.$router);
  },
  computed: {
    ...mapState('locations', ['filterStateLocations']),
  },
  methods: {
    redirectToState(state) {
      const trimState = state?.replaceAll(' ', '-').toLowerCase();
      if (this.$route.name.includes('-fr')) {
        this.$router.push({ name: 'locations-state-fr', params: { state: trimState } });
      } else {
        this.$router.push({ name: 'locations-state-en', params: { state: trimState } });
      }
    },
  },
  watch: {
    $route() {
      this.pageTitle = `${
        this.$route.name.includes('-fr') ? 'Stations de recharge pour VE au Canada' : 'EV Charging Stations in Canada'
      } | Electrify Canada`;

      this.pageDescription = this.$route.name.includes('-fr')
        ? 'Le répertoire des stations de recharge comprend tous les emplacements des stations de recharge Electrify Canada au Canada.'
        : 'The charging station directory includes all Electrify Canada charging station locations in Canada.';
    },
  },
};
</script>

<style lang="scss" scoped>
.locations-page {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 60px;
  min-height: calc(100vh - 550px);
  padding-left: 10px;
  &-wrapper {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    ul {
      padding-left: 8px;
    }
    li {
      list-style-type: none;
      display: inline-block;
      a {
        cursor: pointer;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .locations-page {
    padding: 16px;
    &-wrapper {
      grid-template-columns: 1fr;
    }
  }
}
</style>
