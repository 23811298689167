<template>
  <article>
    <Hero heading="Your verification link has expired." :subheading="true">
      <template #subheading>
        <p>
          Please visit <a class="link" href="https://electrify-canada.ca">www.Electrify-Canada.ca</a> and submit a new
          email sign-up form. Afterwards, please verify your email address by following the instructions in the received
          message. The verification link will remain active for the 48 hours following its reception.
        </p>
        <br />
        <p>For any emergency, you can reach us directly 24 hours a day, 7 days a week by dialing 1-833-231-3226.</p>
      </template>
    </Hero>
  </article>
</template>

<script>
import Hero from '../components/Hero/Hero.vue';

export default {
  name: 'expired-email-en',
  metaInfo: {
    title: 'Verification link',
    meta: [{ name: 'robots', content: 'noindex' }],
  },
  components: { Hero },
};
</script>

<style scoped lang="scss">
.field--name-field-ea-subtitle {
  max-width: 1160px;
  margin: 0 auto;
  font-size: 16px;
}
article {
  overflow: hidden;
  position: relative;
  min-height: 650px;
}
</style>
