<template>
  <article class="taycan">
    <Hero
      heading="Rechargez rapidement grâce à Electrify Canada"
      subheading="Profitez de la recharge CC hyper rapide pour votre véhicule électrique à batterie Porsche"
    >
      <template #image
        ><img
          alt=""
          :src="require('@/assets/images/PorscheTaycan/19_87_957_Porsche_Landing_Page_Image_1_EN.jpg')" /></template
    ></Hero>

    <TwoColumnContentBlock heading="Recharger sur la route est maintenant encore plus pratique">
      <p>
        Les véhicules électriques à batterie (VEB) Porsche sont entièrement équipés pour profiter des bornes de recharge
        rapide CC de 350 kW d’Electrify Canada. Cela signifie que vous pouvez recharger une Porsche Taycan (2025) aux
        stations Electrify Canada de 10 % à 80 % en 18 minutes environ<reference-link num="1">
          Les performances de recharge sont basées sur les modèles Porsche Taycan équipés de la batterie Performance
          Plus, dont la batterie présente un état de charge initial de (SoC) 9 % et une température de 23 °C. Les
          résultats sont obtenus avec une borne de recharge rapide en courant continu (CC) du système de recharge
          combiné (SRC) d’une puissance égale ou supérieure à 320 kW et à 850 V. Les temps de recharge varient en
          fonction de plusieurs facteurs, y compris, sans s’y limiter, la température, le type de borne de recharge,
          l’état initial de la charge de la batterie et l’état de la batterie et du véhicule. </reference-link
        >.
      </p>
      <p>
        De plus, tous les nouveaux propriétaires de VEB Porsche au Canada sont admissibles à recevoir jusqu’à deux ans
        de sessions de recharge gratuites de 30 minutes sur le réseau de recharge hyper rapide d’Electrify
        Canada.<reference-link num="2"
          >La période de recharge inclusive, fournie par le service de recharge de Porsche, commence à la date de début
          de la nouvelle garantie limitée pour les véhicules Porsche admissibles. La date de début de la nouvelle
          garantie limitée sur les véhicules est celle du transfert de la propriété du véhicule au client. La durée de
          l’offre de recharge inclusive dépend du modèle et de l’année de votre modèle Porsche particulier. Vous/les
          clients êtes responsable de l’activation du service de recharge de Porsche. Nous n’avons aucune responsabilité
          pour tout temps perdu ou non utilisé relatif à la période de recharge inclusive. La recharge inclusive est
          offerte sur les sites de recharge d’Electrify Canada partout au pays. Cet avantage comprend des sessions de
          recharge de 30 minutes illimitées, avec une interruption obligatoire de 60 minutes entre les sessions de
          recharge. Si 60 minutes ne se sont pas écoulées entre deux sessions de recharge distinctes, les deux sessions
          seront considérées et facturées comme une seule session de recharge. Si la recharge du véhicule est terminée,
          vous aurez une période de grâce de 10 minutes pour déplacer votre véhicule. Des frais d’occupation peuvent
          être facturés à la suite de la période de grâce. Si la batterie de votre véhicule n’est pas complètement
          chargée à la fin de la période de recharge inclusive de 30 minutes, des frais supplémentaires s’appliqueront
          immédiatement pour la poursuite de la recharge. Nous nous réservons le droit de modifier les conditions de la
          durée de recharge inclusive et l’offre peut être modifiée ou annulée sans préavis.
        </reference-link>
        Grâce à la recharge rapide et pratique, vous reprendrez la route en un rien de temps et profiterez des
        performances incroyables de votre véhicule VEB Porsche.
      </p>
    </TwoColumnContentBlock>

    <TwoColumnContentBlock heading="Directement de votre téléphone">
      <template #image
        ><img
          loading="lazy"
          alt=""
          :src="require('@/assets/images/PorscheTaycan/19_87_957_Porsche_Landing_Page_Image_2_RT_FR.jpg')"
      /></template>
      <p>
        Le service de recharge de la Porsche offre un accès à un maximum de deux ans de sessions de recharge gratuites
        de 30 minutes sur le réseau d’Electrify Canada<reference-link num="2"
          >La période de recharge inclusive, fournie par le service de recharge de Porsche, commence à la date de début
          de la nouvelle garantie limitée pour les véhicules Porsche admissibles. La date de début de la nouvelle
          garantie limitée sur les véhicules est celle du transfert de la propriété du véhicule au client. La durée de
          l’offre de recharge inclusive dépend du modèle et de l’année de votre modèle Porsche particulier. Vous/les
          clients êtes responsable de l’activation du service de recharge de Porsche. Nous n’avons aucune responsabilité
          pour tout temps perdu ou non utilisé relatif à la période de recharge inclusive. La recharge inclusive est
          offerte sur les sites de recharge d’Electrify Canada partout au pays. Cet avantage comprend des sessions de
          recharge de 30 minutes illimitées, avec une interruption obligatoire de 60 minutes entre les sessions de
          recharge. Si 60 minutes ne se sont pas écoulées entre deux sessions de recharge distinctes, les deux sessions
          seront considérées et facturées comme une seule session de recharge. Si la recharge du véhicule est terminée,
          vous aurez une période de grâce de 10 minutes pour déplacer votre véhicule. Des frais d’occupation peuvent
          être facturés à la suite de la période de grâce. Si la batterie de votre véhicule n’est pas complètement
          chargée à la fin de la période de recharge inclusive de 30 minutes, des frais supplémentaires s’appliqueront
          immédiatement pour la poursuite de la recharge. Nous nous réservons le droit de modifier les conditions de la
          durée de recharge inclusive et l’offre peut être modifiée ou annulée sans préavis. </reference-link
        >.
      </p>
      <ul>
        <li>
          <strong>Étape 1: </strong>
          <a rel="noopener" target="_blank" href="https://www.porsche.com/canada/fr/connect/">
            Activez Porsche Connect</a
          >
        </li>
        <li><strong>Étape 2: </strong> Inscrivez-vous au service de recharge Porsche</li>
        <li><strong>Étape 3: </strong> Téléchargez Mon application Porsche</li>
        <li><strong>Étape 4: </strong> Trouvez une borne de recharge</li>
        <li>
          <strong>Étape 5: </strong> Branchez et commencez votre session de recharge à l’aide de Mon application Porsche
          ou de la fonctionnalité Brancher et recharger
        </li>
      </ul>
    </TwoColumnContentBlock>

    <TwoColumnContentBlock heading="Prenez les commandes grâce à la fonctionnalité Brancher et recharger">
      <template #image>
        <img
          loading="lazy"
          alt=""
          :src="require('@/assets/images/PorscheTaycan/19_87_957_Porsche_Landing_Page_Image_3_EN.jpg')"
      /></template>
      <p>
        La fonctionnalité Brancher et recharger simplifie le paiement de votre recharge. Assurez-vous d’adhérer au
        service de recharge de Porsche et d’activer la fonctionnalité Brancher et recharger dans votre Porsche. La
        fonctionnalité Brancher et recharger n’est pas activée par défaut.
      </p>
      <p>Pour activer la fonctionnalité Brancher et recharger dans votre Porsche, suivez ces étapes.</p>
      <ol>
        <li>
          Assurez-vous que les services suivants ont été activés sur le véhicule
          <ol type="a">
            <li>Porsche Connect</li>
            <li>Service de recharge de Porsche</li>
          </ol>
        </li>
        <li>Assurez-vous d’être connecté au véhicule</li>
        <li>Sur l’écran d’accueil, sélectionnez le pavé « Recharge ».</li>
        <li>Sélectionnez « Options »</li>
        <li>Basculez « Activer Brancher et recharger » à « Activé »</li>
      </ol>
      <p>
        Il peut s’écouler jusqu’à six (6) heures après l’activation des services de recharge de la Porsche avant que la
        fonctionnalité Brancher et recharger ne fonctionne dans le véhicule.
      </p>
      <p>
        L’inscription à l’offre peut être aussi aisée que vos déplacements, mais si vous éprouvez des difficultés,
        composez le 1 833 231-3226 pour communiquer avec le service à la clientèle d’Electrify Canada, qui est à votre
        disposition 24 heures sur 24, 7 jours sur 7.
      </p>
    </TwoColumnContentBlock>
  </article>
</template>

<script>
import lang from '@/lang';
import ReferenceLink from '@/components/ReferenceLink.vue';
import Hero from '@/components/Hero/Hero.vue';
import TwoColumnContentBlock from '@/components/TwoColumnContentBlock/TwoColumnContentBlock.vue';

export default {
  name: 'Porsche',
  metaInfo: {
    title: 'Porsche landing page | Electrify Canada',
    meta: [
      {
        name: 'description',
        content: 'Description et renseignements du programme de recharge ultrarapide Porsche d’Electrify Canada',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrify-canada.ca/fr/porsche/' }],
  },
  components: { ReferenceLink, TwoColumnContentBlock, Hero },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
};
</script>
<style scoped lang="scss">
ol {
  font-size: 20px;
}
</style>
