<template>
  <article class="disclosure">
    <h1>e-tron Termes</h1>
    <h2>{{ $t('termsAndConditions') }}</h2>
    <p class="primary-color"><strong>Date d'entrée en vigueur : 24 septembre 2019</strong></p>
    <p>
      En appuyant sur « Accepter » ci-dessous, vous acceptez de recevoir 30 minutes de charge gratuite par session pour
      votre Audi e-tron nouvellement acquise sur le réseau Electrify Canada de bornes de chargement de véhicules
      électriques, sous réserve des présentes {{ $t('termsAndConditions') }}. Aucuns frais, achats ou abonnements
      additionnels ne sont requis pour bénéficier de cette promotion.
    </p>
    <p>
      Après votre inscription, vous pouvez réclamer ce temps de charge gratuit pour votre Audi e-tron lors de
      l’ouverture d’une session de charge à n’importe quelle borne de chargement d’Electrify Canada, exclusivement à
      l’aide de l’application « Swipe to start » ou de la Passe NFC avec le plan approprié sélectionné. Les 30 premières
      minutes de la session de charge seront fournies gratuitement.
      <strong
        >Toute durée de charge supérieure aux 30 premières minutes de chaque session sera facturée à votre compte au
        tarif en vigueur à la borne de chargement,</strong
      >
      identique au tarif de l’abonnement « Passe » Electrify Canada, sous réserve de votre participation à d’autres
      rabais ou abonnements distincts de cette promotion (le cas échéant).
    </p>
    <strong class="primary-color">
      VEUILLEZ NOTER LES LIMITES IMPORTANTES SUIVANTES CONCERNANT VOTRE UTILISATION DE CETTE PROMOTION :
    </strong>
    <ul>
      <li>
        Vous pouvez seulement utiliser ce temps de charge gratuit pour le véhicule Audi e-tron acheté auprès d'un
        concessionnaire Audi autorisé au Canada dont vous avez indiqué le NIV lors de votre inscription à cette
        promotion sur votre compte Electrify Canada. Aucun autre véhicule n’est admissible à recevoir cette charge
        gratuite à partir de votre compte, que vous en soyez le propriétaire ou non.
      </li>
      <li>
        Le temps de charge gratuit ne comprend pas les frais de stationnement ou les frais d’inactivité encourus à la
        borne de chargement.
      </li>
      <li>
        Vous ne pouvez pas utiliser ce temps de charge gratuit dans le cadre d’un service de taxi, de covoiturage (p.
        ex., Uber ou Lyft) ou de tout autre service similaire, ou à toute autre fin commerciale. Cette promotion est
        seulement pour votre usage personnel et non commercial.
      </li>
      <li>
        Vous devez respecter toute la documentation relative au produit, au véhicule, à la sécurité et aux
        renseignements techniques comprise avec votre véhicule Audi e-tron lors d’une session de charge à une borne de
        chargement Electrify Canada.
      </li>
      <li>
        Vous devez utiliser le type de connecteur approprié et charger votre Audi e-tron à la borne de chargement au
        niveau de puissance approprié.
      </li>
    </ul>
    <p>
      Cette promotion se terminera automatiquement vingt-quatre (24) mois après la date d’achat de votre véhicule Audi
      e-tron, après quoi vous ne serez plus en mesure d’utiliser cette promotion ou d’y accéder sur votre compte. Ce
      temps de charge gratuit ne peut être retourné ni échangé contre une valeur pécuniaire, et ne peut être vendu,
      prêté ou autrement distribué à des tiers pour quelque raison que ce soit.
    </p>
    <p>
      Vous reconnaissez que votre utilisation des services Electrify Canada est soumise aux modalités d’utilisation
      d’Electrify Canada disponibles à l’adresse <br />
      <router-link class="link" :to="{ name: 'terms-fr' }"
        >https://www.electrify-canada.ca/fr/mentions-légales</router-link
      >, et que votre utilisation de l’application mobile Electrify Canada est soumise aux modalités d’utilisation
      accessibles en naviguant vers l’icône « <strong>Profil</strong> » dans le coin supérieur droit de la carte, en
      sélectionnant « <strong>Mentions légales</strong> » et par après en sélectionnant «
      <strong>Modalités d’utilisation</strong> » et « <strong>Politique de confidentialité</strong> » Veuillez lire
      attentivement les documents relatifs aux modalités d’utilisation pour des informations portant sur la façon de
      payer les sessions de charge ou les frais liés au temps d’inactivité en dehors des 30 minutes gratuites offertes
      dans le cadre de cette promotion à partir de votre compte.
    </p>
    <p>
      Vous acceptez de vous conformer à tous les lois et règlements applicables lors de l’utilisation des services
      Electrify Canada et lors d’une session de charge de votre véhicule aux bornes de chargement Electrify Canada.
    </p>
    <p>
      Electrify Canada se réserve le droit de retenir, révoquer, réduire, résilier ou suspendre votre accès à tout ou
      une partie de cette promotion, sans préavis, si Electrify Canada détermine ou soupçonne, à sa seule discrétion,
      que vous : (a) enfreignez les modalités d’utilisation d’Electrify Canada, les modalités de cette promotion, ou
      tout autre contrat entre vous et Electrify Canada ; (b) avez pris part à des sessions de charge excessive ou
      frauduleuse, ou avez manifesté d’une manière ou d’une autre une utilisation non autorisée ou une utilisation
      impropre de cette promotion gratuite ; ou (c) avez manifesté quelconque comportement illégal, frauduleux,
      délictueux, préjudiciable, dommageable ou abusif dans votre utilisation des services Electrify Canada, de
      l’application mobile Electrify Canada ou d’une borne de chargement Electrify Canada.
    </p>
    <p>
      Electrify Canada se réserve le droit de mettre fin à cette promotion gratuite à tout moment avant sa date de
      résiliation automatique pour quelque raison que ce soit, à sa seule discrétion, avec ou sans préavis. Suite à la
      résiliation ou à l’expiration de cette promotion pour quelque raison que ce soit, votre accès à cette promotion
      gratuite cessera immédiatement et vous ne pourrez plus utiliser ni réclamer aucune partie restante du temps de
      charge gratuit. Suivant cela, votre compte sera automatiquement converti en un compte d’abonnement de base «
      Passe» Electrify Canada, sans frais ou coûts supplémentaires (ni uniques ni récurrents). Vous pouvez, sans y être
      obligé, souscrire à un type d’abonnement différent via le site Web ou l’application mobile Electrify Canada.
    </p>
  </article>
</template>
<script>
import lang from '@/lang';

export default {
  name: 'eTronTermsFr',
  metaInfo: {
    title: 'e-tron Termes',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/fr/e-tron-termes/',
      },
    ],
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
};
</script>
