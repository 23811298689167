<template>
  <article>
    <Hero :heading="$t('successfulUnsubscribe')" :subheading="true">
      <template #subheading>
        <p>{{ $t('unsubscribedMsg') }}</p>
        <br />
        <p>
          {{ $t('unsubscribedError') }}
          <router-link class="link" :to="{ name: `sign-up-${getLang}` }">{{ $t('subscribe') }}</router-link>
        </p>
      </template>
    </Hero>
  </article>
</template>

<script>
import lang from '@/lang/Forms';
import Hero from '@/components/Hero/Hero.vue';

export default {
  name: 'unsubscribe',
  metaInfo: {
    title: this.$i18n.t('meta.unsubscribe.title'),
    meta: [
      { name: 'robots', content: 'noindex' },
      {
        name: 'description',
        content: this.$i18n.t('meta.unsubscribe.description'),
      },
    ],
  },
  components: { Hero },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
  computed: {
    getLang() {
      return this.$store.state.lang;
    },
  },
};
</script>
