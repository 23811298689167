<template>
  <article class="terms">
    <h1 class="field__item">Nouvelles et mises à jour</h1>
    <div
      class="clearfix text-formatted field field--name-field-ea-subtitle field--type-text-long field--label-hidden field__item"
    >
      <p>Redirecting to media site....</p>
    </div>
  </article>
</template>

<script>
export default {
  name: 'news-updates-fr',
  metaInfo: {
    title: 'Nouvelles et mises à jour',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/fr/nouvelles/',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/news-updates/',
        hreflang: 'en-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/fr/nouvelles/',
        hreflang: 'fr-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/news-updates/',
        hreflang: 'x-default',
      },
    ],
    meta: [
      {
        name: 'description',
        content:
          'Dépasser les limites de ce que les véhicules électriques peuvent faire. Obtenez les dernières mises à jour à propos du réseau public de recharge des véhicules sans émissions (ZEV) d’Electrify Canada.',
      },
    ],
  },

  created() {
    if (!window.PRERENDER_INJECTED) {
      window.location = 'https://media.electrify-canada.ca/fr-ca/';
    }
  },
};
</script>

<style scoped lang="scss">
.field--name-field-ea-subtitle {
  max-width: 1160px;
  margin: 0 auto;
  font-size: 16px;
}
article {
  overflow: hidden;
  position: relative;
  min-height: 650px;
}
</style>
