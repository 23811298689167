<template>
  <article class="single-col-page">
    <div class="l-one__col l-one__col--wide">
      <h1>{{ $t('contactUs') }}</h1>
      <p>{{ $t('lookingForAnswers') }}</p>
      <p>{{ $t('details') }}</p>
      <p>{{ $t('callUs') }}</p>
      <form id="contact-form" @submit.prevent ref="form" :aria-label="$t('contactUs')">
        <label ref="firstname" for="first_name"
          >{{ $t('firstName') }}<span class="require-mark">*</span>
          <input
            id="first_name"
            maxlength="39"
            name="first_name"
            pattern="^[a-zA-Z0-9]+(?:[a-zA-Z0-9-])+(?:[a-zA-Z0-9])$"
            type="text"
            required
            v-model="firstName"
          />
        </label>
        <label ref="lastname" for="last_name"
          >{{ $t('lastName') }}<span class="require-mark">*</span>
          <input
            id="last_name"
            maxlength="39"
            name="last_name"
            type="text"
            pattern="^[A-Za-z0-9$&+,:;=?@#|'<>.^*()%!-_]+(?: +[A-Za-z0-9$&+,:;=?@#|'<>.^*()%!-_]+)*$"
            title="This field only accepts letters and special characters (no trailing spaces)"
            required
            v-model="lastName"
          />
        </label>
        <label ref="email" for="email"
          >{{ $t('email') }}<span class="require-mark">*</span>
          <input
            id="email"
            maxlength="80"
            name="email"
            type="text"
            title="Please enter the correct email address"
            required
            v-model="email"
          />
        </label>
        <label for="phone"
          >{{ $t('phone') }}
          <input
            id="phone"
            maxlength="10"
            name="phone"
            type="text"
            pattern="^\d+$"
            title="This field only accepts numbers"
            v-model="phone"
          />
        </label>
        <label for="comments"
          >{{ $t('howWeCanHelp') }}
          <textarea maxlength="200" id="comments" name="comments" v-model="comments"></textarea>
        </label>
        <label for="topics"
          >{{ $t('topics') }}<span class="require-mark">*</span>
          <select id="topics" name="topics" title="Topics" multiple v-model="topics" required class="topics">
            <option v-for="(item, index) in topicList" :value="item" :key="index">{{ item }}</option>
          </select>
        </label>
        <div>
          <label for="contactme" class="checkbox">
            <input id="contactme" name="contactme" type="checkbox" value="1" v-model="contactMe" />
            {{ $t('contactMe') }}
          </label>
        </div>
        <div>
          <label for="future-comm" class="checkbox">
            <input id="future-comm" name="future-comm" type="checkbox" value="1" v-model="optMeinFutureComm" />
            {{ $t('futureComm') }}
          </label>
        </div>
        <input
          ref="submitButton"
          class="button button--submit"
          name="Sign up"
          type="submit"
          :value="$t('submit')"
          @click="validate"
        />
        <p v-show="serverError !== ''" class="server-error">
          {{ $t('error') }}
          <br />
          {{ serverError }}
        </p>
      </form>
    </div>
  </article>
</template>

<script>
import lang from '@/lang/Forms';
import ApiService from '@/services/Api';
import errorMessages from '@/constants/errorMessages';

export default {
  name: 'contactus',
  metaInfo() {
    return {
      title: this.$i18n.t('meta.contactUs.title'),
      link: [
        {
          rel: 'canonical',
          href:
            this.$i18n.locale === 'en'
              ? 'https://www.electrify-canada.ca/contact-us/'
              : 'https://www.electrify-canada.ca/fr/pour-nous-joindre/',
        },
        {
          rel: 'alternate',
          href: 'https://www.electrify-canada.ca/contact-us/',
          hreflang: 'en-ca',
        },
        {
          rel: 'alternate',
          href: 'https://www.electrify-canada.ca/fr/pour-nous-joindre/',
          hreflang: 'fr-ca',
        },
        {
          rel: 'alternate',
          href: 'https://www.electrify-canada.ca/contact-us/',
          hreflang: 'x-default',
        },
      ],
      meta: [
        {
          name: 'description',
          content: this.$i18n.t('meta.contactUs.description'),
        },
      ],
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'Organization',
            name: 'Electrify Canada',
            url: 'https://www.electrify-canada.ca/',
            sameAs: [
              'https://twitter.com/CanadaElectrify',
              'https://www.facebook.com/Electrify-Canada-677205735992875/',
              'https://www.instagram.com/electrifycanada/',
              'https://www.linkedin.com/company/electrify-canada-ca-eng/about/',
            ],
            logo: 'https://twitter.com/CanadaElectrify/photo',
            parentOrganization: {
              '@type': 'Organization',
              name: 'Electrify Canada',
              url: 'https://www.electrify-canada.ca/',
            },
            contactPoint: [
              {
                '@type': 'ContactPoint',
                telephone: '+1-833-231-3226',
                contactType: 'customer support',
                contactOption: 'TollFree',
                areaServed: 'CA',
              },
            ],
          },
        },
      ],
    };
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      comments: null,
      contactMe: null,
      topics: [],
      optMeinFutureComm: null,
      serverError: '',
    };
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
  computed: {
    topicList() {
      return this.$i18n.t('contactTopicList');
    },
  },
  watch: {
    serverError(newValue) {
      this.resetLableColor();
      if (newValue !== '') {
        if (newValue.includes('Email')) {
          this.$refs.email.style.color = 'red';
          this.$refs.email.scrollIntoView();
        } else if (newValue.includes('Duplicate') || newValue.includes('duplicate')) {
          this.$refs.firstname.style.color = 'red';
          this.$refs.lastname.style.color = 'red';
          this.$refs.firstname.scrollIntoView();
        }
      }
    },
  },
  methods: {
    resetLableColor() {
      this.$refs.email.style.color = '#8089a8';
      this.$refs.firstname.style.color = '#8089a8';
      this.$refs.lastname.style.color = '#8089a8';
    },
    async validate() {
      if (!this.firstName || !this.lastName || !this.email || !this.topics.length) {
        this.$refs.form.scrollIntoView();
        return;
      }
      this.$refs.submitButton.disabled = true;
      this.serverError = '';
      try {
        const params = {
          site: 'ELCA',
          recaptchaToken: await this.recaptcha(),
          body: {
            SuppliedFirstName: this.firstName,
            SuppliedLastName: this.lastName,
            SuppliedEmail: this.email,
            SuppliedPhone: this.phone,
            Customercomments: this.comments,
            ContactMewithaResponse: this.contactMe,
            Topic: this.topics.join(';'),
            OptMeinFutureComm: this.optMeinFutureComm,
            OptMeinFutureResearch: false,
            Origin: this.$i18n.locale === 'en' ? 'Contact Us Web Form (EN)' : 'Contact Us Web Form (FR)',
          },
        };
        this.contactusPut(params);
      } catch (error) {
        console.error(error);
        this.$refs.submitButton.disabled = false;
      }
    },
    async recaptcha() {
      try {
        // Wait for recaptcha loading
        await this.$recaptchaLoaded();
        // Execute reCAPTCHA with action "login" and return the token
        return await this.$recaptcha('login');
      } catch (e) {
        throw new Error('recaptcha login failed');
      }
    },
    contactusPut(params) {
      const loader = this.$loading.show();
      this.$store.commit('setEmail', params.body.SuppliedEmail);
      ApiService.init();
      ApiService.put('/v1/salesforce/contactus', params)
        .then((res) => {
          if (res.status === 'success') {
            this.$router.push({ name: `verify-email-contact-us-${this.$i18n.locale}` });
          } else {
            this.serverError = res.errorMsg;
          }
          loader.hide();
          this.$refs.submitButton.disabled = false;
        })
        .catch((error) => {
          // Commenting out surfacing Salesforce errors to end users until we've made messaging more robust
          /*
          if (error.response.status === 500) {
            this.serverError = errorMessages[this.$store.state.lang].default;
          } else {
            this.serverError = error.response.data.errMsg;
          }
          */
          this.serverError = errorMessages[this.$store.state.lang].default;

          this.$refs.submitButton.disabled = false;
          loader.hide();
          console.error(error);
        });
    },
  },
  mounted() {
    this.$recaptchaLoaded().then(() => {
      this.$recaptchaInstance.showBadge();
    });
  },
  beforeDestroy() {
    this.$recaptchaInstance.hideBadge();
  },
};
</script>

<style scoped lang="scss">
.topics {
  background-image: none;
}
</style>
