<template>
  <div class="what-to-expect">
    <Hero heading="La recharge avec Electrify Canada">
      <template slot="image">
        <picture>
          <source
            srcset="
              @/assets/images/WhatToExpect/hero-desktop.webp,
              @/assets/images/WhatToExpect/hero-desktop@2x.webp 2x
            "
            media="(min-width: 750px)"
          />
          <source
            srcset="@/assets/images/WhatToExpect/hero-mobile.webp, @/assets/images/WhatToExpect/hero-mobile@2x.webp 2x"
          />
          <img src="@/assets/images/WhatToExpect/hero-mobile.webp" alt="" />
        </picture>
      </template>
    </Hero>

    <TwoColumnContentBlock heading="L’avenir de la recharge" headlineDisplayClass="headline3" :maxWidth="1000">
      <p>
        <em
          >Explorez le réseau qui offre certaines des vitesses de recharge les plus rapides actuellement sur le marché,
          Ultrarapide, à 150 kW, et Hyper rapide, à 350 kW,</em
        >
        capable de recharger les véhicules compatibles en aussi peu que 30 minutes!
        <ReferenceLink num="1"
          >Les temps de recharge estimés ne sont fournis qu’à titre de référence. Les temps de recharge réels varient en
          fonction de nombreux facteurs, y compris, sans s’y limiter, l’état de charge de la batterie, le comportement
          au volant, l’état de la batterie, le courant, la résistance, la température ambiante, la tension disponible et
          la fréquence de recharge.</ReferenceLink
        >.
      </p>
      <router-link class="button-link" :to="{ name: 'getting-started-fr' }"
        >APPRENEZ LES PRINCIPES DE <br v-if="isTiny" />
        BASE DE LA RECHARGE D’UN <br v-if="isTiny" />
        VÉHICULE ÉLECTRIQUE<span class="arrow"></span
      ></router-link>
    </TwoColumnContentBlock>

    <section class="feature">
      <picture>
        <source
          srcset="@/assets/images/WhatToExpect/charger-speeds-desktop-fr.webp, @/assets/images/WhatToExpect/charger-speeds-desktop-fr@2x.webp @2x"
          media="(min-width: 750px)"
        />
        <source
          srcset="
            @/assets/images/WhatToExpect/charger-speeds-mobile-fr.webp,
            @/assets/images/WhatToExpect/charger-speeds-mobile-fr@2x.webp 2x
          "
        />
        <img
          loading="lazy"
          src="@/assets/images/WhatToExpect/charger-speeds-mobile-fr.webp"
          alt="Étiquettes sur les bornes de recharge Electrify Canada"
        />
      </picture>

      <Card headlineLevel="h2" heading="Étiquettes de la borne" :isReverse="isPhone" :maxWidth="1000">
        <template #image>
          <picture>
            <source srcset="@/assets/images/WhatToExpect/labels-3-d-desktop-fr.webp" media="(min-width: 750px)" />
            <source
              srcset="
                @/assets/images/WhatToExpect/labels-3-d-mobile-fr.webp,
                @/assets/images/WhatToExpect/labels-3-d-mobile-fr@2x.webp 2x
              "
            />
            <img loading="lazy" src="@/assets/images/WhatToExpect/labels-3-d-mobile-fr.webp" alt="" />
          </picture>
        </template>
        <p>Nous avons récemment conçu de nouvelles étiquettes de bornes avec l’aide de conducteurs comme vous!</p>
        <p>
          Dans le haut de chaque étiquette, vous trouverez des icônes de connecteur qui correspondent au port de
          recharge de votre véhicule. Dans le bas, des icônes représentant un éclair indiquent la puissance maximale de
          la borne de recharge : plus la valeur en kilowatt (kW) est élevée, plus la puissance de recharge est élevée.
        </p>
        <p>Nos bornes de recharge offrent trois types de connecteurs :</p>
        <ul>
          <li>
            Les étiquettes vertes <strong>hyper rapide</strong> indiquent les connecteurs CCS qui fournissent jusqu’à
            350 kW.
          </li>
          <li>
            Les étiquettes turquoise <strong>ultrarapide</strong> indiquent les connecteurs CCS qui fournissent jusqu’à
            150 kW.
          </li>
          <li>
            Les étiquettes bleues <strong>CHAdeMO</strong> indiquent les connecteurs CHAdeMO qui fournissent jusqu’à 50
            kW.
          </li>
        </ul>
        <div class="tip">
          <StarIcon />
          <p>
            <strong>Conseil :</strong> Ce ne sont pas tous les véhicules électriques qui prennent en charge le niveau de
            puissance maximal d’un connecteur. Par exemple, une étiquette hyper rapide signifie que la borne de recharge
            offre jusqu’à 350 kW pour un VE compatible avec les connecteurs CCS. Si votre véhicule ne peut pas recevoir
            une charge maximale de 350 kW, alors la borne de recharge lui fournit automatiquement la puissance la plus
            élevée qu’il peut prendre en charge. Pour connaître les capacités de recharge de votre véhicule, consultez.
          </p>
        </div>
      </Card>
    </section>

    <section class="balance">
      <Card headlineLevel="h2" heading="Alimentation symétrique" :isReverse="true" :maxWidth="1000">
        <template #image>
          <img
            loading="lazy"
            class="image"
            v-if="isPhone"
            src="@/assets/images/WhatToExpect/image-balanced.png"
            alt=""
          />
          <img
            loading="lazy"
            class="image"
            v-else
            src="@/assets/images/WhatToExpect/image-balanced-desktop.png"
            alt=""
          />
        </template>
        <img
          loading="lazy"
          style="order: -1"
          class="bolt"
          src="../../assets/images/WhatToExpect/image-bolts.png"
          alt=""
        />
        <p>
          Aux bornes Electrify Canada, vous pouvez voir la mention « Alimentation symétrique » sur les étiquettes ou sur
          l’écran d’une borne de recharge. Les bornes à alimentation symétrique sont généralement situées côte à côte et
          partagent une baie d’alimentation. Une borne à alimentation symétrique peut fournir des vitesses ultrarapides
          allant jusqu’à 150 kW aux véhicules capables de prendre en charge cette puissance, et peut fournir des
          vitesses hyper rapides allant jusqu’à 350 kW aux véhicules compatibles lorsque la borne adjacente n’est pas
          utilisée.
        </p>
        <strong>Les bornes à alimentation symétrique comprennent l’étiquette suivante:</strong>
        <img
          loading="lazy"
          class="hyper-fast"
          src="@/assets/images/WhatToExpect/power-sharing-fr.png"
          alt="Étiquette « Alimentation symétrique » pour borne de recharge publique "
        />
      </Card>
    </section>

    <HeroWithContentOverlay :overlayBG="false" :darkBG="false" fullWidthMobile>
      <template #image>
        <picture>
          <source
            srcset="
              @/assets/images/WhatToExpect/charging-station-hyper-fast-desktop.webp,
              @/assets/images/WhatToExpect/charging-station-hyper-fast-desktop@2x.webp 2x
            "
            media="(min-width: 750px)"
          />
          <img loading="lazy" src="@/assets/images/WhatToExpect/charging-station-hyper-fast-mobile.webp" alt="" />
        </picture>
      </template>

      <router-link class="button-link" :to="{ name: 'charging-your-ev-fr' }">
        COMMENT<span class="sr-only">recharger son véhicule aux bornes Electrify Canada</span
        ><arrow class="arrow"></arrow>
      </router-link>
      <h3>Comment recharger son véhicule aux bornes Electrify Canada</h3>
      <p>Commencez par effectuer une recharge à l’une de nos bornes ou au moyen de notre application.</p>
    </HeroWithContentOverlay>
  </div>
</template>

<script>
import lang from '@/lang';
import StarIcon from '@/assets/images/Mobile-App/StarIcon.svg';
import ReferenceLink from '@/components/ReferenceLink.vue';
import Card from '@/components/Card/Card.vue';
import Hero from '@/components/Hero/Hero.vue';
import HeroWithContentOverlay from '@/components/HeroWithContentOverlay/HeroWithContentOverlay.vue';
import TwoColumnContentBlock from '@/components/TwoColumnContentBlock/TwoColumnContentBlock.vue';

export default {
  name: 'what-to-expect',
  metaInfo: {
    title: 'Charger votre VE avec nous',
    link: [
      {
        rel: 'canonical',
        href: "https://www.electrify-canada.ca/fr/à-quoi-s'attendre/",
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/what-to-expect/',
        hreflang: 'en-ca',
      },
      {
        rel: 'alternate',
        href: "https://www.electrify-canada.ca/fr/à-quoi-s'attendre/",
        hreflang: 'fr-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/what-to-expect/',
        hreflang: 'x-default',
      },
    ],
    meta: [
      {
        name: 'description',
        content:
          'Presque tous les véhicules électriques (VE) sur la route peuvent se recharger sur le réseau public d’Electrify Canada. Nos options de paiement flexibles facilitent le paiement de la recharge.',
      },
    ],
  },

  components: {
    StarIcon,
    ReferenceLink,
    Card,
    Hero,
    HeroWithContentOverlay,
    TwoColumnContentBlock,
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
  data() {
    return {
      num: null,
      body: null,
    };
  },
  computed: {
    isPhone() {
      return this.$resize && this.$mq.below(749);
    },
    isTiny() {
      return this.$resize && this.$mq.below(500);
    },
  },
};
</script>
