import Vue from 'vue';
import Vuex from 'vuex';
import locations from './modules/locations';
import map from './modules/map';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lang: 'en',
    email: null,
    currentRefID: null,
  },
  mutations: {
    setLang(state, lang) {
      if (state.lang !== lang) {
        state.lang = lang;
        if (this.$app) {
          this.$app.$i18n.locale = lang;
        }
      }
    },
    setEmail(state, email) {
      state.email = email;
    },
    setCurrentRefID(state, payload) {
      state.currentRefID = payload;
    },
  },
  actions: {},
  modules: { locations, map },
});
