<template>
  <article class="about-us">
    <Hero
      heading="À propos d'Electrify Canada"
      subheading="Electrify Canada, Inc., a été créée en juillet 2018 et est basée à Ajax, Ontario, Canada.s"
    >
      <template #image
        ><picture>
          <source media="(min-width: 750px)" :srcset="require(`@/assets/images/AboutUs/about_us_hero.webp`)" />
          <img alt="" :src="require('@/assets/images/AboutUs/about_us_hero_mobile.webp')" />
        </picture>
      </template>
    </Hero>
    <div class="l-one--has-bg l-one--padding-bottom">
      <div class="l-one__col">
        <h2 class="headline4">Notre mission</h2>
        <p>
          L'objectif d'Electrify Canada est de promouvoir une plus grande adoption des véhicules à émissions nulles
          (Zero Emission Vehicle, ZEV) en offrant un réseau de charge ultra-rapide et pratique. Ce réseau alimentera les
          véhicules électriques actuels ainsi que la croissance rapide des modèles de véhicules électriques attendue de
          nombreux constructeurs automobiles.
        </p>
        <h2 class="headline4">Qui sommes-nous</h2>
        <p>
          Electrify Canada, Inc., construit une infrastructure transformatrice de recharge ultrarapide en courant
          continu (CC) pour véhicules électriques (VE) afin de donner aux Canadiens la vitesse et la fiabilité dont ils
          ont besoin pour passer sans crainte à l’électrique.
        </p>
      </div>
    </div>
  </article>
</template>

<script>
import Hero from '../../components/Hero/Hero.vue';

export default {
  name: 'about-us-fr',
  components: { Hero },
  metaInfo: {
    title: 'À propos d’Electrify Canada',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/fr/a-propos/',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/about-us/',
        hreflang: 'en-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/fr/a-propos/',
        hreflang: 'fr-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/about-us/',
        hreflang: 'x-default',
      },
    ],
    meta: [
      {
        name: 'description',
        content:
          'L’objectif d’Electrify Canada est de promouvoir une plus grande adoption des véhicules à émissions nulles (Zero Emission Vehicle, ZEV) en offrant un réseau de recharge ultrarapide et pratique. À propos de nous.',
      },
    ],
  },
};
</script>
