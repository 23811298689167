<template>
  <div
    :class="[
      'hero-with-overlay',
      { 'hero-with-overlay--no-overlay': mobileResponsive !== 'overlay' },
      { 'hero-with-overlay--above': mobileResponsive === 'above' },
      { 'hero-with-overlay--below': mobileResponsive === 'below' },
    ]"
    :style="{ 'max-width': maxWidth + 'px' }"
  >
    <div v-if="overlayBG && mobileResponsive === 'overlay'" class="hero-with-overlay__background"></div>
    <div
      :style="{ 'max-width': maxContentWidth + 'px' }"
      :class="['hero-with-overlay__content', { 'hero-with-overlay__content--lightBG': !darkBG }]"
    >
      <slot></slot>
    </div>
    <div
      :class="[
        'hero-with-overlay__image-container',
        { 'hero-with-overlay__image-container--full-width': fullWidthMobile },
      ]"
    >
      <slot name="image"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeroWithContentOverlay',
  props: {
    darkBG: { default: true, type: Boolean, required: false },
    overlayBG: { default: true, type: Boolean, required: false },
    maxWidth: { default: 1200, type: Number, required: false },
    maxContentWidth: { required: false },
    mobileResponsive: { default: 'overlay', type: String, required: false },
    fullWidthMobile: { default: false, type: Boolean, required: false },
  },
};
</script>

<style lang="scss" scoped>
.hero-with-overlay {
  margin: 0 auto;
  display: flex;
  position: relative;
  width: 100%;
  top: -1px;

  .button-link {
    ::v-deep .arrow {
      background: $c-primary-background;
    }
    &:hover {
      color: $c-highlights;
      ::v-deep .arrow {
        background: $c-highlights;
      }
    }
  }

  // styles for if the content is not overlain on the image on mobile
  &--no-overlay {
    flex-direction: column;

    .hero-with-overlay__image-container {
      padding: 0 24px;
      margin-bottom: 20px;
      @media (min-width: 750px) {
        padding: 0;
      }
    }

    .button-link {
      ::v-deep .arrow {
        background: $c-primary;
      }
      &:hover {
        color: $c-focus;
        ::v-deep .arrow {
          background: $c-focus;
        }
      }
    }
  }

  &--above,
  &--below {
    @media (max-width: 750px) {
      .hero-with-overlay__content {
        top: 0;
        transform: none;
        position: relative;
        color: $c-primary;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        a,
        .button-link,
        p {
          color: $c-primary;
          text-shadow: none;
        }
      }
    }
  }

  &--above {
    @media (max-width: 750px) {
      //hero content above image on mobile
      .hero-with-overlay__content {
        order: 2;
      }
      .hero-with-overlay__image-container {
        order: 3;
      }
    }
  }

  &--below {
    //hero content below image on mobile
    @media (max-width: 750px) {
      .hero-with-overlay__content {
        order: 3;
      }
      .hero-with-overlay__image-container {
        order: 2;
      }
    }
  }

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6) 37%,
      rgba(0, 0, 0, 1) 72%,
      rgba(0, 0, 0, 1) 100%
    );
    top: 0;
    opacity: 0.8;
    border-radius: 10px;
    @media only screen and (max-width: 750px) {
      border-radius: 0;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.82) 40%, #000);
    }
  }

  &__content {
    color: $c-primary-background;
    font-size: 24px;
    line-height: 30px;
    position: absolute;
    padding: 0 24px;
    width: 90%;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);

    @media (min-width: 750px) {
      max-width: 450px;
      left: 40px;
    }
    @media (min-width: 1100px) {
      left: 75px;
    }

    :last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    a,
    p {
      color: $c-primary-background;
      text-shadow: 2px 2px 48px rgba(10, 18, 74, 0.5);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      font-weight: 400;
    }

    p {
      font-size: 18px;
      letter-spacing: 0.9px;
      font-weight: 300;
      line-height: 24px;
    }

    // styles for if the background if light make the font color dark
    &--lightBG {
      color: $c-primary;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      a,
      p {
        color: $c-primary;
        text-shadow: none;
      }

      .button-link {
        ::v-deep .arrow {
          background: $c-primary;
        }
        &:hover {
          color: $c-focus;
          ::v-deep .arrow {
            background: $c-focus;
          }
        }
      }
    }
  }

  &__image-container {
    max-width: 100%;

    @media (min-width: 750px) {
      max-width: none;
      width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }

    // styles for if image should be the full width of the screen on mobile
    &--full-width {
      width: 100%;
    }
  }
}
</style>
