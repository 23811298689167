export default {
  en: {
    error404: 'Error Code: 404',
    pageUnavailable: 'This page is unavailable',
    useSearchBar: 'Use the search bar to recharge your session.',
    search: 'Search',
  },
  fr: {
    error404: 'code d’erreur : 404',
    pageUnavailable: "Cette page n'est pas accessible",
    useSearchBar: 'Utilisez la barre de recherche pour recharger votre session.',
    search: 'Recherche',
  },
};
