<template>
  <article class="disclosure">
    <h1>Règles internes concernant les réseaux sociaux</h1>
    <p>
      Bienvenue à Electrify Canada! Les commentaires affichés par les utilisateurs sur ce site ou sur toute autre page
      de réseau social parrainée ou administrée par Electrify Canada, Inc., (« Contenu utilisateur ») ne reflètent pas
      nécessairement les opinions ou les idéaux d’Electrify Canada, de ses employés ou de ses affiliés. Nous vous
      encourageons à laisser des commentaires ; cependant, nous nous réservons le droit de supprimer à notre discrétion
      tout « Contenu utilisateur » que nous jugeons inapproprié ou offensant. Electrify Canada n’est responsable d’aucun
      contenu utilisateur et ne souscrit à aucune opinion exprimée par les utilisateurs.
    </p>
    <p>
      Vous trouverez ci-dessous les règles de communication et de comportement général à respecter sur les pages des
      réseaux sociaux et les canaux en ligne commandités ou administrés par Electrify Canada :
    </p>
    <ol>
      <li>
        vous vous conformerez entièrement aux
        <router-link class="link" :to="{ name: 'terms-fr' }">conditions d’utilisation d’Electrify Canada</router-link> ;
      </li>
      <li>vous ne publierez pas de messages indécents ou autrement inappropriés ;</li>
      <li>vous ne publierez aucun message diffamatoire, intimidant, harcelant, menaçant ou abusif ;</li>
      <li>
        vous avez l’âge légal de la majorité ou plus, à moins que les publications soient effectuées par un parent ou un
        tuteur légal ou avec son autorisation ;
      </li>
      <li>
        vous ne porterez pas atteinte à la vie privée ou aux droits de publicité d’une autre personne, et vous ne
        distribuerez pas vos renseignements personnels ou ceux de quiconque ;
      </li>
      <li>
        tout « Contenu utilisateur » que vous fournissez :
        <ul>
          <li>est votre propre œuvre originale ;</li>
          <li>ne contient ni ne fait référence à aucune marque de commerce de tiers ;</li>
          <li>ne porte pas atteinte au droit d’auteur ou à tout autre droit d’un tiers ;</li>
          <li>ne contient aucun contenu ou matériel illégal ou autrement inapproprié ; et</li>
          <li>
            vous avez la permission de toutes les personnes (et de leurs parents ou tuteurs légaux s’ils sont mineurs)
            indiquées dans le « Contenu utilisateur » pour afficher leur image et/ou leur ressemblance.
          </li>
        </ul>
      </li>
      <li>
        vous reconnaissez que tout « Contenu utilisateur » que vous soumettez apparaîtra visible par les visiteurs de
        notre ou nos pages et pourra apparaître sur les autres sites Web d’Electrify Canada ou sur ses pages de réseaux
        sociaux ;
      </li>
      <li>
        vous accordez à Electrify Canada une licence perpétuelle non exclusive, entièrement payée et exempte de
        redevances pour l’utilisation et l’affichage de tout « Contenu utilisateur » que vous soumettez dans le monde
        entier sur tout type de support électronique ou numérique à des fins commerciales, de marketing et/ou de
        relations publiques.
      </li>
    </ol>

    <p>
      À retenir : Nous nous réservons le droit, à notre discrétion, de supprimer tout « Contenu utilisateur » jugé en
      violation de ces règles.
    </p>

    <p>
      Pour de plus amples renseignements ou pour communiquer avec le service à la clientèle, veuillez communiquer avec
      notre Centre de service à la clientèle au 1 833 231-3226 ou par courriel à
      <a href="mailto:support@electrify-canada.ca" class="link"> support@electrify-canada.ca.</a>
    </p>
  </article>
</template>

<script>
export default {
  name: 'social-media-house-rules',
  metaInfo: {
    title: 'Social Media House Rules',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/fr/regles-internes-concernant-les-reseaux-sociaux/',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/social-media-house-rules/',
        hreflang: 'en-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/fr/regles-internes-concernant-les-reseaux-sociaux/',
        hreflang: 'fr-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/social-media-house-rules/',
        hreflang: 'x-default',
      },
    ],
    meta: [
      {
        name: 'description',
        content:
          'Electrify America encourages you to leave comments on our websites and social media. Learn how we engage with your comments by reading our Social Media House Rules.',
      },
    ],
  },
};
</script>
