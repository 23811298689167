<template>
  <div class="home">
    <!-- Use of this pixel is subject to the Amazon ad specs and policies at http://www.amazon.com/b/?&node=7253015011 -->
    <img
      height="1"
      width="1"
      border="0"
      alt
      src="https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3D1ba54650-bb4f-9741-9fdf-697694b30237%26type%3DUNKNOWN%26m%3D1&ex-fch=416613&ex-src=https://www.electrifyamerica.com/index&ex-hargs=v%3D1.0%3Bc%3D6544328470501%3Bp%3D1BA54650-BB4F-9741-9FDF-697694B30237"
    />

    <div :class="{ 'wrapper wrapper--xx-large-min': !isPhone }">
      <HeroWithContentOverlay :max-width="1920" full-width-mobile>
        <template #image>
          <picture>
            <source :srcset="require('../assets/images/Home/homepage_hero_image.webp')" :media="`(min-width: 750px)`" />
            <img src="../assets/images/Home/HP_hero_mobile.webp" alt="" />
          </picture>
        </template>
        <div>
          <h1>Find charging <br /><i>fast</i></h1>
          <p>
            Explore the network that pioneered the availability of Ultra-Fast (150 kW) and Hyper-Fast (350 kW) chargers.
          </p>
        </div>
        <form class="search-container" role="search" @submit.prevent="goToLocate()">
          <input v-model="searchParam" class="search-input" placeholder="ENTER CITY OR POSTAL CODE" type="search" />
          <button aria-label="submit" class="green-circle" type="submit" value=""></button>
        </form>
      </HeroWithContentOverlay>
    </div>

    <div class="home__container">
      <section :class="['wrapper wrapper--extra-large-min', { 'wrapper--no-side-padding': !isPhone }]">
        <h2>
          Learn about charging with <br v-if="!isPhone" />
          Electrify Canada
        </h2>
        <div class="l-three-col l-three-col--tablet-two">
          <HomepageCard
            path="pricing-en"
            label="Become a member"
            :src="
              isPhone
                ? require('../assets/images/Home/member-mobile.webp')
                : require('../assets/images/Home/member.webp')
            "
            alt=""
            dataText="Women paying with mobile tile"
          >
            <p>Need to fill up on the road? Enjoy discounted pricing with our Pass+ monthly subscription plan.</p>
          </HomepageCard>

          <HomepageCard
            path="mobile-app-en"
            label="Mobile App"
            :src="
              isPhone
                ? require('../assets/images/Home/home_image_2-mobile.png')
                : require('../assets/images/Home/home_image_2.png')
            "
            alt=""
            dataText="Mobile app tile"
          >
            <p>
              Find stations, start charging, track and end your session from your smartphone with our app for iOS and
              Android. <reference-link :num="1">Data rates may apply.</reference-link>
            </p>
          </HomepageCard>

          <HomepageCard
            v-if="winterCharging"
            path="faqs-en"
            :pathQuery="{ loadOuter: 1, loadInner: 14 }"
            label="Cold Weather Charging Tips"
            :src="require('../assets/images/Home/winter-charging.png')"
            alt="5 tips to stay charged this winter. Be prepared for charging this winter. Learn more"
            dataText="Cold weather charging tile"
          >
            <p>As temperatures begin to drop, here are some tips to consider when planning a winter road trip</p>
          </HomepageCard>

          <HomepageCard
            path="contact-us-en"
            label="Support & Troubleshooting"
            :src="
              isPhone
                ? require('../assets/images/Home/home_image_4-mobile.webp')
                : require('../assets/images/Home/home_image_4.webp')
            "
            alt=""
            dataText="Support and troubleshooting tile"
          >
            <p>
              Have a question or issue with a charging station? Our team is here to help. Call us at 1-833-231-3226 or
              use our contact form.
            </p>
          </HomepageCard>

          <HomepageCard label="Contactless In-app Payment">
            <p>Download the Electrify Canada mobile app to pay for your charging session right from your phone.</p>
            <a
              href="https://apps.apple.com/ca/app/electrify-canada/id1472391910"
              rel="noopener"
              target="_blank"
              data-text="Download the Electrify Canada mobile app tile"
            >
              <img
                loading="lazy"
                :alt="$t('downloadInTheAppStore')"
                class="apple"
                :src="require('@/assets/images/Global/app-store-logo-en.png')"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.ec.evowner&hl=en_CA"
              rel="noopener"
              target="_blank"
            >
              <img
                class="google-play"
                loading="lazy"
                :alt="$t('getItOnGooglePlay')"
                :src="require('@/assets/images/Global/google-play-badge-en.png')"
              />
            </a>
          </HomepageCard>
        </div>
      </section>

      <HeroWithContentOverlay
        :maxWidth="1920"
        :mobileResponsive="isPhone ? 'below' : 'overlay'"
        :maxContentWidth="!isPhone ? 644 : unset"
      >
        <template #image>
          <img
            loading="lazy"
            alt=""
            :src="
              isPhone
                ? require('../assets/images/Home/homepage_about_us_mobile.webp')
                : require('../assets/images/Home/homepage_about-us.webp')
            "
          />
        </template>
        <router-link class="button-link" :to="{ name: 'about-us-en' }">
          About Us
          <span class="arrow" />
        </router-link>
        <h2>We're leading the charge of fast charging.</h2>
        <p>
          We’re building out a convenient, reliable, customer-centric network of electric vehicle chargers near major
          highways and in metropolitan areas.
        </p>
      </HeroWithContentOverlay>
    </div>
  </div>
</template>

<script>
import lang from '@/lang';
import ReferenceLink from '../components/ReferenceLink.vue';
import HeroWithContentOverlay from '../components/HeroWithContentOverlay/HeroWithContentOverlay.vue';
import HomepageCard from '../components/HomepageCard/HomepageCard.vue';
import timestamp from '../helpers/timestamp';

export default {
  name: 'home',
  metaInfo: {
    title: 'Electrify Canada: EV public charging network',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/',
        hreflang: 'en-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/fr/',
        hreflang: 'fr-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/',
        hreflang: 'x-default',
      },
    ],
    meta: [
      {
        name: 'description',
        content:
          "The next generation of electric vehicle (EV) charging. Electrify Canada's public network is designed to offer the most powerful DC fast charging stations.",
      },
    ],
    script: [
      {
        type: 'application/ld+json',
        json: {
          '@context': 'http://schema.org',
          '@type': 'Corporation',
          name: 'Electrify Canada',
          url: 'https://www.electrify-canada.ca/',
          sameAs: [
            'https://twitter.com/CanadaElectrify',
            'https://www.facebook.com/Electrify-Canada-677205735992875/',
            'https://www.instagram.com/electrifycanada/',
            'https://www.linkedin.com/company/electrify-canada-ca-eng/about/',
          ],
          logo: 'https://twitter.com/CanadaElectrify/photo',
          parentOrganization: {
            '@type': 'Corporation',
            name: 'Electrify Canada',
            url: 'https://www.electrify-canada.ca/',
          },
          contactPoint: [
            {
              '@type': 'ContactPoint',
              telephone: '+1-833-231-3226',
              contactType: 'customer support',
              contactOption: 'TollFree',
              areaServed: 'CA',
            },
          ],
        },
      },
    ],
  },

  components: {
    ReferenceLink,
    HeroWithContentOverlay,
    HomepageCard,
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
  data() {
    return {
      site: process.env.VUE_APP_SITE,
      searchParam: null,
      winterCharging: timestamp.get() <= 1708750799000,
    };
  },
  methods: {
    goTo(path, pathQuery) {
      if (pathQuery) {
        this.$router.push({ name: path, query: pathQuery });
      } else {
        this.$router.push({ name: path });
      }
    },
    goToLocate() {
      if (this.searchParam) {
        const zoom = window.innerWidth >= 1200 ? 5 : 4;

        this.$store.commit('map/resetFilters');
        this.$store.commit('map/setIsFilterPanelExpanded', false);
        this.$store.commit('map/setIsStationDetailExpanded', false);
        this.$store.commit('map/setIsStationListExpanded', false);
        this.$store.commit('locations/setUniqueLocation', null);
        this.$store.commit('locations/setUniqueRoute', null);
        this.$store.dispatch('map/updateMapZoomAndCenter', {
          zoom,
          center: { lat: 50, lng: -96.8 },
        });
        this.$router.push({ name: 'locate-charger-en', query: { search: this.searchParam } });
      } else {
        this.$router.push({ name: 'locate-charger-en' });
      }
    },
  },
  computed: {
    isIE11() {
      return navigator.userAgent.toUpperCase().indexOf('TRIDENT/') !== -1;
    },
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    isPhone() {
      return this.$resize && this.$mq.below(750);
    },
  },
};
</script>
