<template>
  <div>
    <Tabs v-model="selectedTab" />
    <TabContent show-banner :selected-tab="selectedTab" />
  </div>
</template>

<script>
import lang from '@/lang/ChargingYourEv';
import Tabs from '@/components/ChargingYourEv/ChargingYourEvTabs.vue';
import TabContent from '@/components/ChargingYourEv/ChargingYourEvTabContent.vue';

export default {
  name: 'ChargingYourEv',
  metaInfo() {
    return {
      title: this.$i18n.t('meta.title'),
      link: [
        {
          rel: 'canonical',
          href:
            this.$i18n.locale === 'en'
              ? 'https://www.electrify-canada.ca/charging-your-ev/'
              : 'https://www.electrify-canada.ca/fr/recharger-votre-ve/',
        },
      ],
      meta: [
        {
          name: 'description',
          content: this.$i18n.t('meta.description'),
        },
      ],
    };
  },
  i18n: {
    messages: lang,
    sharedMessages: {},
  },
  components: {
    Tabs,
    TabContent,
  },
  data() {
    return {
      selectedTab: 'AtTheCharger',
    };
  },
};
</script>
