<template>
  <article class="our-team">
    <Hero heading="Rencontrez l'équipe Electrify Canada" subheading="Apprenez à connaître notre peuple." />
    <div v-for="(array, index) in ourTeamArray" :key="array.name">
      <div :class="['l-one', { 'l-one--has-bg': index % 2 == 0 }]" data-aos="fade-up">
        <div class="l-one__col">
          <p>{{ array.position }}</p>
          <h2>{{ array.name }}</h2>
          <p>{{ array.description }}</p>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import Hero from '@/components/Hero/Hero.vue';

export default {
  name: 'our-team',
  components: { Hero },
  metaInfo: {
    title: 'Rencontrez l’équipe d’Electrify Canada',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/fr/notre-equipe/',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/our-team/',
        hreflang: 'en-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/fr/notre-equipe/',
        hreflang: 'fr-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/our-team/',
        hreflang: 'x-default',
      },
    ],
    meta: [
      {
        name: 'description',
        content:
          'Le leadership d’Electrify Canada fait en sorte de prendre au sérieux notre engagement à investir dans l’infrastructure de recharge publique des véhicules électriques. Apprenez à connaître notre équipe.',
      },
    ],
  },
  data() {
    return {
      ourTeamJson: require('@/assets/json/OurTeam.json'),
    };
  },
  computed: {
    ourTeamArray() {
      return this.ourTeamJson.language[0].fr;
    },
  },
};
</script>
