<template>
  <header data-swiftype-index="false" class="header" ref="headerElement" :class="{ tablet: isTablet }">
    <div class="main-list">
      <div class="container">
        <div class="left-nav">
          <router-link
            class="logo-container"
            :to="{ name: `home-${$i18n.locale}` }"
            aria-label="Electrify Canada Home page"
          >
            <logo style="width: auto" />
          </router-link>

          <!-- Nav overlay -->
          <div v-if="navDropdownIsOpen" @click="navDropdownIsOpen = !navDropdownIsOpen" class="pagemask" />

          <nav class="nav-container" role="navigation" aria-label="Header Primary Site Links">
            <ul class="nav" ref="nav" v-if="!searchActive">
              <template v-for="(navItem, index) of headerNavItems">
                <li
                  class="label"
                  :key="index"
                  :role="navItem.nestedLinks && navItem.nestedLinks.length > 0 ? 'heading' : null"
                  aria-level="2"
                  @keydown="handleMegamenuNavigation(navItem, $event)"
                >
                  <button
                    v-if="navItem.nestedLinks.length"
                    :class="{
                      'label-name': true,
                      'on-nested-page':
                        navItem.nestedLinks.map((link) => `${link.linkTo}`).includes($route.name) && !navDropdownIsOpen,
                      open: navItem.name === activeTopLevelNav && navDropdownIsOpen,
                    }"
                    @mousedown="toggleDropdown(navItem.name)"
                    @keydown.enter.prevent.stop="toggleDropdown(navItem.name)"
                    @focus="updateNavItemFocus(navItem)"
                    :aria-expanded="navItem.name === activeTopLevelNav && navDropdownIsOpen"
                    :id="`${navItem.name.toLowerCase().replace(' ', '-')}-trigger`"
                  >
                    <span>{{ navItem.name }}</span>
                    <span class="label-caret">
                      <HeaderCaret
                        :color="
                          (navItem.name === activeTopLevelNav && navDropdownIsOpen) ||
                          (navItem.nestedLinks.map((link) => `${link.linkTo}`).includes($route.name) &&
                            !navDropdownIsOpen)
                            ? '#35bdd1'
                            : '#fff'
                        "
                      />
                    </span>
                  </button>
                  <router-link
                    v-else
                    :to="{ name: `${navItem.linkTo}` }"
                    :target="navItem.target || null"
                    :event="navItem.links ? '' : 'click'"
                    :class="{
                      'label-name': true,
                      'active-page': !navDropdownIsOpen && $route.name === `${navItem.linkTo}`,
                    }"
                  >
                    {{ navItem.name }}
                  </router-link>

                  <template v-if="navItem.nestedLinks && navItem.nestedLinks.length > 0">
                    <div
                      role="region"
                      :aria-labelledby="`${navItem.name.toLowerCase().replace(' ', '-')}-trigger`"
                      :class="{
                        'nav-dropdown': true,
                        'nav-dropdown--show': navItem.name === activeTopLevelNav && navDropdownIsOpen,
                      }"
                    >
                      <div class="nav-dropdown__container">
                        <ul class="nav-dropdown__list">
                          <li
                            v-for="(link, index) in navItem.nestedLinks"
                            :key="index"
                            :class="{
                              'nav-dropdown__item': true,
                              'nav-dropdown__item--active': activeNavDropdownLinkIndex === index,
                              'nav-dropdown__item--full': navItem.nestedLinks.length < 2,
                            }"
                          >
                            <component
                              :is="link.isExternal ? 'a' : 'router-link'"
                              v-bind="link.isExternal ? { href: link.linkTo } : { to: { name: `${link.linkTo}` } }"
                              :class="{
                                'nav-dropdown__link': true,
                                'nav-dropdown__link--active': activeNavDropdownLinkIndex === index,
                              }"
                              tabindex="-1"
                              :target="link.isExternal ? '_blank' : null"
                              @click.native="navDropdownIsOpen = false"
                            >
                              <div class="nav-dropdown__item-icon">
                                <img :src="require(`@/assets/images/Header/${link.icon}.png`)" alt="" />
                              </div>
                              <div class="nav-dropdown__item-content">
                                <h4
                                  :class="{
                                    'active-link': $route.name === `${link.linkTo}`,
                                  }"
                                >
                                  {{ link.name }}
                                </h4>
                                <p>{{ link.description }}</p>
                                <img
                                  v-if="link.isExternal"
                                  src="@/assets/images/Global/external-link.png"
                                  alt="External link"
                                  class="nav-dropdown__external-icon"
                                />
                              </div>
                            </component>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </template>
                </li>
              </template>
            </ul>
            <HeaderSearch
              :headerNavItems="headerNavItems"
              :searchActive="searchActive"
              :closeSearchbar="closeSearchbar"
              ref="searchComponent"
            />
          </nav>
        </div>

        <div class="right-nav">
          <div class="search">
            <button aria-label="Search" class="search-button" v-if="!searchActive" @click="openSearchbar">
              <searchSVG style="height: 20px" aria-hidden="true" title="Search" />
            </button>
            <button aria-label="Close search" class="search-button" v-if="searchActive" @click="closeSearchbar">
              <searchCloseSVG style="height: 20px" />
            </button>
          </div>
          <div>
            <ul class="lang-toggle">
              <li>
                <button
                  :aria-label="this.$i18n.locale === 'en' ? 'English button is active' : 'Select English'"
                  @click="goToEC()"
                  :class="{ opaque: this.$i18n.locale === 'en' }"
                >
                  EN
                </button>
              </li>
              <li>
                <button
                  :aria-label="this.$i18n.locale === 'fr' ? 'French button is active' : 'Select French'"
                  @click="goToFC()"
                  :class="{ opaque: this.$i18n.locale === 'fr' }"
                >
                  FR
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import lang from '@/lang';
import Navigation from '@/lang/Navigation';
import logo from '@/assets/images/Header/logoCa.svg';
import searchSVG from '@/assets/images/Header/searchIconWhite.svg';
import searchCloseSVG from '@/assets/images/Global/searchCloseIcon.svg';
// import { Loader } from '@googlemaps/js-api-loader';
import HeaderCaret from './HeaderCaret.vue';
import HeaderSearch from './HeaderSearch.vue';

export default {
  name: 'DesktopHeader',
  components: {
    logo,
    searchSVG,
    searchCloseSVG,
    HeaderCaret,
    HeaderSearch,
  },
  props: {
    headerNavItems: { required: true },
  },
  i18n: {
    messages: Navigation,
    sharedMessages: lang,
  },
  data() {
    return {
      activeTopLevelNav: null,
      activeNavDropdownLinkIndex: 0,
      navDropdownIsOpen: false,
      searchActive: false,
      lastScrollTop: 0,
    };
  },
  computed: {
    isTablet() {
      return this.$resize && this.$mq.below(992);
    },
    searchOpen() {
      return require('../../assets/images/Global/searchCloseIcon.svg');
    },
    getLang() {
      return this.$store.state.lang;
    },
  },
  methods: {
    goToEC() {
      if (this.getLang === 'fr') {
        let newName = this.$route.name;
        const newParams = this.$route.params;
        if (newName.includes('-fr')) {
          newName = this.$route.name.replace('-fr', '-en');
          delete newParams.lang;
          this.$router.push({ name: newName, params: newParams });
        }
        this.$store.commit('setLang', 'en');
      }
    },
    goToFC() {
      if (this.getLang !== 'fr') {
        let newName = this.$route.name;
        let newParams = this.$route.params;
        if (newName.includes('-en')) {
          newName = this.$route.name.replace('-en', '-fr');
          newParams = {
            ...newParams,
            lang: 'fr',
          };
          this.$router.push({ name: newName, params: newParams });
        }
        this.$store.commit('setLang', 'fr');
      }
    },
    focusOnMenu() {
      setTimeout(() => {
        const closeMenuRef = this.$refs.closeMenu;
        closeMenuRef.focus();
      }, 300);
    },
    openSearchbar() {
      const priorFocus = document.activeElement;
      if (!this.searchActive) {
        this.searchActive = true;
      }
      this.$nextTick(() => {
        const { headerElement } = this.$refs;
        const focusableElements = headerElement.querySelectorAll('input, .search-button');
        const deactivate = headerElement.querySelectorAll('a');
        [...deactivate].forEach((el) => el.setAttribute('inert', true));
        const firstFocusable = focusableElements[0];
        const lastFocusable = focusableElements[focusableElements.length - 1];
        const searchRef = this.$refs.searchComponent.$refs.search;

        if (this.searchActive) {
          searchRef.focus();
          headerElement.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
              this.closeSearchbar();
              searchRef.value = '';
              priorFocus.focus();
            }

            if (e.key === 'Tab' && this.searchActive) {
              if (e.shiftKey) {
                if (document.activeElement === firstFocusable) {
                  e.preventDefault();
                  lastFocusable.focus();
                }
              } else if (document.activeElement === lastFocusable) {
                e.preventDefault();
                firstFocusable.focus();
              }
            }
          });
        }
      });
    },
    closeSearchbar() {
      const priorFocus = document.activeElement;
      this.searchActive = false;
      const { headerElement } = this.$refs;
      const reactivate = headerElement.querySelectorAll('a');
      [...reactivate].forEach((el) => {
        el.removeAttribute('inert');
      });
      priorFocus.focus();
      clearInterval(this.checkSwiftypeInterval);
      this.checkSwiftypeInterval = null;
    },
    handleMegamenuNavigation(navItem, e) {
      if (navItem.nestedLinks && navItem.nestedLinks.length > 0) {
        const lastItemIndex = navItem.nestedLinks.length - 1;

        switch (e.key) {
          case 'ArrowUp':
            e.preventDefault();

            this.activeNavDropdownLinkIndex =
              this.activeNavDropdownLinkIndex - 1 < 0 ? lastItemIndex : this.activeNavDropdownLinkIndex - 1;
            break;
          case 'ArrowDown':
            e.preventDefault();

            this.activeNavDropdownLinkIndex =
              this.activeNavDropdownLinkIndex + 1 > lastItemIndex ? 0 : this.activeNavDropdownLinkIndex + 1;
            break;
          case 'Enter':
            if (this.navDropdownIsOpen) {
              e.preventDefault();
              const activeLink = navItem.nestedLinks[this.activeNavDropdownLinkIndex];
              this.goToPage(activeLink.linkTo, activeLink.isExternal);
            }
            break;
          case 'Escape':
            this.navDropdownIsOpen = false;
            break;
          default:
            break;
        }
      }
    },
    updateNavItemFocus(navItem) {
      this.activeTopLevelNav = navItem.name;
      this.activeNavDropdownLinkIndex = 0;
    },
    goToPage(path, isExternalLink) {
      this.navDropdownIsOpen = false;
      if (isExternalLink) {
        window.open(path, '_blank');
      } else {
        this.$router.push({ name: `${path}` });
      }
    },
    toggleDropdown(navItemName) {
      if (this.activeTopLevelNav === navItemName || !this.navDropdownIsOpen) {
        this.navDropdownIsOpen = !this.navDropdownIsOpen;
      }
      this.activeTopLevelNav = navItemName;
    },
  },
  watch: {
    $resize() {
      this.$nextTick(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
* {
  text-transform: uppercase;
}

.header {
  height: 70px;

  a,
  button,
  h3 {
    color: $c-primary-background;
  }
  .button {
    margin-bottom: -1px;
    margin-top: -1px;

    background-image: linear-gradient(90deg, rgba(53, 189, 209, 1) 50%, rgba(53, 189, 209, 0) 50%);
    background-size: 202% auto !important;
    border: 1px solid #fff;

    white-space: nowrap;
  }

  .main-list {
    box-sizing: border-box;

    position: fixed;
    top: 0;
    z-index: 101;

    width: 100%;
    height: 70px;

    padding: 0px 20px;

    background-color: #0b0f4d;
    color: white;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 100%;
    width: 100%;
    max-width: 1200px;

    margin: 0 auto 0 auto;

    .logo-container {
      display: flex;

      height: 40px;
      width: 115px;

      svg {
        width: 270px !important;

        cursor: pointer;
      }
    }
    .nav-container {
      display: contents;

      font-weight: $fw-medium;
    }

    .search {
      display: flex;

      width: 30px;

      margin-left: 30px;
    }
  }

  .left-nav {
    display: flex;
    align-items: center;
    flex-grow: 1;

    height: 100%;

    @media only screen and (max-width: 1130px) {
      width: 100%;
    }
  }

  .right-nav-link {
    box-sizing: border-box;

    display: flex;
    align-items: center;

    height: 54px;

    border-left: solid 1px rgba(#fff, 0.3);
    border-right: solid 1px rgba(#fff, 0.3);

    a {
      width: 75px;
    }
  }

  .label {
    list-style-type: none;

    padding: 0 16px;

    cursor: pointer;

    color: white;
    font-size: 14px;
    &:hover,
    .active-page,
    .on-nested-page {
      color: $c-highlights;
    }

    h3 {
      font-size: 14px;
    }
  }

  .label-name {
    padding: 6px;

    font-weight: $fw-medium;

    border-radius: 4px;
    background-color: transparent;
    border: 2px solid transparent;

    &:hover {
      background-color: rgba($c-highlights, 0.2);
    }

    &.open {
      position: relative;

      color: $c-highlights;

      .label-caret svg {
        transform: rotate(180deg);

        color: $c-highlights;
        stroke: $c-highlights;
      }

      &::after {
        content: '';

        position: absolute;
        top: 60px;
        right: -6px;
        z-index: 1000;

        height: 35px;
        width: 35px;

        background: $c-primary-background;

        transform: rotate(45deg);
      }
    }
  }

  .nav {
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    width: 100%;

    padding: 0 30px;

    .label-caret {
      position: relative;
      top: 1px;

      height: 8px;
      width: 8px;

      margin-left: 12px;
    }
  }

  .nav-dropdown {
    display: none;

    position: absolute;
    left: 0;
    right: 0;
    top: 64px;
    z-index: 999;

    background: $c-primary-background;
    border-radius: 8px;
    box-shadow: 0 0 24px 8px rgba($c-primary, 0.35);

    &--show {
      display: block;
    }

    &__container {
      padding: 40px 40px 32px 40px;
    }

    &__list {
      list-style-type: none;

      display: flex;
      flex-wrap: wrap;

      padding: 0;
    }

    &__item {
      box-sizing: border-box;

      width: 50%;

      margin-bottom: 8px;

      border-radius: 8px;
      border: solid 2px transparent;

      &--full {
        width: 100%;
      }

      &--active {
        border: solid 2px $c-highlights;
      }

      &:hover {
        background: $c-secondary-background;

        h4 {
          color: $c-focus;
        }
      }
    }

    &__link {
      box-sizing: border-box;

      display: flex;

      width: 100%;
      height: 100%;

      margin: 0;
      padding: 24px;

      &:active,
      &:focus {
        border-color: transparent;
        box-shadow: 0 0 0 3px transparent;
      }
    }

    &__item-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 46px;
      height: 46px;
      min-width: 46px;

      background: #d7dcf3;
      border-radius: 50%;

      img {
        width: 24px;
        height: 25px;
      }
    }

    &__external-icon {
      position: absolute;
      right: 0;
      top: 50%;

      height: 24px;
      width: 24px;

      transform: translateY(-50%);
    }

    &__item-content {
      position: relative;

      flex-grow: 1;

      margin-left: 16px;

      h4 {
        text-transform: none;
        font-size: 16px;
        margin: 0;

        &.active-link {
          color: $c-focus;
        }
      }

      p {
        width: 90%;
        margin: 0;
        margin-top: 10px;
        font-size: 12px;
        text-transform: none;
        font-weight: $fw-regular;
      }
    }
  }
  .menu-close-button {
    margin-top: 10px;
    padding: 5px 10px;

    background-color: #001252;
    border: none;
    color: #001252;

    &:focus {
      padding: 5px 10px;

      background-color: #fff;
      border-radius: 4px;
      color: #0b0f4d;
      font-weight: 600;
    }
  }
  .menu-close-container {
    list-style-type: none;
  }
  .pagemask {
    position: absolute;
    left: 0;
    top: 70px;
    z-index: 3;

    height: 100vh;
    width: 100%;
  }
  .right-nav {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
    .search-button {
      padding: 0;

      background-color: transparent;
      border: none;
    }
    .lang-toggle {
      display: flex;
      list-style-type: none;
      margin: 0;
      padding: 0 0 0 20px;
      justify-content: flex-end;
      li {
        padding: 15px;
        line-height: 10px;

        button {
          cursor: pointer;
          background: transparent;
          border: none;
          color: #ffffff;
          padding: 0;
          font-family: Ubuntu;
          font-weight: $fw-medium;
          font-size: 16px;
          line-height: 10px;
        }
        .opaque {
          color: #c4c4c4;
          padding-bottom: 3px;
          border-bottom: 1px solid #c4c4c4;
        }
        &:first-child {
          border-right: 1px solid #ffffff;
        }
      }
    }
  }
  .right-nav-item {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 125px;

    border-left: 1px solid rgba(255, 255, 255, 0.3);
    border-right: 1px solid rgba(255, 255, 255, 0.3);

    font-weight: 600;
    font-size: 14px;

    div {
      padding: 25px;
    }
  }
}

.header.tablet {
  .main-list {
    .container {
      .nav {
        justify-content: space-between;

        padding: 0 10px;

        .label {
          padding: 0 4px;
        }

        .label-caret {
          margin-left: 6px;
        }
      }
    }
  }
}
</style>
