<template>
  <article class="faq-column">
    <h2 id="mobile-app-faq-heading">{{ $t('faqsMobileApp') }}</h2>
    <accordion header-id="mobile-app-faq-heading">
      <HowToStartSession></HowToStartSession>
      <HowToPay></HowToPay>
      <accordion-item :title="$t('outOfMoney')">
        <p>
          {{ $t('outOfMoneyAnswer') }}
        </p>
      </accordion-item>
      <accordion-item :title="$t('needNewAccount')">
        <p>{{ $t('needNewAccountAnswer') }}</p>
      </accordion-item>
      <accordion-item :title="$t('redesignedApp')">
        <p>
          {{ $t('redesignedAppAnswer') }}
        </p>
      </accordion-item>
      <accordion-item :title="$t('homestationWithApp')">
        <p>{{ $t('homestationWithAppAnswer') }}</p>
      </accordion-item>
      <accordion-item :title="$t('appToUseHomestation')">
        <p>
          {{ $t('appToUseHomestationAnswer') }}
        </p>
      </accordion-item>
    </accordion>
  </article>
</template>

<script>
import lang from '@/lang/Faqs';
import Accordion from './Accordion.vue';
import AccordionItem from './AccordionItem.vue';
import HowToPay from './Faqs/HowToPay.vue';
import HowToStartSession from './Faqs/HowToStartSession.vue';

export default {
  name: 'faqs',
  components: {
    Accordion,
    AccordionItem,
    HowToPay,
    HowToStartSession,
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
  },
};
</script>
