<template>
  <article class="disclosure">
    <div>
      <h1>Forfait de recharge BMW i7</h1>
      <h2>{{ $t('termsAndConditions') }}</h2>
      <p class="primary-color">
        <strong>En vigueur le 20 février 2024</strong>
      </p>
      <p>
        Electrify Canada et BMW Canada sont heureux d’offrir ce forfait promotionnel de recharge (le « Forfait ») aux
        conducteurs de véhicules BMW i7 neufs, modèles des années 2023, 2024 et 2025 achetés auprès d’un détaillant BMW
        Canada autorisé. En cliquant sur « J’accepte » ci-dessous, vous acceptez de participer à cette promotion, sous
        réserve du respect des présentes modalités (les « Modalités du Forfait »).
      </p>
      <p>
        La participation au Forfait nécessite un compte avec Electrify Canada, l’application mobile Electrify Canada,
        l’inscription au Forfait dans l’application et l’acceptation des
        <router-link class="link" :to="{ name: 'terms-fr' }">Modalités d’Electrify Canada</router-link> qui régissent
        votre utilisation de l’application et du réseau d’Electrify Canada. Les frais standard de messagerie, de données
        et autres tarifs et frais prélevés par votre fournisseur de services mobiles pourraient s’appliquer à votre
        utilisation de l’application Electrify Canada.
      </p>
      <p>
        Dès votre inscription et votre acceptation des Modalités du Forfait, vous serez inscrit au Forfait qui prévoit
        36 mois gratuits de sessions de recharge rapide en courant continu (CCS) sur le réseau d’Electrify Canada pour
        votre BMW i7 2023, 2024 ou 2025 Ce Forfait sera valide pendant 36 mois à compter de la date d’achat du véhicule
        (fournie à Electrify Canada par le fabricant) jusqu’à ce qu’il soit expiré, à moins qu’Electrify Canada ne
        décide de le résilier plus tôt, comme décrit ci-dessous. Un véhicule BMW i7 acheté avant le lancement du
        programme (Le 06 avril 2023) bénéficiera de trois ans de recharge gratuite à compter du 06 avril 2023.
      </p>
      <p>
        Une fois que vous serez inscrit au forfait, vous pourrez commencer à utiliser vos 36 mois de séances gratuites
        de recharge rapide en courant continu (SRC) pour votre BMW i7 2023, 2024 ou 2025 à n’importe quelle borne de
        recharge Electrify Canada, en utilisant la fonctionnalité « Balayez pour commencer la recharge » de
        l’application Electrify Canada ou la fonctionnalité Passe CCP en conjonction avec le
        <strong>
          forfait de recharge pour BMW i7. La recharge comprise dans ce forfait ne peut pas être lancée au moyen de
          l’écran d’interface ni des boutons de la borne de recharge.
        </strong>
        La fonctionnalité Brancher et recharger sera également offerte avec ce forfait. La fonctionnalité Brancher et
        recharger offre aux propriétaires de VE i7 une meilleure expérience de recharge en créant un mode de paiement
        sécuritaire, pratique et sans effort sur le réseau Electrify Canada.
      </p>
      <p>
        Naviguez à la fonction « Balayez pour commencer la recharge » de l’application ou à la fonction Passe CCP avec
        le Forfait de recharge BMW i7 sélectionné. Aucun frais supplémentaires, ni achat ou abonnement n’est exigé, pour
        accéder à cette promotion.<strong>
          À l’épuisement ou à l’expiration de ce Forfait, la recharge s’effectuera au prix de recharge Passe d’Electrify
          Canada en vigueur.</strong
        >
        Sous réserve des restrictions décrites ci-dessous, vous avez droit à un nombre illimité de sessions de recharge
        admissibles ou à la quantité de kWh de recharge incluse dans chaque session pour la durée du Forfait.
      </p>
      <strong
        >VEUILLEZ NOTER LES LIMITATIONS IMPORTANTES SUIVANTES CONCERNANT VOTRE UTILISATION DE LA PRÉSENTE PROMOTION
        :</strong
      >
      <p>
        Vous pouvez utiliser ce Forfait gratuit <strong>uniquement</strong> pour recharger le véhicule BMW i7 dont vous
        avez fourni le NIV en adhérant à cette promotion dans votre compte Electrify Canada.
        <strong>Aucun autre véhicule n’est admissible à ce Forfait au moyen de votre compte</strong>, que le véhicule
        vous appartienne ou non.
      </p>
      <p>
        Vous ne pouvez <strong>pas</strong> utiliser ce Forfait de recharge gratuite dans le cadre d’un service de taxi,
        de covoiturage ou de tout autre service similaire, ni à toute autre fin commerciale.
        <strong>Cette promotion est réservée à votre usage personnel et non commercial.</strong>
      </p>
      <p>
        La recharge gratuite <strong>n’inclut pas</strong> les frais d’inactivité ni les taxes afférentes appliquées à
        la borne de recharge. Si vous en subissez, vous serez responsable de payer ces frais utilisant votre compte.
      </p>
      <p>
        Vous devez toujours respecter l’ensemble de la documentation technique, de sécurité, et du véhicule, fournie
        avec votre BMW i7 lorsque vous le rechargez à une station Electrify Canada.
      </p>
      <p>Vous devez utiliser le bon type de connecteur pour la BMW i7 à la station de recharge.</p>
      <p>
        Lorsque les 36 mois de recharge gratuite seront expirés, vous ne pourrez pas renouveler le
        <strong class="primary-color">Forfait de recharge BMW i7</strong> ni y ajouter des fonds. Pour connaître le
        temps restant à la promotion, référez-vous à la section
        <strong class="primary-color">« Renseignements sur le forfait »</strong> dans l’application.
      </p>
      <p>
        Ce Forfait ne peut être retourné ou échangé contre quelconque valeur monétaire et il ne peut pas être vendu,
        prêté ou distribué à un tiers pour quelque raison que ce soit. Aucune partie du Forfait ne peut être transférée
        à un autre compte d’Electrify Canada.
      </p>
      <p>
        Vous reconnaissez que votre utilisation des services d’Electrify Canada est assujettie aux Modalités d’Electrify
        Canada, que vous pouvez consulter à l’adresse
        <router-link :to="{ name: 'terms-fr' }" class="link"
          >https://www.electrify-canada.ca/fr/mentions-legales</router-link
        >, et que votre utilisation de l’application mobile d’Electrify Canada est assujettie aux Modalités accessibles
        en sélectionnant l’icône <strong class="primary-color">Compte </strong> dans le coin inférieur droit de
        l’application, en sélectionnant <strong class="primary-color">« Mention légale »</strong> et
        <router-link :to="{ name: 'terms-fr' }" class="medium">« Modalités »</router-link> et puis
        <router-link :to="{ name: 'privacy-fr' }" class="medium">« Politique de confidentialité »</router-link>.
        Veuillez lire attentivement ces documents, car ils décrivent la manière dont les frais d’inactivité, les taxes
        applicables et le temps de recharge au-delà de ce qui est inclus dans cette promotion seront appliqués à votre
        compte.
      </p>
      <p>
        Vous acceptez de vous conformer à toutes les lois et les réglementations en vigueur lorsque vous utilisez les
        services d’Electrify Canada et que vous rechargez votre véhicule aux stations de recharge d’Electrify Canada.
      </p>
      <p>
        Electrify Canada se réserve le droit de retirer, de révoquer, de réduire, de résilier ou de suspendre votre
        accès en totalité ou en partie à la présente promotion, sans préavis, si elle détermine ou soupçonne, à sa seule
        discrétion, que vous : (a) enfreignez les Modalités d’Electrify Canada, les Modalités de la promotion ou tout
        autre contrat conclu entre vous et Electrify Canada; (b) avez effectué des sessions de recharge frauduleuses, ou
        qui indiquent une utilisation non autorisée ou non prévue par cette promotion gratuite; ou (c) avez fait preuve
        d’une conduite illégale, frauduleuse, délictuelle, dommageable, préjudiciable ou abusive dans votre utilisation
        des services d’Electrify Canada, de l’application mobile Electrify Canada ou d’une station de recharge
        d’Electrify Canada.
      </p>
      <p>
        Electrify Canada se réserve le droit de mettre fin à cette promotion gratuite à tout moment, avant sa date
        d’échéance automatique pour quelque raison que ce soit, à sa seule discrétion. Dans une telle éventualité,
        Electrify Canada enverra un courriel à l’adresse associée au compte indiquant la modification de la date de fin
        de la promotion. Suite à la résiliation ou de l’expiration anticipée de ce Forfait pour quelque raison que ce
        soit, votre accès à la promotion gratuite cessera immédiatement et vous ne pourrez plus utiliser les avantages
        qu’elle offrait. Votre compte sera alors automatiquement converti en un compte Passe Electrify Canada de base.
        Vous pouvez, sans y être obligé, vous inscrire à un autre type d’abonnement par l’intermédiaire de l’application
        mobile Electrify Canada.
      </p>
    </div>
  </article>
</template>

<script>
import lang from '@/lang';

export default {
  name: 'bmw-i7-unlimited-divulgation-fr',
  metaInfo: {
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/fr/bmw-i7-unlimited-divulgation/',
      },
    ],
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
};
</script>
