<template>
  <div class="partner-dropdown">
    <slot name="label">
      <label :for="id" v-text="labelText" />
    </slot>
    <div>
      <select :id="id" :value="value" @input="handleChange">
        <option
          v-for="option in options"
          :value="option.value"
          :disabled="option.disabled"
          :key="option.value"
          v-text="option.text"
        />
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PartnerDropdown',
  props: {
    value: {
      required: true,
    },
    id: {
      required: false,
    },
    options: {
      type: Array,
      required: false,
      default() {
        return [
          {
            text: 'First Option',
            value: null,
            disabled: true,
          },
        ];
      },
    },
    labelText: {
      type: String,
      required: false,
    },
  },
  methods: {
    handleChange(e) {
      this.$emit('input', e.target.value || null);
    },
  },
};
</script>

<style lang="scss" scoped>
.partner-dropdown {
  label {
    font-weight: $fw-medium;
    font-size: 1rem;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    width: 100%;
    border: 3px solid #011452;
    border-radius: 50px;
    height: 73px;
    font-size: 20px;
    text-indent: 20px;
    background: url('~@/assets/images/PartnerDropdown/carat.svg?external') right 1em center/25px no-repeat;
    margin-top: 12px;
    padding-right: 55px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>
