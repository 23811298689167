<template>
  <article class="disclosure">
    <h1>Privacy</h1>
    <p>
      <strong>Effective Date: September 23, 2023</strong>
      <br />
      Electrify Canada, Inc. ("Electrify Canada," “we,” or “us”), collects and processes information about you as
      described in this Privacy Notice (“Notice”). This Notice applies to all of our products and services, and contains
      details about how we collect, use, access, and share Personal Information that we obtain from and about you when
      you interact with us via our website, email, mobile applications, social media accounts (collectively, our
      “Digital Properties”), charging stations, or Call Center, and in other online and offline interactions.
    </p>
    <h2>Data Collection</h2>
    <p>
      <strong>Personal Information</strong>
      <br />
      We collect personal information you voluntarily provide; for example, when you visit our websites, use our app,
      create an account, charge your vehicle at one our charging stations, or contact us.
    </p>
    <p>
      Personal information we collect includes contact information such as your first and last name, address, e-mail
      address, social media handles and phone number; payment card information (when you register for an Electrify
      Canada account); professional information (such as your company name, title, and any other information you
      directly provide to us, including information about your vehicle, such as vehicle ID number (“VIN”).
    </p>
    <p>
      We also collect video and audio recordings of our charging stations (such as security footage or voice commands)
      and call center recordings for security, quality assurance and training purposes.
    </p>
    <p>
      If you participate in a promotional charging program through your vehicle manufacturer (“OEM”), we may collect
      information from the OEM in order to enroll your vehicle and provide our Services. This information may include
      your VIN, name and contract information, as well as the charging status of your vehicle. We receive information
      about you and your interactions with us from third parties, such as our service providers, entities who sell
      and/or administer our products, charging station providers, partners who host our charging stations at their
      location(s), utility providers, marketing and advertising providers, social network services, ecommerce platforms,
      and data brokers.
    </p>
    <p>
      We also automatically collect technical information, or information that may inferred from your online
      interactions with us, such as your IP address, your browsing history, and search history, or other similar
      identifiers.
    </p>
    <p>
      This information may be collected through various technologies, including but not limited to cookies and web
      beacons.
    </p>
    <p>
      We may also use service providers, such as Google Analytics, that may use cookies to collect information about
      your online activities across this and other sites over time for non-advertising purposes. To learn more about how
      Google Analytics collects and processes data and the choices Google may offer to control these activities, visit
      <a href="https://www.google.com/intl/en/policies/privacy/partners/" class="link" rel="noopener" target="_blank"
        >https://www.google.com/intl/en/policies/privacy/partners/</a
      >
    </p>
    <h2>Data Use</h2>
    <p>
      We may use both personal and non-personal information that we collect from you for our business purposes. For
      example, we may use your information in the following ways:
    </p>
    <ol>
      <li>To provide you with more relevant advertising and consumer education content;</li>
      <li>To respond to your messages and requests;</li>
      <li>To operate, maintain, and improve our products and Services, including to maintain security;</li>
      <li>For our statistical, research, recordkeeping, and reporting purposes;</li>
      <li>To personalize and improve your experience when you use our Services;</li>
      <li>To monitor our Services for compliance with our terms of use;</li>
      <li>For any other purpose identified at the point of collection.</li>
    </ol>
    <p>
      When you provide permission online or through our app, we use location information to locate the charging station
      closest to you and the ability to begin charging once you are within a specified proximity to the charger. With
      your permission, we may also use location information to provide relevant ads and offers to you.
    </p>
    <p>
      Personal information may be accessed by persons within our organization, our third party service providers and
      those persons described in this Privacy Policy, who require such access to carry out the purposes described in
      this Privacy Policy, or such other purposes as may be permitted or required by the applicable law.
    </p>
    <h2>Consent</h2>
    <p>
      Privacy legislation requires Electrify Canada to obtain the consent of an individual to the collection, use or
      disclosure of personal information in many circumstances.
    </p>
    <p>
      By providing your personal information to us, you agree that we may collect, use and disclose your personal
      information in accordance with this privacy policy and as otherwise permitted or required by law.
    </p>
    <p>
      When Electrify Canada receives personal information from you for the purposes of providing you with our Services
      or for other uses identified herein by us, you are providing us with consent as follows:
    </p>
    <ol>
      <li>to allow us to deal with that personal information in a reasonable manner;</li>
      <li>
        to allow us to provide your personal information to third parties it engages to provide or support our Services;
      </li>
      <li>
        to allow us to use and store your personal information for the purpose of providing you with the products and
        Services you purchase from us and to utilize the Services;
      </li>
      <li>
        to allow us to share your personal information with vehicle manufacturers who arrange for or pay for some or all
        of the services you receive, charging station site hosts, and utility companies; and
      </li>
      <li>
        to allow us to transfer your personal information outside of Canada (whereby your personal information will be
        treated in accordance with applicable foreign laws) for the purpose of storage and use of your personal
        information by us or on our behalf; and
      </li>
      <li>
        to allow us to transfer your personal information between Canadian provinces and territories (whereby your
        personal information will be treated in accordance with applicable laws of the provinces and/or territories to
        which it is transferred) for the purpose of storage and use of your personal information by us or on our behalf.
      </li>
    </ol>
    <p>
      If you need to provide us with personal information about other individuals, you represent and warrant to us that,
      where required by law and prior to your disclosure to us, you will obtain the consent of each individual to the
      collection, use and disclosure by us for the specific purpose(s) that the disclosure is made by you.
    </p>
    <p>
      Providing us with your personal information is always your choice. When you request products and/or Services from
      us, utilize our Services, or register for our Service(s), we ask that you provide information that enables us to
      respond to your request and/or provide you with our Services. In doing so, you consent to our collection, use and
      disclosure to appropriate third parties of such personal information for these purposes.
    </p>
    <p>
      There are legal exceptions whereby we will not need to obtain consent or explain the purposes for the collection,
      use or disclosure of personal information. Some examples of situations where consent is not required include an
      emergency that threatens the life, health or security of an individual, or if we must comply with a court order or
      governmental order.
    </p>
    <p>
      If we sell our organization or merge with another party, your consent will be implied to transfer your information
      to our successor for the same purposes as set out in this privacy policy.
    </p>
    <h2>Data Sharing</h2>
    <p>We do not share personal information with unaffiliated third parties other than as follows:</p>
    <ul>
      <li>
        With government entities including in connection with evaluating proposals, or as needed to provide our Services
        and where required by law;
      </li>
      <li>
        With our affiliates including parents, subsidiaries, business units and other companies that share common
        ownership and service providers. These third-party service providers and affiliates may be located outside of
        Canada. Whenever we hire other organizations to provide support services, they will be required to conform to
        our privacy standards. These third parties shall use the personal information only for purposes related to the
        supply of services to us and they will also respect strict contractual conditions that oblige them to maintain
        the confidentiality of all information;
      </li>
      <li>
        With our business customers, including for third-party marketing. In some cases, this may include sharing your
        information with vehicle manufacturers and dealers who arrange for or pay for some or all of the services you
        receive, charging station site hosts, and utility companies.
      </li>
      <li>Pursuant to a subpoena, court order, or other legal process;</li>
      <li>
        To investigate, respond to, or resolve problems or inquiries (including law enforcement requests) or to protect,
        enforce, or defend our rights, assets, and/or interests;
      </li>
      <li>
        As part of an acquisition or merger with another company, a partial or total sale of assets, or another
        corporate change, including at bankruptcy;
      </li>
      <li>To prevent or respond to a violation of any law, regulation, or our terms of service; or</li>
      <li>With your consent or as otherwise disclosed at the time of collection.</li>
    </ul>
    <h2>Third Party Websites and Apps</h2>
    <p>
      Our Services may contain links to third-party websites or mobile apps. Please be aware that we do not control
      these linked websites or apps, and that this privacy policy does not apply to those websites or apps. We encourage
      you to read the privacy notice of any third-party website or app you visit before providing information to its
      owner.
    </p>
    <h2>Cookies</h2>
    <p>
      We also collect information (or permit third parties to collect information directly from our website, mobile app,
      or in other online interactions), via cookies, web beacons, pixels, tags or other tracking technologies. Such
      information may include your Internet Service Provider and IP address, the date and time you access our website,
      the pages you accessed while visiting our website, and the Internet address from which you accessed our website.
      Some cookies exist only during a single session and some are persistent over multiple sessions over time. We use
      these technologies to remember user preferences, maximize the performance of our website and services, provide you
      with offers that may be of interest to you, measure the effectiveness of our email campaigns and to personalize
      online content. These cookies and other technologies may be used to track you across devices and different
      websites over time.
    </p>
    <p>
      To provide you with more relevant and interesting experience, we may work with third party companies to display
      ads or customize the content on our website or mobile app and those of third parties (like on social media
      networks). These companies may use cookies and similar tracking technologies as described in this Policy to gather
      information about your visits to the website, as well as your visits elsewhere on the Internet in order to provide
      you with more relevant advertising, known as interest-based advertising (See more on this in the “Marketing &
      Interest-Based Advertising Choices section”).
    </p>
    <h2>Marketing & Interest-Based Advertising Choices</h2>
    <p>
      We have third-party advertising partners that may collect information about your visits to our websites, digital
      properties and other non-affiliated websites and/or apps in order to provide advertisements to you on our
      properties and across other websites and apps. Some of those ads may be interest-based, meaning that they are
      intended to be relevant to you based on the information these ad networks collect about your visits to our
      websites and digital properties and other non-affiliated web sites or apps over time.
    </p>
    <p>
      To find out more about interest-based advertising and/or to opt out of the use of your information for this
      purpose, you may visit
      <a href="https://youradchoices.ca/" class="link" rel="noopener" target="_blank">https://youradchoices.ca/</a>
    </p>
    <p>
      Please note that if you use a different Internet browser or disable cookies on your browser, you may need to renew
      your opt-out choices.
    </p>
    <p>
      You may choose how we contact you for marketing-related communications. You may request not to receive marketing
      from Electrify Canada by sending an email to
      <a href="mailto:privacy@electrify-canada.ca" class="link"> privacy@electrify-canada.ca </a>
      or by using the “Unsubscribe” link in any marketing email you receive from us. We may still contact you for
      non-promotional purposes, such as to provide receipts or send you messages related to your account with us.
    </p>
    <h2>Children’s Privacy</h2>
    <p>
      Electrify Canada does not knowingly collect personal information from visitors to our online services under the
      age of 13. In the event that we learn that we have collected personal information from a child under age 13
      without verification of parental consent, we will delete that information.
    </p>
    <h2>Updating this Policy</h2>
    <p>
      We may change this Policy from time to time. We will post changes in this notice, or as otherwise required by law.
      We suggest that you periodically revisit this notice to stay informed of any changes.
    </p>
    <h2 class="h4">Security, Retention and Destruction of Personal Information</h2>
    <p>
      We take reasonable steps to protect the security and confidentiality of your personal information. We protect the
      personal information within our custody or control with appropriate organizational, technological and physical
      safeguards.
    </p>
    <p>
      We store personal information in electronic files that are secure, and our security measures include secure on-
      and off-site storage, restricted access to records and data processing equipment, password protocols, and
      encryption and security software. We conduct audits and monitor compliance with our privacy practices.
    </p>
    <p>
      The amount of time we will retain personal information varies, depending on the Services we have provided and the
      nature of the personal information that we hold. We retain personal information for such period of time as is
      necessary for us to maintain sufficient information so we may respond to any issues that arise at a later date or
      as is required by law.
    </p>
    <p>
      When personal information is no longer required by us or by law, we will either convert it into an aggregated
      non-identifying form or we will appropriately destroy or erase the personal information in a manner that is in
      accordance with our current policies and procedures.
    </p>
    <h2 class="h4">Electronic Communications</h2>
    <p>
      We endeavor to comply with Canada’s Anti-Spam laws (“CASL”) in our commercial electronic communication and
      software downloads with you. Should you fail to provide us with the requisite consent to send you commercial
      electronic messages or download software, or withdraw such consent, we may be unable to provide, or continue to
      provide, some of our products and/or Services that can only be provided if we receive appropriate and required
      personal information. CASL consent can be provided or withdrawn at
      <a href="mailto:privacy@electrify-canada.ca" class="link"> privacy@electrify-canada.ca. </a>
    </p>

    <h2 class="h4">Withdrawal of Consent</h2>
    <p>
      You may, at any time, withdraw your consent to our collection, use and disclosure of your personal information,
      subject to reasonable notice and any legal and/or contractual restrictions.
    </p>
    <p>
      Should you choose to withdraw your consent, we may be unable to provide, or continue to provide, all or some of
      our products and/or Services that can only be provided if we receive appropriate and required personal
      information. Consequently, should you choose not to provide us with the required personal information, we may be
      unable to offer such products and/or Services to you.
    </p>
    <h2>Access, correction and accountability</h2>
    <p>We will strive to keep all personal information in our files complete, up-to-date and accurate.</p>
    <p>
      You or a person authorized on your behalf has the right to request to see and obtain a copy of your personal
      information and to request changes or corrections to inaccurate or incomplete information. Please note that we may
      charge a minimal fee for processing such requests.
    </p>
    <p>
      If you have questions regarding our privacy notice or information practices, please contact our Privacy Officer at
      <a href="mailto:privacy@electrify-canada.ca" class="link"> privacy@electrify-canada.ca. </a>
    </p>
  </article>
</template>

<script>
export default {
  name: 'privacy',
  metaInfo: {
    title: 'Privacy policy ',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/privacy/',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/privacy/',
        hreflang: 'en-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/fr/confidentialite/',
        hreflang: 'fr-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/privacy/',
        hreflang: 'x-default',
      },
    ],
    meta: [
      {
        name: 'description',
        content:
          'Be informed. Read our privacy policy to learn how Electrify Canada will collect, access, use, and disclose your information when you use our products and services.',
      },
    ],
  },
};
</script>
