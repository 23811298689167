<template>
  <div class="what-to-expect">
    <Hero heading="Charging with Electrify Canada">
      <template slot="image">
        <picture>
          <source
            srcset="
              @/assets/images/WhatToExpect/hero-desktop.webp,
              @/assets/images/WhatToExpect/hero-desktop@2x.webp 2x
            "
            media="(min-width: 750px)"
          />
          <source
            srcset="@/assets/images/WhatToExpect/hero-mobile.webp, @/assets/images/WhatToExpect/hero-mobile@2x.webp 2x"
          />
          <img src="@/assets/images/WhatToExpect/hero-mobile.webp" alt="" />
        </picture>
      </template>
    </Hero>
    <TwoColumnContentBlock heading="The future of charging" headlineDisplayClass="headline3" :maxWidth="1000">
      <p>
        Explore the network
        <em
          >that offers some of the fastest charging speeds available today, Ultra-Fast 150 kW and Hyper-Fast 350 kW—</em
        >
        letting capable vehicles charge in as little as 30 minutes!
        <ReferenceLink :num="1"
          >Estimated charging times are provided as a reference only. Actual charging times will vary based on many
          factors, including but not limited to battery state of charge, driving behaviour, battery condition, current,
          resistance, ambient temperature, available voltage and charging frequency.</ReferenceLink
        >
      </p>
      <router-link class="button-link" :to="{ name: 'getting-started-en' }"
        >learn the basics of <br v-if="isTiny" />
        electric vehicle charging<span class="arrow"></span
      ></router-link>
    </TwoColumnContentBlock>
    <section class="feature">
      <picture>
        <source
          srcset="@/assets/images/WhatToExpect/charger-speeds-desktop.webp, @/assets/images/WhatToExpect/charger-speeds-desktop@2x.webp @2x"
          media="(min-width: 750px)"
        />
        <source
          srcset="
            @/assets/images/WhatToExpect/charger-speeds-mobile.webp,
            @/assets/images/WhatToExpect/charger-speeds-mobile@2x.webp 2x
          "
        />
        <img
          loading="lazy"
          src="@/assets/images/WhatToExpect/charger-speeds-mobile.webp"
          alt="Electrify Canada charging display portraying the teal ultra-fast and blue CHAdeMO charging labels. The Charger Number is at the top of the charger, the connector type is on the top of the label, the max charging speed is on the bottom of the label, customer support information is below the charger display, and membership payment is below the charger display"
        />
      </picture>

      <Card headlineLevel="h2" heading="Charger labels" :isReverse="isPhone" :maxWidth="1000">
        <template #image>
          <picture>
            <source
              srcset="@/assets/images/WhatToExpect/labels-3-d-desktop.webp, @/assets/images/WhatToExpect/labels-3-d-desktop@2x.webp @2x"
              media="(min-width: 750px)"
            />
            <source
              srcset="
                @/assets/images/WhatToExpect/labels-3-d-mobile.webp,
                @/assets/images/WhatToExpect/labels-3-d-mobile@2x.webp 2x
              "
            />
            <img loading="lazy" src="@/assets/images/WhatToExpect/labels-3-d-mobile.webp" alt="" />
          </picture>
        </template>
        <p>Newly designed charger labels were recently created with help from drivers like you!</p>
        <p>
          At the top of each label, you’ll find connector icons that match your vehicle’s charging port. At the bottom,
          lightning bolt icons signify the charger's maximum available power—the higher the kilowatt (kW), the greater
          the available power.
        </p>
        <p>Our charging stations offer three connectors:</p>
        <ul>
          <li>Green <strong>Hyper-Fast</strong> labels indicate CCS connectors delivering up to 350 kW.</li>
          <li>Teal <strong> Ultra-Fast</strong> labels indicate CCS connectors delivering up to 150 kW.</li>
          <li>Blue <strong>CHAdeMO</strong> labels indicate CHAdeMO connectors delivering up to 50 kW.</li>
        </ul>
        <div class="tip">
          <StarIcon />
          <p>
            <strong>Tip:</strong> Not all EVs can charge at a connector’s maximum power. For example, a Hyper-Fast label
            means the charger offers up to 350 kW for a CCS-compatible EV. If your car is not capable of a 350 kW
            maximum charge, the charger automatically supplies the highest power your vehicle requests. Check your
            vehicle’s manufacturer manual to learn your car’s capacity.
          </p>
        </div>
      </Card>
    </section>

    <section class="balance">
      <Card headlineLevel="h2" heading="Balanced Charging" :isReverse="true" :maxWidth="1000">
        <template #image>
          <img
            loading="lazy"
            class="image"
            v-if="isPhone"
            src="@/assets/images/WhatToExpect/image-balanced.png"
            alt=""
          />
          <img
            loading="lazy"
            class="image"
            v-else
            src="@/assets/images/WhatToExpect/image-balanced-desktop.png"
            alt=""
          />
        </template>
        <img
          loading="lazy"
          style="order: -1"
          class="bolt"
          src="../../assets/images/WhatToExpect/image-bolts.png"
          alt=""
        />
        <p>
          At Electrify Canada stations, you may notice the word “Balanced” on a charger’s labels or screen. Balanced
          chargers are typically located side-by-side and share a power cabinet. When a charger is Balanced, it can
          supply Ultra-Fast speeds up to 150 kW for vehicles capable of accepting this much power and can provide
          Hyper-Fast speeds up to 350 kW to capable vehicles when the adjacent charger is unused.
        </p>
        <strong>Balanced chargers are labeled with the tag below:</strong>
        <img
          loading="lazy"
          class="hyper-fast"
          src="@/assets/images/WhatToExpect/power-sharing.png"
          alt="Balanced Charging label is green with the words: CCS Balanced/Equilibre, Hyper fast/rapide, up to/jusqu'a 350 kw"
        />
      </Card>
    </section>

    <HeroWithContentOverlay :overlayBG="false" :darkBG="false" fullWidthMobile>
      <template #image>
        <picture>
          <source
            srcset="
              @/assets/images/WhatToExpect/charging-station-hyper-fast-desktop.webp,
              @/assets/images/WhatToExpect/charging-station-hyper-fast-desktop@2x.webp 2x
            "
            media="(min-width: 750px)"
          />
          <img loading="lazy" src="@/assets/images/WhatToExpect/charging-station-hyper-fast-mobile.webp" alt="" />
        </picture>
      </template>

      <router-link class="button-link" :to="{ name: 'charging-your-ev-en' }">
        How To<span class="sr-only">charge at Electrify Canada stations</span><arrow class="arrow"></arrow>
      </router-link>
      <h3>How to charge at Electrify Canada stations</h3>
      <p>Start with a charge at one of our stations or with our app.</p>
    </HeroWithContentOverlay>
  </div>
</template>

<script>
import lang from '@/lang';
import StarIcon from '@/assets/images/Mobile-App/StarIcon.svg';
import ReferenceLink from '@/components/ReferenceLink.vue';
import Card from '@/components/Card/Card.vue';
import Hero from '@/components/Hero/Hero.vue';
import HeroWithContentOverlay from '@/components/HeroWithContentOverlay/HeroWithContentOverlay.vue';
import TwoColumnContentBlock from '@/components/TwoColumnContentBlock/TwoColumnContentBlock.vue';

export default {
  name: 'what-to-expect',
  metaInfo: {
    title: 'Electrify Canada Charging Speed',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/what-to-expect/',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/what-to-expect/',
        hreflang: 'en-ca',
      },
      {
        rel: 'alternate',
        href: "https://www.electrify-canada.ca/fr/à-quoi-s'attendre/",
        hreflang: 'fr-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/what-to-expect/',
        hreflang: 'x-default',
      },
    ],
    meta: [
      {
        name: 'description',
        content:
          "EV Fast Charger | With Electrify America's DC fast chargers, you can charge in as little as 30 minutes! Learn more about EV charging speeds at our fast charging stations.",
      },
    ],
  },

  components: {
    StarIcon,
    ReferenceLink,
    Card,
    Hero,
    HeroWithContentOverlay,
    TwoColumnContentBlock,
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
  data() {
    return {
      num: null,
      body: null,
    };
  },
  computed: {
    isPhone() {
      return this.$resize && this.$mq.below(749);
    },
    isTiny() {
      return this.$resize && this.$mq.below(500);
    },
  },
};
</script>
