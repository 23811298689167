<template>
  <section :class="['card', { 'card--reverse': isReverse, 'card--small': isSmall, 'card--bg-color': bgColor }]">
    <div class="card__container" :style="{ 'max-width': maxWidth + 'px' }">
      <section class="card__image"><slot name="image" /></section>
      <div class="card__column">
        <component :is="headlineLevel" :class="['card__headline', headlineDisplayClass]">
          {{ heading }}
          <slot name="reference"></slot>
        </component>
        <slot />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Card',
  props: {
    heading: { type: String, required: false },
    headlineDisplayClass: { type: String, required: false },
    headlineLevel: { type: String, required: false },
    isReverse: { type: Boolean, required: false },
    isSmall: { type: Boolean, required: false },
    maxWidth: { type: Number, required: false, default: 950 },
    bgColor: { type: Boolean, required: false, default: false },
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 32px 0px 57.5px 0px;
  p:not(:last-child) {
    margin-bottom: 24px;
  }
  .button {
    margin-top: 10px;
  }

  &__container {
    flex-direction: column;
    display: flex;
    padding: 0 24px;
    box-sizing: border-box;
  }

  &__headline {
    margin-top: 40px;
    margin-bottom: 24px;
  }

  &__image {
    @media (max-width: 750px) {
      display: flex;
      justify-content: center;
    }

    img {
      max-width: 100%;
    }
  }

  @media (min-width: 750px) {
    padding: 60px 0;
    width: 100%;

    &__column {
      max-width: 502px;
      padding-left: 20px;
    }

    &__container {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      margin: 0 auto;
      max-width: 950px;
    }

    &__headline {
      margin-top: 0;
    }

    img {
      max-width: 360px;
    }
  }

  // Reverse modifier, renders the image on the right
  &--reverse {
    .card__image {
      order: 2;
    }

    .card__column {
      display: grid;
      order: 1;
    }
  }

  // Small modifier, renders the mobile view at all viewport sizes
  &--small {
    max-width: 100%;

    .card__container {
      flex-direction: column;
    }

    .card__column {
      padding-left: 0;
    }

    .card__headline {
      margin-top: 32px;
      font-size: 24px;
    }
    .headline4 {
      font-size: 24px;
      @media (max-width: 750px) {
        font-size: 16px;
        margin: 0;
      }
    }
    @media (min-width: 750px) {
      max-width: 320px;
      img {
        max-width: 320px;
      }
    }
  }
  &--bg-color {
    background-color: $c-secondary-background;
  }
  .tip {
    display: flex;
    align-content: center;
    img {
      width: 25px;
      height: 25px;
      order: 1;
    }
    p {
      max-width: 470px;
      margin: 0;
      margin-left: 15px;
      order: 2;
    }
  }
}
</style>
