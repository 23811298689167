<template>
  <article>
    <Hero heading="Merci ! Maintenant, vérifiez vos courriels pour terminer l’inscription." :subheading="true">
      <template #subheading>
        <p>
          Nous avons bien reçu votre requête, et nous vous avons fait parvenir un courriel de validation à l’adresse
          {{ userEmail }}.
        </p>
        <br />
        <p>
          Sur réception du courriel, vous aurez 48 heures pour compléter votre enregistrement en cliquant sur
          l’hyperlien qui y sera joint. De cette façon, nous vous tiendrons au courant de toutes nos mises à jour.
        </p>
        <br />
        <p>
          Pour toute urgence, vous pouvez nous joindre 24 heures sur 24, 7 jours sur 7; en composant le 1-833-231-3226.
        </p>
      </template>
    </Hero>
  </article>
</template>

<script>
import Hero from '@/components/Hero/Hero.vue';

export default {
  name: 'verify-email-signup-fr',
  metaInfo: {
    title: 'Vérifiez vos courriels',
    meta: [{ name: 'robots', content: 'noindex' }],
  },
  components: { Hero },
  computed: {
    userEmail() {
      return this.$store.state.email;
    },
  },
};
</script>
