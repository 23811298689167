export default {
  en: {
    agreeFutureComms: 'I agree to receive further information from Electrify Canada',
    agreeUnsubscribe: 'I am aware that I may unsubscribe to electronic communications at any time.',
    callUs: 'Call us directly 24 hours a day / 7 days a week at 1-833-231-3226.',
    city: 'City',
    communication: 'Communication',
    communicationMethods: ['Text', 'Email'],
    contactMe: 'Contact me with a response',
    contactTopicList: ['Company News', 'General Information', 'Locations', 'Press Releases', 'Website', 'Other'],
    contactUs: 'Contact us',
    country: 'Country',
    countryList: ['United States', 'Canada'],
    details: 'Please fill in your details below and we will be in touch with you shortly.',
    email: 'Email',
    error: 'Error:',
    firstName: 'First name',
    furtherUpdates: 'Sign up to receive further information from Electrify Canada.*',
    futureComm: 'I would like to receive further information from Electrify Canada',
    howWeCanHelp: 'Please tell us how we can help you',
    informationRecieved: 'Your information has been received.',
    language: 'Language',
    languageList: ['English', 'French'],
    lastName: 'Last name',
    lookingForAnswers: "Looking for answers? You've come to the right place.",
    mailingAddress: 'Mailing address',
    meta: {
      contactUs: {
        title: 'Contact us with a question or problem',
        description:
          "Have a question or need to report a problem? Electrify Canada wants to hear from you. Fill out the form and we'll get back to you soon.",
      },
      signUp: {
        title: 'Sign up for updates',
        description:
          "Want to stay up-to-date on the latest news about Electrify Canada's electric vehicle (EV) public charging network? Use the form to subscribe and get updates.",
      },
      unsubscribe: {
        title: 'Unsubscribe',
        description: 'You have successfully unsubscribed from our email list.',
      },
    },
    phone: 'Phone',
    postalCode: 'Postal code',
    preference: ' Preferences (optional)',
    province: 'Province',
    provinces: 'Provinces',
    requiredInfo: 'Required Information',
    searchTerm: 'Enter the terms you wish to search for.',
    selectACountry: 'Select a country',
    selectCommunicationMethod: 'Select a communication method',
    selectLanguage: 'Select a language',
    selection: 'Select a ',
    signup: 'Sign up for updates',
    signupTopicList: ['Company News', 'Events', 'Locations', 'Press Releases', 'Pricing'],
    states: 'States',
    state: 'State',
    submit: 'submit',
    subscribe: 'Subscribe.',
    successfulUnsubscribe: 'You have successfully unsubscribed from our email list.',
    street: 'Street',
    thankYou: 'Thank You',
    topics: 'Topics',
    unsubscribedError: 'Did you unsubscribe in error?',
    unsubscribedMsg: 'Electrify Canada will no longer send you this kind of email message.',
    zipCode: 'Zip code',
  },
  fr: {
    agreeFutureComms: "J'accepte de recevoir d'autres informations d'Electrify Canada",
    agreeUnsubscribe: 'Je sais que je peux me désabonner des communications électroniques à tout moment',
    callUs: 'Appelez-nous 24 heures sur 24, 7 jours sur 7 au 1-833-231-3226.',
    city: 'Ville',
    communication: 'Communication',
    communicationMethods: ['Messagerie texte', 'E-mail'],
    contactMe: 'Merci de me répondre',
    contactTopicList: [
      "Nouvelles de l'entreprise",
      'Informations générales',
      'Sites',
      'Communiqué de presse',
      'Site Web',
      'Autre',
    ],
    contactUs: 'Pour nous joindre',
    country: 'Pays',
    countryList: ['États-Unis', 'Canada'],
    details: 'Veuillez remplir vos coordonnées ci-dessous, et nous vous contacterons rapidement.',
    email: 'Adresse de courriel',
    error: 'Error:',
    firstName: 'Prénom',
    furtherUpdates: "Inscrivez-vous pour recevoir plus d'information d'Electrify Canada*",
    futureComm: "J'aimerais recevoir des informations supplémentaires d'Electrify Canada",
    howWeCanHelp: 'Laissez-nous savoir comment nous pouvons vous aider',
    informationRecieved: 'Vos informations ont été reçues.',
    language: 'Langue',
    languageList: ['Anglais', 'Français'],
    lastName: 'Nom de famille',
    lookingForAnswers: 'Vous cherchez des réponses? Vous êtes au bon endroit.',
    mailingAddress: 'Adresse postale',
    meta: {
      contactUs: {
        title: 'Contactez-nous pour toute question ou tout problème',
        description:
          'Vous avez une question ou vous devez signaler un problème? Electrify Canada souhaite recevoir vos commentaires. Veuillez remplir le formulaire et nous vous répondrons dès que possible.',
      },
      signUp: {
        title: 'S’inscrire aux mises à jour',
        description:
          'Vous voulez rester au courant des dernières nouvelles à propos du réseau public de recharge des véhicules électriques (VE) d’Electrify Canada? Utilisez le formulaire pour vous abonner et obtenir des mises à jour.',
      },
      unsubscribe: {
        title: 'Désinscription',
        description: 'Vous vous êtes désinscrit de notre liste de distribution avec succès.',
      },
    },
    phone: 'Téléphone',
    postalCode: 'Code postal',
    preference: 'Préférences (facultatif)',
    provinces: 'Province',
    province: ' ',
    requiredInfo: 'Informations requises',
    searchTerm: 'Entrez les termes que vous souhaitez rechercher.',
    selectACountry: 'Sélectionnez une pays',
    selectCommunicationMethod: 'Sélectionnez une méthode de communication',
    selectLanguage: 'Sélectionnez une langue',
    selection: 'Selectionnez',
    signup: 'S’inscrire pour recevoir des mises à jour',
    signupTopicList: ["Nouvelles de l'entreprise", 'Events', 'Sites', 'Communiqué de presse', 'Pricing'],
    states: 'État',
    state: ' ',
    street: 'Adresse ligne',
    submit: 'soumettre',
    subscribe: 'Inscrivez vous de nouveau.',
    successfulUnsubscribe: 'Vous vous êtes désinscrit de notre liste de distribution avec succès.',
    thankYou: 'Merci',
    topics: 'Thèmes',
    unsubscribedError: 'Vous êtes vous désinscrit par erreur?',
    unsubscribedMsg: 'Electrify Canada ne vous enverra plus ce genre de message courriel.',
    zipCode: 'Code postal',
  },
};
