<template>
  <article class="disclosure">
    <h1>Forfait de recharge pour les véhicules ID.4™ 2023 et 2024</h1>
    <h2>{{ $t('termsAndConditions') }}</h2>
    <p class="primary-color">
      <strong>En vigueur à compter du 15 février 2024</strong>
    </p>
    <p>
      Electrify Canada et Groupe Volkswagen Canada sont heureux d’offrir ce forfait promotionnel de recharge (le «
      forfait ») aux conducteurs de véhicules neufs ID.4 de Volkswagen des années 2023 et 2024 achetés auprès d’un
      détaillant Volkswagen Canada autorisé. En cliquant sur « Accepter » ci-dessous, vous acceptez de participer à
      cette promotion. Votre participation dépend de votre respect des présentes modalités (les « modalités du forfait
      »).
    </p>
    <p>
      La participation au forfait nécessite un compte Electrify Canada, l’application mobile Electrify Canada,
      l’inscription au forfait dans l’application et l’acceptation des
      <router-link class="link" :to="{ name: 'terms-fr' }">conditions d’utilisation d’Electrify Canada</router-link> ,
      qui régissent votre utilisation de l’application et du réseau d’Electrify Canada. Des frais de messagerie, de
      données et d’autres frais normaux de votre fournisseur de services cellulaires peuvent s’appliquer à votre
      utilisation de l’application Electrify Canada.
    </p>
    <p>
      Immédiatement après votre inscription et l’acceptation des présentes modalités du forfait, vous serez inscrit au
      forfait comprenant 1 400 kWh de recharge rapide à courant continu (SRC, système de recharge combiné) sans frais
      supplémentaires pour votre ID.4 de Volkswagen sur le réseau de stations de recharge pour véhicules électriques
      d’Electrify Canada. Ce forfait sera valide pendant 48 mois à compter de la date d’achat de votre véhicule (fournie
      à Electrify Canada par le fabricant) jusqu’à ce qu’il soit épuisé, ou si Electrify Canada décide de le résilier
      plus tôt, comme décrit ci-dessous.
    </p>
    <p>
      Une fois que vous serez inscrit au forfait, vous pourrez commencer à utiliser vos 1 400 kWh de recharge inclus à
      n’importe quelle borne de recharge Electrify Canada, en utilisant la fonctionnalité « Balayez pour commencer la
      recharge » de l’application Electrify Canada ou la fonctionnalité Passe CCP en conjonction avec le
      <strong class="primary-color-text">forfait de recharge pour ID.4 2023 et 2024 </strong>.
      <strong
        >La recharge comprise dans ce forfait ne peut pas être lancée au moyen de l’écran d’interface ni des boutons de
        la borne de recharge.</strong
      >
      La fonctionnalité Brancher et recharger sera également offerte avec ce forfait. La fonctionnalité Brancher et
      recharger offre aux propriétaires de VE ID.4 une meilleure expérience de recharge en créant un mode de paiement
      sécuritaire, pratique et sans effort sur le réseau Electrify Canada.
    </p>
    <p>
      Aucuns frais supplémentaires, ni achat, ni abonnement ne sont requis pour accéder à cette promotion.
      <strong
        >À l’épuisement ou à l’expiration de ce forfait, les recharges se poursuivront sans interruption et seront
        facturées selon le tarif de recharge en vigueur pour la passe Electrify Canada.</strong
      >
    </p>
    <strong>
      VEUILLEZ NOTER LES LIMITATIONS IMPORTANTES SUIVANTES CONCERNANT VOTRE UTILISATION DE LA PRÉSENTE PROMOTION
      :</strong
    >
    <p>
      Vous pouvez <strong>uniquement</strong> utiliser le crédit inclus pour recharger le véhicule ID.4 de Volkswagen
      dont vous avez fourni le NIV lors de votre inscription à la promotion dans votre compte Electrify Canada.
      <strong>Aucun autre véhicule n’est admissible à ce crédit au moyen de votre compte</strong> , qu’il appartienne à
      vous ou à d’autres.
    </p>
    <p>
      Vous ne pouvez <strong>pas</strong> utiliser ce forfait de recharge gratuite dans le cadre d’un service de taxi,
      de covoiturage ou d’un autre service similaire, ni à toute autre fin commerciale.
      <strong>Cette promotion est réservée à votre usage personnel et non commercial.</strong>
    </p>
    <p>
      La recharge gratuite <strong>n’inclut pas</strong> les frais d’occupation ni les taxes applicables appliquées à la
      station de recharge. Vous serez responsable du paiement des frais applicables durant les sessions de recharge au
      moyen de votre compte.
    </p>
    <p>
      Vous devez respecter toute la documentation technique relative au produit, à l’ID.4 de Volkswagen et à la sécurité
      fournie avec le véhicule lorsque vous le rechargez à une station Electrify Canada.
    </p>
    <p>Vous devez utiliser le bon type de connecteur pour l’ID.4 de Volkswagen à la station de recharge.</p>
    <p>
      Une fois que les 1 400 kWh de recharge gratuite seront épuisés, vous ne pourrez plus renouveler le
      <strong class="primary-color">forfait de recharge pour véhicules ID.4 de Volkswagen 2023 et 2024</strong> ni y
      ajouter des fonds. Pour voir le temps restant à la promotion, reportez-vous aux données contenues dans la section
      <strong class="primary-color">Renseignements sur le forfait</strong> de l’application.
    </p>
    <p>
      Ce crédit n’est ni remboursable ni échangeable contre une valeur en argent et ne peut être vendu, prêté ou
      autrement distribué à un tiers pour quelque raison que ce soit. Vous ne pouvez transférer aucune partie du crédit
      vers un autre compte Electrify Canada.
    </p>
    <p>
      Vous reconnaissez que votre utilisation des services d’Electrify Canada est soumise aux conditions d’utilisation
      d’Electrify Canada, accessibles à l’adresse suivante :
      <router-link class="link" :to="{ name: 'terms-fr' }"
        >https://www.electrify-canada.ca/fr/mentions-legales</router-link
      >
      , et que votre utilisation de l’application mobile d’Electrify Canada est soumise aux conditions d’utilisation
      accessibles en sélectionnant l’icône <strong class="primary-color">Compte</strong> dans le coin inférieur droit de
      l’application, puis
      <strong class="primary-color">Juridique > Conditions d’utilisation > Politique de confidentialité</strong>.
      Veuillez lire attentivement ces documents, car ils décrivent la manière dont les frais d’occupation, les taxes
      applicables et le temps de recharge au-delà de ce qui est inclus dans cette promotion seront portés à votre
      compte.
    </p>
    <p>
      Vous acceptez de vous conformer à toutes les lois et les réglementations en vigueur lorsque vous utilisez les
      services d’Electrify Canada et que vous rechargez votre véhicule aux stations de recharge d’Electrify Canada.
    </p>
    <p>
      Electrify Canada se réserve le droit de retirer, de révoquer, de réduire, de résilier ou de suspendre votre accès
      à cette promotion, en totalité ou en partie, sans préavis, si Electrify Canada détermine ou soupçonne, à sa seule
      discrétion, que vous : (a) êtes en violation des conditions d’utilisation d’Electrify Canada, des présentes
      {{ $t('termsAndConditions') }} ou de tout autre contrat entre vous et Electrify Canada; (b) avez effectué des
      sessions de facturation frauduleuses ou qui indiquent une utilisation non autorisée ou involontaire de cette
      promotion; ou (c) avez eu une conduite illégale, frauduleuse, délictuelle, préjudiciable, nuisible ou abusive dans
      votre utilisation des services d’Electrify Canada, de l’application mobile Electrify Canada ou d’une station de
      recharge d’Electrify Canada.
    </p>
    <p>
      Electrify Canada se réserve le droit de mettre fin à cette promotion gratuite à tout moment avant sa date de
      résiliation automatique pour quelque raison que ce soit, à sa seule discrétion. Dans une telle éventualité,
      Electrify Canada enverra un courriel à l’adresse associée à votre compte indiquant la modification de la date de
      fin de la promotion. À la suite de la résiliation ou de l’expiration de ce forfait pour quelque raison que ce
      soit, votre accès à la promotion cessera immédiatement et vous ne pourrez plus utiliser les avantages qu’elle
      offrait. À ce moment, votre compte sera automatiquement converti en un compte Passe Electrify Canada de base. Vous
      pouvez (sans obligation) souscrire un autre type d’abonnement au moyen de l’application mobile Electrify Canada.
    </p>
  </article>
</template>

<script>
import lang from '@/lang';

export default {
  name: 'VWID4-disclosure-fr',
  metaInfo: {
    title: 'Divulgation VW ID.4 d’Electrify Canada pour 2023 et 2024',
    link: [
      {
        name: 'description',
        content:
          'Divulgation des modalités de la promotion du forfait de recharge VW ID.4 d’Electrify Canada pour 2023 et 2024',
      },
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/fr/2023-2024-vw-id4-divulgation-concernant-le-forfait-de-recharge/',
      },
    ],
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
};
</script>
