<template>
  <div class="email-verification">
    <div v-show="loading" class="loading">
      <span></span>
    </div>
    <div v-show="!loading" class="container" :class="brand">
      <!-- <img v-if="brand === 'jeep-4xe'" src="@/assets/images/EmailVerification/jeep-4xe-logo-lockup.png" alt="" /> -->
      <img src="@/assets/images/EmailVerification/LogoBolt.png" alt="Logo de boulon d’Electrify Canada" />
      <h1 v-if="verified">Merci! Votre compte est vérifié.</h1>
      <h1 v-else>Oups! Un problème s’est produit.</h1>
      <template v-if="verified">
        <p v-if="brand === 'jeep-4xe'">
          N'hésitez pas à fermer cette page et à continuer à utiliser l'appli Electrify Canada. Vous devrez peut-être
          ouvrir une nouvelle session.
        </p>
        <p v-else>
          N'hésitez pas à fermer cette page et à continuer à utiliser l'appli Electrify Canada. Vous devrez peut-être
          ouvrir une nouvelle session.
        </p>
      </template>
      <template v-else>
        <template v-if="brand === 'jeep-4xe'">
          <p>Retournez à l'appli Electrify Canada et sélectionnez « Envoyer de nouveau » pour réessayer.</p>
        </template>
        <template v-else>
          <p>Retournez à l'appli Electrify Canada et sélectionnez « Envoyer de nouveau » pour réessayer.</p>
        </template>
        <p>Cela ne fonctionne toujours pas? Appelez notre équipe d’assistance au 1 833 231-3226.</p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'email-validate-en',
  metaInfo: {
    title: 'Erreur de validation de courriel',
    meta: [
      { name: 'robots', content: 'noindex' },
      {
        name: 'description',
        content:
          'Retournez Electrify Canada et sélectionnez « Envoyer de nouveau » pour réessayer.Vous rencontrez encore une erreur? Appelez d’assistance 1 833 231-3226. ',
      },
    ],
  },
  created() {
    setTimeout(() => {
      const isSafari = window.safari !== undefined;
      const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      if (isSafari && !iOS) {
        this.loading = false;
      } else {
        if (this.brand === 'jeep-4xe') {
          window.location = 'eajeepapp://validated_email';
        } else {
          window.location = 'ecapp://validated_email';
        }
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    }, 1000);
    const parameters = this.$route.query.success;
    if (parameters === 'true') {
      this.verified = !this.verified;
    }
  },
  data() {
    return {
      loading: true,
      verified: null,
    };
  },
  computed: {
    brand() {
      switch (this.$route.name) {
        case 'email-validate-jeep-4xe-en':
          return 'jeep-4xe';
        default:
          return 'ea';
      }
    },
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    userEmail() {
      return this.$store.state.email;
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  min-height: 680px;
}

.loading {
  @keyframes loading {
    to {
      transform: rotateZ(360deg);
    }
  }
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    display: block;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: #02125d;
    border-bottom-color: #02125d;
    animation: loading 850ms ease-in-out infinite;
  }
}

.container {
  padding: 80px 20px 0;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

img {
  width: 43px;
  height: auto;
}

h1 {
  margin: 32px 0 24px;
  font-size: 48px;
  font-weight: $fw-medium;
}

p {
  font-size: 16px;
  color: #000;
}

// Brand styles
.jeep-4xe {
  img {
    width: 242px;
  }
}
</style>
