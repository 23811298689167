import Locations from '../../services/Locations';

// initial state for locations data
const state = () => ({
  // Unmodified location data returned from the API
  locations: [],
  // Location data with pricing data added
  locationMarkers: [],
  error: {},
  uniqueLocation: null,
  uniqueRoute: null,
  filterStateLocations: {},
  selectedStateCityLocations: {},
  selectedCityLocations: [],
});

/**
 * Parse Pricing Data
 *
 * Using the provided pricingData from API response, gather an object for pass
 * pricing in both ENglish and French.
 *
 * @param {Object} pricingData
 * @returns
 */
const parsePricingData = (pricingData) => {
  const parsedData = { en: { pass: [], pass_plus: [] }, fr: { pass: [], pass_plus: [] } };

  // Failsafe in case pricingData is null, return static values
  if (pricingData === null || pricingData.length < 1) {
    parsedData.en.pass.push({
      level: `UP TO 90 kW`,
      price: `$0.27 / min`,
      rate: 'min',
    });
    parsedData.en.pass.push({
      level: `1 - 350 kW`,
      price: `$0.57 / min`,
      rate: 'min',
    });
    parsedData.fr.pass.push({
      level: `JUSQU'À 90 KW`,
      price: `0,27 $/min`,
      rate: 'min',
    });
    parsedData.fr.pass.push({
      level: `1 - 350 kW`,
      price: `0,57 $/min`,
      rate: 'min',
    });
    parsedData.en.pass_plus.push({
      level: `UP TO 90 kW`,
      price: `$0.21 / min`,
      rate: 'min',
    });
    parsedData.en.pass_plus.push({
      level: `1 - 350 kW`,
      price: `$0.44 / min`,
      rate: 'min',
    });
    parsedData.fr.pass_plus.push({
      level: `JUSQU'À 90 KW`,
      price: `0,21 $/min`,
      rate: 'min',
    });
    parsedData.fr.pass_plus.push({
      level: `1 - 350 KW`,
      price: `0,44 $/min`,
      rate: 'min',
    });
  } else {
    pricingData.forEach((price) => {
      if (typeof price.time !== 'undefined') {
        parsedData.en.pass.push({
          level: `${price.minPower} - ${price.maxPower} kW`,
          price: `$${price.time} / min`,
          rate: 'min',
        });
        const priceFR = price.time.toString().replace('.', ',');
        parsedData.fr.pass.push({
          level: `${price.minPower} - ${price.maxPower} kW`,
          price: `${priceFR} $/min`,
          rate: 'min',
        });
        // TODO - This Pass+ data is static for now.
        parsedData.en.pass_plus.push({
          level: `${price.minPower}-${price.maxPower} kW`,
          price: `$${price.maxPower > 90 ? '0.44' : '0.21'} / min`,
          rate: 'min',
        });
        parsedData.fr.pass_plus.push({
          level: `${price.minPower}-${price.maxPower} kW`,
          price: `${price.maxPower > 90 ? '0,44' : '0,21'} $/min`,
          rate: 'min',
        });
      } else if (typeof price.energy !== 'undefined') {
        parsedData.en.pass.push({
          level: `${price.minPower} - ${price.maxPower} kW`,
          price: `$${price.energy}/kWh`,
          rate: 'kWh',
        });
        const priceFR = price.energy.toString().replace('.', ',');
        parsedData.fr.pass.push({
          level: `${price.minPower} - ${price.maxPower} kW`,
          price: `${priceFR} $/kWh`,
          rate: 'kWh',
        });
        //! Note - Don't have static value for kW.
      }
    });
  }

  return parsedData;
};

const getters = {
  /**
   * Locate a Charger - Individual Location Details
   *
   * Get the location marker data from locationMarkers based on a unique identifier
   * provided in id.
   *
   * @param {int} id
   * @returns Individual location object
   */
  // eslint-disable-next-line no-shadow
  getLocationMarkerById: (state) => (id) => state.locationMarkers.find((location) => location.id === id),
};

// actions
const actions = {
  async getAllLocations({ commit }, router) {
    const locations = await Locations.getLocations();
    commit('setLocations', locations);
    commit('setLocationMarkers', { locations, router });
    commit('setStateLocations', locations);
  },
  async getLocationDetail({ commit }, locationid) {
    let location = {};
    try {
      location = await Locations.getLocationDetail(locationid);
    } catch (error) {
      commit('setError', error);
    }
    commit('setLocationMarkerDetail', location);
  },
};

// mutations
const mutations = {
  // eslint-disable-next-line no-shadow
  setLocations(state, locations) {
    state.locations = locations;
  },
  // eslint-disable-next-line no-shadow
  setError(state, error) {
    state.error = error;
  },
  // eslint-disable-next-line no-shadow
  setUniqueLocation(state, marker) {
    state.uniqueLocation = marker;
  },
  // eslint-disable-next-line no-shadow
  setUniqueRoute(state, route) {
    state.uniqueRoute = route;
  },
  /**
   * Set Location Markers
   *
   * Using unmodified locations data provided in locations, create an array of locationMarkers and save to state.
   *
   * @param {Object} state - Store state passed in
   * @param {*} locations - Array of locations data coming from the API
   */
  // eslint-disable-next-line no-shadow
  setLocationMarkers(state, { locations, router }) {
    const locationMarkers = [];
    locations.forEach((res) => {
      const data = {
        id: res.id,
        position: { lat: res.coordinates.latitude, lng: res.coordinates.longitude },
        comingSoon: res.type === 'COMING_SOON',
        distance: null,
        name: res.name,
        enterpriseName: res.suboperator && res.suboperator.name ? res.suboperator.name : null,
        city: res.city,
        state: res.state,
        zipCode: res.postalCode,
        address: res.address,
        locationid: res.siteId,
        facility: res.type,
        description: null,
        markerSelected: null,
        passPricing: [],
        stationTypes: null,
        stationCounts: {},
        CCS: null,
        active: true,
        isCommercialChargerLocation: res.type === 'COMMERCIAL',
        isCcs: res.isCcs,
        isChademo: res.isChademo,
      };
      locationMarkers.push(data);
    });
    state.locationMarkers = locationMarkers;
    if (router.currentRoute.params.id) {
      const selected = state.locationMarkers.filter((marker) => marker.id === router.currentRoute.params.id);
      // eslint-disable-next-line prefer-destructuring
      state.uniqueLocation = selected[0];
    }
  },

  /**
   * Set Location Marker Detail
   *
   * For the provided location:
   *   - Calculate station charger counts
   *   - Parse pricing data
   *   - Store EVSEs and description
   * Save these updates to the existing state.locationMarkers object.
   *
   * @param {Object} state - Store state passed in
   * @param {*} location
   */
  // eslint-disable-next-line no-shadow
  setLocationMarkerDetail(state, location) {
    state.locationMarkers.map((marker) => {
      if (marker.id === location.id) {
        const counts = {
          ccs: 0,
          chademo: 0,
          l2: 0,
        };
        location.evses.forEach((station) => {
          if (station.connectors) {
            let type = 'ccs'; // IEC_62196_T1_COMBO
            station.connectors.some((port) => {
              if (port.standard.toLowerCase().indexOf('chademo') > -1) {
                type = 'chademo';
                return true;
              }
              if (port.standard.toLowerCase() === 'iec_62196_t1') {
                type = 'l2';
                return true;
              }
              return false;
            });
            counts[type] += 1;
          }
        });
        marker.passPricing = parsePricingData(location.pricing);
        marker.stationCounts = counts;
        marker.stationTypes = location.evses.sort((a, b) => (a.id.split('-').pop() > b.id.split('-').pop() ? 1 : -1));
        marker.description = location.description;
      }
      return marker;
    });
  },

  /**
   * Set State locations
   *
   * @param {Object} state - Store state passed in
   * @param {*} locations - Array of locations data coming from the API
   */
  // eslint-disable-next-line no-shadow
  setStateLocations(state, locations) {
    const stateMap = {};
    locations.forEach((e) => {
      if (e.state && e.city && e.country !== 'USA') {
        if (!stateMap[e.state]) {
          stateMap[e.state] = [];
        }
        stateMap[e.state].push(e);
      }
    });

    Object.keys(stateMap).forEach((item) => {
      stateMap[item].sort();
    });
    const sortedStateCityMap = {};
    Object.keys(stateMap)
      .sort()
      .forEach((i) => {
        sortedStateCityMap[i] = stateMap[i];
      });
    state.filterStateLocations = sortedStateCityMap;
  },
  /**
   * Set Selected State City locations
   *
   * @param {Object} state - Store state passed in
   * @param {*} selectedState - Selected state
   */
  // eslint-disable-next-line no-shadow
  setSelectedStateCityLocations(state, selectedState) {
    if (Object.keys(state.filterStateLocations).length > 0) {
      const filterCityLocation = Object.keys(state.filterStateLocations).filter(
        (e) => e.replaceAll(' ', '-').toLowerCase() === selectedState
      );
      const cityLocations = filterCityLocation.length
        ? Object.values(state.filterStateLocations[filterCityLocation[0]])
        : [];
      const stateCityMap = {};
      cityLocations.forEach((e) => {
        if (e.city) {
          if (!stateCityMap[e.city]) {
            stateCityMap[e.city] = [];
          }
          stateCityMap[e.city].push(e);
        }
      });
      Object.keys(stateCityMap).forEach((city) => {
        stateCityMap[city].sort();
      });
      const sortedCityMap = {};
      Object.keys(stateCityMap)
        .sort()
        .forEach((city) => {
          sortedCityMap[city] = stateCityMap[city];
        });
      state.selectedStateCityLocations = sortedCityMap;
    }
  },
  /**
   * Set Selected City stations
   *
   * @param {Object} state - Store state passed in
   * @param {*} selectedCity - Selected city
   */
  // eslint-disable-next-line no-shadow
  setSelectedCityLocations(state, selectedCity) {
    if (Object.keys(state.selectedStateCityLocations).length > 0) {
      const filterCityLocation = Object.keys(state.selectedStateCityLocations).filter(
        (e) => e.replaceAll(' ', '-').toLowerCase() === selectedCity
      );
      const cityLocations = filterCityLocation.length
        ? Object.values(state.selectedStateCityLocations[filterCityLocation[0]])
        : [];
      const sortedCityLocations = cityLocations.sort((a, b) => a.name.localeCompare(b.name));
      state.selectedCityLocations = sortedCityLocations;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
