<template>
  <article class="expired-email">
    <Hero heading="Votre lien de validation est expiré." :subheading="true">
      <template #subheading>
        <p>
          Veuillez visiter le <a class="link" href="https://electrify-canada.ca/fr/">www.Electrify-Canada.ca</a> et
          soumettre de nouveau le formulaire d’inscription courriel. Par la suite, veuillez valider votre adresse
          courriel en suivant les instructions dans le message reçu. Le lien de validation restera actif pour les 48
          heures suivant sa réception.
        </p>
        <br />
        <p>
          Pour toute urgence, vous pouvez nous joindre directement 24 heures sur 24, 7 jours sur 7; en composant le
          1-833-231-3226.
        </p>
      </template>
    </Hero>
  </article>
</template>

<script>
import Hero from '../components/Hero/Hero.vue';

export default {
  name: 'expired-email-fr',
  metaInfo: {
    title: 'Hyperlien de validation',
    meta: [{ name: 'robots', content: 'noindex' }],
  },
  components: { Hero },
};
</script>
