<template>
  <article class="disclosure">
    <h1>2021 and 2022 ID.4 Charging Plan</h1>
    <h2 class="primary-color">{{ $t('termsAndConditions') }}</h2>
    <p>
      <strong class="primary-color">Effective as of October 1, 2021</strong>
    </p>
    <p>
      By clicking “Agree” below, you agree to receive three years of complimentary charging sessions for your newly
      purchased model year 2021 and 2022 Volkswagen ID.4 electric vehicle on Electrify Canada’s network of electric
      vehicle charging stations, subject to these {{ $t('termsAndConditions') }}. There is no additional fee, purchase,
      or subscription required for your access to this promotion.
    </p>
    <p>
      After signing up, you can redeem this complimentary charging time for your vehicle during your initiation of a
      charging session at any Electrify Canada charging station, exclusively by using the Electrify Canada app “Swipe to
      start” or NFC Pass feature with the appropriate plan selected.
    </p>
    <p><strong>PLEASE NOTE THE FOLLOWING IMPORTANT LIMITATIONS ON YOUR USE OF THIS PROMOTION:</strong></p>
    <ul>
      <li>
        You may <strong>only</strong> use this complimentary charging time for the eligible model year 2021 and 2022
        Volkswagen ID.4 electric vehicle, purchased from an authorized Volkswagen dealer in Canada, whose VIN you
        submitted when signing up for this promotion on your Electrify Canada account.
        <strong>No other vehicle is eligible to receive this complimentary charging through your account,</strong>
        whether owned by you or others.
      </li>
      <li>
        To enroll, you will need to provide a valid credit card which will be charged a selected autofill amount at the
        first Electrify Canada charging session, and thereafter any time the wallet balance falls below the threshold
        noted in the app during signup. We recommend selecting a lower autofill amount, such as $10, since the
        complimentary charging provided under this plan will not deduct any fees from this wallet balance.
      </li>
      <li>
        There will be a 10-minute grace period immediately following the end of your charging session, during which you
        will not be assessed any parking or idle fees. Except for this grace period, complimentary charging time
        <strong>does not include</strong> any parking or idle fees assessed at the charging station.
      </li>
      <li>
        You may <strong>not</strong> use this complimentary charging time in connection with any taxi, ride-share (e.g.,
        Uber or Lyft), or other similar service, or for any other commercial purpose.
        <strong>This promotion is for your personal, non-commercial use only.</strong>
      </li>
      <li>
        You must follow all product, vehicle, safety, and technical documentation included with your vehicle when
        charging at an Electrify Canada charging station.
      </li>
      <li>
        Complimentary charging will be provided under this promotion only for the specific 2021 and 2022 Volkswagen ID.4
        vehicle registered to your account.
      </li>
    </ul>
    <p>
      This promotion will automatically end thirty-six (36) months after the date of vehicle purchase of your 2021 or
      2022 Volkswagen ID.4 electric vehicle. After this date you will not be able to access or use this promotion on
      your account. This complimentary charging time is not returnable or redeemable for any cash value, and may not be
      sold, loaned, or otherwise distributed to any third party for any reason.
    </p>
    <p>
      You acknowledge that your use of Electrify Canada’s services is subject to the Electrify Canada Terms of Use
      available at <router-link to="/terms" class="link">https://www.electrify-canada.ca/terms,</router-link> and that
      your use of the Electrify Canada mobile app is subject to the Terms of Use accessible by selecting
      <strong>“Legal”</strong> in the Account Management portion of the mobile app (accessed by navigating to the
      <strong>Profile</strong> icon in top right hand corner of map), then selecting
      <strong>“Terms of Use” & “Privacy policy.”</strong> Please read these Terms of Use documents carefully for
      information about how you can pay through your account for charging session time outside of the complimentary
      three years offered under this promotion.
    </p>
    <p>
      You agree to comply with all applicable laws and regulations when using Electrify Canada services, and when
      charging your vehicle at Electrify Canada charging stations.
    </p>
    <p>
      Electrify Canada reserves the right to withhold, revoke, reduce, terminate, or suspend your access to all or any
      portion of this promotion, without notice, if Electrify Canada determines or suspects, in its sole discretion,
      that you: (a) are in violation of the Electrify Canada Terms of Use, these {{ $t('termsAndConditions') }}, or any
      other contract between you and Electrify Canada; (b) have engaged in charging sessions that are excessive,
      fraudulent, or otherwise indicate an unauthorized or unintended use of this complimentary promotion; or (c) have
      engaged in any illegal, fraudulent, tortious, injurious, harmful, or abusive conduct in your use of the Electrify
      Canada services, the Electrify Canada mobile app, or an Electrify Canada charging station.
    </p>
    <p>
      Following the termination or expiration of this promotion for any reason, your access to the complimentary
      promotion will immediately cease, and you may not use or redeem any remaining portion of complimentary charging
      time. At that time, your account will automatically convert to a basic Electrify Canada
      <strong>“Pass”</strong> membership account, at no additional fee or charge to you (neither one-time nor
      recurring). You may, but are not obligated to, sign up for a different type of subscription via the Electrify
      Canada website or mobile app.
    </p>
  </article>
</template>

<script>
import lang from '@/lang';

export default {
  name: '2021-2022-vw-id4-charging-plan-disclosure',
  metaInfo: {
    title: 'MY 2021-2022 Electrify Canada VW ID.4 disclosure',
    meta: [
      {
        name: 'description',
        content: 'MY 2021-2022 Electrify Canada VW ID.4 Charging Plan Promotion Terms and Conditions disclosure',
      },
    ],
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/2021-2022-vw-id4-charging-plan-disclosure/',
      },
    ],
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
};
</script>
