<template>
  <article class="disclosure">
    <h1>Plan de recharge pour les véhicules e-tron 2023 et 2024</h1>
    <h2>Conditions générales de la promotion</h2>
    <p class="primary-color">
      <strong>En vigueur à compter du 24 août 2022</strong>
    </p>
    <p>
      Electrify Canada et Audi Canada sont heureux d’offrir ce forfait promotionnel de recharge (le « forfait ») aux
      conducteurs de nouveaux véhicules e-tron d’Audi des années modèles 2023 et 2024 achetés auprès d’un détaillant
      Audi Canada autorisé. En cliquant sur « Accepter » ci-dessous, vous acceptez de participer à cette promotion, sous
      réserve de votre respect des présentes conditions générales (les « conditions du forfait »).
    </p>
    <p>
      La participation au plan nécessite un compte Electrify Canada, l’application mobile Electrify Canada,
      l’inscription au forfait dans l’application et l’acceptation des
      <router-link class="link" :to="{ name: 'terms-fr' }">conditions d’utilisation d’Electrify Canada</router-link>,
      qui régissent votre utilisation de l’application et du réseau d’Electrify Canada. Des frais de messagerie, de
      données et d’autres frais normaux de votre fournisseur de services cellulaires peuvent s’appliquer à votre
      utilisation de l’application Electrify Canada.
    </p>
    <p>
      Immédiatement après votre inscription et l’acceptation des présentes conditions du forfait, vous serez inscrit au
      forfait comprenant 1 400 kWh de courant continu rapide (CCS) sans frais supplémentaires pour votre e-tron d’Audi
      sur le réseau de stations de recharge pour véhicules électriques d’Electrify Canada. Ce forfait sera valide
      pendant 48 mois à partir de la date d’achat de votre véhicule (fournie à Electrify Canada par le fabricant)
      jusqu’à ce qu’il soit épuisé, ou si Electrify Canada décide de le résilier plus tôt, comme décrit ci-dessous.
    </p>
    <p>
      Une fois que vous serez inscrit au forfait, vous pouvez commencer à utiliser vos 1 400 kWh de recharge inclus à
      n’importe quelle station de recharge Electrify Canada, <strong>exclusivement</strong> en utilisant la
      fonctionnalité « Balayez pour commencer la recharge » de l’application Electrify Canada ou la fonctionnalité Passe
      CCP en conjonction avec le
      <strong class="primary-color">forfait de recharge e-tron 2023-2024.</strong>
      <strong>
        Les kWh de recharge inclus dans ce forfait ne peuvent pas être utilisés au moyen de l’écran d’interface ni des
        boutons de la borne.</strong
      >
    </p>
    <p>
      Il n’y a aucuns frais supplémentaires, achat, ni abonnement requis pour accéder à cette promotion.
      <strong
        >À l’épuisement ou à l’expiration de ce forfait, la facturation se poursuivra au prix de facturation en vigueur
        pour la passe Electrify Canada.</strong
      >
      Sous réserve de la période d’attente et des autres restrictions décrites ci-dessous, il n’y a aucune limite quant
      au nombre de sessions de recharge ou à la quantité de kWh de recharge lors de chaque session incluse dans le
      forfait.
    </p>
    <strong>
      VEUILLEZ NOTER LES LIMITATIONS IMPORTANTES SUIVANTES CONCERNANT VOTRE UTILISATION DE LA PRÉSENTE
      PROMOTION:</strong
    >
    <p>
      Vous pouvez <strong>uniquement</strong> utiliser le crédit inclus pour recharger le véhicule e-tron d’Audi dont
      vous avez fourni le NIV lors de votre inscription à la promotion dans votre compte Electrify Canada.
      <strong>Aucun autre véhicule n’est admissible à ce crédit au moyen de votre compte</strong>, qu’il appartienne à
      vous ou à d’autres.
    </p>
    <p>
      Vous ne pouvez <strong>pas</strong> utiliser ce forfait de recharge gratuite dans le cadre d’un service de taxi,
      de covoiturage ou d’un autre service similaire, ni à toute autre fin commerciale.
      <strong>Cette promotion est réservée à votre usage personnel et non commercial.</strong>
    </p>
    <p>
      La recharge gratuite <strong>n’inclut pas</strong> les frais d’occupation ni les taxes applicables appliquées à la
      station de recharge. Vous serez responsable du paiement des frais applicables durant les sessions de recharge au
      moyen de votre compte.
    </p>
    <p>
      Vous devez respecter toutes les consignes relatives aux produits, aux véhicules et à la sécurité ainsi que toute
      documentation technique incluse avec l’e-tron d’Audi lors de la recharge à une station Electrify Canada.
    </p>
    <p>Vous devez utiliser le bon type de connecteur pour l’e-tron d’Audi à la station de recharge.</p>
    <p>
      Une fois que les 1 400 kWh de recharge gratuite sont épuisés, vous ne pourrez plus renouveler le
      <strong class="primary-color">forfait de recharge pour e-tron d’Audi 2023-2024</strong> ni y ajouter des fonds.
      Pour voir le temps restant à la promotion, reportez-vous aux données contenues dans la section
      <strong class="primary-color">Renseignements sur le forfait</strong> de l’application.
    </p>
    <p>
      Ce crédit n’est ni remboursable ni échangeable contre une valeur en argent et ne peut être vendu, prêté ou
      autrement distribué à un tiers pour quelque raison que ce soit. Vous ne pouvez transférer aucune partie du crédit
      vers un autre compte Electrify Canada.
    </p>
    <p>
      Vous reconnaissez que votre utilisation des services d’Electrify Canada est assujettie aux conditions
      d’utilisation d’Electrify Canada, accessibles sur la page
      <router-link class="link" :to="{ name: 'terms-fr' }"
        >https://www.electrify-canada.ca/fr/mentions-legales</router-link
      >, et que votre utilisation de l’application Electrify Canada est soumise aux conditions d’utilisation. Vous
      pouvez les consultez en sélectionnant l’icône <strong class="primary-color">Profil</strong> dans le coin supérieur
      droit de la carte, <strong class="primary-color">Juridique</strong>, puis
      <strong class="primary-color">Conditions d’utilisation</strong> et
      <strong class="primary-color">Politique de confidentialité</strong>. Veuillez lire attentivement ces documents,
      car ils décrivent la manière dont les frais d’occupation, les taxes applicables et le temps de recharge au-delà de
      ce qui est inclus dans cette promotion seront portés à votre compte.
    </p>
    <p>
      Vous acceptez de vous conformer à toutes les lois et les réglementations en vigueur lorsque vous utilisez les
      services d’Electrify Canada et que vous rechargez votre véhicule aux stations de recharge d’Electrify Canada.
    </p>
    <p>
      Electrify Canada se réserve le droit de retirer, de révoquer, de réduire, de résilier ou de suspendre votre accès
      à cette promotion, en totalité ou en partie, sans préavis, si Electrify Canada détermine ou soupçonne, à sa seule
      discrétion, que vous : (a) êtes en violation des conditions d’utilisation d’Electrify Canada, des présentes
      conditions générales de la promotion ou de tout autre contrat entre vous et Electrify Canada; (b) avez effectué
      des sessions de facturation frauduleuses ou qui indiquent une utilisation non autorisée ou involontaire de cette
      promotion gratuite; ou (c) avez eu une conduite illégale, frauduleuse, délictuelle, préjudiciable, préjudiciable
      ou abusive dans votre utilisation des services d’Electrify Canada, de l’application mobile Electrify Canada ou
      d’une station de recharge d’Electrify Canada.
    </p>
    <p>
      Electrify Canada se réserve le droit de mettre fin à cette promotion gratuite à tout moment avant sa date de
      résiliation automatique pour quelque raison que ce soit, à sa seule discrétion. Dans une telle éventualité,
      Electrify Canada enverra un courriel à l’adresse associée à votre compte indiquant la modification de la date de
      fin de la promotion. À la suite de la résiliation ou de l’expiration de ce forfait pour quelque raison que ce
      soit, votre accès à la promotion gratuite cessera immédiatement et vous ne pourrez utiliser les avantages qu’elle
      offrait. À ce moment-là, votre compte sera automatiquement converti en un compte Passe Electrify Canada de base.
      Vous pouvez (sans obligation) souscrire un autre type d’abonnement au moyen de l’application mobile Electrify
      Canada.
    </p>
  </article>
</template>

<script>
export default {
  name: 'etron-disclosure-fr',
  metaInfo: {
    title: 'Divulgation pour Audi e-tron 2023 et 2024 d’Electrify Canada',
    meta: [
      {
        name: 'description',
        content:
          'Divulgation des modalités de la promotion du forfait de recharge pour véhicules Audi e-tron 2023 et 2024 d’Electrify Canada',
      },
    ],
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/fr/2023-2024-e-tron-charging-plan-disclosure/',
      },
    ],
  },
};
</script>
