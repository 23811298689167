<template>
  <article class="faq-column">
    <h1 id="glossary">Glossary</h1>
    <accordion header-id="glossary">
      <accordion-item title="AC Charging">
        <p>
          Alternating current (AC) charging is typically how people charge their electric vehicles overnight. AC
          charging uses a lower voltage, either Level 1 (120 volts or normal household current) or Level 2 (240 volts or
          the equivalent power of an electric dryer). Though the low voltage levels mean a slower charge, AC charging
          can be easily installed in most households. It’s a great solution for residential, workplace, multi-unit
          dwellings, and other longer-term parking locations like hotels and municipal or airport parking garages.
        </p>
      </accordion-item>
      <accordion-item title="Balanced Chargers">
        <p>
          At Electrify Canada stations, the word “Balanced” may appear on a charger’s label or on the charger’s screen.
          Balanced chargers are typically located side-by-side and share a power cabinet, which supplies up to 350 kW
          across both chargers.
        </p>

        <p>
          When a charger is Balanced, it can supply Ultra-Fast speeds up to 150 kW for vehicles capable of accepting
          this much power and can provide Hyper-Fast speeds up to 350 kW to capable vehicles when the adjacent charger
          is unused.
        </p>
      </accordion-item>
      <accordion-item title="BEV (Battery Electric Vehicle)">
        <p>
          A battery electric vehicle (BEV) is a type of electric vehicle. BEVs store energy within the batteries inside
          the vehicle. A BEV does not have an internal combustion engine, but relies solely on an electric battery
          system for energy. It must be plugged into a charging source to replenish its battery. As of 2018, many BEVs
          can operate between 100 and 300 miles on a single charge.
        </p>
      </accordion-item>
      <accordion-item title="CCS (Combined Charging System)">
        <p>
          CCS is a direct current (DC) fast charging protocol that is Society of Automotive Engineers (SAE) certified,
          and is featured on vehicles produced by European and American car companies. The “combined” term in the CCS
          name designates its capability to incorporate the Level 2 (J1772™ standard) plug and DC fast charging
          connector into the same larger plug. The CCS plug nozzle is also commonly referred to as a “Combo plug.”
          You’ll have to find out from your car’s manufacturer whether your vehicle is compatible with a CCS or CHAdeMO
          plug. All Electrify Canada stations offer both CCS and CHAdeMO connectors.
        </p>
      </accordion-item>
      <accordion-item title="CHAdeMO">
        <p>
          CHAdeMO is a DC fast charging protocol that was first developed for the Japanese EV market; in the US, it is
          currently capable of charging vehicles like the Nissan Leaf and Mitsubishi iMiEV. The CHAdeMO protocol is
          officially recognized as an international DC charging standard by the International Electrotechnical
          Commission (IEC) alongside CCS plugs for US and Europe, and the Chinese GB/T plug. All Electrify America
          stations offer both CHAdeMO and CCS chargers.
        </p>
      </accordion-item>
      <accordion-item title="Charging an EV">
        <p>
          “Refueling” an electric vehicle battery with electricity. The time a battery takes to charge depends on the
          size of the battery in kWh and the amount of electric current being supplied.
        </p>
      </accordion-item>
      <accordion-item title="Charger ID">
        <p>
          Each Electrify Canada charger is identified by a Charger ID, which can be found on the charger, the charger’s
          screen, and in the Electrify Canada app.
        </p>
        <img
          src="@/assets/images/Glossary/chargerId.svg?external"
          alt="charger ID is shown above the hmi screen and in the app on the left side of the charger details"
          class="charger-id"
        />
      </accordion-item>
      <accordion-item title="DC (Direct Current)">
        <p>
          Direct current is an electric current of constant direction. Electrify Canada fast charging stations all
          support DC.
        </p>
      </accordion-item>
      <accordion-item title="DC Fast Charging">
        <p>
          Direct current (DC) charging for electric vehicles allows for higher charging speeds, since DC can be supplied
          directly to the electric vehicle’s battery at power levels normally higher than AC charging. The higher the DC
          power supplied, the faster the electric vehicle can be charged—provided the EV is designed to handle such
          power. Charging will slow down toward the end of your session in order to preserve your vehicle’s battery.
          This typically happens around 80% full, but is dependent on the model of your EV.
        </p>
        <p>
          To illustrate the charging power difference between AC and DC fast charging, a Level 2, 7.2 kW AC charger can
          take one hour to deliver about 27 miles of EV range. A 50 kW DC fast charger can deliver the same 27 miles of
          range in about 10 minutes.
        </p>
      </accordion-item>
      <accordion-item title="Digital Membership Pass">
        <p>
          A virtual “card” on your smartphone that uses NFC (your phone’s near-field communication feature) to
          communicate your account information to the charger, allowing you to use funds from your Electrify Canada
          account balance.
        </p>
        <p>
          This means you can pay for a charge by simply holding your phone near the charger’s RFID reader (not the
          credit card reader).
        </p>
      </accordion-item>
      <accordion-item title="EV (Electric Vehicle)">
        <p>
          An electric vehicle uses electric motors and motor controllers to power the vehicle instead of propulsion via
          an internal combustion engine. EVs store electricity in a battery that powers the vehicle’s wheels through an
          electric motor. Different types of EVs include the battery electric vehicle (BEV), the plug-in hybrid electric
          vehicle (PHEV), the hybrid electric vehicle (HEV), and the fuel cell electric vehicle (FCEV).
        </p>
      </accordion-item>
      <accordion-item title="Hyper-Fast">
        <p>
          Hyper-Fast is a descriptive term that indicates CCS connectors delivering up to 350 kW. Hyper-Fast CCS
          connectors are identified by green labels on Electrify Canada chargers.
        </p>
      </accordion-item>
      <accordion-item title="Idle Time">
        <p>
          Electrify Canada’s pricing policy includes a $0.40 per-minute idle fee starting ten minutes after your
          charging session is complete, if you have not unplugged and moved your vehicle. This feature is designed to
          encourage drivers to move their car after they’re done charging to make room for others who need to charge.
        </p>
      </accordion-item>
      <accordion-item title="J1772™">
        <p>
          One of the most common connectors is the Level 2 J1772™. Society of Automotive Engineers (SAE) standard
          adopted in North America and Japan.
        </p>
      </accordion-item>
      <accordion-item title="kWh (Kilowatt-hour)">
        <p>
          A unit of energy equivalent to the energy transferred or expended in one hour by one kilowatt of power.
          Electric car battery size is measured in kilowatt-hours, so think of it as the electric car’s equivalent of
          the size of an internal combustion vehicle’s gas tank.
        </p>
      </accordion-item>
      <accordion-item title="OCPP">
        <p>
          OCPP is the standard developed to provide powerful, open, and interoperable communication between the
          different electric vehicle charging infrastructure companies, hardware, and networks. The Open Charge Alliance
          (OCA) is the global consortium of public and private EV infrastructure leaders that have come together to
          promote this standard.
        </p>
      </accordion-item>
      <accordion-item title="PHEV (Plug-in Hybrid Electric Vehicle)">
        <p>
          A PHEV is a type of hybrid electric vehicle that combines an internal combustion engine with an electric motor
          and a large battery that can be recharged by plugging into an electrical outlet—or in some cases, an electric
          vehicle charging station. Plug-in hybrids typically can run in at least two modes: “all-electric,” where the
          motor and battery provide all the car’s energy, and “hybrid,” where both electricity and gasoline are used.
        </p>
      </accordion-item>
      <accordion-item title="Plug&Charge">
        <p>
          This technology features the IEC/ISO 15118 standard to make paying for a charge easier. Following setting up
          an Electrify Canada account, and inputting a payment method, the charger can recognize capable vehicles and
          will automatically complete the charge’s payment. For capable vehicles, there’s no need for cards or apps at
          the station; simply plug in and charge. This innovative system is now available on all Electrify Canada
          chargers across Canada and can be used with Plug&Charge-capable EVs that Electrify Canada is working with,
          including the Porsche Taycan.
        </p>
      </accordion-item>
      <accordion-item title="SOC (State of Charge)">
        <p>
          SOC is the equivalent of a fuel gauge for the battery pack in an EV. The units of SOC are percentage points,
          with 0% meaning no charge left and 100% meaning fully charged. If your car makes the information available,
          our charging stations will tell you the SOC of your vehicle as you charge.
        </p>
      </accordion-item>
      <accordion-item title="Station ID">
        <p>
          Each Electrify Canada station is identified by a unique Station ID. This can be found on the charger, the
          charger’s screen, and in the Electrify Canada app.
        </p>
        <img
          loading="lazy"
          :src="require('@/assets/images/Glossary/stationId.png')"
          alt="station ID is shown below and to the left of the hmi screen and in the app on the left side of the charger details under the charger ID"
          class="station-id"
        />
      </accordion-item>
      <accordion-item title="Ultra-Fast">
        <p>
          Ultra-Fast is a descriptive term that indicates CCS connectors delivering up to 150 kW. Ultra-Fast CCS
          connectors are identified by teal labels on Electrify Canada chargers.
        </p>
      </accordion-item>
    </accordion>
  </article>
</template>

<script>
import Accordion from '../../components/Accordion.vue';
import AccordionItem from '../../components/AccordionItem.vue';

export default {
  name: 'glossary',
  metaInfo: {
    title: 'EV charging glossary',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/glossary/',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/glossary/',
        hreflang: 'en-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/fr/glossary',
        hreflang: 'fr-ca',
      },
      {
        rel: 'alternate',
        href: 'https://www.electrify-canada.ca/glossary/',
        hreflang: 'x-default',
      },
    ],
    meta: [
      {
        name: 'description',
        content:
          'Read our glossary to learn important terms relating to charging your electric vehicle (EV) on our Electrify Canada public charging network.',
      },
    ],
  },

  components: { Accordion, AccordionItem },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    load() {
      return this.$route.query.load;
    },
  },

  mounted() {
    if (this.load) {
      const item = document.querySelectorAll('[class~=accordion-trigger]');
      const itemID = item[this.load].id.split('-')[0];
      const accordion = document.querySelectorAll('[class~=accordion]');
      const accordionId = accordion[0].id.split('-')[0];

      this.$root.$emit('toggleAccordion', {
        accordionID: accordionId,
        uniqueID: itemID,
      });
      setTimeout(() => {
        const el = document.getElementById(item[this.load].id);
        el.scrollIntoView({ behavior: 'smooth' });
      }, 300);
    }
  },
};
</script>
