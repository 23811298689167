<template>
  <article>
    <Hero heading="Thanks! Now check your email to finish submitting this form." :subheading="true">
      <template #subheading>
        <p>We received your request, and we have sent a verification email to {{ userEmail }}</p>
        <br />
        <p>
          Upon receiving the email, you will have 48 hours to complete your registration by clicking on the link within
          the mail. That way, we’ll keep you informed of all our updates.
        </p>
        <br />
        <p>Have an urgent request? Call us directly 24 hours a day, 7 days a week at 1-833-231-3226.</p>
      </template>
    </Hero>
  </article>
</template>

<script>
import Hero from '@/components/Hero/Hero.vue';

export default {
  name: 'verify-email-signup-en',
  metaInfo: {
    title: 'Verify your email',
    meta: [{ name: 'robots', content: 'noindex' }],
  },
  components: { Hero },
  computed: {
    userEmail() {
      return this.$store.state.email;
    },
  },
};
</script>
