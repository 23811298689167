<template>
  <div class="disclosure">
    <h1>Le manuel d'installation et d'utilisation de la HomeStation&trade;</h1>
    <p>
      Le manuel d'installation et d'utilisation de la HomeStation&trade;, avec les instructions d'utilisation, peut être
      trouvé
      <a
        href="/pdf/Homestation/HomeStation-Installation-and-Operation-Manual EN_FR_2.0.1_091721.pdf/"
        class="link"
        target="_blank"
        rel="noopener"
        aria-label="Le manuel d'installation et d'utilisation de la HomeStation opens in a new window"
        >ici.</a
      >
    </p>
    <h2>Garantie</h2>
    <h3 class="headline4">Réclamations au titre de la garantie</h3>
    <p>
      Pour commencer le processus de réclamation au titre de la garantie, veuillez composer le
      <a class="link" target="_blank" rel="noopener" href="tel: 1-833-531-3226">(833) 531-3226</a> et un agent du
      service à la clientèle travaillera avec vous pour régler tout problème et pour vous aider à déposer une
      réclamation au titre de la garantie, au besoin.
    </p>
    <p>
      Une fois votre réclamation au titre de la garantie déposée, vous recevrez une étiquette d’expédition de retour et
      des instructions sur la façon d’emballer le chargeur.
    </p>
    <p><strong>Pour en savoir plus, veuillez consulter notre Politique de garantie :</strong></p>
    <a
      class="link"
      target="_blank"
      rel="noopener"
      href="/pdf/Homestation/062023-HomeStation-Limited-Warranty-Canada-French.pdf/"
      >Garantie sur la borne domestique HomeStation&trade; de niveau 2</a
    >
  </div>
</template>

<script>
export default {
  name: 'homestation',
  metaInfo: {
    title: 'HomeStation | Electrify Home',
    meta: [
      {
        name: 'description',
        content: 'Informations sur le produit et la garantie du chargeur Electrify Home Level 2 HomeStation',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrify-canada.ca/fr/homestation/' }],
  },
};
</script>
