const BMW2023BMWDisclosure = () => import('@/views/Disclosures/2023-2025BMWi7Disclosure.vue');
const BMW2023BMWDisclosureFR = () => import('@/views/Disclosures/2023-2025BMWi7Disclosure-fr.vue');
const ETronTerms = () => import('@/views/Disclosures/ETronTerms.vue');
const ETronTermsFR = () => import('@/views/Disclosures/ETronTerms-fr.vue');
const EGolfTerms = () => import('@/views/Disclosures/EGolfTerms.vue');
const EGolfTermsFR = () => import('@/views/Disclosures/EGolfTerms-fr.vue');
const EtronDisclosure = () => import('@/views/Disclosures/Etron-Disclosure.vue');
const EtronDisclosureFR = () => import('@/views/Disclosures/Etron-Disclosure-fr.vue');
const ChargingPlan = () => import('@/views/Disclosures/ChargingPlan.vue');
const ChargingPlanFR = () => import('@/views/Disclosures/ChargingPlan-fr.vue');
const Terms = () => import('@/views/Disclosures/Terms.vue');
const TermsFR = () => import('@/views/Disclosures/Terms-fr.vue');
const VWIDDisclosure = () => import('@/views/Disclosures/2023-2024VWID4Disclosure.vue');
const VWIDDisclosureFR = () => import('@/views/Disclosures/2023-2024VWID4Disclosure-fr.vue');
const VW2025Disclosure = () => import('@/views/Disclosures/2025VWDisclosure.vue');
const VW2025DisclosureFR = () => import('@/views/Disclosures/2025VWDisclosure-fr.vue');

const routes = [
  {
    path: '/bmw-i7-unlimited-plan-disclosure/',
    name: 'bmw-i7-unlimited-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: BMW2023BMWDisclosure,
  },
  {
    path: '/fr/bmw-i7-unlimited-divulgation/',
    name: 'bmw-i7-unlimited-plan-disclosure-fr',
    pathToRegexpOptions: { strict: true },
    component: BMW2023BMWDisclosureFR,
  },

  {
    path: '/2021-2022-vw-id4-charging-plan-disclosure/',
    name: '2021-2022-vw-id4-charging-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: ChargingPlan,
  },
  {
    path: '/fr/2021-2022-vw-id4-charging-plan-disclosure/',
    name: '2021-2022-vw-id4-charging-plan-disclosure-fr',
    pathToRegexpOptions: { strict: true },
    component: ChargingPlanFR,
  },
  {
    path: '/2023-2024-e-tron-charging-plan-disclosure/',
    name: '2023-2024-e-tron-charging-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: EtronDisclosure,
    meta: {
      title: 'e-tron-disclosure',
    },
  },

  {
    path: '/fr/2023-2024-e-tron-charging-plan-disclosure/',
    name: '2023-2024-e-tron-charging-plan-disclosure-fr',
    pathToRegexpOptions: { strict: true },
    component: EtronDisclosureFR,
    meta: {
      title: 'e-tron-disclosure',
    },
  },
  {
    path: '/2020-e-golf-terms/',
    name: '2020-e-golf-terms-en',
    pathToRegexpOptions: { strict: true },
    component: EGolfTerms,
  },
  {
    path: '/fr/2020-e-golf-termes/',
    name: '2020-e-golf-terms-fr',
    pathToRegexpOptions: { strict: true },
    component: EGolfTermsFR,
  },
  {
    path: '/e-tron-charging-plan-terms/',
    name: 'e-tron-charging-plan-terms-en',
    pathToRegexpOptions: { strict: true },
    component: ETronTerms,
  },
  {
    path: '/fr/e-tron-termes/',
    name: 'e-tron-charging-plan-terms-fr',
    pathToRegexpOptions: { strict: true },
    component: ETronTermsFR,
  },
  {
    path: '/terms/',
    name: 'terms-en',
    pathToRegexpOptions: { strict: true },
    component: Terms,
  },
  {
    path: '/fr/mentions-legales/',
    name: 'terms-fr',
    pathToRegexpOptions: { strict: true },
    component: TermsFR,
  },
  {
    path: '/2023-2024-vw-id4-charging-plan-disclosure/',
    name: '2023-2024-vw-id4-charging-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: VWIDDisclosure,
    meta: {
      title: '2023-2024-VW-ID4-Charging-plan-disclosure',
    },
  },
  {
    path: '/fr/2023-2024-vw-id4-divulgation-concernant-le-forfait-de-recharge/',
    name: '2023-2024-vw-id4-charging-plan-disclosure-fr',
    pathToRegexpOptions: { strict: true },
    component: VWIDDisclosureFR,
    meta: {
      title: '2023-2024-vw-id4-divulgation-concernant-le-forfait-de-recharge',
    },
  },
  {
    path: '/2025-vw-charging-plan-disclosure/',
    name: '2025-vw-charging-plan-disclosure-en',
    pathToRegexpOptions: { strict: true },
    component: VW2025Disclosure,
  },
  {
    path: '/fr/2025-vw-charging-plan-disclosure/',
    name: '2025-vw-charging-plan-disclosure-fr',
    pathToRegexpOptions: { strict: true },
    component: VW2025DisclosureFR,
  },
];
export default routes;
