export default {
  asOfDate: 'À compter du 14 décembre 2021.',
  autoReloadDisclaimer:
    "Pour que vous puissiez recharger votre véhicule en tout temps, le solde de votre compte Electrify Canada est automatiquement rechargé lorsqu'il descend sous le seuil de 5 $. Le montant de la recharge automatique que vous aurez choisi dans l'application vous sera facturé la première fois que vous rechargerez votre batterie à une station Electrify Canada. Le montant facturé sera conservé dans votre compte pour couvrir les coûts non inclus dans ce forfait, comme les frais d'occupation et les taxes applicables.",
  canadaEgolfExpansion: "Expansion d'electrify canada la carte de la couverture de reseau est donnee a titre indicatif uniquement",
  charger: 'Borne',
  chargingAtHome: 'La recharge à la maison simplifiée',
  chargingStation: 'Station de recharge',
  chargingStations: 'Stations de recharge Electrify Canada',
  chargingTimesMayVary:
    "Plusieurs facteurs peuvent influencer la durée de chargement notamment la source d'alimentation électrique offerte, le niveau de charge et la température de la batterie.",
  childSleepingWithHeadphones: 'Enfant dormant, des écouteurs sur les oreilles, pendant un trajet en voiture',
  closeUpOfApp: 'Application Electrify Canada sur appareil mobile',
  creditCard: 'Carte de crédit',
  customerAssistance: 'Paiement sans contact d’Electrify Canada',
  diagramShowing:
    'Schéma montrant comment mettre un téléphone intelligent en contact avec le lecteur RFID de la station de recharge.',
  digitalCard: 'Téléphone intelligent affichant une carte numérique',
  downloadInTheAppStore: 'Télécharger dans l’App Store',
  downloadTheApp: 'Téléchargez l’application Electrify Canada',
  electrifyAmerica: 'Electrify America',
  electrifyAmericaLabelOnCharger: 'Étiquette Electrify America sur de borne',
  etronEvPluggedinIn: 'VE e-tron branché sur une borne',
  etronParked: 'Carte des bournes de VE et des stations de recharge prévues au Canada',
  getItOnGooglePlay: 'Télécharger sur Google Play',
  handCloseUp: 'Gros plan d’une main insérant une carte de crédit dans un lecteur de carte',
  handCloseUp2:
    'Gros plan d’une personne insérant une carte de crédit dans un lecteur de carte à une station de recharge Electrify America.',
  homeCharger: 'Borne domestique',
  homeStationAlt: 'Borne de recharge HomeStation™ Electrify Canada',
  manCharging: 'Homme en train de recharger un véhicule électrique',
  map: 'Carte',
  mapOfChargersInCanada: 'Carte des bournes de VE et des stations de recharge prévues au Canada',
  messagingAndDataRates:
    "Les frais standard de messagerie et de données ainsi que les autres frais prélevés par votre fournisseur de services cellulaires s'appliqueront à votre utilisation de l'application Electrify Canada.",
  plugPayCharge: 'Branchez, payez, chargez et partez.',
  plusFees: 'Frais et taxes applicables en sus.',
  porscheAppUser: 'Utilisateur de l’application Porsche Taycan ',
  porscheAtCharger: 'Porsche Taycan à une borne Electrify Canada ',
  powerUpYourLiveWire: 'Alimentez votre LiveWire™ avec Electrify America',
  swipeToStart: 'le curseur « Balayez pour commencer la recharge » de l’application.',
  termsAndConditions: 'Modalités de la promotion',
  twoStations: 'Deux stations de recharge Electrify America',
  twoStationsGlowingGreen: '2 bornes de recharge Electrify Canada au crépuscule',
  vehiclePluggedIn: 'Véhicule électrique branché',
  vinLocation:
    'Le NIV se trouve habituellement sur le tableau de bord du côté du conducteur, près de la partie inférieure du pare-brise ou sous celle-ci.',
  startingACharge: 'Electrify Canada amorçant une recharge',
  womanUsingPhoneToPay: 'Personne utilisant l’application pour commencer une recharge à une station de recharge',
  womenCharging: "Femmes en train d'effectuer une recharge",
  womenPaying: 'Femmes en train de payer',
  pieConeTreeImage: 'Branche de conifère avec cône de pin',
  cleaningTrash: 'Personnes qui ramassent des ordures dans la forêt',
  plantingSeeds: 'deux personnes qui plantent un semis',
  flower: 'illustration de fleurs',
};
