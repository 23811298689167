<template>
  <article class="disclosure">
    <h1>2020 e-Golf Charging Plan</h1>
    <h2>{{ $t('termsAndConditions') }}</h2>
    <strong class="primary-color">Effective as of December 4, 2019</strong>
    <p>
      By clicking “Agree” below, you agree to receive 30 minutes of complimentary charging per session (minimum 60
      minutes between eligible sessions) for your newly purchased 2020 Volkswagen e-Golf electric vehicle on Electrify
      Canada’s network of electric vehicle charging stations, subject to these {{ $t('termsAndConditions') }}. There is
      no additional fee, purchase, or subscription required for your access to this promotion.&nbsp;
    </p>
    <p>
      After signing up, you can redeem this complimentary charging time for your vehicle during your initiation of a
      charging session at any Electrify Canada charging station, exclusively by using the Electrify Canada app “Swipe to
      start” or NFC Pass feature with the appropriate plan selected. &nbsp;The first 30 minutes of the charging session
      will be provided on a complimentary basis. &nbsp;
      <strong
        >Any charging time beyond the first 30 minutes of each session will be charged to your account at the charging
        station’s regular rate</strong
      >, identical to the Electrify Canada “Pass” membership rate, subject to your participation in other discounts or
      subscriptions separate from this promotion (if any).&nbsp;
    </p>

    <strong class="primary-color"
      >PLEASE NOTE THE FOLLOWING IMPORTANT LIMITATIONS ON YOUR USE OF THIS PROMOTION:</strong
    >
    <ul>
      <li>
        You may
        <strong>only</strong> use this complimentary charging time for the eligible Model Year 2020 Volkswagen e-Golf
        electric vehicle, purchased from an authorized Volkswagen dealer in Canada, whose VIN you submitted when signing
        up for this promotion on your Electrify Canada account. &nbsp;
        <strong>No other vehicle is eligible to receive this complimentary charging through your account</strong>,
        whether owned by you or others.
      </li>
      <li>
        To enroll, you will need to provide a valid credit card which will be charged a selected autofill amount at the
        first Electrify Canada charging session, and thereafter any time the wallet balance falls below the threshold
        noted in the app during signup. We recommend selecting a lower autofill amount, such as $10, since the
        complimentary charging provided under this plan will not deduct any fees from this wallet balance.
      </li>
      <li>
        There will be a 10-minute grace period immediately following the end of your charging session, during which you
        will not be assessed any parking or idle fees. &nbsp;Except for this grace period, complimentary charging time
        <strong>does not include</strong> any parking or idle fees assessed at the charging station.
      </li>
      <li>
        You must wait for at least 60 minutes after the end of any complimentary charging session before you can
        initiate another complimentary charging session under this program.
      </li>
      <li>
        You may
        <strong>not</strong> use this complimentary charging time in connection with any taxi, ride-share (e.g., Uber or
        Lyft), or other similar service, or for any other commercial purpose. &nbsp;
        <strong>This promotion is for your personal, non-commercial use only.</strong>
      </li>
      <li>
        You must follow all product, vehicle, safety, and technical documentation included with your vehicle when
        charging at an Electrify Canada charging station.
      </li>
      <li>
        Complimentary charging will be provided under this promotion only for the specific 2020 e-Golf vehicle
        registered to your account.
      </li>
    </ul>

    <p>
      This promotion will automatically end twenty-four (24) months after the date of retail vehicle delivery of your
      2020 Volkswagen e-Golf electric vehicle. &nbsp;For vehicles delivered prior to March 1, 2020, this promotion will
      automatically end no later than February 28, 2022. &nbsp;After these dates you will not be able to access or use
      this promotion on your account. &nbsp;This complimentary charging time is not returnable or redeemable for any
      cash value, and may not be sold, loaned, or otherwise distributed to any third party for any reason.
    </p>

    <p>
      You acknowledge that your use of Electrify Canada’s services is subject to the Electrify Canada Terms of Use
      available at&nbsp;
      <a class="link" href="https://www.electrify-canada.ca/terms">https://www.electrify-canada.ca/terms</a>, and that
      your use of the Electrify Canada mobile app is subject to the Terms of Use accessible by selecting
      <strong>“Legal”</strong> in the Account Management portion of the mobile app (accessed by navigating to the
      <strong>Profile</strong> icon in top right hand corner of map), then selecting
      <strong>“Terms of Use”</strong> &amp; <strong>“Privacy policy.”</strong> &nbsp;Please read these Terms of Use
      documents carefully for information about how you can pay through your account for charging session time outside
      of the complimentary 30 minutes offered under this promotion.
    </p>

    <p>
      You agree to comply with all applicable laws and regulations when using Electrify Canada services, and when
      charging your vehicle at Electrify Canada charging stations.
    </p>

    <p>
      Electrify Canada reserves the right to withhold, revoke, reduce, terminate, or suspend your access to all or any
      portion of this promotion, without notice, if Electrify Canada determines or suspects, in its sole discretion,
      that you: (a) are in violation of the Electrify Canada Terms of Use, these {{ $t('termsAndConditions') }}, or any
      other contract between you and Electrify Canada; (b) have engaged in charging sessions that are excessive,
      fraudulent, or otherwise indicate an unauthorized or unintended use of this complimentary promotion; or (c) have
      engaged in any illegal, fraudulent, tortious, injurious, harmful, or abusive conduct in your use of the Electrify
      Canada services, the Electrify Canada mobile app, or an Electrify Canada charging station.
    </p>

    <p>
      Following the termination or expiration of this promotion for any reason, your access to the complimentary
      promotion will immediately cease, and you may not use or redeem any remaining portion of complimentary charging
      time. &nbsp;At that time, your account will automatically convert to a basic Electrify Canada
      <strong>“Pass”</strong> membership account, at no additional fee or charge to you (neither one-time nor
      recurring). &nbsp;You may, but are not obligated to, sign up for a different type of subscription via the
      Electrify Canada website or mobile app.
    </p>
  </article>
</template>

<script>
import lang from '@/lang';

export default {
  name: 'eGolfTerms',
  metaInfo: {
    title: 'e-Golf Terms',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.electrify-canada.ca/2020-e-golf-terms/',
      },
    ],
  },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
};
</script>
