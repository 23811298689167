<template>
  <article class="eGolf">
    <Hero
      heading="Reprenez la route rapidement grâce à Electrify Canada"
      subheading="Profitez de la recharge rapide CC pour votre Volkswagen e-Golf 2020"
    >
      <template #image> <img src="@/assets/images/eGolf/egolf_hero.jpg" alt="" /></template>
    </Hero>

    <TwoColumnContentBlock heading="Un forfait de recharge exclusif pour les propriétaires d’une e-Golf 2020">
      <p>
        Tous les modèles e-Golf de VW sont entièrement équipés pour profiter des chargeurs rapides CC d’Electrify
        Canada. Ainsi, Electrify Canada peut recharger votre e-Golf à raison de 3 à 4 km d’autonomie par minute.
        <reference-link num="1">{{ $t('chargingTimesMayVary') }}</reference-link>
        Electrify Canada prévoit de passer des 32 stations actuellement prévues à plus de 100 lieux de recharge et 500
        chargeurs d’ici 2026. Grâce à ces 68 stations de recharge supplémentaires prévues, Electrify Canada continuera à
        se concentrer sur l’offre de recharge pour véhicules électriques à des endroits stratégiques dans les
        agglomérations urbaines, le long des grands axes routiers et à proximité des lieux de grande affluence.
      </p>
      <p>
        De plus, les propriétaires de véhicules e-Golf 2020 sont admissibles au forfait de recharge e-Golf 2020
        d’Electrify Canada. Ce forfait de recharge exclusif comprend des sessions de recharge gratuites de 30 minutes
        pendant deux années sur le réseau de recharge haute puissance d’Electrify Canada.
        <reference-link num="2"
          >S’applique uniquement aux propriétaires de VW e-Golf 2020 ayant acheté une nouvelle e-Golf 2020 auprès d’un
          concessionnaire VW canadien autorisé. L’offre s’applique aux véhicules VW e-Golf 2020 achetés au plus tard le
          31 décembre 2020, et est en vigueur jusqu’au 31 décembre 2022. Pour une VW e-Golf 2020 achetée le 1er janvier
          2021 ou après, le programme est en vigueur pendant vingt-quatre (24) mois à compter de la date d’achat. Les 30
          premières minutes de chaque recharge sont gratuites. Au moins soixante (60) minutes doivent s’écouler entre
          les sessions de recharge gratuites. Les taxes et les frais d’occupation ne sont pas compris. Pour en savoir
          plus, consultez la page,
          <a href="https://www.electrify-canada.ca/fr/faqs/"
            >https://www.electrify-canada.ca/fr/faqs/</a
          ></reference-link
        >
        Grâce aux recharges gratuites à la fois rapides et pratiques, vous pourrez profiter de la superbe maniabilité et
        de la performance de votre VW e-Golf en un rien de temps.
      </p>

      <router-link class="button" :to="{ name: 'locate-charger-fr' }">TROUVER UN CHARGEUR</router-link>
    </TwoColumnContentBlock>

    <div class="l-one l-one--has-bg l-one--centered-col l-one--padding-bottom">
      <img
        class="l-one__col--wide l-one__col l-one__col--no-side-padding"
        loading="lazy"
        :alt="$t('canadaEgolfExpansion')"
        src="@/assets/images/eGolf/egolf_expansion_fr.png"
      />
    </div>

    <TwoColumnContentBlock heading="Avec l’application, c’est facile">
      <template #image>
        <img
          loading="lazy"
          alt=""
          data-entity-type="file"
          data-entity-uuid="0a415be2-759d-4aa2-83c1-209e2ed941a2"
          src="@/assets/images/eGolf/egolf2_fr.webp"
        />
      </template>
      <p>
        Grâce à l’application d’Electrify Canada, vous pouvez facilement localiser les chargeurs rapides CC d’Electrify
        Canada et savoir s’ils sont disponibles. Plus important encore, l’application Electrify Canada vous donne accès
        à votre forfait de recharge gratuit.
      </p>
      <p>
        Pour recharger votre e-Golf 2020 à l’aide de votre forfait de recharge gratuit d’Electrify Canada, suivez ces
        étapes :
      </p>
      <ul>
        <li>
          <strong>Étape 1 :</strong> {{ $t('downloadTheApp')
          }}<reference-link :num="3">{{ $t('messagingAndDataRates') }}</reference-link
          >, créez un compte et sélectionnez « Configurer la recharge publique ».
        </li>
        <li>
          <strong>Étape 2 :</strong> Sélectionnez « Sélectionner le forfait d’adhésion », puis « Offres Premium »,
          <ul>
            <li>ou recherchez une offre Premium selon les caractéristiques du véhicule.</li>
            <li>
              Les membres actuels d’Electrify Canada peuvent sélectionner « Offres Premium » sous l’onglet « Compte »,
              puis « J’ai un code d’inscription ».
            </li>
          </ul>
        </li>
        <li>
          <strong>Étape 3 :</strong> Entrez le code d’inscription à 10 chiffres que vous aurez reçu de votre
          concessionnaire Volkswagen, puis sélectionnez le forfait de recharge de la e-Golf 2020. Entrez le
          NIV<reference-link :num="4">{{ $t('vinLocation') }}</reference-link>
          à 17 chiffres de votre véhicule à des fins de vérification.
        </li>
        <li>
          <strong>Étape 4 :</strong> Acceptez la divulgation du forfait, puis touchez « Sélectionner ce forfait ».
        </li>
        <li>
          <strong>Étape 5 :</strong> Entrez les données relatives au paiement<reference-link num="5">{{
            $t('autoReloadDisclaimer')
          }}</reference-link>
          puis configurez la recharge automatique.

          <ul>
            <li>
              Vous devez entrer un mode de paiement<reference-link num="5">{{
                $t('autoReloadDisclaimer')
              }}</reference-link>
              afin de couvrir les frais d’occupation<reference-link num="6"
                >Les utilisateurs qui ne débranchent pas et ne déplacent pas leur véhicule dans les dix minutes qui
                suivent la fin de leur session de recharge se verront imposer des frais d’occupation de 0,40 $ par
                minute. Ces frais visent à inciter les conducteurs à déplacer rapidement leur voiture afin que d’autres
                puissent utiliser le chargeur. Vous pouvez être informé de la fin de votre session de recharge par
                message texte²; vous pouvez également consulter l’écran du chargeur.</reference-link
              >
              ainsi que les taxes applicables.
            </li>
          </ul>
        </li>
      </ul>
      <p>
        Votre inscription au forfait devrait s’effectuer en douceur, tout comme vos déplacements. Toutefois, si vous
        rencontrez des obstacles en cours de route, composez le 1 833 231-3226 pour joindre le service à la clientèle
        d’Electrify Canada, accessible 24 heures par jour, 7 jours sur 7.
      </p>
      <span class="partner-page__cta">
        <router-link class="button partner-page__cta__link" :to="{ name: 'mobile-app-fr' }"
          >TÉLÉCHARGEZ L’APPLICATION</router-link
        >
        <a
          rel="noopener"
          target="_blank"
          class="partner-page__cta__link"
          href="https://apps.apple.com/ca/app/electrify-canada/id1472391910?l=fr"
        >
          <img loading="lazy" :alt="$t('downloadInTheAppStore')" src="@/assets/images/Global/app-store-logo-fr.png" />
        </a>
        <a
          rel="noopener"
          target="_blank"
          class="partner-page__cta__link"
          href="https://play.google.com/store/apps/details?id=com.ec.evowner&hl=fr_CA"
        >
          <img loading="lazy" :alt="$t('getItOnGooglePlay')" src="@/assets/images/Global/google-play-badge-fr.png" />
        </a>
      </span>
    </TwoColumnContentBlock>

    <TwoColumnContentBlock heading="Un nouveau réseau novateur">
      <template #image>
        <img
          loading="lazy"
          alt=""
          data-entity-type="file"
          data-entity-uuid="678381b9-4a2b-43ef-8cf5-18a50ee9afed"
          src="@/assets/images/eGolf/egolf_2.jpg"
        />
      </template>
      <p>
        Faites l’expérience d’une recharge rapide digne de ce nom grâce au réseau de stations de recharge rapide CC
        d’Electrify Canada. Ce réseau pratique et fiable de chargeurs haute puissance est en cours de construction dans
        les agglomérations urbaines à forte densité de circulation et le long des principaux axes routiers du Canada.
        Grâce à leur silhouette haute et élancée, un éclairage de nuit suffisant et des câbles refroidis par liquide
        uniques en leur genre, les chargeurs rapides d’Electrify Canada sont plus faciles que jamais à utiliser.
        Découvrez le nouveau réseau d’Electrify Canada, conçu pour offrir aux Canadiens la vitesse et la fiabilité dont
        ils ont besoin.
      </p>
      <router-link class="button" :to="{ name: 'faqs-fr' }">EN SAVOIR PLUS</router-link>
    </TwoColumnContentBlock>
  </article>
</template>

<script>
import lang from '@/lang';
import ReferenceLink from '@/components/ReferenceLink.vue';
import Hero from '@/components/Hero/Hero.vue';
import TwoColumnContentBlock from '@/components/TwoColumnContentBlock/TwoColumnContentBlock.vue';

export default {
  name: 'eGolf',
  metaInfo: {
    title: 'e-Golf',
    link: [{ rel: 'canonical', href: 'https://www.electrify-canada.ca/fr/e-golf/' }],
  },
  components: { ReferenceLink, TwoColumnContentBlock, Hero },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
};
</script>
