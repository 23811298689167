<template>
  <div>
    <section
      :id="`tabpanel-${selectedTab}`"
      class="tab-content-container"
      role="tabpanel"
      :aria-labelledby="`tab-${selectedTab}`"
      tabindex="0"
    >
      <div v-if="showBanner" id="tab-content-banner">tab-content-banner</div>
      <div v-if="['InTheApp'].includes(selectedTab)" class="mobile-app-banner">
        <div class="mobile-app-icon-container">
          <div class="mobile-app-icon">
            <img
              loading="lazy"
              alt="Electrify Canada Logo"
              src="@/assets/images/ChargingYourEv/InTheApp/elca-logo.webp"
            />
          </div>
          <div v-if="selectedTab == 'InTheApp'" class="mobile-app-text">
            {{ $t('getThe') }}<strong>{{ $t('mobileApp') }}</strong>
          </div>
        </div>
        <div class="app-store-icons">
          <a
            :href="`https://apps.apple.com/ca/app/electrify-canada/id1472391910?l=${$i18n.locale}`"
            rel="noopener"
            target="_blank"
            ><img loading="lazy" :alt="$t('downloadInTheAppStore')" :src="images[$i18n.locale].appStore"
          /></a>
          <a
            :href="`https://play.google.com/store/apps/details?id=com.ec.evowner&hl=${$i18n.locale}_CA`"
            rel="noopener"
            target="_blank"
            ><img loading="lazy" :alt="$t('getItOnGooglePlay')" :src="images[$i18n.locale].googlePlay"
          /></a>
        </div>
      </div>
      <div class="table-of-contents">
        <h2>{{ $t('steps') }}</h2>
        <ol>
          <li v-for="(content, index) in tabsData[selectedTab]" :key="index">
            <a :href="`#${content.text}`" v-text="content.text" />
          </li>
        </ol>
        <router-link id="cta-button" v-if="selectedTab == 'AtTheCharger'" :to="{ name: `contact-us-${$i18n.locale}` }"
          ><PhoneIcon />{{ $t('contactUs') }}</router-link
        >
      </div>
      <div v-if="selectedTab == 'AtTheCharger'" id="AtTheCharger">
        <div class="steps-container">
          <Card num="1" :heading="$t('park')">
            <template v-slot:image>
              <img
                loading="lazy"
                :src="
                  isPhone
                    ? require(`@/assets/images/ChargingYourEv/AtTheCharger/step1-mobile.webp`)
                    : require(`@/assets/images/ChargingYourEv/AtTheCharger/step1.webp`)
                "
                alt=""
            /></template>
            <ul>
              <li>
                <p>
                  {{ $t('atTheCharger.step1.a') }}
                </p>
              </li>
              <li>
                <p>{{ $t('atTheCharger.step1.b') }}</p>
              </li>
            </ul>
          </Card>
          <Card num="2" :heading="$t('checkConnectorType')">
            <template v-slot:image>
              <picture>
                <source
                  media="(min-width: 750px )"
                  :srcset="
                    require(`@/assets/images/ChargingYourEv/AtTheCharger/step2-${$i18n.locale}.webp`) +
                    ' 1x, ' +
                    require(`@/assets/images/ChargingYourEv/AtTheCharger/step2-${$i18n.locale}@2x.webp`) +
                    ' 2x'
                  "
                />
                <source
                  :srcset="
                    require(`@/assets/images/ChargingYourEv/AtTheCharger/step2-mobile-${$i18n.locale}.webp`) +
                    ' 1x, ' +
                    require(`@/assets/images/ChargingYourEv/AtTheCharger/step2-mobile-${$i18n.locale}@2x.webp`) +
                    ' 2x'
                  "
                />
                <img
                  loading="lazy"
                  :src="require(`@/assets/images/ChargingYourEv/AtTheCharger/step2-mobile-${$i18n.locale}.webp`)"
                  alt="Electrify Canada charger display with teal Ultra-Fast up to 150 kW label on left, blue CHAdeMo up to 50 kW label on right, and charger number at the top"
                />
              </picture>
            </template>
            <ul>
              <li>
                <p>
                  {{ $t('atTheCharger.step2.a') }}
                </p>
                <ul style="padding-left: 25px; margin-top: 10px">
                  <li>
                    <p>
                      {{ $t('atTheCharger.step2.b') }}<strong>{{ $t('atTheCharger.step2.c') }}</strong
                      >{{ $t('atTheCharger.step2.d') }} <strong>{{ $t('atTheCharger.step2.e') }}</strong
                      >{{ $t('atTheCharger.step2.f') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>{{ $t('atTheCharger.step2.g') }}</p>
              </li>
            </ul>
            <div class="small-text">
              {{ $t('atTheCharger.step2.h') }}
            </div>
          </Card>
          <Card num="3" :heading="$t('plugAndClick')">
            <template v-slot:image>
              <img
                loading="lazy"
                :src="
                  isPhone
                    ? require(`@/assets/images/ChargingYourEv/AtTheCharger/step3-mobile-en.webp`)
                    : require(`@/assets/images/ChargingYourEv/AtTheCharger/step3-en.webp`)
                "
                alt=""
            /></template>
            <ul>
              <li>
                <p>{{ $t('atTheCharger.step3.a') }}</p>
              </li>
              <li>
                <p>
                  {{ $t('atTheCharger.step3.b') }}
                </p>
              </li>
              <li>
                <p>
                  {{ $t('atTheCharger.step3.c') }}<strong>{{ $t('atTheCharger.step3.d') }}</strong>
                  {{ $t('atTheCharger.step3.e') }}
                </p>
              </li>
            </ul>
          </Card>

          <div :id="$t('pay')" class="step">
            <div class="step__label">
              <span class="step__label__number"> 4 </span>
              <h3>{{ $t('pay') }}</h3>
            </div>
            <div class="step__image-container">
              <picture>
                <source
                  media="(min-width:750px)"
                  :srcset="
                    require(`@/assets/images/ChargingYourEv/AtTheCharger/step4-${$i18n.locale}.webp`) +
                    ' 1x, ' +
                    require(`@/assets/images/ChargingYourEv/AtTheCharger/step4-${$i18n.locale}@2x.webp`) +
                    ' 2x'
                  "
                />
                <source
                  :srcset="
                    require(`@/assets/images/ChargingYourEv/AtTheCharger/step4-mobile-${$i18n.locale}.webp`) +
                    ' 1x, ' +
                    require(`@/assets/images/ChargingYourEv/AtTheCharger/step4-mobile-${$i18n.locale}@2x.webp`) +
                    ' 2x'
                  "
                />
                <img
                  loading="lazy"
                  alt="Guests use the card reader, members can use the contactless reader to pay with phone"
                  :src="require(`@/assets/images/ChargingYourEv/AtTheCharger/step4-mobile-${$i18n.locale}.webp`)"
                />
              </picture>
            </div>
            <div class="step__columns">
              <section>
                <div>
                  <div class="step__columns__image">
                    <picture>
                      <source
                        media="(min-width:750px)"
                        :srcset="
                          require(`@/assets/images/ChargingYourEv/AtTheCharger/step4a-${$i18n.locale}.webp`) +
                          ' 1x, ' +
                          require(`@/assets/images/ChargingYourEv/AtTheCharger/step4a-${$i18n.locale}@2x.webp`) +
                          ' 2x'
                        "
                      />
                      <source
                        :srcset="
                          require(`@/assets/images/ChargingYourEv/AtTheCharger/step4a-mobile-${$i18n.locale}.webp`) +
                          ' 1x, ' +
                          require(`@/assets/images/ChargingYourEv/AtTheCharger/step4a-mobile-${$i18n.locale}@2x.webp`) +
                          ' 2x'
                        "
                      />
                      <img
                        loading="lazy"
                        alt=""
                        :src="require(`@/assets/images/ChargingYourEv/AtTheCharger/step4a-mobile-${$i18n.locale}.webp`)"
                      />
                    </picture>
                  </div>
                  <div class="step__columns__text">
                    <p>
                      <strong>{{ $t('atTheCharger.step4.a') }}</strong>
                    </p>
                    <p>{{ $t('atTheCharger.step4.b') }}</p>
                    <ul>
                      <li>{{ $t('atTheCharger.step4.c') }}</li>
                      <li>{{ $t('atTheCharger.step4.d') }}</li>
                    </ul>
                    <div class="small-text">{{ $t('atTheCharger.step4.e') }}</div>
                  </div>
                </div>
              </section>

              <section>
                <div class="step__columns__image">
                  <picture>
                    <source
                      media="(min-width:750px)"
                      :srcset="
                        require(`@/assets/images/ChargingYourEv/AtTheCharger/step4b-${$i18n.locale}.webp`) +
                        ' 1x, ' +
                        require(`@/assets/images/ChargingYourEv/AtTheCharger/step4b-${$i18n.locale}@2x.webp`) +
                        ' 2x'
                      "
                    />
                    <source
                      :srcset="
                        require(`@/assets/images/ChargingYourEv/AtTheCharger/step4b-mobile-${$i18n.locale}.webp`) +
                        ' 1x, ' +
                        require(`@/assets/images/ChargingYourEv/AtTheCharger/step4b-mobile-${$i18n.locale}@2x.webp`) +
                        ' 2x'
                      "
                    />
                    <img
                      loading="lazy"
                      alt=""
                      :src="require(`@/assets/images/ChargingYourEv/AtTheCharger/step4b-mobile-${$i18n.locale}.webp`)"
                    />
                  </picture>
                </div>
                <div class="step__columns__text">
                  <p>
                    <strong>{{ $t('atTheCharger.step4.f') }}</strong>
                  </p>
                  <p>{{ $t('atTheCharger.step4.g') }}</p>
                  <ul>
                    <li>
                      {{ $t('atTheCharger.step4.h') }}
                    </li>
                    <li>
                      {{ $t('atTheCharger.step4.i') }}
                      <strong>{{ $t('atTheCharger.step4.j') }}</strong>
                      {{ $t('atTheCharger.step4.k') }}
                    </li>
                    <li>
                      {{ $t('atTheCharger.step4.l') }}
                    </li>
                  </ul>
                </div>
                <div class="step__columns__cta">
                  <StarIcon />
                  <p>{{ $t('atTheCharger.step4.m') }}</p>
                </div>
                <div class="step__columns__icons">
                  <a
                    :href="`https://apps.apple.com/ca/app/electrify-canada/id1472391910?l=${$i18n.locale}`"
                    rel="noopener"
                    target="_blank"
                    ><img loading="lazy" :alt="$t('downloadInTheAppStore')" :src="images[$i18n.locale].appStore"
                  /></a>
                  <a
                    :href="`https://play.google.com/store/apps/details?id=com.ec.evowner&hl=${$i18n.locale}_CA`"
                    rel="noopener"
                    target="_blank"
                    ><img loading="lazy" :alt="$t('getItOnGooglePlay')" :src="images[$i18n.locale].googlePlay"
                  /></a>
                </div>
              </section>
            </div>
          </div>
          <Card num="5" :heading="$t('startYourCharge')">
            <template v-slot:image>
              <img
                loading="lazy"
                :src="
                  isPhone
                    ? require(`@/assets/images/ChargingYourEv/AtTheCharger/step5-mobile-${$i18n.locale}.webp`)
                    : require(`@/assets/images/ChargingYourEv/AtTheCharger/step5-${$i18n.locale}.webp`)
                "
                alt="Session details displayed on charger screen including charging time, charging cost, energy delivered, state of charge and the options to view Pricing on the left, Help in the center, and Stop on the right"
            /></template>
            <p class="header">{{ $t('atTheCharger.step5.a') }}</p>
            <ul>
              <li>
                <p>{{ $t('atTheCharger.step5.b') }}</p>
                <ul style="padding-left: 22px">
                  <li>
                    <router-link class="link medium" :to="{ name: `mobile-app-${$i18n.locale}` }">{{
                      $t('downloadTheApp')
                    }}</router-link>
                  </li>
                  <li>{{ $t('atTheCharger.step5.c') }}</li>
                </ul>
              </li>
              <li>
                {{ $t('atTheCharger.step5.d') }}<strong>{{ $t('atTheCharger.step5.e') }}</strong
                >.
              </li>
            </ul>
          </Card>
        </div>
      </div>
      <div v-else-if="selectedTab == 'InTheApp'" id="InTheApp">
        <div class="steps-container">
          <Card num="1" :heading="$t('selectStation')">
            <template v-slot:image>
              <picture>
                <source
                  media="(min-width:750px)"
                  :srcset="
                    require(`@/assets/images/ChargingYourEv/InTheApp/step1-${$i18n.locale}.webp`) +
                    ' 1x, ' +
                    require(`@/assets/images/ChargingYourEv/InTheApp/step1-${$i18n.locale}@2x.webp`) +
                    ' 2x'
                  "
                />
                <source
                  :srcset="
                    require(`@/assets/images/ChargingYourEv/InTheApp/step1-mobile-${$i18n.locale}.webp`) +
                    ' 1x, ' +
                    require(`@/assets/images/ChargingYourEv/InTheApp/step1-mobile-${$i18n.locale}@2x.webp`) +
                    ' 2x'
                  "
                />
                <img
                  loading="lazy"
                  alt=""
                  :src="require(`@/assets/images/ChargingYourEv/InTheApp/step1-mobile-${$i18n.locale}.webp`)"
                />
              </picture>
            </template>
            <ul>
              <li>
                <p>{{ $t('inTheApp.step1') }}</p>
              </li>
            </ul>
          </Card>
          <Card num="2" :heading="$t('selectCharger')">
            <template v-slot:image>
              <picture>
                <source
                  media="(min-width:750px)"
                  :srcset="
                    require(`@/assets/images/ChargingYourEv/InTheApp/step2-${$i18n.locale}.webp`) +
                    ' 1x, ' +
                    require(`@/assets/images/ChargingYourEv/InTheApp/step2-${$i18n.locale}@2x.webp`) +
                    ' 2x'
                  "
                />
                <source
                  :srcset="
                    require(`@/assets/images/ChargingYourEv/InTheApp/step2-mobile-${$i18n.locale}.webp`) +
                    ' 1x, ' +
                    require(`@/assets/images/ChargingYourEv/InTheApp/step2-mobile-${$i18n.locale}@2x.webp`) +
                    ' 2x'
                  "
                />
                <img
                  loading="lazy"
                  alt=""
                  :src="require(`@/assets/images/ChargingYourEv/InTheApp/step2-mobile-${$i18n.locale}.webp`)"
                />
              </picture>
            </template>
            <ul>
              <li>
                <p>
                  {{ $t('inTheApp.step2.a') }}<strong>{{ $t('inTheApp.step2.b') }}</strong
                  >{{ $t('inTheApp.step2.c') }}
                </p>
              </li>
              <li>
                <p>
                  {{ $t('inTheApp.step2.d') }}<strong>{{ $t('inTheApp.step2.e') }}</strong
                  >.
                </p>
              </li>
            </ul>
          </Card>
          <Card num="3" :heading="$t('plugAndClick')">
            <template v-slot:image>
              <picture>
                <source
                  media="(min-width:750px)"
                  :srcset="require(`@/assets/images/ChargingYourEv/InTheApp/step3-en.webp`)"
                />
                <source
                  :srcset="
                    require(`@/assets/images/ChargingYourEv/InTheApp/step3-mobile-en.webp`) +
                    ' 1x, ' +
                    require(`@/assets/images/ChargingYourEv/InTheApp/step3-mobile-en@2x.webp`) +
                    ' 2x'
                  "
                />
                <img
                  loading="lazy"
                  alt=""
                  :src="require(`@/assets/images/ChargingYourEv/InTheApp/step3-mobile-en.webp`)"
                />
              </picture>
            </template>
            <ul>
              <li>
                <p>{{ $t('inTheApp.step3.a') }}</p>
              </li>
              <li>
                <p>
                  {{ $t('inTheApp.step3.b') }}
                </p>
              </li>
              <li>
                <p>
                  {{ $t('inTheApp.step3.c') }}<strong>{{ $t('inTheApp.step3.d') }}</strong
                  >{{ $t('inTheApp.step3.e') }}
                </p>
              </li>
            </ul>
          </Card>
          <Card num="4" :heading="$t('swipeToStartCharge')">
            <template v-slot:image>
              <picture>
                <source
                  media="(min-width:750px)"
                  :srcset="
                    require(`@/assets/images/ChargingYourEv/InTheApp/step4-${$i18n.locale}.webp`) +
                    ' 1x, ' +
                    require(`@/assets/images/ChargingYourEv/InTheApp/step4-${$i18n.locale}@2x.webp`) +
                    ' 2x'
                  "
                />
                <source
                  :srcset="require(`@/assets/images/ChargingYourEv/InTheApp/step4-mobile-${$i18n.locale}.webp`)"
                />
                <img
                  loading="lazy"
                  alt=""
                  :src="require(`@/assets/images/ChargingYourEv/InTheApp/step4-mobile-${$i18n.locale}.webp`)"
                />
              </picture>
            </template>
            <ul>
              <li>
                <p>{{ $t('inTheApp.step4.a') }}</p>
              </li>
              <li>
                <p>{{ $t('inTheApp.step4.b') }}</p>
              </li>
              <li>
                <p>
                  {{ $t('inTheApp.step4.c') }}<strong>{{ $t('inTheApp.step4.d') }}</strong
                  >.
                </p>
              </li>
            </ul>
          </Card>
          <Card num="5" :heading="$t('charge')">
            <template v-slot:image>
              <picture>
                <source
                  media="(min-width:750px)"
                  :srcset="require(`@/assets/images/ChargingYourEv/InTheApp/step5-${$i18n.locale}.svg?external`)"
                />
                <source
                  :srcset="require(`@/assets/images/ChargingYourEv/InTheApp/step5-mobile-${$i18n.locale}.svg?external`)"
                />
                <img
                  loading="lazy"
                  alt="Current session details can be seen in the mobile app such as current charging speed, charging cost, charging time, and energy delivered"
                  :src="require(`@/assets/images/ChargingYourEv/InTheApp/step5-mobile-${$i18n.locale}.svg?external`)"
                />
              </picture>
            </template>
            <p>
              {{ $t('inTheApp.step5.a') }}
            </p>
            <ul>
              <li>
                <p>
                  {{ $t('inTheApp.step5.b') }}<strong>{{ $t('inTheApp.step5.c') }}</strong
                  >{{ $t('inTheApp.step5.d') }}
                </p>
              </li>
            </ul>
          </Card>
        </div>
      </div>
      <div v-else>Select a tab</div>
    </section>
  </div>
</template>

<script>
import lang from '@/lang';
import ChargingYourEv from '@/lang/ChargingYourEv';
import Card from '@/components/ChargingYourEv/ChargingYourEvCard.vue';
import StarIcon from '@/assets/images/Mobile-App/StarIcon.svg';
import PhoneIcon from '@/assets/images/ChargingYourEv/AtTheCharger/phone.svg';
import googlePlayEn from '@/assets/images/Global/google-play-badge-en.png';
import googlePlayFr from '@/assets/images/Global/google-play-badge-fr.png';
import appleEn from '@/assets/images/Global/app-store-logo-en.png';
import appleFr from '@/assets/images/Global/app-store-logo-fr.png';

export default {
  name: 'TabContent',
  props: ['selectedTab', 'showBanner'],
  components: { Card, StarIcon, PhoneIcon },
  i18n: {
    messages: ChargingYourEv,
    sharedMessages: lang,
  },
  data() {
    return {
      images: {
        en: {
          appStore: appleEn,
          googlePlay: googlePlayEn,
        },
        fr: {
          appStore: appleFr,
          googlePlay: googlePlayFr,
        },
      },
    };
  },
  computed: {
    tabsData() {
      return {
        AtTheCharger: [
          { text: this.$i18n.t('park') },
          {
            text: this.$i18n.t('checkConnectorType'),
          },
          {
            text: this.$i18n.t('plugAndClick'),
          },
          { text: this.$i18n.t('pay') },
          { text: this.$i18n.t('startYourCharge') },
        ],
        InTheApp: [
          { text: this.$i18n.t('selectStation') },
          { text: this.$i18n.t('selectCharger') },
          {
            text: this.$i18n.t('plugAndClick'),
          },
          { text: this.$i18n.t('swipeToStartCharge') },
          { text: this.$i18n.t('charge') },
        ],
      };
    },
    isPhone() {
      return this.$resize && this.$mq.below(750);
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-content-container {
  padding: 20px 0;
  padding-bottom: 30px;
  @media (min-width: 750px) {
    padding: 80px 20px;
    margin: 0 auto;
  }
}
.table-of-contents {
  padding: 0 30px;
  max-width: 955px;
  margin: 0 auto;
  @media (min-width: 750px) {
    padding-bottom: 20px;
  }
  h2 {
    font-size: 16px;
    line-height: 1;
    margin: 3px 0 25px;
    font-weight: 600;
    @media (min-width: 750px) {
      font-size: 32px;
      font-weight: 600;
    }
  }
  ol {
    padding: 0 18px;
    margin: 0;
    li {
      padding-bottom: 13px;
      font-size: 16px;
      font-weight: $fw-medium;
      a {
        text-decoration: underline;
        text-underline-offset: 2px;
        text-decoration-thickness: 1px;
      }
    }
  }
}

.steps-container {
  max-width: 1000px;
  margin: 0 auto;

  ul {
    margin-top: 16px;
    margin-bottom: 0;
    padding: 0 40px;
    ::marker {
      font-size: 12px;
    }
    ul {
      list-style-type: disc;
    }
    li {
      margin-bottom: 16px;
      color: black;
      &:last-child {
        margin-bottom: 0;
      }
    }
    p {
      padding: 0;
      margin: 0;
    }
    strong {
      font-weight: $fw-medium;
    }
  }
  p {
    margin-top: 0;
    padding: 0 20px;
    color: #000;
    @media (min-width: 1000px) {
      padding: 0;
    }
  }
  .header {
    color: #000;
    padding-left: 20px;
    @media (min-width: 750px) {
      padding-left: 20px;
    }
  }
  .small-text {
    margin-top: 20px;
    color: black;
    font-size: 14px;
    margin-left: 16px;
  }
  .step {
    margin-bottom: 50px;
    @media (min-width: 750px) {
      display: grid;
      grid-template-columns: 390px 1fr;
      grid-gap: 0 60px;
    }
  }
}

.connector-tips {
  text-decoration: underline;
  color: black;
  font-weight: $fw-medium;
}

#cta-button {
  background-color: #0a124a;
  border: 3px solid #97cce8;
  border-radius: 30px;
  color: white;
  display: inline-flex;
  font-size: 14px;
  font-weight: $fw-regular;
  line-height: 14.05px;
  margin-left: calc(100vw - 200px);
  padding: 14px 24px;
  white-space: nowrap;
  svg {
    margin-right: 10px;
    width: 12.22px;
  }
  @media (min-width: 750px) {
    display: none;
  }
}

#AtTheCharger {
  .card:first-of-type {
    padding-top: 0;
  }
  @media (min-width: 1000px) {
    .card:first-of-type {
      padding-top: 60px;
    }
    .card:nth-of-type(2),
    .card:nth-of-type(3),
    .card:nth-of-type(4) {
      padding-top: 0;
    }
    .card:nth-of-type(4) {
      img {
        padding: 0 20px 0 20px;
      }
    }
  }

  // these styles are for the Pay section under At The Charger
  .step {
    display: flex;
    flex-direction: column;
    p {
      color: #000;
    }
    &__columns {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 24px;
      margin: 0 auto;

      @media (min-width: 1000px) {
        flex-direction: row;
        justify-content: space-between;
        margin-top: 56px;

        section:first-of-type {
          width: 45%;
          display: flex;
          justify-content: flex-end;
          > div {
            width: 78%;
          }
        }
        section:last-of-type {
          width: 45%;
        }
      }
      &__cta {
        display: flex;
        margin: 24px 0;
        padding-left: 20px;
        max-width: 500px;
        @media (min-width: 1000px) {
          padding-left: 0;
        }
        &--fr {
          p {
            padding-left: 0;
          }
        }
        p {
          text-transform: uppercase;
          color: #122771;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0.84px;
          line-height: 1;
          width: 80%;
        }
        svg {
          width: 32px;
          margin-right: 16px;
          position: relative;
          bottom: 6px;
        }
      }
      &__icons {
        margin: 0 auto;
        width: fit-content;
        @media (min-width: 1000px) {
          margin: 0;
          width: auto;
        }
        a:first-of-type {
          margin-right: 16px;
        }
        img {
          width: auto;
          height: 34px;
        }
      }
      &__image {
        margin-bottom: 16px;
        margin-top: 16px;
        display: flex;
        justify-content: center;
        @media (min-width: 1000px) {
          margin-top: 0;
          justify-content: initial;
        }
      }
      &__text {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 20px;
        max-width: 500px;
        margin: 0 auto;
        @media (min-width: 1000px) {
          padding-left: 0;
          max-width: auto;
          margin: 0;
        }

        p {
          width: 100%;
        }
        ul {
          width: 90%;
          padding: 0 40px;

          @media (min-width: 1000px) {
            width: auto;
            padding-left: 20px;
          }
        }
        .small-text {
          margin-top: 20px;
          color: black;
          font-size: 14px;
          margin-left: 0;
          width: 100%;
          @media (min-width: 1000px) {
            margin-left: 16px;
          }
        }
      }
    }
    &__image-container {
      @media (min-width: 750px) {
        justify-self: end;
        align-self: center;
      }
      img {
        width: 100%;
      }
    }
    &__label {
      align-items: flex-start;
      display: flex;
      margin-bottom: 30px;
      padding: 0 20px;
      @media (min-width: 750px) {
        padding: 0;
      }
      h3 {
        color: #0a124a;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0.48px;
        line-height: 1;
        margin: 0;
        @media (min-width: 750px) {
          font-size: 32px;
        }
      }
      &__number {
        align-self: flex-start;
        margin-right: 20px;
        font-size: 16px;
        background-color: #0a124a;
        color: white;
        padding: 4px 12px;
        border-radius: 21px;
        font-weight: 600;
        @media (min-width: 750px) {
          align-self: auto;
        }
      }
    }
  }
}

#InTheApp {
  p {
    padding-left: 20px;
  }
  ul {
    li {
      p {
        padding-left: 0;
      }
    }
  }
}

.mobile-app-banner {
  background-color: whitesmoke;
  margin: 0 auto;
  margin-top: -20px;
  margin-bottom: 20px;
  padding: 25px;
  border-radius: 8px;
  font-weight: $fw-regular;
  max-width: 1200px;
  box-sizing: border-box;
  @media (min-width: 750px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -40px;
    margin-bottom: 50px;
    padding: 25px 100px;
  }
  .mobile-app-icon-container {
    display: flex;
    margin-bottom: 20px;
    @media (min-width: 750px) {
      margin-bottom: 0;
    }
    .mobile-app-icon {
      width: 64px;
      height: 64px;
      @media (min-width: 750px) {
        width: 96px;
        height: 96px;
      }
      img {
        width: 64px;
        height: 64px;
        @media (min-width: 750px) {
          width: 96px;
          height: 96px;
        }
      }
    }
    .mobile-app-text {
      margin: auto 0;
      margin-left: 15px;
      font-size: 16px;
      max-width: 200px;
      strong {
        font-weight: 600;
      }
      @media (min-width: 750px) {
        max-width: 436px;
        margin-left: 55px;
        font-size: 24px;
      }
    }
  }
  .app-store-icons {
    a {
      display: inline-block;
      width: 106px;
      height: 34px;
      &:first-child {
        margin-right: 20px;
      }
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .app-store-icons {
    text-align: center;
    @media (min-width: 750px) {
      display: flex;
      align-items: center;
      max-width: 375px;
    }
  }
}

.trademark {
  max-width: 317px;
  margin: 0 auto;
  display: block;
  color: black;
  font-style: italic;
}
</style>
