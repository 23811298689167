const ChargingYourEv = () => import('@/views/EvCharging/ChargingYourEv.vue');
const Faqs = () => import('@/views/EvCharging/Faqs.vue');
const FaqsFR = () => import('@/views/EvCharging/Faqs-fr.vue');
const GettingStarted = () => import('@/views/EvCharging/GettingStarted.vue');
const GettingStartedFR = () => import('@/views/EvCharging/GettingStarted-fr.vue');
const Glossary = () => import('@/views/EvCharging/Glossary.vue');
const GlossaryFR = () => import('@/views/EvCharging/Glossary-fr.vue');
const MobileApp = () => import('@/views/EvCharging/MobileApp.vue');
const MobileAppFR = () => import('@/views/EvCharging/MobileApp-fr.vue');
const Pricing = () => import('@/views/EvCharging/Pricing.vue');
const PricingFR = () => import('@/views/EvCharging/Pricing-fr.vue');
const WhatToExpect = () => import('@/views/EvCharging/WhatToExpect.vue');
const WhatToExpectFR = () => import('@/views/EvCharging/WhatToExpect-fr.vue');
const Homestation = () => import('@/views/EvCharging/Homestation.vue');
const HomestationFR = () => import('@/views/EvCharging/Homestation-fr.vue');

const routes = [
  {
    path: '/charging-your-ev/',
    name: 'charging-your-ev-en',
    pathToRegexpOptions: { strict: true },
    component: ChargingYourEv,
    props: true,
  },
  {
    path: '/fr/recharger-votre-ve/',
    name: 'charging-your-ev-fr',
    pathToRegexpOptions: { strict: true },
    component: ChargingYourEv,
  },

  {
    path: '/homestation/',
    name: 'homestation-en',
    pathToRegexpOptions: { strict: true },
    component: Homestation,
  },
  {
    path: '/fr/homestation/',
    name: 'homestation-fr',
    pathToRegexpOptions: { strict: true },
    component: HomestationFR,
  },

  {
    path: '/faqs/',
    name: 'faqs-en',
    pathToRegexpOptions: { strict: true },
    component: Faqs,
  },
  {
    path: '/fr/faqs/',
    name: 'faqs-fr',
    pathToRegexpOptions: { strict: true },
    component: FaqsFR,
  },

  {
    path: '/getting-started/',
    name: 'getting-started-en',
    pathToRegexpOptions: { strict: true },
    component: GettingStarted,
    props: true,
  },
  {
    path: '/fr/premiers-pas/',
    name: 'getting-started-fr',
    pathToRegexpOptions: { strict: true },
    component: GettingStartedFR,
  },
  {
    path: '/glossary/',
    name: 'glossary-en',
    pathToRegexpOptions: { strict: true },
    props: true,
    component: Glossary,
  },
  {
    path: '/glossary/:load?',
    name: 'glossary-load-en',
    pathToRegexpOptions: { strict: true },
    props: true,
    component: Glossary,
  },
  {
    path: '/fr/glossary/:load?',
    name: 'glossary-load-fr',
    pathToRegexpOptions: { strict: true },
    props: true,
    component: GlossaryFR,
  },
  {
    path: '/fr/glossary/',
    name: 'glossary-fr',
    pathToRegexpOptions: { strict: true },
    props: true,
    component: GlossaryFR,
  },
  {
    path: '/glossary/:faq',
    pathToRegexpOptions: { strict: true },
    props: true,
    component: Glossary,
  },

  {
    path: '/mobile-app/',
    name: 'mobile-app-en',
    pathToRegexpOptions: { strict: true },
    component: MobileApp,
  },
  {
    path: '/fr/application-mobile/',
    name: 'mobile-app-fr',
    pathToRegexpOptions: { strict: true },
    component: MobileAppFR,
  },

  {
    path: '/pricing/',
    name: 'pricing-en',
    pathToRegexpOptions: { strict: true },
    component: Pricing,
  },
  {
    path: '/fr/tarification/',
    name: 'pricing-fr',
    pathToRegexpOptions: { strict: true },
    component: PricingFR,
  },
  {
    path: '/what-to-expect/',
    name: 'what-to-expect-en',
    pathToRegexpOptions: { strict: true },
    component: WhatToExpect,
    props: true,
  },
  {
    // in the route: à-quoi-s'attendre the à character has to be encoded for the direct route to work
    path: "/fr/%C3%A0-quoi-s'attendre/",
    name: 'what-to-expect-fr',
    pathToRegexpOptions: { strict: true },
    component: WhatToExpectFR,
  },
];

const exportedRoutes = routes;
export default exportedRoutes;
