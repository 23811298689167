<template>
  <footer class="footer" data-swiftype-index="false">
    <h2 class="sr-only">Footer</h2>
    <nav role="navigation" aria-label="Footer Site Links">
      <ul class="footer__top-section">
        <li class="footer__col footer__col--site-information">
          <ul>
            <li class="footer__top-section__logo">
              <router-link :to="{ name: `home-${lang}` }" class="footer__logo" :aria-label="homepageAriaLabel">
                <img :src="logoSrc" alt="" class="footer__logo" />
              </router-link>
            </li>
            <li class="footer__top-section__description" v-if="$slots.description">
              <slot name="description"></slot>
            </li>
            <li class="footer__top-section__social">
              <ul class="footer__row-links footer__row-links--social">
                <h3 class="sr-only">Social Media Links</h3>
                <li v-for="(link, index) of socialMediaLinks" :key="index">
                  <a :href="link.route" rel="noopener" target="_blank">
                    <img loading="lazy" :src="link.src" :alt="link.alt" />
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li class="footer__links-container">
          <h3 class="sr-only">Site Links</h3>
          <details
            class="footer__col footer__links-container-details"
            v-for="(column, index) of footerLinks"
            :key="index"
            :open="!isMobile ? true : null"
            :disabled="!isMobile"
          >
            <summary :tabindex="!isMobile ? -1 : null">
              {{ column.summary }}
            </summary>
            <ul>
              <li v-for="(link, idx) of column.details" :key="idx">
                <router-link
                  v-if="link.route === 'news-updates'"
                  target="_blank"
                  class="external"
                  :to="{ name: `${link.route}-${lang}` }"
                  >{{ link.name }}</router-link
                >
                <a
                  v-else-if="link.route.search(/^http[s]?:\/\//) > -1"
                  :href="link.route"
                  rel="noopener"
                  target="_blank"
                  class="external"
                  >{{ link.name }}</a
                >
                <router-link v-else :to="{ name: `${link.route}-${lang}` }">{{ link.name }}</router-link>
              </li>
            </ul>
          </details>
        </li>
      </ul>
      <ul class="footer__lower-section" v-if="legalLinks">
        <h3 class="sr-only">Site Information</h3>
        <li v-if="legalLinks.levelOne">
          <ul class="footer__bottom-links footer__bottom-links--levelOne">
            <li v-for="(link, index) of legalLinks.levelOne" :key="index">
              <a v-if="link.external" :href="link.linkTo" rel="noopener" target="_blank">{{ link.name }}</a>
              <button v-else-if="link.button" :class="[link.class, 'footer__link-button']">
                {{ link.name }} <img loading="lazy" v-if="link.src" :src="link.src" :alt="link.alt" />
              </button>
              <span v-else-if="link.copyright" v-html="link.name" />
              <p v-else-if="link.support">
                {{ link.name }}:
                <a :href="`tel:${link.route}`" rel="noopener" target="_blank">
                  <strong>{{ link.route }}</strong>
                </a>
              </p>
              <router-link v-else-if="link.hash" :to="{ name: `${link.route}-${lang}`, hash: `${link.hash}` }">{{
                link.name
              }}</router-link>
              <router-link v-else :to="{ name: `${link.route}-${lang}` }">{{ link.name }}</router-link>
            </li>
          </ul>
        </li>
        <li v-if="legalLinks.levelTwo">
          <ul class="footer__bottom-links footer__bottom-links--levelTwo">
            <li v-for="(link, idx) of legalLinks.levelTwo" :key="idx">
              <span v-if="link.copyright" v-html="link.name" />
              <span v-else-if="link.support">
                {{ link.name }}:
                <a class="link" :href="`tel:${link.route}`" rel="noopener" target="_blank">
                  <strong>{{ link.route }}</strong>
                </a>
              </span>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </footer>
</template>
<script>
export default {
  props: {
    socialMediaLinks: { type: Array, required: false },
    footerLinks: { type: Array, required: false },
    legalLinks: { type: Object, required: false },
    homepageAriaLabel: { type: String, required: true },
    logoSrc: { type: String, required: true },
    lang: { type: String, required: true },
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(749);
    },
  },
};
</script>

<style lang="scss" scoped>
$footer_content_max_width: $site_max_width - 128px;
.footer {
  color: $c-primary;

  &__col {
    grid-column-start: auto;
    padding-left: 0;

    li {
      line-height: 1.7;
    }

    &--site-information {
      width: 100%;
      margin-bottom: 32px;

      @media (min-width: 750px) {
        margin-bottom: 0;
        margin-right: 32px;
        max-width: 293px;
        padding-right: 0;
        padding-bottom: 0;
      }

      @media (min-width: 1000px) {
        max-width: 309px;
      }
    }
  }

  &__top-section,
  &__lower-section {
    display: flex;
    ul {
      padding: 0;
      margin: 0;
    }
  }

  &__top-section {
    max-width: $footer_content_max_width;
    margin: 0 auto;
    background-color: $c-primary-background;
    padding: 80px 32px 48px;
    flex-wrap: wrap;

    @media (min-width: 750px) {
      padding: 80px 32px 32px;
      flex-wrap: nowrap;
    }

    @media (min-width: 1025px) {
      padding: 80px 64px 32px;
    }

    &__logo {
      display: flex;
      justify-content: center;
      margin-bottom: 32px;

      @media (min-width: 750px) {
        justify-content: flex-start;
      }

      .footer__logo {
        height: 55px;
        width: auto;
      }
    }

    &__description {
      text-align: center;
      max-width: 375px;
      margin: 0 auto 32px;

      @media (min-width: 750px) {
        text-align: start;
      }

      p {
        margin: 0;
        line-height: 18px;
        font-size: 12px;

        @media (min-width: 750px) {
          line-height: 21px;
          font-size: 14px;
        }
      }
    }
  }

  &__lower-section {
    background-color: $c-secondary-background;
    padding: 32px 16px 64px;
    margin: 0 auto;
    flex-wrap: wrap;

    @media (min-width: 750px) {
      padding: 32px 32px 32px;
    }

    @media (min-width: 1025px) {
      padding: 32px 64px 32px;
    }

    & > li {
      max-width: $footer_content_max_width;
      margin: 0 auto;
      width: 100%;
    }
  }

  &__links-container {
    gap: 2em 1.5em;
    display: flex;
    margin-left: auto;
    flex-wrap: wrap;

    @media (min-width: 750px) {
      gap: 4em 2em;
    }

    @media (min-width: 900px) {
      gap: 4em;
    }

    &,
    & > details {
      width: 100%;

      @media (min-width: 750px) {
        width: auto;
      }
    }

    & > details {
      min-width: 100px;
      border-bottom: 1px solid $c-primary;

      @media (min-width: 750px) {
        border-bottom: none;
      }

      summary {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        padding-bottom: 12px;

        @media (min-width: 750px) {
          font-weight: 700;
          font-size: 14px;
          line-height: 21px;
          padding-bottom: 24px;
        }
      }

      &.disabled summary,
      &[disabled] summary {
        &::-webkit-details-marker,
        &::marker {
          display: none;
          content: none;
        }

        @media (min-width: 750px) {
          pointer-events: none;
          user-select: none;
        }
      }

      & summary {
        &::after {
          content: '\2329';
          transform: rotateZ(-90deg) scaleY(0.9) translateY(-30%);
          display: inline-block;
          transform-origin: 80% 50%;
          margin-left: auto;

          @media (min-width: 750px) {
            content: '';
            display: none;
          }
        }
        &::before {
          border: 0;
          clip: rect(0, 0, 0, 0);
          content: '(opens in a new window)';
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
        }
      }

      &[open] summary {
        padding-bottom: 40px;

        @media (min-width: 750px) {
          padding-bottom: 24px;
        }

        &::after {
          content: '\2329';
          transform: rotateZ(90deg) scaleY(0.9) translateY(30%);

          @media (min-width: 750px) {
            content: '';
            display: none;
          }
        }
        &::before {
          border: 0;
          clip: rect(0, 0, 0, 0);
          content: '(opens in a new window)';
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
        }
      }

      & > ul > li {
        margin-bottom: 40px;

        @media (min-width: 750px) {
          margin-bottom: 24px;
        }
      }

      & > ul > li > a {
        display: inline-block;
        width: 100%;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  &__row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    margin-bottom: 46px;
    margin-top: 56px;
    @media (min-width: 1025px) {
      flex-direction: row;
      max-width: 1217px;
      margin-top: 0;
    }
  }
  &__row-links {
    display: flex;

    &--app {
      margin-bottom: 24px;
      :first-child {
        margin-right: 10px;
      }
      img {
        height: 36px;
        width: auto;
      }
    }
    &--social {
      justify-content: center;

      @media (min-width: 750px) {
        justify-content: flex-start;
      }

      & > li:not(:last-child) {
        margin-right: 12.5px;
      }

      img {
        height: auto;
        width: 56.5%;
      }
      a {
        border-radius: 50%;
        background-color: $c-primary;
        width: 29px;
        aspect-ratio: 1 / 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &__link-button {
    background: transparent;
    color: $c-primary;
    border: none;
    font-size: 12px;
    padding: 0;
    font-weight: 300;

    img {
      margin-left: 0.3rem;
    }
  }

  .footer__bottom-links {
    & > li {
      @media (min-width: 750px) {
        display: inline;
      }
    }

    &--levelOne {
      margin-bottom: 32px;

      & > li:not(:last-of-type) {
        @media (min-width: 750px) {
          margin-right: 24px;
        }
      }

      a {
        line-height: 18px;
        font-size: 12px;
      }
    }

    &--levelTwo {
      & > li:not(:last-of-type) {
        @media (min-width: 750px) {
          margin-right: 40px;
        }
      }

      span,
      p,
      a {
        line-height: 24px;
        font-size: 14px;
      }
    }
    .footer__link-button {
      font-size: 12px;
    }
  }

  a,
  router-link {
    color: $c-primary;
    margin: 0;
    font-size: 14px;
    letter-spacing: 0.28px;

    &:focus,
    &:hover {
      color: $c-focus;
    }

    &.external::after {
      content: '';
      background-image: url('~@/assets/images/Global/external-link.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 15px;
      height: 15px;
      display: inline-block;
      position: relative;
      margin-left: 0.25em;
    }
    &.external::before {
      border: 0;
      clip: rect(0, 0, 0, 0);
      content: '(opens in a new window)';
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
  }
  dl {
    margin: 0;
  }

  ul,
  li {
    list-style-type: none;
  }
}
</style>
