<template>
  <!-- Station Menu -->
  <div class="station-menu" v-if="isMobile && mapZoom > 10 && !isStationListExpanded">
    <button
      v-if="visibleMarkers.length > 0"
      class="station-menu__button"
      @click="expandStationList()"
      :aria-expanded="isStationListExpanded ? 'true' : 'false'"
      ref="expandStationList"
      :aria-label="$t('expandStationList')"
    >
      <span class="station-menu__list-view"
        ><Carat class="station-menu__icon" ref="expandIcon" />{{ $t('listView') }}</span
      >
    </button>
    <span v-else></span>
    <p class="station-menu__station-count">
      <span v-if="visibleMarkers.length > 0">{{
        visibleMarkers.length > 1 ? `${visibleMarkers.length} stations` : `${visibleMarkers.length} station`
      }}</span>
      <span v-else>{{ $t('noStationsInView') }}</span>
    </p>
  </div>
</template>

<script>
import lang from '@/lang/LocateCharger';
import Carat from '@/assets/images/Map/map-carat.svg';

export default {
  name: 'ListView',
  props: {},
  components: { Carat },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
  methods: {
    expandStationList() {
      this.$root.$emit('expandStationList');
    },
  },
  computed: {
    mapZoom() {
      return this.$store.state.map.mapZoom;
    },
    isMobile() {
      return this.$resize && this.$mq.below(750);
    },
    visibleMarkers() {
      return this.$store.state.map.visibleMarkers;
    },
    isStationListExpanded() {
      return this.$store.state.map.isStationListExpanded;
    },
  },
};
</script>

<style lang="scss" scoped>
// Station menu button
.station-menu {
  align-items: center;
  background-color: #eef0f4;
  bottom: 0px;
  box-sizing: border-box;
  display: flex;
  height: 53px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  padding-left: 14px;
  padding-right: 8.5px;
  transition: bottom 750ms ease;
  width: 100vw;
  z-index: 20;

  &__button {
    border: none;
    color: $c-focus;
    letter-spacing: 0.6px;
    margin: 0;
    text-transform: uppercase;
  }
  &__list-view {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: $fw-medium;
    margin: 0;
  }

  &__station-count {
    background-color: #e2e5ed;
    font-size: 12px;
    text-transform: uppercase;
    color: $c-primary;
    font-weight: $fw-regular;
    padding: 8px;
  }

  &__icon {
    height: auto;
    width: 26px;
    margin-right: 8px;
  }

  &__icon--rotate {
    transform: rotate(180deg) translateX(3px);
  }

  &--close {
    @media (max-width: 750px) {
      transform: translateY(0px);
      transition: 500ms ease transform;
    }
  }
  &--open {
    @media (max-width: 750px) {
      transform: translateY(40px);
      transition: 500ms ease transform;
    }
    @media (min-width: 750px) {
      transition: 750ms ease left;
      left: 400px;
    }
  }
}
</style>
